<div class="change-role-dialog-container">
  <h2
    class="header"
    data-cy="edit-user-role-dialog-title"
    mat-dialog-title
  >{{ 'UserList.ChangeUserRole.Title' | translate }}
  </h2>
</div>

<div class="edit-role-container" mat-dialog-content>

  <div class="display-name"> {{ "UserList.AddUser.User" | translate }}
    : {{ data.user.firstName }} {{ data.user.lastName }} ({{ data.user.userid }})
  </div>

  <div class="role-selector">
    <app-select-user-role
      [defaultRoles]="data.user.serviceRoles"
      [autoLabel]="'Role selection'"
      [autoPlaceholder]="'Select the user role'"
      (selectedRolesChanged)="rolesChanged($event)">
    </app-select-user-role>
  </div>
  <app-dialog-hint data-cy="permission-information" class="remove-user-warning" [isWarning]="false" [message]="(message| translate) ">
  </app-dialog-hint>
</div>

<div mat-dialog-actions [align]="'end'">
  <button
    data-cy="cancel-edit-role-button"
    mat-flat-button
    mat-dialog-close
  >
    {{ "General.Cancel" | translate }}
  </button>
  <button
    class="edit-role-confirm-btn"
    data-cy="submit-edit-role-button"
    mat-flat-button
    (click)="submit()"
    [disabled]="selectedUserRoles.length <= 0"
  >
    {{ confirmText | translate }}
  </button>
</div>

