<div class="dialog-header">
  <h2 mat-dialog-title>{{ "Jobs.Step.Title" | translate }}</h2>
</div>

<div mat-dialog-content class="content">
  <ng-container *ngIf="!loading; else loadingBar">
    <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <!-- Index Column -->
      <ng-container matColumnDef="index">
        <mat-header-cell mat-header-cell *matHeaderCellDef class="table-header"
          >#Index</mat-header-cell
        >
        <mat-cell mat-cell *matCellDef="let element" mat-sort-header>{{
          childJobIndex
        }}</mat-cell>
      </ng-container>

      <!-- Step Column -->
      <ng-container matColumnDef="step">
        <mat-header-cell
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="table-header"
          >Step</mat-header-cell
        >
        <mat-cell mat-cell *matCellDef="let element">
          {{ element.Step }}
        </mat-cell>
      </ng-container>

      <!-- Exit Code Column -->
      <ng-container matColumnDef="exitCode">
        <mat-header-cell
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="table-header"
          >App exit code</mat-header-cell
        >
        <mat-cell mat-cell *matCellDef="let element">
          {{ element.AppExitCode ? element.AppExitCode : "-" }}
        </mat-cell>
      </ng-container>

      <!-- Sil Factor Column -->
      <ng-container matColumnDef="silFactor">
        <mat-header-cell
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="table-header"
          >App SIL factor</mat-header-cell
        >
        <mat-cell mat-cell *matCellDef="let element">
          {{ element.AppSILFactor ? element.AppSILFactor : "-" }}
        </mat-cell>
      </ng-container>

      <!-- Run Time Ratio Column -->
      <ng-container matColumnDef="runTimeRatio">
        <mat-header-cell
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="table-header"
          >App run time ratio</mat-header-cell
        >
        <mat-cell mat-cell *matCellDef="let element"
          >{{ element.AppRunTimeRatio ? element.AppRunTimeRatio : "-" }}
        </mat-cell>
      </ng-container>

      <!-- Sim Exit Code Column -->
      <ng-container matColumnDef="slimExitCode">
        <mat-header-cell
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="table-header"
          >Sim exit code</mat-header-cell
        >
        <mat-cell mat-cell *matCellDef="let element">{{
          element.SimulationExitCode ? element.SimulationExitCode : "-"
        }}</mat-cell>
      </ng-container>

      <!-- Sim Exit Code Description Column -->
      <ng-container matColumnDef="exitCodeDescription">
        <mat-header-cell
          class="table-header"
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
        >Sim Exit Code Description</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">{{
          element.SimulationExitCodeDescription
        }}</mat-cell>
      </ng-container>

      <!-- Export Step Logs Column -->
      <ng-container matColumnDef="export">
        <mat-header-cell
          mat-header-cell
          *matHeaderCellDef
          class="table-header"
        ></mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          <ng-container *ngIf="!isRequestLoading; else requestSpinner">
            <mat-icon
              class="export-btn"
              (click)="handleExportClick(element)"
              matTooltip="{{ 'Jobs.Step.DownloadLogs' | translate }}"
              matTooltipPosition="left"
            >
              file_download
            </mat-icon>
          </ng-container>
        </mat-cell>
      </ng-container>

      <mat-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-row>
      <mat-row
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>

      <!-- Row shown when there is no matching data. -->
      <mat-row class="mat-row" *matNoDataRow>
        <mat-cell mat-row data-cy="jobs-no-data" colspan="8">
          <app-empty-section
            class="empty-container"
            [iconName]="EmptySectionIcon.COFFEE_MAKER"
          >
            {{ "Jobs.EmptyTable" | translate }}
          </app-empty-section>
        </mat-cell>
      </mat-row>
    </mat-table>
  </ng-container>

  <ng-template #loadingBar>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-template>

  <ng-template #requestSpinner>
    <mat-progress-spinner
      mode="indeterminate"
      [diameter]="20"
    ></mat-progress-spinner>
  </ng-template>
</div>

<div mat-dialog-actions [align]="'end'" class="actions">
  <button class="close-btn" mat-flat-button [mat-dialog-close]="true">
    {{ "General.Close" | translate }}
  </button>
</div>
