import { Pipe, PipeTransform } from '@angular/core';
import { VecuDeletionResponse } from '../models/vecu';

@Pipe({
  name: 'checkDeleteStatus',
  standalone: true,
})
export class CheckDeleteStatusPipe implements PipeTransform {
  transform(
    vecuName: string,
    deletionResponse: VecuDeletionResponse[] | null,
  ): number | string {
    const response = deletionResponse?.find(
      (r) => r.displayName === vecuName,
    );
    switch (response?.statusCode) {
      case 207:
        return 'Request accepted';
      default:
        return response?.message || '';
    }
  }
}
