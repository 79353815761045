import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { DialogProps } from 'src/app/shared/components/dialog/models/dialogProps';
import { DialogType } from 'src/app/shared/components/dialog/models/dialogType';
import { CommonModule } from '@angular/common';
import { getParamFromUrl } from 'src/app/shared/utils/getParamFromId';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-delete-project-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './delete-project-dialog.component.html',
  styleUrl: './delete-project-dialog.component.scss'
})
export class DeleteProjectDialogComponent {

  deleteProjectFormGroup!: FormGroup;
  readonly projectId = getParamFromUrl(window.location.pathname, '/project/');
  
  @ViewChild('deletionHint') readonly deletionHint?: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<DeleteProjectDialogComponent>,
    private translate: TranslateService,
  ) {
    this.deleteProjectFormGroup = this.fb.group({
      deleteProjectCheckbox: [false, [Validators.requiredTrue]],
      projectName: ['', [Validators.required, Validators.pattern(this.projectId)]]
    });
  }

  closeDialogEmission(): void {
    this.dialogRef.close(null);
  }

  deleteProjectEmission(): void {
    const dialogConfig: DialogProps = {
      title: this.translate.instant('Project.DeleteProjectDialog.SecondDialog.Title'),
      type: DialogType.CONFIRM,
      message: this.translate.instant('Project.DeleteProjectDialog.SecondDialog.MainText'),
      confirmText: this.translate.instant('Project.DeleteProjectDialog.SecondDialog.ConfirmText'),
      delete: true,
      onConfirm: () => this.dialogRef.close(this.projectId)
    };

    this.dialogService.openDialogRef(
      dialogConfig,
      this.deletionHint
    );
  }
}
