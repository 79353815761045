import { Component, Input } from '@angular/core';
import { JsonPipe, KeyValuePipe } from '@angular/common';
import { INewJob } from '../../models/new-job';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-add-job-overview',
  standalone: true,
  imports: [JsonPipe, KeyValuePipe, MatIconModule],
  templateUrl: './add-job-overview.component.html',
  styleUrl: './add-job-overview.component.scss',
})
export class AddJobOverviewComponent {
  @Input() newJob: INewJob = {} as INewJob;
  @Input() stage!: string | null;
  @Input() repo!: string | null;
  @Input() runId: string = '';
}
