import { Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarService } from '../../core/services/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CopyToClipboardService {
  constructor(
    private clipboard: Clipboard,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
  ) {}

  copyToClipboard(data: string | string[]) {
    const copy = data.toString();
    this.clipboard.copy(copy);
    this.snackbarService.notifyInfo(this.translate.instant('General.Copied'));
  }
}
