import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ChipComponent,
  ChipVariant,
} from 'src/app/shared/components/chip/chip.component';
import { convertToAppTypeLabel } from '../../../../../../../shared/utils/convertToAppTypeLabels';

@Component({
  selector: 'app-application-list-item',
  standalone: true,
  imports: [CommonModule, ChipComponent],
  templateUrl: './application-list-item.component.html',
  styleUrls: ['./application-list-item.component.scss'],
})
export class ApplicationListItemComponent implements OnInit {
  @Input({ required: true }) applicationName = '';
  @Input({ required: true }) applicationType = '';
  @Input({ required: true }) applicationRepo = '';

  ChipVariant = ChipVariant;

  ngOnInit(): void {
    this.applicationType = convertToAppTypeLabel(this.applicationType);
  }
}
