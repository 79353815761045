import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRecord } from '../../../shared/stores/config/models/apiRecord';
import { catchError, lastValueFrom, map } from 'rxjs';
import { VdiConfig, VdiConfigData } from '../models/vdi-config';
import { VdiInstance, VdiInstanceData } from '../models/vdi-instance';
import { OsType } from '../models/os-type';
import { VisualizationType } from '../models/visualization-type';

export interface VdiExtensionParams {
  projectName: string,
  osType: OsType,
  visualizationType?: VisualizationType
}

@Injectable({
  providedIn: 'root',
})
export class VdiRequestService {
  constructor(
    private http: HttpClient,
  ) { }

  putVdiInstance(api: ApiRecord, projectName: string) {
    return lastValueFrom(this.http.put(api.url, {}, {
      params: {
        project_name: projectName,
      }
    }));
  }

  putVdiInstanceExtension(apiRecord: ApiRecord, vdiExtensionParams: VdiExtensionParams) {

    let reqParams = undefined;

    if (!vdiExtensionParams.visualizationType) {
      reqParams = {
        project_name: vdiExtensionParams.projectName,
        ami_type: vdiExtensionParams.osType,
      };
    } else {
      reqParams = {
        project_name: vdiExtensionParams.projectName,
        ami_type: vdiExtensionParams.osType,
        visualization_type: vdiExtensionParams.visualizationType,
      };
    }

    return lastValueFrom(
      this.http.put(apiRecord.url, {}, { params: reqParams })
    );
  }

  deleteVdiInstance(api: ApiRecord) {
    const request = this.http.delete(api.url, {
      responseType: 'text',
      observe: 'response'
    },).pipe(
      catchError((err) => {
        return Promise.reject(err);
      })
    );
    return lastValueFrom(request);
  }

  getVdiInstance(api: ApiRecord): Promise<VdiInstance | undefined> {
    const request = this.http.get<VdiInstanceData>(api.url,
      { observe: 'response' }
    ).pipe(
      map((response) => {
        return response.body
          ? {
            instanceId: response.body.instance_id,
            password: response.body.password,
            username: response.body.username,
            connectionGatewayIp: response.body.connection_gateway_ip,
            sessionId: response.body.session_id,
            authToken: response.body.auth_token,
          }
          : undefined;
      }),
      catchError((err) => {
        return Promise.reject(err);
      }
      )
    );
    return lastValueFrom(request);
  }

  getVdiConfig(api: ApiRecord): Promise<VdiConfig> {
    const request = this.http.get<VdiConfigData>(api.url).pipe(
      map((response: VdiConfigData) => {
        return {
          elasticBeanstalkHostedUrl: response.elasticbeanstalk_hosted_url,
        };
      }),
      catchError((err) => {
        return Promise.reject(err);
      })
    );
    return lastValueFrom(request);
  }

  downloadScript(api: ApiRecord) {
    const request = this.http.get(api.url, { responseType: 'blob' });
    return lastValueFrom(request);
  }
}
