import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogContentService {
    private dialogContentValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private dialogContentValid$ = this.dialogContentValid.asObservable();

    dialogContentValidObservable(): Observable<boolean> {
        return this.dialogContentValid$;
    }

    makeDialogContentValid(isValid: boolean) {
        this.dialogContentValid.next(isValid);
    }
}
