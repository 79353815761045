import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InitialUrlService {

  private initialUrl: BehaviorSubject<string> = new BehaviorSubject('');
  initialUrl$: Observable<string> = this.initialUrl.asObservable();

  constructor(
    private location: Location,
  ) { 
    this.setInitialUrl();
  }

  get initialUrlValue(): string {
    return this.initialUrl.value;
  }

  setInitialUrl(): void {
    this.initialUrl.next(this.location.path());
  }
}
