import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DialogHintComponent } from '../dialog-hint/dialog-hint.component';

@Component({
  selector: 'app-delete-workspace-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogTitle,
    MatButtonModule,
    MatDialogClose,
    MatDialogActions,
    MatIconModule,
    DialogHintComponent,
  ],
  templateUrl: './delete-workspace-dialog.component.html',
  styleUrl: './delete-workspace-dialog.component.scss',
})
export class DeleteWorkspaceDialogComponent {
  readonly title = 'Workspaces.DeleteDialog.Title';
  readonly message = 'Workspaces.DeleteDialog.Message';
  readonly warningText = 'Workspaces.DeleteDialog.Warning';

  constructor(public dialogRef: MatDialogRef<DeleteWorkspaceDialogComponent>) {}

  deleteWorkspaceInstance() {
    this.dialogRef.close(true);
  }
}
