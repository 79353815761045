import { Component, Input } from '@angular/core';
import { ChipComponent, ChipVariant } from '../../../../shared/components/chip/chip.component';
import { MatIconModule } from '@angular/material/icon';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { WorkspaceApi } from '../../workspace-page.component';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-update-version',
    standalone: true,
    templateUrl: './update-version.component.html',
    styleUrl: './update-version.component.scss',
    imports: [
      ChipComponent,
      MatIconModule,
      CommonModule,
      MatTooltipModule
    ]
})
export class UpdateVersionComponent
  extends FeatureComponent<WorkspaceApi>
{
  @Input() currentImageVersion!: string;
  @Input() updatedVersion!: string;
  ChipVariant = ChipVariant;
}
