import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {
  Job,
  Run,
} from '../../../features/simulation-job-monitoring/models/job';
import { MatIconModule } from '@angular/material/icon';
import {
  MatSort,
  MatSortModule,
  Sort,
  SortDirection,
} from '@angular/material/sort';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { CustomFormatDatePipe } from '../../../core/pipes/custom-format-date.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EmptySectionComponent } from 'src/app/shared/components/empty-section/empty-section.component';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EmptySectionIcon } from '../empty-section/models/empty-section';
import { ChipComponent } from '../chip/chip.component';
import { CustomVariantColorPipe } from '../../../core/pipes/custom-variant-color.pipe';

enum JobColumns {
  RUN_ID = 'RunId',
  WORKFLOW_NAME = 'WorkflowName',
  CALLER = 'Caller',
  SUBMISSION_DATE_TIME = 'SubmissionDateTime',
  START_DATE_TIME = 'StartDateTime',
  END_DATE_TIME = 'EndDateTime',
  STATUS = 'Status',
}

interface NavigationConfig {
  navigationCommands?: any[];
  navigationExtras: NavigationExtras;
}

export const compare = (
  a: string | number | boolean | Date,
  b: string | number | boolean | Date,
) => (a < b ? -1 : a > b ? 1 : 0);

@Component({
  selector: 'app-job-table',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatIconModule,
    MatSortModule,
    CustomFormatDatePipe,
    ReactiveFormsModule,
    TranslateModule,
    EmptySectionComponent,
    MatTooltipModule,
    ChipComponent,
    CustomVariantColorPipe,
  ],
  templateUrl: './job-table.component.html',
  styleUrls: ['./job-table.component.scss'],
})
export class JobTableComponent implements OnChanges, OnInit {
  @Input() job: Job | null = {} as Job;
  @Input() tab = 0;

  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  displayedColumns: string[] = [
    'RunID',
    'WorkflowName',
    'Caller',
    'SubmissionDateTime',
    'StartDateTime',
    'EndDateTime',
    'Status',
    'link',
  ];

  EmptySectionIcon = EmptySectionIcon;
  dataSource = new MatTableDataSource<Run>([]);
  pageSizeOptions = [5, 10, 20];
  pageSize = 10;
  searchInput = '';
  sortingStartDirections: Record<JobColumns, SortDirection> = {
    RunId: 'asc',
    WorkflowName: 'asc',
    Caller: 'asc',
    SubmissionDateTime: 'asc',
    StartDateTime: 'asc',
    EndDateTime: 'asc',
    Status: 'asc',
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const textToFilter: string = params['filterText'];
      this.searchInput = textToFilter;
      this.dataSource.filter = textToFilter;
    });
  }

  changeSortingForColumn(targetColumn: JobColumns, newSorting: SortDirection) {
    this.sortingStartDirections = Object.assign(
      {},
      this.sortingStartDirections,
      { [targetColumn]: newSorting },
    );
  }

  applyFilter(event: Event) {
    const filterValue: string = (event.target as HTMLInputElement).value;
    const finalFilteredValue: string = filterValue.trim().toLowerCase();
    this.dataSource.filter = finalFilteredValue;
    const navigationConfig: NavigationConfig = {
      navigationExtras: {
        queryParams: {
          filterText: filterValue !== '' ? this.searchInput : null,
        },
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
      },
    };
    this.setQueryParamsInUrl(navigationConfig);
  }

  sortDataSourceBasedOnCriteria(
    job: Job,
    sortDirection: string,
    columnToBeSorted: string,
  ): Run[] {
    return job.runs.sort((a: Run, b: Run) => {
      const attribute = columnToBeSorted as keyof Run;
      const values =
        sortDirection === 'asc'
          ? { first: a[attribute], second: b[attribute] }
          : { first: b[attribute], second: a[attribute] };
      return compare(values.first, values.second);
    });
  }

  applySorting(sort: Sort) {
    const navigationConfig: NavigationConfig = {
      navigationExtras: {
        queryParams: {
          sort: sort.direction !== '' ? sort.direction : null,
          sortedColumn: sort.active ? sort.active : null,
        },
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
      },
    };
    this.setQueryParamsInUrl(navigationConfig);
  }

  clearInput(event: Event) {
    event.stopPropagation();
    this.dataSource.filter = '';

    this.searchInput = '';

    const navigationConfig: NavigationConfig = {
      navigationExtras: {
        queryParams: {
          filterText: null,
        },
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
      },
    };
    this.setQueryParamsInUrl(navigationConfig);
  }

  convertStringToSortDirection(targetString: string): SortDirection {
    switch (targetString) {
      case 'asc':
        return 'asc';
      case 'desc':
        return 'desc';
      default:
        return '';
    }
  }

  ngOnChanges(): void {
    const job = this.job;
    if (!job) return;
    this.initializeSorting(job);
  }

  initializeSorting(job: Job) {
    this.activatedRoute.queryParams.subscribe((params) => {
      const sortOrder: SortDirection = this.convertStringToSortDirection(
        params['sort'],
      );
      const sortedColumn: string = params['sortedColumn'];

      if (sortOrder === '' || sortedColumn === '') {
        this.changeSortingForColumn(JobColumns.SUBMISSION_DATE_TIME, 'desc');
        this.dataSource.data = this.getSortedRuns(job);
      } else {
        this.changeSortingForColumn(sortedColumn as JobColumns, sortOrder);

        this.dataSource.data = this.sortDataSourceBasedOnCriteria(
          job,
          sortOrder,
          sortedColumn,
        );
      }
    });
  }

  getSortedRuns(job: Job): Run[] {
    return job.runs.sort((a, b) => {
      const dateA = new Date(a.SubmissionDateTime).getTime();
      const dateB = new Date(b.SubmissionDateTime).getTime();

      return dateB - dateA;
    });
  }

  navigateToDetailsPage(jobId: string) {
    const navigationConfig: NavigationConfig = {
      navigationCommands: ['./', jobId],
      navigationExtras: {
        queryParams: { tab: this.tab },
        relativeTo: this.activatedRoute,
      },
    };
    this.setQueryParamsInUrl(navigationConfig);
  }

  setQueryParamsInUrl(navigationConfig: NavigationConfig) {
    this.router.navigate(
      navigationConfig.navigationCommands
        ? navigationConfig.navigationCommands
        : [],
      navigationConfig.navigationExtras,
    );
  }
}
