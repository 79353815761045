import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ProjectDetails } from '../models/project-details';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ProjectDetailsService {
  private http = inject(HttpClient);
  getProjectDetailsById(id: string): Observable<ProjectDetails> {
    const url =
      environment.COGNITO_CONFIG.BASE_DISPATCH_API_URL + `projects/${id}`;
    return this.http
      .get<any>(url)
      .pipe(map((val) => ProjectDetails.Factory(val.project)));
  }
}
