import { PipelineStatus } from './pipeline';
export interface IVersion {
  versionId: string;
  versionReference: string;
  lastArtifactId: string;
  executionStatus: PipelineStatus;
  lastExecutionDate: string;
  repositoryUrl: string;
}

export interface IDataVersion {
  versionId: string;
  versionReference: string;
  idOfLastArtifact: string;
  executionStatus: PipelineStatus | '';
  lastExecutionDate: string;
  repository_url: string;
}

export class Version implements IVersion {
  versionId!: string;
  versionReference!: string;
  lastArtifactId!: string;
  executionStatus!: PipelineStatus;
  lastExecutionDate!: string;
  repositoryUrl!: string;
  public static Factory(data: IDataVersion): Version {
    const version = new Version();
    version.versionId = data.versionId;
    version.versionReference = data.versionReference;
    version.lastArtifactId = data.idOfLastArtifact;
    version.executionStatus = data.executionStatus || PipelineStatus.NOT_FOUND;
    version.lastExecutionDate = data.lastExecutionDate;
    version.repositoryUrl = data.repository_url;
    return version;
  }
}
