import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { Job } from '../models/job';
import { ApiRecord } from '../../../shared/stores/config/models/apiRecord';
import { ApiService } from '../../../core/services/api/api.service';
import { CurrentTab } from '../job-monitoring.component';
import { ITreeNode, TreeNode } from '../models/tree-node';
import { SYSDAQChildJobs, SYSDAQJobDetails } from '../models/jobDetails';
import { generatePath } from '../../../shared/utils/generatePath';
import { HttpParams } from '@angular/common/http';
import { WorkflowConfiguration } from '../models/workflow-configuration';
import { environment } from '../../../../environments/environment';
import { PlaylistDataset } from '../models/playlist-dataset';
import { INewJob } from '../models/new-job';
import { ProjectNameMapping } from 'src/app/shared/utils/projectNameMapping';

@Injectable({
  providedIn: 'root',
})
export class JobMonitoringService {
  constructor(private apiService: ApiService) {}

  getJobs(apiRecord: ApiRecord): Promise<Job> {
    const response = this.apiService
      .request<Job>({ apiRecord })
      .pipe(map((result) => Job.Factory(result)));
    return lastValueFrom(response);
  }

  getJobDetails(apiRecord: ApiRecord, dagRunId: string) {
    return this.apiService
      .request<SYSDAQJobDetails>({
        apiRecord: {
          ...apiRecord,
          url: generatePath(apiRecord.url, { dagRunId }),
        },
      })
      .pipe(map((details) => SYSDAQJobDetails.Factory(details)));
  }

  getAllChildJobs(apiRecord: ApiRecord, dagRunId: string) {
    return this.apiService
      .request<SYSDAQChildJobs>({
        apiRecord: {
          ...apiRecord,
          url: generatePath(apiRecord.url, { dagRunId }),
        },
      })
      .pipe(map((childs) => SYSDAQChildJobs.Factory(childs)));
  }

  getFailedChildJobs(apiRecord: ApiRecord, dagRunId: string) {
    const filterParam = new HttpParams().set('filter', 'Failed');
    return this.apiService
      .request<SYSDAQChildJobs>({
        apiRecord: {
          ...apiRecord,
          url: generatePath(apiRecord.url, {
            dagRunId,
          }),
        },
        params: filterParam,
      })
      .pipe(map((child) => SYSDAQChildJobs.Factory(child)));
  }

  getWorkflows(
    apiRecord: ApiRecord,
    projectType: CurrentTab,
    projectName: string,
  ): Promise<string[]> {
    const name =
      'rad6base' === projectName
        ? 'rad6base'
        : ProjectNameMapping[projectName] || projectName;
    const api = {
      ...apiRecord,
      url: generatePath(apiRecord.url, {
        projectName: name,
      }),
    };
    const response = this.apiService
      .request<Workflow>({ apiRecord: api })
      .pipe(
        map((result) =>
          projectType === CurrentTab.REPORTING ? result.kpi : result.simulation,
        ),
      );
    return lastValueFrom(response);
  }

  getInputDataSets(
    apiRecord: ApiRecord,
    projectName: string,
  ): Promise<TreeNode[]> {
    const name =
      'rad6base' === projectName
        ? 'rad6base'
        : ProjectNameMapping[projectName] || projectName;
    const api = {
      ...apiRecord,
      url: generatePath(apiRecord.url, {
        projectName: name.toUpperCase(),
      }),
    };

    const body = {
      playlist_bucket: `dpr-etl-${environment.AWS_STAGE}-playlist-bucket`,
      project: ProjectNameMapping[name] || name,
    };
    const response = this.apiService
      .request<ITreeNode>({ apiRecord: api, body })
      .pipe(map((result) => TreeNode.Factory(result)));
    return lastValueFrom(response);
  }

  getPlaylist(
    projectName: string,
    stage: string,
    apiRecord: ApiRecord,
  ): Promise<PlaylistDataset[]> {
    const name = ProjectNameMapping[projectName] || projectName;
    const body = {
      playlist_bucket: `dpr-etl-${environment.AWS_STAGE}-playlist-bucket`,
      project: ProjectNameMapping[name] || name,
    };
    const response = this.apiService
      .request<IPlaylist>({
        apiRecord,
        body,
      })
      .pipe(map((res: IPlaylist) => res.datasets))
      .pipe(
        map((result: PlaylistDataset[]) =>
          result.filter((element) =>
            stage ? element.type === stage || element.type === '' : element,
          ),
        ),
      );
    return lastValueFrom(response);
  }

  getParameterTemplate(apiRecord: ApiRecord): Promise<WorkflowConfiguration> {
    const response = this.apiService.request<WorkflowConfiguration>({
      apiRecord,
    });
    return lastValueFrom(response);
  }

  getOutputValue(apiRecord: ApiRecord, projectName: string) {
    const name = ProjectNameMapping[projectName] || projectName;
    const body = { project: ProjectNameMapping[name] || name };
    const response = this.apiService
      .request<any>({
        apiRecord,
        body,
      })
      .pipe(
        map((value) => {
          const property = 'project-simulation-bucket-properties';
          return value.properties[property].bucket;
        }),
      );
    return lastValueFrom(response);
  }

  createJob(
    apiRecord: ApiRecord,
    projectName: string,
    newJob: INewJob,
  ): Promise<{ run_id: string }> {
    const api = {
      ...apiRecord,
      url: generatePath(apiRecord.url, {
        projectName,
        workflow: newJob.workflow,
      }),
    };
    const response = this.apiService.request<{ run_id: string }>({
      apiRecord: api,
      body: newJob,
    });
    return lastValueFrom(response);
  }

  uploadFile(api: ApiRecord, body: { content: string; file_name: string }) {
    const response = this.apiService.request<{
      content: string;
      file_name: string;
    }>({
      apiRecord: api,
      body: body,
    });
    return lastValueFrom(response);
  }
}
export interface Workflow {
  kpi: string[];
  simulation: string[];
}

export interface IPlaylist {
  datasets: PlaylistDataset[];
}
