import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { PopoverService } from 'src/app/core/services/popover/popover.service';
import { Theme, ThemeService } from 'src/app/core/services/theme/theme.service';
import { ProfileComponent } from 'src/app/features/dashboard/components/profile/profile.component';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguagePickerComponent } from '../../components/language-picker/language-picker.component';
import { HelpMenuComponent } from '../../components/help-menu/help-menu.component';
import { TabletModeService } from 'src/app/core/services/tablet-mode/tablet-mode.service';
import { FeatureService } from 'src/app/core/services/config/feature.service';
import { FeatureConfig } from 'src/environments/featureConfig';

export const SvgIcons = [
  'dark-mode-toggle',
  'light-mode-toggle',
  'light-links',
  'dark-links',
  'light-profile',
  'dark-profile',
];

@Component({
  standalone: true,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    LanguagePickerComponent,
    MatTooltipModule,
    TranslateModule,
  ],
})
export class HeaderComponent implements OnInit {
  @ViewChild('profile', { read: ElementRef<MatIconButton> })
  matButtonRef!: ElementRef<MatIconButton>;
  theme$ = this.themeService.getTheme();
  isTooltip = true;
  languageSelection = this.featureService.isFeatureEnabled(
    FeatureConfig.languageSelection,
  );

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private popoverService: PopoverService,
    private themeService: ThemeService,
    private tabletModeService: TabletModeService,
    private featureService: FeatureService,
  ) {
    SvgIcons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/images/${icon}.svg`
        )
      );
    });
  }

  toggleTheme(theme: Theme) {
    const newTheme = theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT;
    this.themeService.setTheme(newTheme);
  }

  openHelpMenu(): void {
    this.popoverService.open<HelpMenuComponent>(
      this.matButtonRef,
      HelpMenuComponent,
      {
        offsetX: 30,
        originX: 'start',
        overlayX: 'end',
        originY: 'bottom',
        overlayY: 'top',
      }
    );
  }

  openLinks(): void { }

  openProfileView(): void {
    this.popoverService.open<ProfileComponent>(
      this.matButtonRef,
      ProfileComponent,
      {
        offsetX: 30,
        originX: 'start',
        overlayX: 'end',
        originY: 'bottom',
        overlayY: 'top',
      }
    );
  }

  ngOnInit(): void {
    this.isTooltip = !this.tabletModeService.isTabletModeActive();
  }
}
