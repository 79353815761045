import {ActionCommand} from '../../../shared/components/cae-card/cae-card.component';

export const actionsRequest = {
    buttonText: 'VDI.Request',
    command: ActionCommand.REQUEST,
    disabled: false,
    icon: ''
};

export const actionsRefresh = {
    buttonText: 'VDI.Refresh',
    command: ActionCommand.REFRESH,
    disabled: false,
    icon: ''
};

export const actionsDelete = {
    buttonText: 'VDI.Delete',
    command: ActionCommand.DELETE,
    disabled: false,
    icon: ''
};

export const actionsStop = {
    buttonText: 'VDI.Stop',
    command: ActionCommand.STOP,
    disabled: false,
    icon: ''
};

export const actionsConfigs = [
    {
        buttonText: 'VDI.Copy', command: ActionCommand.COPY, disabled: false, icon: 'file_copy'
    },
    {
        buttonText: 'VDI.Refresh',
        command: ActionCommand.REFRESH,
        disabled: false,
        icon: ''
    },
];

export const actionSetupGuide = [
    {
        buttonText: 'VDI.Download',
        command: ActionCommand.DOWNLOAD,
        disabled: false,
        icon: ''
    },
];
