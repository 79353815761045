import { Injectable } from '@angular/core';
import {
  Observable,
  interval,
  /*distinctUntilChanged,*/ map,
  startWith,
  switchMap,
} from 'rxjs';

import { Quota } from 'src/app/core/models/interfaces/quota';
import { IQuota } from 'src/app/core/models/interfaces/quotaAPI';

import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';

import { ApiService } from 'src/app/core/services/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class QuotaService {
  constructor(private apiService: ApiService) {}

  readonly interval: number = 60000;

  getQuota(apiRecord: ApiRecord): Observable<Quota> {
    const minute: number = 60;
    const fullPercentage: number = 100;
    return interval(this.interval).pipe(
      startWith(this.apiService.request<IQuota>({ apiRecord })),
      switchMap(() => this.apiService.request<IQuota>({ apiRecord })),
      map((quota) => {
        return {
          isLimited: quota.limitedByTime,
          projectQuotaMinutes: parseFloat(
            (quota.projectQuotaTime / minute).toFixed(1),
          ),
          userQuotaMinutes: parseFloat(
            (quota.consumedVhpcTime / minute).toFixed(1),
          ),
          remainingUserQuotaPercentage:
            fullPercentage -
            parseFloat(
              (
                (quota.consumedVhpcTime / quota.projectQuotaTime) *
                fullPercentage
              ).toFixed(0),
            ),
          isQuotaExceeded: quota.limitedByTime
            ? quota.consumedVhpcTime >= quota.projectQuotaTime
            : false,
        };
      }),
    );
  }
}
