<div class="footer">
    <div class="pagination">{{ currentStepIndex + 1 }}/{{ steps.length }}</div>
    <div class="actions" mat-dialog-actions>
      <button 
        data-cy="playlist-stage-footer-cancel"
        class="cancel"
        mat-flat-button 
        mat-dialog-close>
        {{ "General.Cancel" | translate }}
      </button>
      @if(currentStepIndex === 0) {
        <button
          data-cy="playlist-stage-footer-next-step"
          class="pagination-button"
          (click)="nextStep()"
          [disabled]="!enableNextButton"
          mat-flat-button
        >
        {{ "General.Next" | translate }}
      </button>
      } @else {
        <button
          data-cy="playlist-stage-footer-previous-step"
          class="pagination-button"
          (click)="prevStep()"
          mat-flat-button
        >
          {{ "General.Back" | translate }}
        </button>
      }
      <button
        data-cy="playlist-stage-create"
        class="create-stage-btn"
        mat-flat-button
        type="submit"
        (click)="submitForm()"
        [disabled]="!isValid()"
      >
      @if(stageNumber === StageNumber.STAGE_ONE) {
        {{ "Playlists.CreateStageOne" | translate }}
      } @else {
        {{ "Playlists.CreateStageTwo" | translate }}
      }
      </button>
    </div>
</div>
  