import { Pipe, PipeTransform } from '@angular/core';
import { VhpcRelease } from '../components/device-list/models/vhpcRelease';

@Pipe({
  name: 'filterByRegion',
  standalone: true,
})
export class FilterByRegionPipe implements PipeTransform {
  transform(
    releases: VhpcRelease[],
    region: string | undefined,
  ): VhpcRelease[] {
    if (!region || region === RegionSelectAll) {
      return releases;
    }
    return releases.filter((release) => release.vhpcConfig.region === region);
  }
}

export const RegionSelectAll = 'ALL';
