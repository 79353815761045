export enum FeatureId {
  PROJECT_DASHBOARD_PAGE = 'PROJECT_DASHBOARD_PAGE',
  PROJECT_USER_LIST = 'PROJECT_USER_LIST',
  PROJECT_DASHBOARD_DETAILS_PAGE = 'PROJECT_DASHBOARD_DETAILS_PAGE',
  APPLICATION_DASHBOARD_PAGE = 'APPLICATION_DASHBOARD_PAGE',
  DEPLOYMENTS_LIST_PAGE = 'DEPLOYMENTS_LIST_PAGE',
  DEVICE_LIST_PAGE = 'DEVICE_LIST_PAGE',
  HDK_DEVICE_LIST_PAGE = 'HDK_DEVICE_LIST_PAGE',
  HDK_DEVICE_DETAILS = 'HDK_DEVICE_DETAILS',
  HDK_DEPLOYMENTS_LIST_PAGE = 'HDK_DEPLOYMENTS_LIST_PAGE',
  VECU_LIST_PAGE = 'VECU_LIST_PAGE',
  VECU_GETTING_STARTED_PAGE = 'VECU_GETTING_STARTED_PAGE',
  DEVICE_DETAILS = 'DEVICE_DETAILS',
  DEFINE_DATA_DRIVEN_JOBS_PAGE = 'DEFINE_DATA_DRIVEN_JOBS_PAGE',
  SIMULATION_JOB_MONITORING = 'SIMULATION_JOB_MONITORING',
  SIMULATION_JOB_MONITORING_DETAILS = 'SIMULATION_JOB_MONITORING_DETAILS',
  VIRTUAL_DEVELOPMENT_INFRASTRUCTURE = 'VIRTUAL_DEVELOPMENT_INFRASTRUCTURE',
  WORKSPACE_PAGE = 'WORKSPACE_PAGE',
  YIELD_REPORT_PAGE = 'YIELD_REPORT_PAGE',
  PLAYLIST = 'PLAYLIST',
  PLAYLIST_DETAILS = 'PLAYLIST_DETAILS',
}
