import { Injectable } from '@angular/core';
import { debounceTime, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WindowResizeService {
  private currentSize$: Subject<number> = new Subject<number>();
  private currentHeight$: Subject<number> = new Subject<number>();

  private readonly mobileSize = 768;
  private readonly desktopSize = 1280;

  /**
   * Get the current screen size as Observable
   */
  getSize(): Observable<number> {
    return this.currentSize$.asObservable().pipe(debounceTime(500));
  }

  /**
   * Get the current screen height as Observable
   */
  getHeight(): Observable<number> {
    return this.currentHeight$.asObservable().pipe(debounceTime(500));
  }

  /**
   * Set the current screen size
   * @param size - the new screen size
   */
  setSize(size: number): void {
    this.currentSize$.next(size);
  }

  /**
   * Set the current screen height
   * @param size - the new screen size
   */
  setHeight(size: number): void {
    this.currentHeight$.next(size);
  }

  /**
   * Get the current screen size
   */
  getCurrentSize(): number {
    return window.innerWidth;
  }

  /**
   * True in case the screen has the size of a mobile device
   */
  isMobile(): boolean {
    return window.innerWidth < this.mobileSize;
  }

  /**
   * True in case the screen has the size of a tablet device
   */
  isTablet(): boolean {
    return (
      window.innerWidth >= this.mobileSize &&
      window.innerWidth < this.desktopSize
    );
  }

  /**
   * True in case the screen has the size of a desktop device
   */
  isDesktop(): boolean {
    return window.innerWidth >= this.desktopSize;
  }
}
