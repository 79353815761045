<form [formGroup]="requestVdiFormGroup">
  <h2 mat-dialog-title data-cy="vdi-request-dialog-title">Request VDI</h2>
  <div mat-dialog-content>
    <p>Please choose the following</p>

    <mat-form-field class="caedge-form-field-fill">
      <mat-label>Operating System</mat-label>
      <mat-select [formControl]="osTypeControl" data-cy="vdi-os-select-field">
        <mat-option *ngFor="let option of osType" [value]="option.id">
          {{ option.label }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="osTypeControl.hasError('required')">
        {{ "General.ErrorMessage.Required" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="caedge-form-field-fill">
      <mat-label>Visualization Type</mat-label>
      <mat-select [formControl]="visualizationTypeControl" data-cy="vdi-visualization-select-field">
        <mat-option data-cy="vdi-visualization-option" *ngFor="let option of visualizationType" [value]="option.id">
          {{ option.label }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="visualizationTypeControl.hasError('required')">
        {{ "General.ErrorMessage.Required" | translate }}
      </mat-error>
    </mat-form-field>

  </div>
  <div mat-dialog-actions align="end">
    <button mat-flat-button (click)="closeDialog()">Close</button>
    <button class="request-btn" data-cy="vdi-request-extension-btn" mat-flat-button [disabled]="requestVdiFormGroup.invalid" (click)="requestVdi()">
      Request VDI
    </button>
  </div>
</form>
