export interface UpdateWorkbenchRequestBody {
  workbenchId: string;
  updateType: string;
  scheduleDate: string;
}

export enum UpdateType {
  DEFAULT = 'default',
  SCHEDULE = 'schedule',
  IMMEDIATE = 'immediate',
}
