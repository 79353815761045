export const expiredIdToken =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJUZXN0IiwiaWF0IjoxNjg0NDA3MDA3LCJleHAiOjE2ODQ0MDcwMTIsImF1ZCI6Ind3dy50ZXN0LmNvbSIsInN1YiI6InRlc3RAZXhhbXBsZS5jb20ifQ.LbI8m-sEsGeXRVCmIQoPNS1AwpYGIxcw7jnU0VFanxA';

export const freshIdToken =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJUZXN0IiwiaWF0IjoxNjg0Mzk2MDU1LCJleHAiOjE3NDc0NjgwNTUsImF1ZCI6Ind3dy50ZXN0LmNvbSIsInN1YiI6InRlc3RAZXhhbXBsZS5jb20iLCJ0b2tlbl91c2UiOiJpZCJ9.PJA8wFsJww-az50uwutfSmHEGsMhzVth1Ed5It-J0f0';

export const accessToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJtb2NrLXN1Yi1hdHRyIiwiY29nbml0bzpncm91cHMiOlsiaW9hc2pkaW9hc2pkaW9zYWpkb2lzYSJdLCJpc3MiOiJhaXVzaGRzYWhkdXNoZHVpc2hkcyIsInZlcnNpb24iOjIsImNsaWVudF9pZCI6IjEyODMxODMxMjk4MzEyIiwib3JpZ2luX2p0aSI6InlhczhkMTIzMTIzIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSIsImF1dGhfdGltZSI6MTIzMTMxMjMxMzEzMSwiZXhwIjoxMjEzMTIzMTMxMzEsImlhdCI6MTIxMzEyMzEzMzMzLCJqdGkiOiJhc3VkdWFzOWR1MDlhc2RpOTBhc2RzYSIsInVzZXJuYW1lIjoiVEVTVEVUU1RFU1RFUyJ9.9Z0F6IK6EcvNFZ4kTEEW5ooB7ZOxfifPTfjxMvSEOWg';

export const idToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ1c2VyMTIzQGNvbnRpd2FuLmNvbSIsInN1YiI6IjEyMzQ1Njc4OTAiLCJuYW1lIjoiSm9obiBEb2UiLCJpYXQiOjE1MTYyMzkwMjJ9.ykwqo1ct_AJ1ZDLZx_a8joBoJo_KkO8nSmGdu3hE-eo';

export const idTokenWithDomain =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ1c2VyMTIzIiwic3ViIjoiMTIzNDU2Nzg5MCIsIm5hbWUiOiJKb2huIERvZSIsImlhdCI6MTUxNjIzOTAyMn0.zJ_JkvRmgqo4PCihXaTXrJhCkbWdIY7LZpK_IIM6n_A';
