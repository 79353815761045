import { Injectable } from '@angular/core';
import { FeatureConfig } from '../../../../environments/featureConfig';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  isFeatureEnabled(feature: FeatureConfig): boolean {
    return environment.features.includes(feature);
  }
}
