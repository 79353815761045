import { Injectable } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';

import { ProjectUser } from 'src/app/shared/components/user-list-components/models/user';
import { ProjectUsersState } from 'src/app/shared/stores/project-users/models/project-users-state';

@Injectable({
  providedIn: 'root',
})
export class ProjectUsersService {
  combineProjectUsersData$(
    filteredProjectUserList$: Observable<ProjectUser[]>,
    isLoading: Observable<boolean>,
    hasError: Observable<boolean>,
  ): Observable<ProjectUsersState> {
    return combineLatest([filteredProjectUserList$, isLoading, hasError]).pipe(
      map(([projectUsers, isLoading, hasError]) => {
        return { projectUsers, isLoading, hasError };
      }),
    );
  }
}
