import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  config = (panel: string) => {
    return {
      duration: 5000,
      panelClass: [panel],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    };
  };

  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {}

  notifySuccess(messageOrKey: string): void {
    let message = this.checkTranslationKeyExists(messageOrKey);
    this.snackBar.open(message, '', this.config('success-snackbar'));
  }

  notifyError(messageOrKey: string): void {
    let message = this.checkTranslationKeyExists(messageOrKey);
    this.snackBar.open(message, '', this.config('error-snackbar'));
  }

  notifyInfo(messageOrKey: string): void {
    let message = this.checkTranslationKeyExists(messageOrKey);
    this.snackBar.open(message, '', this.config('info-snackbar'));
  }

  notifyWarn(messageOrKey: string): void {
    let message = this.checkTranslationKeyExists(messageOrKey);
    this.snackBar.open(message, '', this.config('warn-snackbar'));
  }

  private checkTranslationKeyExists(key: string): string {
    let message = this.translate.instant(key);
    if (message === key) {
      // The translation key does not exist, use the original string as the message
      message = key;
    }
    return message;
  }
}
