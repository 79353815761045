import {
  Component,
  OnDestroy,
  OnInit,
  signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { SelectMenuComponent } from 'src/app/shared/components/select-menu/select-menu.component';
import {
  DeviceCreatorKey,
  DeviceSortKey,
  DeviceStatusKey,
  DeviceTypeKey,
} from './utils/selectionKeys';
import { DeviceListService } from './services/device-list/device-list.service';
import { FunctionBarComponent } from 'src/app/shared/components/function-bar/function-bar.component';
import { FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DeviceListItemComponent } from './components/device-list-item/device-list-item.component';
import { DeviceStore } from 'src/app/shared/stores/devices/devices.store';
import { DeviceListApiService } from './services/device-list-api/device-list-api.service';
import { DevicesState } from 'src/app/shared/stores/devices/models/devicesState';
import { ContentWrapperComponent } from 'src/app/shared/components/content-wrapper/content-wrapper.component';
import { DeviceListContentComponent } from './components/device-list-content/device-list-content.component';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { Option } from 'src/app/core/models/interfaces/option';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { HeaderTemplateComponent } from 'src/app/shared/components/header/header-template.component';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  CreateDeviceDialogComponent,
  CreateDeviceRequestBody,
} from './components/create-device-dialog/create-device-dialog.component';
import { DeviceType } from 'src/app/shared/stores/devices/models/deviceType';
import { CreateVhpcDeviceDialogComponent } from './components/create-vhpc-device-dialog/create-vhpc-device-dialog.component';
import { EmptySectionScope } from 'src/app/shared/utils/emptySectionScope';
import { SnackbarService } from '../../core/services/snackbar/snackbar.service';
import { Vhpc } from './models/vhpc';
import { RoutingStore } from '../../shared/stores/config/routing.store';
import { DownloadDevToolsDialogComponent } from './components/download-devtools-dialog/download-devtools-dialog.component';
import { FeatureService } from '../../core/services/config/feature.service';
import { FeatureConfig } from '../../../environments/featureConfig';
import { CaeButtonComponent } from '../../shared/components/cae-button/cae-button.component';
import { DialogService } from '../../core/services/dialog/dialog.service';
import { DeleteMultipleDevicesComponent } from './components/delete-multiple-devices/delete-multiple-devices.component';
import { DialogProps } from '../../shared/components/dialog/models/dialogProps';
import { DialogType } from '../../shared/components/dialog/models/dialogType';
import { SelectedDeviceService } from './services/selected-device/selected-device.service';
import { DeviceDeletionService } from './services/device-deletion/device-deletion.service';
import { QuotaBarComponent } from 'src/app/shared/components/quota-bar/quota-bar.component';
import { Quota } from 'src/app/core/models/interfaces/quota';
import { QuotaService } from './services/user-quota/user-quota.service';
import { cloneDeep } from 'lodash';

export type DeviceListApi =
  | 'getDevDevices'
  | 'devDeviceActionRequest'
  | 'vHPCDeviceActionRequest'
  | 'createRealDevice'
  | 'deleteRealDevice'
  | 'deleteSimulatedDeviceDev'
  | 'getDevConnectionPackage'
  | 'getDevFwUpdates'
  | 'updateFwVersionDev'
  | 'getApplications'
  | 'getAppVersions'
  | 'createDevDeployment'
  | 'downloadDeviceScriptsDev'
  | 'downloadVhpcDeviceScripts'
  | 'createVhpcDevice'
  | 'deleteVhpcDevice'
  | 'getVhpcReleases'
  | 'downloadHwDeviceScript'
  | 'getPresignedUrlForAudio'
  | 'getPresignedUrlForHdkTools'
  | 'bulkDeleteRealDevices'
  | 'bulkDeleteVhpcDevices'
  | 'getGrafanaDashboard'
  | 'getQuota';

@Component({
  standalone: true,
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss'],
  imports: [
    TranslateModule,
    SelectMenuComponent,
    FunctionBarComponent,
    CommonModule,
    DeviceListItemComponent,
    ContentWrapperComponent,
    DeviceListContentComponent,
    HeaderTemplateComponent,
    MatButtonModule,
    CaeButtonComponent,
    DeleteMultipleDevicesComponent,
    QuotaBarComponent,
  ],
})
export class DeviceListComponent
  extends FeatureComponent<DeviceListApi>
  implements OnInit, OnDestroy
{
  @ViewChild('deleteMultipleDevicesComponent')
  deleteMultipleDevicesComponent!: TemplateRef<DeleteMultipleDevicesComponent>;
  defaultQuota: Quota = {
    isLimited: false,
    projectQuotaMinutes: 0,
    userQuotaMinutes: 0,
    remainingUserQuotaPercentage: 0,
    isQuotaExceeded: false,
  };
  quota = cloneDeep(this.defaultQuota);
  devicesData$: Observable<DevicesState>;
  DeviceType = DeviceType;
  checkActiveFiltersSignal = signal<boolean>(false);
  EmptySectionScope = EmptySectionScope;
  featureFlagDownloadDevTools = this.featureService.isFeatureEnabled(
    FeatureConfig.downloadDevTools,
  );
  presignedUrlAvailableSignal = signal<boolean>(false);
  selectedDevices$ = this.selectedDeviceService.devices$;
  config: DialogProps = {} as DialogProps;
  createDeviceApiRecords = {
    toolchain: this.API?.createRealDevice,
    vHPC: this.API?.createVhpcDevice,
  };
  createVhpcDeviceApiRecords = {
    releases: this.API?.getVhpcReleases,
    create: this.API?.createVhpcDevice,
  };
  searchFilterControl: FormControl = new FormControl('');
  searchFilter$: Observable<string> =
    this.searchFilterControl.valueChanges.pipe(startWith(''));
  filteredDevices$ = combineLatest([
    this.deviceStore.devices$,
    this.searchFilter$,
    this.deviceListService.selectedDeviceType$,
    this.deviceListService.selectedDeviceConnectionStatus$,
    this.deviceListService.selectedSortBy$,
    this.deviceListService.selectedDeviceCreator$,
  ]).pipe(map(this.deviceListService.deviceMap));
  deviceCreatorData$ = combineLatest([
    this.deviceListService.DeviceCreatorOptions$,
    this.deviceListService.selectedDeviceCreator$,
  ]).pipe(
    map(
      ([options, selectedOption]): {
        options: Option<DeviceCreatorKey>[];
        selectedOption: Option<DeviceCreatorKey>;
      } => ({ options, selectedOption }),
    ),
  );
  deviceTypeData$ = combineLatest([
    this.deviceListService.deviceTypeOptions$,
    this.deviceListService.selectedDeviceType$,
  ]).pipe(
    map(
      ([options, selectedOption]): {
        options: Option<DeviceTypeKey>[];
        selectedOption: Option<DeviceTypeKey>;
      } => ({ options, selectedOption }),
    ),
  );
  deviceConnectionStatusData$ = combineLatest([
    this.deviceListService.deviceConnectionStatusOptions$,
    this.deviceListService.selectedDeviceConnectionStatus$,
  ]).pipe(
    map(
      ([options, selectedOption]): {
        options: Option<DeviceStatusKey>[];
        selectedOption: Option<DeviceStatusKey>;
      } => ({ options, selectedOption }),
    ),
  );
  sortByData$ = combineLatest([
    this.deviceListService.sortByOptions$,
    this.deviceListService.selectedSortBy$,
  ]).pipe(
    map(
      ([options, selectedOption]): {
        options: Option<DeviceSortKey>[];
        selectedOption: Option<DeviceSortKey>;
      } => ({ options, selectedOption }),
    ),
  );
  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    private deviceListService: DeviceListService,
    private deviceStore: DeviceStore,
    private deviceListApiService: DeviceListApiService,
    private dialog: MatDialog,
    private deviceApiService: DeviceListApiService,
    private snackbarService: SnackbarService,
    private routingStore: RoutingStore,
    private featureService: FeatureService,
    private dialogService: DialogService,
    private selectedDeviceService: SelectedDeviceService,
    private deviceDeletionService: DeviceDeletionService,
    private translate: TranslateService,
    private quotaService: QuotaService,
  ) {
    super();
    this.devicesData$ = this.combineDevicesData$(
      this.filteredDevices$,
      this.deviceStore.isLoading$,
      this.deviceStore.hasError$,
      this.deviceStore.errorStatusCode$,
    );
  }

  ngOnInit(): void {
    this.checkPresignedUrlHdkTools();
    this.fetchDeviceList();
    this.initQuota();
  }

  initQuota() {
    if (!this.API) {
      return;
    }

    this.quotaService
      .getQuota(this.API.getQuota)
      .pipe(
        catchError(() => {
          return of(this.defaultQuota);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (quota: Quota) => {
          this.quota = quota;
        },
      });
  }

  selectDeviceCreatorOption(
    deviceCreatorOption: Option<DeviceCreatorKey>,
  ): void {
    this.deviceListService.selectedDeviceCreator(deviceCreatorOption);
    this.checkActiveFiltersSignal.set(
      this.deviceListService.checkActiveFilters(),
    );
  }

  selectDeviceTypeOption(deviceTypeOption: Option<DeviceTypeKey>): void {
    this.deviceListService.selectedDeviceType(deviceTypeOption);
    this.checkActiveFiltersSignal.set(
      this.deviceListService.checkActiveFilters(),
    );
  }

  selectDeviceConnectionStatusOption(
    deviceConnectionStatus: Option<DeviceStatusKey>,
  ): void {
    this.deviceListService.setSelectedDeviceConnectionStatus(
      deviceConnectionStatus,
    );
    this.checkActiveFiltersSignal.set(
      this.deviceListService.checkActiveFilters(),
    );
  }

  selectSortOption(option: Option<DeviceSortKey>): void {
    this.deviceListService.setSelectedSortBy(option);
    this.checkActiveFiltersSignal.set(
      this.deviceListService.checkActiveFilters(),
    );
  }

  combineDevicesData$(
    devices: Observable<Device[]>,
    isLoading: Observable<boolean>,
    hasError: Observable<boolean>,
    errorStatusCode: Observable<number | undefined>,
  ): Observable<DevicesState> {
    return combineLatest([devices, isLoading, hasError, errorStatusCode]).pipe(
      map(([devices, isLoading, hasError, errorStatusCode]) => {
        return { devices, isLoading, hasError, errorStatusCode };
      }),
    );
  }

  fetchDeviceList() {
    if (this.API) {
      this.deviceListApiService
        .getDevices(this.API.getDevDevices)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe();
    }
  }

  checkPresignedUrlHdkTools() {
    if (this.API) {
      this.deviceListApiService
        .getHdkTools(this.API.getPresignedUrlForHdkTools)
        .pipe(take(1), takeUntil(this.unsubscribe$))
        .subscribe({
          next: (value) => {
            if (value.url.length != 0)
              this.presignedUrlAvailableSignal.set(true);
            else this.presignedUrlAvailableSignal.set(false);
          },
          error: () => {
            this.presignedUrlAvailableSignal.set(false);
          },
        });
    }
  }

  openCreateDeviceDialog(deviceType: DeviceType) {
    let deviceName = '';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    dialogConfig.data = {
      deviceType: deviceType,
      apiRecords: this.createDeviceApiRecords,
    };
    const dialogRef = this.dialog.open(
      CreateDeviceDialogComponent,
      dialogConfig,
    );
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((data: CreateDeviceRequestBody) => {
          if (!data) {
            return of(null);
          }
          deviceName = data.deviceName;
          return this.deviceApiService.createDevice(
            data.deviceName,
            data.api,
            data.tokenAttribute,
          );
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (value) => {
          if (value) {
            this.snackbarService.notifyInfo(
              'DeviceList.CreateDialog.SuccessMessage',
            );
            this.routingStore.navigateToDeviceDetails(deviceName);
          }
        },
        error: () => {
          this.snackbarService.notifyError(
            'DeviceList.CreateDialog.ErrorMessage',
          );
        },
      });
  }

  /**
   * Retrieves the branch name from the dev url of the VHPC API
   * configuration. The branch name of the VHPC url is needed
   * for communicating with the VHPC API.
   *
   * @returns The branch extracted from the devAPIURL.
   */
  getBranchName(): string {
    const devApiUrl = this.API?.getDevDevices.url;

    let branch: string = '';

    if (devApiUrl) {
      const regex = /^https?:\/\/([^\.]+)\./;
      const match = devApiUrl.match(regex);

      if (match && match[1]) {
        branch = match[1];
      }
    }

    return branch;
  }

  openCreateVhpcDeviceDialog() {
    const branchName = this.getBranchName();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '811px';
    dialogConfig.height = '600px';
    dialogConfig.data = {
      apiRecords: this.createVhpcDeviceApiRecords,
    };
    const dialogRef = this.dialog.open(
      CreateVhpcDeviceDialogComponent,
      dialogConfig,
    );
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((vhpc: Vhpc) => {
          if (!vhpc || !this.createVhpcDeviceApiRecords.create) {
            return of(null);
          }
          return this.deviceApiService.createVhpcDevice(
            this.createVhpcDeviceApiRecords.create,
            vhpc,
            branchName,
          );
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (value) => {
          if (value) {
            this.snackbarService.notifyInfo(
              'DeviceList.CreateDialog.SuccessMessage',
            );
          }
        },
        error: () => {
          this.snackbarService.notifyError(
            'DeviceList.CreateDialog.ErrorMessage',
          );
        },
      });
  }

  openGetDevToolsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '500px';
    dialogConfig.height = '300px';
    const dialogRef = this.dialog.open(
      DownloadDevToolsDialogComponent,
      dialogConfig,
    );

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (value) => {
          if (value) {
            if (!this.API) {
              return;
            }
            this.deviceListApiService.downloadHdkTools(
              this.API.getPresignedUrlForHdkTools,
              this.unsubscribe$,
            );
          }
        },
      });
  }

  handleResetFilters() {
    this.deviceListService.clearActiveFilters();
  }

  openBulkDeletionDialog() {
    this.config = {
      disableConfirmButton: false,
      type: DialogType.CUSTOM,
      title: this.translate.instant('DeviceList.DeleteDialog.RequestDeletion'),
      message: this.translate.instant('DeviceList.DeleteDialog.Message'),
      customButtonText: this.translate.instant('General.Delete'),
      width: '500px',
      onCustomButtonClick: () => this.handleDeviceDeletion(dialogRef),
      onCancel: () => this.selectedDeviceService.clearDeleteDeviceResponse(),
    };

    const dialogRef = this.dialogService.openDialogRef(
      this.config,
      this.deleteMultipleDevicesComponent,
    );
  }

  handleDeviceDeletion(dialogRef: MatDialogRef<any>) {
    this.deviceDeletionService.handleDeviceDeletion(
      dialogRef,
      this.config,
      this.API,
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
