export interface BackendDataDistributionOverlapSensorCombinations {
    fr_rl_rr: string[];
    fl_rl_rr: string[];
    rl_rr: string[];
};

export interface AdjustedDistributionOverlapSensorCombinations {
    frRlRr: string[];
    flRlRr: string[];
    rlRr: string[];
};

export class DistributionOverlapSensorCombinations implements AdjustedDistributionOverlapSensorCombinations {
    frRlRr!: string[];
    flRlRr!: string[];
    rlRr!: string[];

    public static Factory(dataDistributionOverlapSensorCombinations: BackendDataDistributionOverlapSensorCombinations | undefined): DistributionOverlapSensorCombinations {
        const newDistributionOverlapSensorCombinations = new DistributionOverlapSensorCombinations();
        if (dataDistributionOverlapSensorCombinations) {
            newDistributionOverlapSensorCombinations.flRlRr = dataDistributionOverlapSensorCombinations.fl_rl_rr;
            newDistributionOverlapSensorCombinations.frRlRr = dataDistributionOverlapSensorCombinations.fr_rl_rr;
            newDistributionOverlapSensorCombinations.rlRr = dataDistributionOverlapSensorCombinations.rl_rr;
        }
        return newDistributionOverlapSensorCombinations;
    }
}
