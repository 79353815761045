import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabletModeService {

  readonly regex: RegExp = /Android|iPhone|iPad/i;

  isTabletModeActive(): boolean {
    const clientDeviceType = window.navigator.userAgent;
    return this.regex.test(clientDeviceType);
  }
}
