import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { AuthUser } from 'src/app/core/models/interfaces/authState';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { AuthStore } from 'src/app/shared/stores/auth/auth.store';

@Component({
  standalone: true,
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  imports: [CommonModule, MatButtonModule, MatIconModule, TranslateModule, MatTooltipModule, MatDividerModule],
})
export class ProfileComponent {
  loggedTenant: string;
  isLogoutButtonEnabled = true;
  profileData$: Observable<AuthUser & { name: string }> =
    this.authStore.user$.pipe(
      map((user) => ({ ...user, name: `${user.firstName} ${user.lastName}` }))
    );
  constructor(private authService: AuthService, private authStore: AuthStore) {
    this.loggedTenant = authStore.state.tenant;
  }

  logout() {
    this.isLogoutButtonEnabled = false;
    this.authService.logoutUser();
  }
}
