import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { Method } from 'src/app/shared/stores/config/models/method';
import { environment } from 'src/environments/environment';
import { generatePath } from 'src/app/shared/utils/generatePath';
import {
    BlueprintDeploymenRequestResponse,
    BlueprintDeployment,
    Blueprint,
    BlueprintType,
    BlueprintProductBody
} from 'src/app/shared/stores/config/models/blueprintDeployments';
import { cloneDeep, remove } from 'lodash';
import { BlueprintDeploymentStore } from 'src/app/shared/stores/blueprint-deployment/blueprint-deployment.store';
import { Contact } from 'src/app/core/components/home/components/add-project-dialog/models/contact';

@Injectable({
    providedIn: 'root',
})
export class DeployBlueprintApiService {
    private readonly BASE_URL = environment.COGNITO_CONFIG.BASE_DISPATCH_API_URL;

    constructor(
        private apiService: ApiService,
        private blueprintDeploymentStore: BlueprintDeploymentStore,
    ) { }

    deployBlueprintProduct(
        projectId: string,
        accountId: string,
        description: string,
        owner: Contact,
        blueprintProduct: BlueprintProductBody,
    ): Observable<BlueprintDeploymenRequestResponse> {
        const postBlueprintDeployment: string = `${this.BASE_URL}projects/:projectId/blueprints`;
        const apiRecord: ApiRecord = {
            url: generatePath(postBlueprintDeployment, { projectId }),
            method: Method.POST,
        };

        let blueprintDeploymentBody = {
            AccountId: accountId,
            Stage: environment.AWS_STAGE,
            Description: description,
            BlueprintProduct: blueprintProduct,
            Owner: owner,
        };

        return this.apiService.request<BlueprintDeploymenRequestResponse>({
            apiRecord,
            body: blueprintDeploymentBody
        });
    }

    filterAndStoreBlueprintDeployments(blueprintDeployments: BlueprintDeployment[]): void {
        const finalListOfBlueprintDeployments: BlueprintDeployment[] = [];
        const listsOfSimilarBlueprintDeployments: Array<BlueprintDeployment[]> = this.getListOfAllDuplicatedBlueprintDeployments(blueprintDeployments);
        const blueprintDeploymentsWithoutDuplicates: BlueprintDeployment[] = this.removeDuplicatesFromBlueprintDeployments(listsOfSimilarBlueprintDeployments, blueprintDeployments);
        const blueprintDeploymentsFromDuplicates: BlueprintDeployment[] = this.getUniqueBlueprintDeploymentsFromDuplicates(listsOfSimilarBlueprintDeployments);
        finalListOfBlueprintDeployments.push(...blueprintDeploymentsFromDuplicates, ...blueprintDeploymentsWithoutDuplicates);

        this.blueprintDeploymentStore.setState({
            blueprintDeployments: [...this.blueprintDeploymentStore.state.blueprintDeployments, ...finalListOfBlueprintDeployments],
            hasInconsistentBlueprintDeployments: (listsOfSimilarBlueprintDeployments.length > 0) ? true : false
        });
    }

    getListOfAllDuplicatedBlueprintDeployments(blueprintDeployments: BlueprintDeployment[]): Array<BlueprintDeployment[]> {
        const listsOfSimilarBlueprintDeployments: Array<BlueprintDeployment[]> = [];

        for (const blueprintDeploymentIndex in blueprintDeployments) {
            const clonedBlueprintDeployments: BlueprintDeployment[] = cloneDeep(blueprintDeployments);
            const currentBlueprintDeploymentIndexAsNumber: number = parseInt(blueprintDeploymentIndex);

            const currentBlueprintDeploymet: BlueprintDeployment | undefined = clonedBlueprintDeployments.at(currentBlueprintDeploymentIndexAsNumber);
            if (currentBlueprintDeploymet !== undefined) {
                const blueprintDeploymentsWithoutCurrentBpd: BlueprintDeployment[] = clonedBlueprintDeployments.slice(currentBlueprintDeploymentIndexAsNumber + 1, clonedBlueprintDeployments.length);

                const similarBlueprintDeployments: BlueprintDeployment[] = this.getAllBlueprintDeploymentsSimilarIncluding(currentBlueprintDeploymet, blueprintDeploymentsWithoutCurrentBpd);
                if (similarBlueprintDeployments.length > 0) {
                    listsOfSimilarBlueprintDeployments.push(similarBlueprintDeployments);
                }
            }
        }
        return listsOfSimilarBlueprintDeployments;
    }

    removeDuplicatesFromBlueprintDeployments(duplicatedEntries: Array<BlueprintDeployment[]>, originalEntries: BlueprintDeployment[]): BlueprintDeployment[] {
        const flattenedDuplicates: BlueprintDeployment[] = duplicatedEntries.flat();
        const listOfBlueprintDeploymentPkeys: string[] = flattenedDuplicates.map((flattenedBlueprintDeployment: BlueprintDeployment) => flattenedBlueprintDeployment.pkey);
        remove(originalEntries, (blueprintDeployment: BlueprintDeployment) => {
            if (listOfBlueprintDeploymentPkeys.includes(blueprintDeployment.pkey)) {
                return true;
            } else {
                return false;
            }
        });
        return originalEntries;
    }

    getUniqueBlueprintDeploymentsFromDuplicates(nonUniqueBlueprintDeployments: Array<BlueprintDeployment[]>): BlueprintDeployment[] {
        const uniqueBlueprintDeployments: BlueprintDeployment[] = [];
        nonUniqueBlueprintDeployments.forEach((blueprintDeployments: BlueprintDeployment[]) => {
            const selectedBlueprintDeployment: BlueprintDeployment = this.getNewestCreatedBlueprintDeployment(blueprintDeployments);
            uniqueBlueprintDeployments.push(selectedBlueprintDeployment);
        });
        return uniqueBlueprintDeployments;
    }

    /**
     * Method used to compare two or more Blueprint deployments and return the one which is the newest created.
     * Given two or more Blueprint Products, it sorts them from newest to oldest creation datetime and gets the first one.
     * @param blueprintDeployments The list of BlueprintDeployments
     * @returns The newest BlueprintDeployment
     */
    getNewestCreatedBlueprintDeployment(blueprintDeployments: BlueprintDeployment[]): BlueprintDeployment {
        let copyOfBlueprintDeployments: BlueprintDeployment[] = cloneDeep(blueprintDeployments);
        copyOfBlueprintDeployments.sort(function (a: BlueprintDeployment, b: BlueprintDeployment) {
            const aDate: number = new Date(a.CreatedAt).getTime();
            const bDate: number = new Date(b.CreatedAt).getTime();
            return bDate - aDate;
        });
        return copyOfBlueprintDeployments[0];
    }

    getAllBlueprintDeploymentsSimilarIncluding(blueprintDeploymentToCompare: BlueprintDeployment, otherBlueprintDeployments: BlueprintDeployment[]): BlueprintDeployment[] {
        const similarBlueprintDeployments: BlueprintDeployment[] = [];
        otherBlueprintDeployments.forEach((blueprintDeployment: BlueprintDeployment) => {
            if (this.areTwoBlueprintDeploymentsOfTheSameType(blueprintDeployment, blueprintDeploymentToCompare)) {
                similarBlueprintDeployments.push(blueprintDeployment);
            }
        });

        if (similarBlueprintDeployments.length > 0) {
            similarBlueprintDeployments.push(blueprintDeploymentToCompare);
        }
        return similarBlueprintDeployments;
    }

    /**
     * Method used to check if two Blueprint Deployments are the same.
     * The conditions are:
     * - Both Blueprint Deployments must have the same number of Blueprints
     * - The blueprints are the same type
     * @param left First Blueprint Deployment
     * @param right Second Blueprint Deployment
     * @returns True if both Blueprint Deployments have the same type, false otherwise
     */
    areTwoBlueprintDeploymentsOfTheSameType(left: BlueprintDeployment, right: BlueprintDeployment): boolean {
        let areOfTheSameType: boolean = false;
        if (left.BlueprintProduct.Blueprints.length === right.BlueprintProduct.Blueprints.length) {
            const blueprintTypesForLeftBlueprintDeployment: BlueprintType[] = this.getBlueprintTypesForBlueprintDeployment(left);
            const blueprintTypesForRightBlueprintDeployment: BlueprintType[] = this.getBlueprintTypesForBlueprintDeployment(right);
            const allBlueprintTypesExist: boolean = blueprintTypesForLeftBlueprintDeployment.every((blueprintType: BlueprintType) => blueprintTypesForRightBlueprintDeployment.includes(blueprintType));
            if (allBlueprintTypesExist) {
                areOfTheSameType = true;
            }
        }
        return areOfTheSameType;
    }

    getBlueprintTypesForBlueprintDeployment(blueprintDeployment: BlueprintDeployment): BlueprintType[] {
        return blueprintDeployment.BlueprintProduct.Blueprints.map((blueprint: Blueprint) => blueprint.BlueprintType);
    }
}
