<ul data-cy="breadcrumbs-menu" *ngIf="breadcrumbs$ | async as breadcrumbs">
  <li *ngFor="let breadcrumb of breadcrumbs; let i = index">
    <a *ngIf="i === 0" data-cy="breadcrumbs-item" [routerLink]="breadcrumb.url">
      {{ breadcrumb.label === 'Honda' ? 'CAEdge' :  breadcrumb.label}}
    </a>
    <a *ngIf="i === 1" data-cy="breadcrumbs-item" [routerLink]="breadcrumb.url">
      {{ breadcrumb.label }}
    </a>
    <a
      *ngIf="i === 2"
      data-cy="breadcrumbs-item"
      [routerLink]="breadcrumbs[i].url"
    >
      Application
    </a>
  </li>
</ul>
<ng-template #test> test </ng-template>
