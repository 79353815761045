<mat-form-field class="caedge-form-field-outline">
    <mat-label>{{ "Playlists.AddStageDialogCommon.SelectSensors" | translate }}</mat-label>
    <mat-select
        data-cy="playlist-gen-sensors-select"
        [(value)]="selectedSensor"
        (selectionChange)="onSensorSelected($event.value)"
    >
    <mat-option>{{ "Playlists.AddStageDialogCommon.None" | translate }}</mat-option>
    @for (singleSensor of allSensors; track singleSensor) {
        <mat-option [value]="singleSensor">{{ singleSensor }}</mat-option>
    }
    </mat-select>
</mat-form-field>

@if(this.projectName === ProjectNames.RAD6BASE) {
    <mat-form-field class="caedge-form-field-outline">
        <mat-label>{{ "Playlists.AddStageDialogCommon.FilterSections" | translate }}</mat-label>
        <mat-select
            data-cy="playlist-gen-filter-select"
            [(value)]="selectedFilterSection"
            (selectionChange)="onFilterSectionSelected($event.value)"
        >
            <mat-option>{{ "Playlists.AddStageDialogCommon.None" | translate }}</mat-option>
            @for (filterSection of filterSections; track filterSection) {
                <mat-option [value]="filterSection">{{ filterSection }}</mat-option>
            }
        </mat-select>
    </mat-form-field>
}

@if(this.projectName === ProjectNames.SRR620DP24 && 
    this.selectedSensor !== undefined &&
    this.selectedSensor !== Sensors.SRR_ALL
    ) {
    <mat-form-field class="caedge-form-field-outline">
        <mat-label>{{ "Playlists.AddStageDialogCommon.SelectCombination" | translate }}</mat-label>
        <mat-select
            data-cy="playlist-gen-combination-select"
            [(value)]="selectedSensorCombination"
            (selectionChange)="onSensorCombinationSelected($event.value)"
        >
            <mat-option>{{ "Playlists.AddStageDialogCommon.None" | translate }}</mat-option>
            @for (sensorCombination of sensorCombinationList; track sensorCombination) {
                <mat-option [value]="sensorCombination">{{ sensorCombination }}</mat-option>
            }
        </mat-select>
    </mat-form-field>
}

@if(sensorVersions.length > 0) {
    <mat-form-field class="caedge-form-field-outline">
        <mat-label>{{ "Playlists.AddStageDialogCommon.SelectVersion" | translate }}</mat-label>
        <mat-select
            data-cy="playlist-gen-version-select"
            [(value)]="selectedSensorVersion"
            (selectionChange)="onSensorVersionSelected($event.value)"
        >
            <mat-option>{{ "Playlists.AddStageDialogCommon.None" | translate }}</mat-option>
            @for (sensorVersion of sensorVersions; track sensorVersion) {
                <mat-option [value]="sensorVersion">{{ sensorVersion }}</mat-option>
            }
        </mat-select>
    </mat-form-field>
}
