<button
  [ngClass]="[color, disable ? 'disabled' : '']"
  [disabled]="disable"
  (click)="clicked.emit()"
  mat-flat-button
  data-cy="cy-cae-button"
>
  <ng-container *ngIf="!buttonText">
    <ng-content></ng-content>
  </ng-container>
  <ng-container *ngIf="buttonText">
    {{ buttonText | translate }}
  </ng-container>
  <mat-icon *ngIf="icon !== ''">{{ icon }}</mat-icon>
</button>
