import { ApplicationDashboardComponent } from 'src/app/features/application-dashboard/application-dashboard.component';
import { DeploymentListComponent } from 'src/app/features/deployment-list/deployment-list.component';
import { DeviceListComponent } from 'src/app/features/device-list/device-list.component';
import { ProjectDashboardComponent } from 'src/app/features/project-dashboard/project-dashboard.component';
import { FeatureId } from 'src/app/shared/stores/config/models/featureId';
import { ProjectDashboardDetailsComponent } from '../../../../features/project-dashboard-details/project-dashboard-details.component';
import { VirtualDevelopmentInfrastructureComponent } from '../../../../features/virtual-development-infrastructure/virtual-development-infrastructure.component';
import { JobDetailsComponent } from '../../../../features/simulation-job-monitoring/components/job-details/job-details.component';
import { WorkspacePageComponent } from '../../../../features/workspace-page/workspace-page.component';
import { JobMonitoringComponent } from '../../../../features/job-monitoring/job-monitoring.component';
import { YieldReportComponent } from 'src/app/features/yield-report/yield-report.component';
import { PlaylistComponent } from '../../../../features/playlist/playlist.component';
import { PlaylistDetailsComponent } from '../../../../features/playlist/components/playlist-details/playlist-details.component';
import { ProjectUsersComponent } from 'src/app/features/project-users/project-users.component';
import { DeviceListItemDetailsComponent } from '../../../../features/device-list/components/device-list-item-details/device-list-item-details/device-list-item-details.component';

export const featureComponentMap: Partial<Record<FeatureId, any>> = {
  [FeatureId.PROJECT_DASHBOARD_PAGE]: ProjectDashboardComponent,
  [FeatureId.PROJECT_DASHBOARD_DETAILS_PAGE]: ProjectDashboardDetailsComponent,
  [FeatureId.PROJECT_USER_LIST]: ProjectUsersComponent,
  [FeatureId.APPLICATION_DASHBOARD_PAGE]: ApplicationDashboardComponent,
  [FeatureId.DEVICE_LIST_PAGE]: DeviceListComponent,
  [FeatureId.DEVICE_DETAILS]: DeviceListItemDetailsComponent,
  [FeatureId.DEPLOYMENTS_LIST_PAGE]: DeploymentListComponent,
  [FeatureId.SIMULATION_JOB_MONITORING]: JobMonitoringComponent,
  [FeatureId.SIMULATION_JOB_MONITORING_DETAILS]: JobDetailsComponent,
  [FeatureId.VIRTUAL_DEVELOPMENT_INFRASTRUCTURE]:
    VirtualDevelopmentInfrastructureComponent,
  [FeatureId.WORKSPACE_PAGE]: WorkspacePageComponent,
  [FeatureId.YIELD_REPORT_PAGE]: YieldReportComponent,
  [FeatureId.PLAYLIST]: PlaylistComponent,
  [FeatureId.PLAYLIST_DETAILS]: PlaylistDetailsComponent,
};
