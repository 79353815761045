import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { ProjectUser } from 'src/app/shared/components/user-list-components/models/user';
import { ProjectUsersState } from 'src/app/shared/stores/project-users/models/project-users-state';

@Injectable({
  providedIn: 'root'
})
export class ProjectUsersStore extends Store<ProjectUsersState> {
  constructor() {
    super({ projectUsers: [], isLoading: true, hasError: false });
  }

  get projectUsers$(): Observable<ProjectUser[]> {
    return this.state$.pipe(map((state) => state.projectUsers));
  }

  get isLoading$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.isLoading));
  }

  get hasError$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.hasError));
  }
}
