<span>{{ "UserList.AddUser.EnterEmail" | translate }}</span>
<form data-cy="add-users-dialog">
  <mat-form-field class="caedge-form-field-fill">
    <mat-label>{{ "UserList.AddUser.User" | translate }}</mat-label>
    <input
      placeholder="{{ 'UserList.AddUser.EnterEmail' | translate }}"
      [attr.aria-label]="'UserList.AddUser.EmailPlaceholder' | translate"
      [formControl]="selectUserEmail"
      matInput
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      data-cy="add-users-input"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      hideSingleSelectionIndicator="true"
      (optionSelected)="autocompleteOptionSelected($event.option)"
    >
      @for (option of filteredOptions | async; track option.email) {
        <mat-option data-cy="user-option" [value]="option.email">{{ option.email }}</mat-option>
      }
    </mat-autocomplete>
    <mat-hint align="end">{{ selectedEmails.length }}/{{ maxAllowedUsers }} {{ 'General.ProjectUser' | translate | lowercase }}</mat-hint>
  </mat-form-field>
  <div class="user-chips-container">
    <mat-chip-grid
      #chipGrid
      [attr.aria-label]="'UserList.AddUser.EmailPlaceholder' | translate"
    >
      @for (selectedEmail of selectedEmails; track selectedEmail) {
        <mat-chip-row
          data-cy="chip-user-email"
          class="contact-chip"
          (removed)="removeEmailFromList(selectedEmail)"
        >
          {{ selectedEmail }}
          <button  data-cy="remove-chip-user-email" matChipRemove [attr.aria-label]="'remove ' + selectedEmail">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
    </mat-chip-grid>
  </div>
</form>
<span>{{ "UserList.AddUser.SelectRole" | translate }}</span>
<form data-cy="add-users-role">
  <app-select-user-role
    autoPlaceholder="{{ 'UserList.AddUser.Roles' | translate }}"
    autoLabel="{{ 'UserList.AddUser.Roles' | translate }}"
    [defaultRoles]="defaultUserRoles"
    (selectedRolesChanged)="rolesChanged($event)"
  />
</form>
<app-dialog-hint
  [isWarning]="false"
  message="{{ 'UserList.AddUser.AddUserInformation' | translate }}">
</app-dialog-hint>
