import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UrlParameterStateService {
  private stateSubject = new BehaviorSubject<Record<string, any>>({});
  state$: Observable<Record<string, any>> = this.stateSubject.asObservable();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.listenToQueryParams();
  }

  private listenToQueryParams(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const parsedParams = this.parseParams(params);
      this.stateSubject.next(parsedParams);
    });
  }

  // Helper function to parse parameters, accommodating both scenarios
  private parseParams(
    params: Record<string, string | string[]>,
  ): Record<string, any> {
    const parsedParams: Record<string, any> = {};
    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (typeof value === 'string' && value.includes(',')) {
        // Delimiter-based parsing
        parsedParams[key] = value.split(',');
      } else {
        // Direct assignment for single values or arrays
        parsedParams[key] = value;
      }
    });
    return parsedParams;
  }

  // Get a specific param value, supporting multiple values
  getParam(key: string): Observable<string | string[] | null> {
    return this.state$.pipe(map((state) => state[key] || null));
  }

  // Update one or more params in the URL
  updateParams(params: Record<string, any>, extras?: NavigationExtras): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      queryParamsHandling: 'merge', // merge with existing query params
      ...extras,
    });
  }
}
