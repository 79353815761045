<app-header-template
  translationKey="Jobs.Title"
  [showBackButton]="false"
  data-cy="project-details-overview"
>
</app-header-template>
<div class="jobs-container">
  <mat-tab-group
    data-cy="job-tab-group"
    (selectedTabChange)="onTabChange($event.index)"
    [selectedIndex]="tab"
  >
    <mat-tab
      data-cy="simulation-jobs-tab-btn"
      label="{{ 'Jobs.TabSimulated' | translate }}"
    >
      <ng-template
        [ngTemplateOutletContext]="{ title: 'Jobs.Simulated.Title' }"
        [ngTemplateOutlet]="tabButtons"
      ></ng-template>
    </mat-tab>
    <mat-tab
      data-cy="reporting-jobs-tab-btn"
      label="{{ 'Jobs.TabReporting' | translate }}"
    >
      <ng-template
        [ngTemplateOutletContext]="{ title: 'Jobs.Reporting.Title' }"
        [ngTemplateOutlet]="tabButtons"
      ></ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #tabButtons let-title="title">
  <div class="tab-content">
    <div class="tab-header">
      <div class="tab-title" data-cy="jobs-title">{{ title | translate }}</div>
      <div class="job-count" data-cy="jobs-count">
        <ng-container *ngIf="job$ | async as job">
          {{ "Jobs.Count" | translate }} {{ job.count }}
          {{ "Jobs.Jobs" | translate }}
        </ng-container>
      </div>
    </div>
    <div class="tab-action">
      <app-cae-button
        data-cy="create-job-btn"
        buttonText="{{ 'Jobs.Create' | translate }}"
        [color]="'secondary'"
        [icon]="'add_task'"
        (click)="createJob()"
      >
      </app-cae-button>
    </div>
  </div>
  <div class="job-data">
    <app-job-table [tab]="tab" [job]="job$ | async"></app-job-table>
  </div>
</ng-template>
