<div class="child-job-header-content">
  <div class="child-job-header">
    <div class="title">{{ "Jobs.Child.Title" | translate }}</div>
    <div class="subtitle">{{ "Jobs.Child.Subtitle" | translate }}</div>
  </div>
  <app-cae-button
    data-cy="export-child-jobs"
    buttonText="Export .csv"
    [color]="'secondary'"
    [icon]="'file_download'"
    (click)="downloadChildJobCsv()"
  >
  </app-cae-button>
</div>

<mat-form-field
  class="caedge-form-field-fill"
  *ngIf="!loading; else loadingBar"
>
  <div class="search">
    <mat-icon
      (click)="
        $event.stopPropagation(); dataSource.filter = ''; input.value = ''
      "
      data-cy="clear-search-icon"
      aria-label="search and clear"
    >
      {{ input.value.length > 0 ? "close" : "search" }}
    </mat-icon>
    <input
      matInput
      data-cy="search-input"
      (keyup)="applyFilter($event)"
      placeholder="Search..."
      #input
    />
    <div class="filter-container">
      <span>Status: </span>
      <mat-form-field>
        <mat-select
          [(value)]="selectedStatus"
          (selectionChange)="filterChildJobs($event.value)"
          #statusSelect
        >
          <mat-option
            *ngFor="let status of statusOptions"
            [value]="status.value"
          >
            {{ status.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-form-field>

<mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  <!-- Index Column -->
  <ng-container matColumnDef="ChildJobIndex">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="table-header"
      >#Index</mat-header-cell
    >
    <mat-cell mat-cell *matCellDef="let element">{{
      element.ChildJobIndex
    }}</mat-cell>
  </ng-container>

  <!-- RecId Column -->
  <ng-container matColumnDef="RECID">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="table-header"
      sortActionDescription="Sort by Rec Id"
      >RecId
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">{{ element.RECID }}</mat-cell>
  </ng-container>

  <!-- Input Field Name Column -->
  <ng-container matColumnDef="InputFileName">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="table-header"
      >Input file name</mat-header-cell
    >
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.InputFileName }}
    </mat-cell>
  </ng-container>

  <!-- Section Start Column -->
  <ng-container matColumnDef="SectionStart">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="table-header"
      >Section start</mat-header-cell
    >
    <mat-cell mat-cell *matCellDef="let element">
      {{ element.SectionStart }}
    </mat-cell>
  </ng-container>

  <!-- Section End Column -->
  <ng-container matColumnDef="SectionEnd">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="table-header"
      >Section end</mat-header-cell
    >
    <mat-cell mat-cell *matCellDef="let element"
      >{{ element.SectionEnd }}
    </mat-cell>
  </ng-container>

  <!-- Job Exit Code Column -->
  <ng-container matColumnDef="JobExitCode">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="table-header"
      >Job exit code</mat-header-cell
    >
    <mat-cell mat-cell *matCellDef="let element">{{
      element.JobExitCode ? element.JobExitCode : "-"
    }}</mat-cell>
  </ng-container>

  <!-- Steps Column -->
  <ng-container matColumnDef="StepNames">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="table-header"
      >Steps</mat-header-cell
    >
    <mat-cell
      mat-cell
      *matCellDef="let element"
      [matTooltip]="element.StepNames.toString()"
      matTooltipPosition="left"
    >
      {{
        element.StepNames !== []
          ? (element.StepNames.toString() | slice: 0 : 10) + "..."
          : "-"
      }}
    </mat-cell>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="Status">
    <mat-header-cell
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="table-header"
      >Status</mat-header-cell
    >
    <mat-cell mat-cell *matCellDef="let element">
      <app-chip
        class="type-chip"
        [label]="element.Status"
        [variant]="
          element.Status === 'FAILED'
            ? ChipVariant.ERROR
            : element.Status === 'SUCCEEDED'
              ? ChipVariant.SUCCESS
              : ChipVariant.NEUTRAL_WHITE
        "
      />
    </mat-cell>
  </ng-container>

  <!-- Steps Button Column -->
  <ng-container matColumnDef="link">
    <mat-header-cell mat-header-cell *matHeaderCellDef class="table-header"
      >Actions</mat-header-cell
    >
    <mat-cell mat-cell *matCellDef="let element">
      <mat-icon
        (click)="openStepsDialog(element.ChildJobIndex)"
        matTooltip="{{ 'Jobs.ChildJobDetails' | translate }}"
        matTooltipPosition="right"
      >
        navigate_next
      </mat-icon>
    </mat-cell>
  </ng-container>

  <mat-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-row>
  <mat-row mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td mat-row data-cy="jobs-no-data" colspan="8">
      @if (
        this.dataSource.data.length === 0 &&
        !loading &&
        selectedStatus !== "All"
      ) {
        <app-empty-section [iconName]="EmptySectionIcon.COOKIE">
          {{ "Jobs.EmptyTableForFilter" | translate }}
        </app-empty-section>
      } @else if (
        this.dataSource.data.length === 0 &&
        !loading &&
        selectedStatus === "All"
      ) {
        <app-empty-section [iconName]="EmptySectionIcon.COFFEE_MAKER">
          {{ "Jobs.EmptyTable" | translate }}
        </app-empty-section>
      }
    </td>
  </tr>
</mat-table>

<mat-paginator
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  showFirstLastButtons
  aria-label="Show page size"
>
</mat-paginator>

<ng-template #loadingBar>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
