<form class="form" [formGroup]="formGroup">
  <div class="content" mat-dialog-content>
    <table class="contact-info-table">
      <tr>
        <td class="contact-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <span matPrefix class="form-icon"
              ><mat-icon>manage_accounts</mat-icon></span
            >
            <mat-label>{{
              "Project.AddProjectDialog.ProjectOwner" | translate
            }}</mat-label>
            <input
              data-cy="add-project-owner"
              #projectOwnerInput
              placeholder="{{
                'Project.AddProjectDialog.ProjectOwner' | translate
              }}"
              matInput
              formControlName="projectOwner"
              [matAutocomplete]="projectOwnerAuto"
              (input)="filter(projectOwnerInput)"
              (focus)="filter(projectOwnerInput)"
            />
            <mat-autocomplete
              [displayWith]="getUserMail"
              #projectOwnerAuto="matAutocomplete"
            >
              @for (option of filteredUsers; track option.email) {
                <mat-option [value]="option">{{option.email}} </mat-option>
              }
            </mat-autocomplete>
            <mat-error>
              {{ 'Project.AddProjectDialog.ErrorMessage.EmailNotSelected' | translate }}
            </mat-error>
          </mat-form-field>
        </td>
        <td class="contact-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <span matPrefix class="form-icon"
              ><mat-icon>admin_panel_settings</mat-icon></span
            >
            <mat-label>{{
              "Project.AddProjectDialog.SecurityCentralOPS" | translate
            }}</mat-label>
            <input
              data-cy="add-project-sec-central"
              #secCentralInput
              placeholder="{{
                'Project.AddProjectDialog.SecurityCentralOPS' | translate
              }}"
              formControlName="securityCentral"
              matInput
              [matAutocomplete]="securityCentralAuto"
              (input)="filter(secCentralInput)"
              (focus)="filter(secCentralInput)"
            />
            <mat-autocomplete
              [displayWith]="getUserMail"
              #securityCentralAuto="matAutocomplete"
            >
              @for (option of filteredUsers; track option.email) {
                <mat-option [value]="option">{{option.email}} </mat-option>
              }
            </mat-autocomplete>
            <mat-error>
              {{ 'Project.AddProjectDialog.ErrorMessage.EmailNotSelected' | translate }}
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="contact-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <span matPrefix class="form-icon"
              ><mat-icon>manage_accounts</mat-icon></span
            >
            <mat-label>{{
              "Project.AddProjectDialog.ProjectCoOwner" | translate
            }}</mat-label>
            <input
              matInput
              #coOwnerInput
              data-cy="project-co-owner-input"
              [matAutocomplete]="coOwnerAuto"
              formControlName="projectCoOwner"
              (input)="filter(coOwnerInput)"
              (focus)="filter(coOwnerInput)"
            >

            <mat-autocomplete #coOwnerAuto="matAutocomplete" [displayWith]="getUserMail">
              <mat-option
                *ngFor="let option of filteredUsers"
                [value]="option"
              >
                <div>
                  <mat-checkbox [checked]="selectedCoOwners.includes(option)" (change)="toggleSelection(option)" (click)="$event.stopPropagation()">
                    {{option.email}}
                  </mat-checkbox>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </td>
        <td class="contact-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <span matPrefix class="form-icon"
              ><mat-icon>admin_panel_settings</mat-icon></span
            >
            <mat-label>{{
              "Project.AddProjectDialog.SecurityProjectOwner" | translate
            }}</mat-label>
            <input
              data-cy="add-project-sec-po"
              #secPoInput
              placeholder="{{
                'Project.AddProjectDialog.SecurityProjectOwner' | translate
              }}"
              formControlName="securityProjectOwner"
              matInput
              [matAutocomplete]="securityOwnerAuto"
              (input)="filter(secPoInput)"
              (focus)="filter(secPoInput)"
            />
            <mat-autocomplete
              [displayWith]="getUserMail"
              #securityOwnerAuto="matAutocomplete"
            >
              @for (option of filteredUsers; track option.email) {
                <mat-option [value]="option">{{option.email}} </mat-option>
              }
            </mat-autocomplete>
            <mat-error>
              {{ 'Project.AddProjectDialog.ErrorMessage.EmailNotSelected' | translate }}
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="contact-info coowner-column" rowspan="2">
          <div class="coowner-container" data-cy="coowner-section">
            <mat-chip-row
              class="contact-chip"
              *ngFor="let contact of selectedCoOwners"
              (removed)="removeCoOwner(contact)"
              [editable]="false"
            >
              {{contact.email}}
              <button matChipRemove [attr.aria-label]="'remove ' + contact">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </div>
        </td>
        <td class="contact-info technical-column">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <span matPrefix class="form-icon"
              ><mat-icon>engineering</mat-icon></span
            >
            <mat-label>{{"Project.AddProjectDialog.TechnicalContact" | translate}}</mat-label>
            <input
              data-cy="add-project-tech"
              #techInput
              placeholder="{{
                'Project.AddProjectDialog.TechnicalContact' | translate
              }}"
              formControlName="technicalContact"
              matInput
              [matAutocomplete]="technicalAuto"
              (input)="filter(techInput)"
              (focus)="filter(techInput)"
            />
            <mat-autocomplete
              [displayWith]="getUserMail"
              #technicalAuto="matAutocomplete"
            >
              @for (option of filteredUsers; track option.email) {
                <mat-option [value]="option">{{option.email}} </mat-option>
              }
            </mat-autocomplete>
            <mat-error data-cy="add-project-tech-error">
              {{ 'Project.AddProjectDialog.ErrorMessage.EmailNotSelected' | translate }}
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
    </table>
  </div>
</form>
