import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PlaylistDataset } from '../../models/playlist-dataset';
import { SelectionModel } from '@angular/cdk/collections';
import { TruncatePipe } from 'src/app/core/pipes/truncate.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ApiRecord } from '../../../../shared/stores/config/models/apiRecord';
import { JobMonitoringService } from '../../services/job-monitoring.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { EmptySectionComponent } from '../../../../shared/components/empty-section/empty-section.component';

@Component({
  selector: 'app-simulation-playlist',
  standalone: true,
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatTableModule,
    TruncatePipe,
    MatTableModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    EmptySectionComponent,
    MatPaginatorModule,
  ],
  templateUrl: './simulation-playlist.component.html',
  styleUrls: ['./simulation-playlist.component.scss'],
})
export class SimulationPlaylistComponent implements OnChanges {
  @Input({ required: true }) api!: ApiRecord;
  @Input({ required: true }) projectName: string = '';
  @Input({ required: true }) stage: string | null = '';

  @Output() setId: EventEmitter<string> = new EventEmitter();

  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort) set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  searchInput: string = '';
  loading: boolean = false;

  dataSource = new MatTableDataSource<PlaylistDataset>([]);
  selection = new SelectionModel<PlaylistDataset>(false, []);
  displayedColumns: string[] = ['select', 'ID', 'Name', 'Type'];

  constructor(private jobMonitoringService: JobMonitoringService) {}

  async ngOnChanges(changes: SimpleChanges) {
    const stage = 'stage';
    const prev = changes[stage].previousValue;
    const current = changes[stage].currentValue;

    if (prev === current) return;
    await this.getPlaylist();
  }

  async getPlaylist(): Promise<void> {
    try {
      const stage = this.stage ? this.stage : '';
      this.loading = true;
      this.dataSource.data = await this.jobMonitoringService.getPlaylist(
        this.projectName,
        stage,
        this.api,
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  clearInput(event: Event) {
    event.stopPropagation();
    this.dataSource.filter = '';
    this.searchInput = '';
  }

  applyFilter(event: Event) {
    const filterValue: string = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  setSelectedValue(row: PlaylistDataset): void {
    const hasValue = this.selection.hasValue();
    this.setId.emit(hasValue ? row.id : '');
  }
}
