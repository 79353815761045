import { Component, Input, OnChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SnackbarService } from '../../../../core/services/snackbar/snackbar.service';
import { JobMonitoringService } from '../../services/job-monitoring.service';
import { Method } from '../../../../shared/stores/config/models/method';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-upload-files',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatProgressSpinnerModule],
  templateUrl: './upload-files.component.html',
  styleUrl: './upload-files.component.scss',
})
export class UploadFilesComponent implements OnChanges {
  @Input() config!: any;

  fileName = '';
  allowedFileEndings: string = '';
  name: string = '';
  loading: boolean = false;

  constructor(
    private jobMonitoringService: JobMonitoringService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
  ) {}

  ngOnChanges(): void {
    this.name = JSON.parse(this.config.args).button_title;
    if (this.name) {
      this.allowedFileEndings = this.extractFileEnding(this.name);
    }
  }

  extractFileEnding(name: string) {
    // match and extract "Choose .cfg file" the file ending of strings
    const match = name.match(/\.([0-9a-z]+) file/i);

    if (match) {
      return '.' + match[1];
    } else {
      console.error('No file extension found.');
      return '';
    }
  }

  async onFileSelected(event: Event): Promise<void> {
    const target = event.target as HTMLInputElement;
    let readerResult: string | ArrayBuffer = '';
    if (!target.files) return;
    const file = target.files[0];

    if (!file.name.includes(this.allowedFileEndings)) {
      this.snackbarService.notifyError(
        'This file ending is not allowed! Only ' + this.allowedFileEndings,
      );
      return;
    }

    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file && reader.result) {
          readerResult = reader.result;
        }
      };

      const fileBase64 = readerResult.toString().split(',')[1];
      const body = {
        content: fileBase64,
        file_name: file.name,
      };
      const api = {
        url: this.config.api,
        method: Method.POST,
      };
      this.loading = true;
      await this.jobMonitoringService.uploadFile(api, body);
      this.fileName = file.name;
      this.snackbarService.notifySuccess(
        this.translate.instant('General.Uploaded'),
      );
      this.loading = false;
    } catch {
      this.snackbarService.notifyError(
        this.translate.instant('General.UploadFailed') + file.name,
      );
    }
  }
}
