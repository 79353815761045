import { DeploymentListComponent } from '../components/deployment-list/deployment-list.component';
import { DeviceListItemDetailsComponent } from '../components/device-list/components/device-list-item-details/device-list-item-details/device-list-item-details.component';
import { DeviceListComponent } from '../components/device-list/device-list.component';

export enum FeatureId {
    DEVICE_LIST_PAGE = 'DEVICE_LIST_PAGE',
    DEVICE_DETAILS = 'DEVICE_DETAILS',
    DEPLOYMENTS_LIST_PAGE = 'DEPLOYMENTS_LIST_PAGE',
};

export const vEcuAmiComponentMap: Partial<Record<FeatureId, any>> = {
    [FeatureId.DEVICE_LIST_PAGE]: DeviceListComponent,
    [FeatureId.DEVICE_DETAILS]: DeviceListItemDetailsComponent,
    [FeatureId.DEPLOYMENTS_LIST_PAGE]: DeploymentListComponent,
};
