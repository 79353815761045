<mat-form-field class="caedge-form-field-fill">
  <div class="search">
    <mat-icon
      (click)="clearInput($event)"
      data-cy="clear-search-icon"
      aria-label="search and clear"
    >
      {{ input.value.length > 0 ? "close" : "search" }}
    </mat-icon>
    <input
      matInput
      [(ngModel)]="searchInput"
      data-cy="jobs-search-input"
      (keyup)="applyFilter($event)"
      placeholder="Search..."
      #input
    />
  </div>
</mat-form-field>

<div class="table-container">
  <mat-table
    [dataSource]="dataSource"
    matSort
    data-cy="job-table"
    (matSortChange)="applySorting($event)"
  >
    <!-- Run ID Column -->
    <ng-container matColumnDef="RunID">
      <mat-header-cell
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        sortActionDescription="Sort by Run Id"
        class="table-header"
        [start]="sortingStartDirections.RunId"
      >
        # Run ID
      </mat-header-cell>
      <mat-cell data-cy="job-run-id" mat-cell *matCellDef="let element">
        {{ element.RunID }}
      </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="WorkflowName">
      <mat-header-cell
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="table-header"
        [start]="sortingStartDirections.WorkflowName"
      >
        Workflow name
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element" class="grey-text">
        {{ element.WorkflowName }}
      </mat-cell>
    </ng-container>

    <!-- Caller Column -->
    <ng-container matColumnDef="Caller">
      <mat-header-cell
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="table-header"
        [start]="sortingStartDirections.Caller"
      >
        Caller
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element">
        {{ element.Caller }}
      </mat-cell>
    </ng-container>

    <!-- Submission date Column -->
    <ng-container matColumnDef="SubmissionDateTime">
      <mat-header-cell
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="table-header"
        [start]="sortingStartDirections.SubmissionDateTime"
      >
        Submission date
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element">
        {{ element.SubmissionDateTime | date : "MMM dd, yyyy hh:mm" }}
      </mat-cell>
    </ng-container>

    <!-- Start date Column -->
    <ng-container matColumnDef="StartDateTime">
      <mat-header-cell
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="table-header"
        [start]="sortingStartDirections.StartDateTime"
      >
        Start date
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element">
        {{ element.StartDateTime | date : "MMM dd, yyyy hh:mm" }}
      </mat-cell>
    </ng-container>

    <!-- End date Column -->
    <ng-container matColumnDef="EndDateTime" data-cy="table-end">
      <mat-header-cell
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="table-header"
        data-cy="table-end-header"
        [start]="sortingStartDirections.EndDateTime"
      >
        End date
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element">
        {{ element.EndDateTime | date : "MMM dd, yyyy hh:mm" }}
      </mat-cell>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="Status">
      <mat-header-cell
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="table-header"
        [start]="sortingStartDirections.Status"
      >
        Status
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element">
        <app-chip
          class="type-chip"
          [label]="element.Status"
          [variant]="element.Status | customVariantColorPipe"
        />
      </mat-cell>
    </ng-container>

    <!-- Details Button Column -->
    <ng-container matColumnDef="link">
      <mat-header-cell class="table-header" mat-header-cell *matHeaderCellDef>
        Actions
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element">
        <mat-icon data-cy="child-job-details-button" (click)="navigateToDetailsPage(element.RunID)" matTooltip="{{ 'Jobs.JobDetails' | translate }}" matTooltipPosition="right">
          navigate_next
        </mat-icon>
      </mat-cell>
    </ng-container>

    <mat-row
      mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    <!-- Row shown when there is no matching data. -->
    <td mat-row colspan="8">
      <app-empty-section
        class="empty-container"
        data-cy="jobs-no-data"
        *matNoDataRow
        [iconName]=EmptySectionIcon.COFFEE_MAKER
      >
        <p>{{ "Jobs.EmptyTable" | translate }}</p>
      </app-empty-section>
    </td>
  </mat-table>
</div>
<mat-paginator
  *ngIf="dataSource.data.length !== 0"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  showFirstLastButtons
  aria-label="Select page of periodic elements"
>
</mat-paginator>
