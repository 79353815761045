export interface INewJob {
  input_location: string | string[];
  output_location: string;
  project: string;
  workflow: string;
  step: string;
  repo: string;
  workflow_configuration: { [key: string]: string }[];
}

export class NewJob implements INewJob {
  input_location: string | string[] = '';
  output_location!: string;
  project!: string;
  workflow!: string;
  step!: string;
  repo!: string;
  workflow_configuration!: { [key: string]: string }[];
}
