import { Component, HostListener } from '@angular/core';
import { LanguageService } from './core/services/language/language.service';
import { WindowResizeService } from './core/services/windowResize/window-resize.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private languageService: LanguageService,
    private resizeService: WindowResizeService,
  ) {
    this.languageService.init();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.resizeService.setSize(+event.target.innerWidth);
    this.resizeService.setHeight(+event.target.innerHeight);
  }
}
