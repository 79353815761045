import { ApplicationType } from '../stores/applications/models/applicationType';
import { ApplicationTypeLabels } from '../../features/project-dashboard/components/add-application-dialog/models/applicationTypeLabels';

export const convertToAppTypeLabel = (appType: string): string => {
  switch (appType) {
    case ApplicationType.SINGLE_SERVICE:
      return ApplicationTypeLabels.LINUX_CONTAINER;
    case ApplicationType.ANDROID:
      return ApplicationTypeLabels.ANDROID;
    case ApplicationType.QNX:
      return ApplicationTypeLabels.QNX;
    case ApplicationType.CLASSIC_AUTOSAR_IMAGE:
      return ApplicationTypeLabels.CLASSIC_AUTOSAR;
    case ApplicationType.LINUX_NATIVE:
      return ApplicationTypeLabels.LINUX_NATIVE;
    case ApplicationType.ADAPTIVE_AUTOSAR:
      return ApplicationTypeLabels.ADAPTIVE_AUTOSAR_NEW;
    default:
      return '';
  }
};
