import { Injectable } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { State } from '../../../shared/stores/State';
import { PlaylistDataset } from '../../job-monitoring/models/playlist-dataset';
import { Option } from '../../../core/models/interfaces/option';
import { StageOptions } from '../playlist.component';

@Injectable({
  providedIn: 'root',
})
export class PlaylistSearchService {
  combinePlaylistDatasetState$(
    filteredPlaylist$: Observable<PlaylistDataset[]>,
    isLoading: Observable<boolean>,
    hasError: Observable<boolean>,
  ): Observable<State<PlaylistDataset[]>> {
    return combineLatest([filteredPlaylist$, isLoading, hasError]).pipe(
      map(([playlist, isLoading, hasError]) => {
        return { data: playlist, isLoading, hasError };
      }),
    );
  }

  playlistMap = ([playlist, searchFilter, selectedOption]: [
    PlaylistDataset[],
    string,
    Option<string>,
  ]): PlaylistDataset[] => {
    return playlist.filter((playlist) => {
      const id = playlist.id.toLowerCase();
      const name = playlist.name.toLowerCase();
      const stage = playlist.type.toLowerCase();
      const playlistId = playlist.playlist_id.toLowerCase();

      const search = searchFilter.toLowerCase();

      const selectedOptionFilter =
        selectedOption.id !== StageOptions.ALL
          ? selectedOption.id
          : '';

      const searchAttributes = [id, name, stage, playlistId].some((attr) =>
        attr.includes(search),
      );

      const searchFilters = stage.includes(selectedOptionFilter);
      return searchAttributes && searchFilters;
    });
  };
}
