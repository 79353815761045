import { Component, Inject } from '@angular/core';
import { ProjectUser } from '../../../../shared/components/user-list-components/models/user';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { SelectUserRoleComponent } from '../../select-user-role/select-user-role.component';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { DialogHintComponent } from '../../../workspace-page/components/dialog-hint/dialog-hint.component';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-user-change-role',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatInputModule,
    MatSelectModule,
    TranslateModule,
    MatChipsModule,
    MatIconModule,
    FormsModule,
    SelectUserRoleComponent,
    DialogHintComponent,
    MatDialogTitle,
    MatDialogContent,
    MatButton,
    MatDialogActions,
    MatDialogClose,
  ],
  templateUrl: './user-change-role.component.html',
  styleUrl: './user-change-role.component.scss',
})
export class UserChangeRoleComponent {
  selectedUserRoles: string[] = [];
  readonly title = 'UserList.ChangeUserRole.Title';
  readonly confirmText = 'UserList.ChangeUserRole.Confirm';
  readonly message = 'UserList.ChangeUserRole.Note';
  constructor(
    public dialogRef: MatDialogRef<UserChangeRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: ProjectUser },
  ) {}

  rolesChanged(roles: string[] | null) {
    if (roles) {
      this.selectedUserRoles = roles;
    }
  }

  submit() {
    this.dialogRef.close(this.selectedUserRoles);
  }
}
