import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { DialogHintComponent } from '../../../../shared/components/dialog-hint/dialog-hint.component';
import { ResetWorkbenchRequestBody } from '../../models/reset-worspace-instance-request-body';

@Component({
  selector: 'app-reset-workspace',
  standalone: true,
  templateUrl: './reset-workspace.component.html',
  styleUrl: './reset-workspace.component.scss',
  imports: [
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    DialogHintComponent,
  ],
})
export class ResetWorkspaceComponent {
  retainUserData = false;
  workbenchId = this.data.workbenchId;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ResetWorkspaceComponent>,
  ) {}

  onCheckboxChange(change: MatCheckboxChange) {
    this.retainUserData = change.checked;
  }

  resetWorkbench() {
    const requestBody: ResetWorkbenchRequestBody = {
      workbenchId: this.workbenchId,
      retainUserData: this.retainUserData,
    };
    const data = {
      body: requestBody,
      retainUserData: this.retainUserData,
    };
    this.dialogRef.close(data);
  }
}
