import {
  animate,
  query,
  sequence,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const listAnimation = trigger('listAnimation', [
  transition('* => *', [
    query(
      ':enter',
      [
        style({ opacity: 0 }),
        stagger(50, [animate('250ms', style({ opacity: 1 }))]),
      ],
      { optional: true }
    ),
    query(
      ':leave',
      [
        style({ opacity: 1 }),
        sequence([animate('300ms', style({ opacity: 0 }))]),
      ],
      { optional: true }
    ),
  ]),
]);
