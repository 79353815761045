import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RestrictedInputData } from '../../components/restricted-input-display/models/restricted-input-data';

@Injectable({
  providedIn: 'root'
})
export class ResourceRestrictionHelperService {

  private restrictResourceData: BehaviorSubject<RestrictedInputData> = new BehaviorSubject({ fieldValue: '' });
  restrictResourceData$ = this.restrictResourceData.asObservable();

  updateData(data: RestrictedInputData): void {
    this.restrictResourceData.next(data);
  }
}
