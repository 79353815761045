import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function noUserIdValidator(userId: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const newPassword = control.value;

    if (newPassword && newPassword.toLowerCase().includes(userId.toLowerCase())) {
      return { containsUserId: true };
    }

    return null;
  };
}
