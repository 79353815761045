import { Contact } from '../../../../core/components/home/components/add-project-dialog/models/contact';

export interface BlueprintDeploymentResponse {
  BlueprintDeployments: BlueprintDeployment[];
}

export interface BlueprintDeploymenRequestResponse {
  BlueprintDeploymentId: string;
  BlueprintDeployment: string;
}

export enum BlueprintDeploymenRequestStatus {
  BlueprintDeployment = 'Successfully initiated blueprint deployment.',
  ERROR = 'failed',
}
export interface BlueprintDeployment {
  pkey: string;
  skey: string;
  ProjectId: string;
  TenantId: string;
  AccountId?: string;
  AccountName: string;
  WorkbenchId: string;
  Description: string;
  Stage: Stage;
  Owner: Contact;
  BlueprintProductId: string;
  BlueprintUpdateEnabled: string;
  BlueprintProduct: BlueprintProduct;
  CreatedAt: string;
  UpdatedAt: string;
  Status: BlueprintDeploymentStatus;
}

export class BlueprintDeployment implements BlueprintDeployment {
  pkey!: string;
  skey!: string;
  ProjectId!: string;
  TenantId!: string;
  AccountId?: string;
  AccountName!: string;
  WorkbenchId!: string;
  Description!: string;
  Owner!: Contact;
  Stage!: Stage;
  BlueprintProductId!: string;
  BlueprintUpdateEnabled!: string;
  BlueprintProduct!: BlueprintProduct;
  CreatedAt!: string;
  UpdatedAt!: string;
}

export interface BlueprintProductsResponse {
  BlueprintProducts: BlueprintProduct[]
}

export interface BlueprintProduct {
  pkey: string,
  BlueprintProductId: string;
  BlueprintProductName: string;
  Description: string;
  BlueprintProductVersion: string;
  Blueprints: Blueprint[];
  Requester: Contact;
  CreatedAt: string;
  UpdatedAt: string;
  Status: BlueprintStatus;
  Stage: Stage;
}

export interface Blueprint {
  pkey?: string;
  BlueprintId: string;
  BlueprintName: string;
  BlueprintType: BlueprintType;
  BlueprintVersion: string;
  AccountId?: string;
  UpdatedAt?: string;
  Description?: string;
  Environment_variables?: any;
}

export interface BlueprintBody {
  BlueprintId: string;
  Environment_variables: any;
}

export interface BlueprintProductBody {
  BlueprintProductId: string;
  Blueprints: BlueprintBody[];
}

export enum BlueprintStatus {
  CREATE_INITIATED = 'CREATE_INITIATED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED',
}

export enum BlueprintDeploymentStatus {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  DELETED = 'DELETED',
}
export enum BlueprintType {
  HDK = 'hdk-workbench',
  VHPC = 'vhpc-workbench',
  Observability = 'obs-app',
  vECU = 'vecu-backend'
}

export enum Stage {
  DEV = 'dev',
  QA = 'qa',
  PROD = 'prod',
}
