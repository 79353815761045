import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { of, Subject, switchMap, takeUntil } from 'rxjs';
import {
  NewDeploymentComponent,
  NewDeploymentDialogResponse,
} from 'src/app/features/vecu-ami/components/deployment-list/components/new-deployment/new-deployment.component';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { DeploymentService } from '../deployment-list/deployment.service';
import { SnackbarService } from '../snackbar/snackbar.service';
import { RoutingStore } from '../../../shared/stores/config/routing.store';
import { TranslateService } from '@ngx-translate/core';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { DeviceType } from 'src/app/shared/stores/devices/models/deviceType';

export type StartDeploymentApi = {
  createDevDeployment: ApiRecord;
  getApplications?: ApiRecord;
  getApplicationDetails?: ApiRecord;
  getVersions?: ApiRecord;
  getDevDevices?: ApiRecord;
};

@Injectable({
  providedIn: 'root',
})
export class StartDeploymentService {
  private deviceName: string = '';
  constructor(
    private dialog: MatDialog,
    private deploymentService: DeploymentService,
    private snackbarService: SnackbarService,
    private routingStore: RoutingStore,
    private translateService: TranslateService,
  ) {}

  openStartDeploymentDialog(
    appName: string,
    apiRecords: StartDeploymentApi,
    predefinedValues: {
      versionId?: string;
      device?: Device;
      partitionName?: string;
    },
    unsubscribe$: Subject<void>,
  ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    dialogConfig.data = {
      appName: appName,
      apiRecords: apiRecords,
      predefinedValues: predefinedValues,
    };
    const dialogRef = this.dialog.open(NewDeploymentComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((data: NewDeploymentDialogResponse) => {
          if (!data) {
            return of(null);
          }
          this.deviceName = data.device;
          return this.deploymentService.startDeployment(
            data.app,
            data.version,
            data.device,
            apiRecords.createDevDeployment,
            data.partition,
          );
        }),
        takeUntil(unsubscribe$),
      )
      .subscribe({
        next: (value) => {
          if (!value) {
            return;
          }
          if (predefinedValues.device) {
            if (predefinedValues.device.instanceType === DeviceType.REAL) {
              this.routingStore.navigateToHdkDeviceDetails(this.deviceName);
            } else {
              //only do an automatic redirect in the device list / details view
              this.routingStore.navigateToDeviceDetails(this.deviceName);
            }
          } else if (value) {
            this.snackbarService.notifyInfo(
              this.translateService.instant('Deployments.Initiated'),
            );
          }
        },
        error: () => {
          this.snackbarService.notifyError(
            this.translateService.instant('Deployments.DeploymentFailed'),
          );
        },
      });
  }
}
