import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ResourceRestrictionHelperService } from '../../utils/resource-restriction-helper-service/resource-restriction-helper.service';
import { restrictedNames } from './models/restricted-names';
import { Subject, takeUntil } from 'rxjs';
import { RestrictedInputData } from 'src/app/shared/components/restricted-input-display/models/restricted-input-data';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-restricted-input-display',
  standalone: true,
  imports: [CommonModule, MatListModule, MatIconModule, TranslateModule],
  templateUrl: './restricted-input-display.component.html',
  styleUrl: './restricted-input-display.component.scss',
})
export class RestrictedInputDisplayComponent implements OnDestroy {
  readonly destroy$: Subject<void> = new Subject();
  readonly nameRestrictions: string[] = restrictedNames;
  restrictedNames: { name: string; value: boolean }[] = [];
  hasApplicationPatternFailed: boolean | undefined;
  hasDevicePatternFailed: boolean | undefined;
  hasVersionPatternFailed: boolean | undefined;
  hasNameRestrictionFailed: boolean | undefined;
  hasFieldValue: boolean | undefined;
  nameRestrictor: string | undefined | null;
  applicationPattern: { name: string; value: boolean }[] | undefined = [];
  devicePattern: { name: string; value: boolean }[] | undefined = [];
  versionPattern: { name: string; value: boolean }[] | undefined = [];

  constructor(
    private resourceRestrictionHelperService: ResourceRestrictionHelperService,
  ) {
    this.checkFieldChange();
  }

  checkFieldChange(): void {
    this.resourceRestrictionHelperService.restrictResourceData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: RestrictedInputData) => {
        if (data.fieldValue?.length === 0) {
          this.hasFieldValue = false;
        } else {
          this.hasFieldValue = true;
        }
        this.restrictedNames = restrictedNames.map((name) => ({
          name: name,
          value: false,
        }));
        this.hasNameRestrictionFailed = data.hasNameRestrictionFailed;
        this.hasApplicationPatternFailed = data.hasApplicationPatternFailed;
        this.hasDevicePatternFailed = data.hasDevicePatternFailed;
        this.hasVersionPatternFailed = data.hasVersionPatternFailed;
        data.nameRestrictor
          ? (this.restrictedNames[
              this.restrictedNames.findIndex(
                (obj) => obj.name == data.nameRestrictor,
              )
            ].value = true)
          : this.restrictedNames;
        this.applicationPattern = data.applicationPattern;
        this.devicePattern = data.devicePattern;
        this.versionPattern = data.versionPattern;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
