export enum DeviceStatus {
  CREATING = 'Creating',
  CONNECTING = 'Connecting',
  CONNECTED = 'Connected',
  DISCONNECTED = 'Disconnected',
  DELETING = 'Deleting',
  FAILED = 'Failed',
  STARTING = 'Starting',
  STOPPING = 'Stopping',
  ERROR = 'error',
  OFFLINE = 'offline',
  UNDEFINED = 'undefined',
}
