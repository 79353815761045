import {
  Component,
  computed,
  EventEmitter,
  Input,
  model,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ServiceRole } from '../../../shared/components/user-list-components/models/user';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-select-user-role',
  standalone: true,
  imports: [
    TranslateModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatInputModule,
    MatSelectModule,
    TranslateModule,
    MatChipsModule,
    MatIconModule,
    FormsModule,
  ],
  templateUrl: './select-user-role.component.html',
  styleUrl: './select-user-role.component.scss',
})
export class SelectUserRoleComponent implements OnChanges {
  @Input({ required: true }) defaultRoles!: string[];
  @Input({ required: true }) autoLabel!: string;
  @Input({ required: true }) autoPlaceholder!: string;

  @Output() selectedRolesChanged: EventEmitter<string[] | null> =
    new EventEmitter();

  selectedUserRoles = new FormControl(
    [''],
    [Validators.required, Validators.minLength(1)],
  );

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  readonly currentRole = model('');
  readonly roles = signal(['']);
  readonly allRoles: string[] = Object.values(ServiceRole);
  readonly filteredRoles = computed(() => {
    const currentRole = this.currentRole().toLowerCase();
    return currentRole
      ? this.allRoles.filter((role) => role.toLowerCase().includes(currentRole))
      : this.allRoles.slice();
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['defaultRoles'].currentValue) {
      this.defaultRoles = changes['defaultRoles'].currentValue;
      this.roles.set(this.defaultRoles);
    }
  }

  remove(role: string): void {
    this.roles.update((roles) => {
      const index = roles.indexOf(role);
      if (index < 0) {
        return roles;
      }

      roles.splice(index, 1);
      return [...roles];
    });
    this.selectedRolesChanged.emit(this.roles());
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.roles.update((roles) =>
      roles.includes(event.option.value)
        ? [...roles]
        : [...roles, event.option.viewValue],
    );
    this.selectedUserRoles.setValue(this.roles());
    this.selectedUserRoles.updateValueAndValidity();

    this.currentRole.set('');
    event.option.deselect();
    this.selectedRolesChanged.emit(this.roles());
  }
}
