<table class="list-container">
    <thead>
      <tr>
        <th>Name</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
        @for (vecu of vecus | async; track $index) {
            <tr>
                <td>{{ vecu.displayName }}</td>
                @if ((loading | async) === false) {
                  <td data-cy="bulk-deletion-status">
                    {{ vecu.displayName| checkDeleteStatus: (deletionResponse | async) }}
                  </td>
                } @else {
                  <td><div class="dot-pulse"></div></td>
                }
            </tr>
        }
    </tbody>
</table>  
