export interface VecuCreatePayload {
  displayName: string;
  configuration: string;
}

export interface VecuCreateResponse {
  displayName: string;
  vecuId: string;
  message: string;
}

export interface VecuDeletePayload {
  vecuIdOrNames: string[];
}

export interface VecuDeletionResponse {
  vecuId: string;
  displayName: string;
  message: string;
  statusCode: number;
}

export interface VecuBulkDeletionResponse {
  bulkDeletionStatus: VecuDeletionResponse[];
}

export enum VecuColorStates {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
}

export enum VecuUiStates {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  IN_PROGRESS = 'In progress',
  ERROR = 'Error',
}

export interface VecuState {
  ui: VecuUiStates,
  color: VecuColorStates,
  message: string,
}

export interface Vecu {
  vecuId: string,
  vecuConnectCommand: string,
  displayName: string,
  createdAt: string,
  state: VecuState,
}
