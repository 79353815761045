import { Auth } from '@aws-amplify/auth';
import { Injectable } from '@angular/core';
import { AwsOrg } from '../../models/enums/AwsOrg';
import { cognitoConfig } from './cognitoConfig';
import { AuthStore, initialState } from 'src/app/shared/stores/auth/auth.store';
import { Router } from '@angular/router';
import { EnvironmentStore } from 'src/app/shared/stores/environment/environment.store';
import { Observable, catchError, from, map, of } from 'rxjs';
import { CognitoConfig } from '../../models/interfaces/cognitoConfig';
import { AuthState } from '../../models/interfaces/authState';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private authStore: AuthStore,
    private environmentStore: EnvironmentStore,
    private router: Router,
  ) {}

  configureAuthService() {
    const awsOrg = this.environmentStore.state.AWS_ORG;
    const config = this.getCognitoConfig(awsOrg);
    this.setAuthConfigure(config);
    return config;
  }

  getCognitoConfig(awsOrg: AwsOrg): CognitoConfig {
    const {
      AWS_COGNITO_REGION: region,
      AWS_COGNITO_IDENTITY_PROVIDER: identityProvider,
      AWS_COGNITO_CLIENT_ID: clientID,
      AWS_COGNITO_AUTH_URL: authURL,
      AWS_COGNITO_USER_POOL_ID: userPoolId,
    } = cognitoConfig[awsOrg];

    return {
      region,
      identityProvider,
      clientID,
      authURL,
      userPoolId,
    };
  }

  private setAuthConfigure(config: CognitoConfig) {
    Auth.configure({
      mandatorySignIn: true,
      region: config.region,
      userPoolId: config.userPoolId,
      userPoolWebClientId: config.clientID,
      oauth: {
        scope: ['openid', 'profile'],
        clientID: config.clientID,
        domain: config.authURL,
        responseType: 'code',
        redirectSignIn: window.location.origin + '/login',
        redirectSignOut: window.location.origin + '/logout',
      },
    });
  }

  async authenticateUser(): Promise<void> {
    const { identityProvider } = this.configureAuthService();
    try {
      await this.retrieveUserData();
    } catch (error) {
      if (error === 'The user is not authenticated') {
        const customProvider = { customProvider: identityProvider };
        await Auth.federatedSignIn(customProvider).catch((error) => {
          console.error(error);
        });
        await this.retrieveUser();
      }
      console.error(error);
    }
  }

  async retrieveUser() {
    await this.retrieveUserData().catch((error) => {
      console.error(error);
      this.router?.navigate(['/getting-started']);
    });
  }

  get userIsAuthenticated$(): Observable<boolean> {
    this.configureAuthService();
    return from(this.retrieveUserData()).pipe(
      map(() => true),
      catchError((error) => {
        console.error(error);
        return of(false);
      }),
    );
  }

  get retrieveUserData$(): Observable<AuthState> {
    this.configureAuthService();
    return from(this.retrieveUserData()).pipe(
      catchError((error) => {
        console.error(error);
        return of(initialState);
      }),
    );
  }

  async retrieveUserData(): Promise<AuthState> {
    const {
      signInUserSession: {
        accessToken: { jwtToken: accessToken },
        idToken: {
          jwtToken: idToken,
          payload: {
            email,
            'cognito:username': username,
            'custom:Tenant': tenant,
            'custom:FirstName': firstName,
            'custom:LastName': lastName,
          },
        },
        refreshToken: { token: refreshToken },
      },
    } = await Auth.currentAuthenticatedUser();
    const authData = {
      tokens: {
        idToken,
        refreshToken,
        accessToken,
      },
      user: {
        email,
        username,
        firstName,
        lastName,
      },
      tenant,
    };

    this.authStore.setState(authData);
    return authData;
  }

  async logoutUser(): Promise<void> {
    this.configureAuthService();

    try {
      await Auth.signOut();
      await this.router.navigate(['/logout']);
    } catch (e) {
      console.error(e);
    }
  }
}
