import { VecuUiStates } from './vecu';
import { VECU_STATUS_KEYS } from '../utils/selection-keys';

export const vecuStatusLabelMap = {
  [VecuUiStates.ACTIVE]: VECU_STATUS_KEYS.ACTIVE,
  [VecuUiStates.INACTIVE]: VECU_STATUS_KEYS.INACTIVE,
  [VecuUiStates.IN_PROGRESS]: VECU_STATUS_KEYS.IN_PROGRESS,
  [VecuUiStates.ERROR]: VECU_STATUS_KEYS.ERROR,
  'RUNNING': VECU_STATUS_KEYS.RUNNING,
  'UNKNOWN': VECU_STATUS_KEYS.UNKNOWN,
  'CREATE_IN_PROGRESS': VECU_STATUS_KEYS.CREATE_IN_PROGRESS,
  'DELETE_IN_PROGRESS': VECU_STATUS_KEYS.DELETE_IN_PROGRESS,
  'DELETION_FAILED': VECU_STATUS_KEYS.DELETION_FAILED,
  'PENDING_INSTALL': VECU_STATUS_KEYS.PENDING_INSTALL,
  'PENDING_UPGRADE': VECU_STATUS_KEYS.PENDING_UPGRADE,
  'PENDING_ROLLBACK': VECU_STATUS_KEYS.PENDING_ROLLBACK,
  'UNINSTALLING': VECU_STATUS_KEYS.UNINSTALLING,
  'IDLE': VECU_STATUS_KEYS.IDLE,
  'UNDEFINED': VECU_STATUS_KEYS.UNDEFINED,
  'DEPLOYED': VECU_STATUS_KEYS.DEPLOYED,
  'FAILED': VECU_STATUS_KEYS.FAILED,
  'PENDING': VECU_STATUS_KEYS.PENDING,
  'SUCCEEDED': VECU_STATUS_KEYS.SUCCEEDED,
  'WAITING': VECU_STATUS_KEYS.WAITING,
  'TERMINATED': VECU_STATUS_KEYS.TERMINATED,
};
