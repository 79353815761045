import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { HeaderTemplateComponent } from 'src/app/shared/components/header/header-template.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';
import { YieldReportService } from './services/yield-report.service';
import { CustomFormatDatePipe } from 'src/app/core/pipes/custom-format-date.pipe';
import { countryCode } from 'src/app/core/pipes/custom-format-date.pipe';
import { Subject } from 'rxjs';
import { ProjectNameMapping } from 'src/app/shared/utils/projectNameMapping';

export type YieldReportApi =
  | 'downloadYieldReport';

@Component({
  selector: 'app-yield-report',
  standalone: true,
  imports: [
    CommonModule,
    HeaderTemplateComponent,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    FormsModule,
  ],
  providers: [CustomFormatDatePipe],
  templateUrl: './yield-report.component.html',
  styleUrl: './yield-report.component.scss'
})
export class YieldReportComponent extends FeatureComponent<YieldReportApi> implements OnDestroy {

  private readonly destroy$: Subject<void> = new Subject();
  selectedDate: Date = new Date();
  projectName = '';

  constructor(
    private yieldReportService: YieldReportService,
    private customFormatDatePipe: CustomFormatDatePipe,
  ) {
    super();
    const urlPieces = window.location.href.split('/');
    this.projectName = urlPieces[urlPieces.length - 2];
  }

  downloadReport() {
    
    if(!this.API) {
      return;
    }
    const formatedDate: string = this.customFormatDatePipe.transformDate(
      this.selectedDate,
      countryCode.en,
      'yyyy-MM-dd'
    );
    const fileName: string = [
      formatedDate,
      ProjectNameMapping [this.projectName] || this.projectName,
      'yield_report'
    ].join('_') + '.csv';

    this.yieldReportService.getYieldReport(
      ProjectNameMapping [this.projectName] || this.projectName,
      fileName,
      this.API.downloadYieldReport.url
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
