import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaeButtonComponent } from '../cae-button/cae-button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cae-card',
  standalone: true,
  imports: [CommonModule, CaeButtonComponent, TranslateModule],
  templateUrl: './cae-card.component.html',
  styleUrls: ['./cae-card.component.scss'],
})
export class CaeCardComponent {
  @Input({ required: true }) title = 'title';
  @Input() subtitle = '';
  @Input() actions: Actions[] = [];
  @Input() isDarkButton = false;

  @Output() clickEvent = new EventEmitter();
}

export interface Actions {
  buttonText: string;
  icon: string;
  command: ActionCommand;
  disabled: boolean;
}

export enum ActionCommand {
  REQUEST = 'request',
  COPY = 'copy',
  REFRESH = 'refresh',
  DOWNLOAD = 'download',
  DEFAULT = 'default',
  STOP = 'stop',
  DELETE = 'delete',
  UPDATE = 'update',
}
