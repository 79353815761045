import { Injectable } from '@angular/core';
import { SortKey } from 'src/app/shared/utils/sortKeys';
import { Option } from 'src/app/core/models/interfaces/option';
import { Application } from 'src/app/shared/stores/deployment/models/application';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import {
  APPLICATION_TYPE_KEYS,
  ApplicationTypeKey,
  filterKeyToApplicationPropertyMap,
} from 'src/app/features/device-list/utils/applicationSelectionKeys';
import { DefaultFilters } from 'src/app/core/services/default/defaultFilters';
import { TranslateService } from '@ngx-translate/core';

type ApplicationListSelectionData = {
  applicationType: Option<ApplicationTypeKey>;
};

@Injectable({
  providedIn: 'root',
})
export class ApplicationListService implements DefaultFilters {
  constructor(private translate: TranslateService) {}

  defaultFilterValues(): ApplicationListSelectionData {
    return {
      applicationType: {
        id: APPLICATION_TYPE_KEYS[0],
        label: this.translate.instant(APPLICATION_TYPE_KEYS[0]),
      },
      /* sortBy: {
        id: DEVICE_SORT_KEYS[0],
        label: this.translate.instant(DEVICE_SORT_KEYS[0]),
      }, */
    };
  }

  applicationMap = ([applications, searchFilter, typeFilter, sortByOption]: [
    Application[],
    string,
    Option<ApplicationTypeKey>,
    Option<SortKey>,
  ]) => {
    return applications
      .filter((application) =>
        this.applicationFilter(application, searchFilter, typeFilter),
      )
      .sort(this.applicationSort(sortByOption.id));
  };

  applicationFilter = (
    application: Application,
    searchFilter: string,
    typeFilter: Option<ApplicationTypeKey>,
  ) => {
    return (
      application.appName.toLowerCase().includes(searchFilter.toLowerCase()) &&
      (typeFilter.id === 'ApplicationList.FilterKeys.Type.All' ||
        application.appType ===
          filterKeyToApplicationPropertyMap[typeFilter.id])
    );
  };

  applicationSort = (
    sortBy: SortKey,
  ): ((a: Application, b: Application) => number) => {
    switch (sortBy) {
      case 'SortKeys.Alphabetical':
        return (a: Application, b: Application) =>
          a.appName.localeCompare(b.appName, 'en');
      case 'SortKeys.AscendingDate': {
        return (a: Application, b: Application) => {
          const aDate = new Date(a.creationDate).getTime();
          const bDate = new Date(b.creationDate).getTime();
          return aDate - bDate;
        };
      }
      case 'SortKeys.DescendingDate': {
        return (a: Application, b: Application) => {
          const aDate = new Date(a.creationDate).getTime();
          const bDate = new Date(b.creationDate).getTime();
          return bDate - aDate;
        };
      }
    }
  };

  private selectMenusSource: BehaviorSubject<ApplicationListSelectionData> =
    new BehaviorSubject<ApplicationListSelectionData>(
      this.defaultFilterValues(),
    );

  get selectedApplicationType$(): Observable<Option<ApplicationTypeKey>> {
    return this.selectMenusSource.pipe(map((value) => value.applicationType));
  }

  selectedApplicationType = (applicationType: Option<ApplicationTypeKey>) => {
    this.selectMenusSource.next({
      ...this.selectMenusSource.value,
      applicationType,
    });
  };

  get applicationTypeOptions$(): Observable<Option<ApplicationTypeKey>[]> {
    return this.getOptions(
      APPLICATION_TYPE_KEYS,
      'applicationType',
      this.selectedApplicationType,
    );
  }

  getOptions<T extends string>(
    keys: readonly T[],
    optionKey: keyof ApplicationListSelectionData,
    setSelectedOption: (option: Option<T>) => void,
  ): Observable<Option<T>[]> {
    return this.translate.stream([...keys]).pipe(
      map((translations: Record<T, string>) =>
        Object.entries(translations as Record<string, string>).map(
          ([sortKey, translation]: [string, string]): Option<T> => ({
            id: sortKey as T,
            label: translation,
          }),
        ),
      ),
      tap((options) => {
        const selectedOption = this.selectMenusSource.value[optionKey];
        setSelectedOption(
          options.find((option) => option.id === selectedOption.id)!,
        );
      }),
    );
  }
}
