import { Injectable, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  Subject,
  takeUntil,
} from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { getParamFromUrl } from '../../utils/getParamFromId';
import { AuthStore } from '../auth/auth.store';
import { Project } from '../projects/models/project';
import { ProjectsStore } from '../projects/projects.store';
import { Breadcrumb } from './models/breadcrumb';
import { RoutingStore } from './routing.store';

export const initialBreadcrumbState: Breadcrumb[] = [];

const deviceBreadcrumbMapping: Record<string, string> = {
  'device-list': 'vECU AMI devices',
  'hdk': 'HDK devices',
  'vecu-list': 'vECU',
  'applications': 'Applications'
};

const DeviceBreadcrumbType = {
  VECU_AMI: 'device-list',
  HDK: 'hdk',
  VECU: 'vecu-list',
  APPLICATIONS: 'applications'
} as const;

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsStore extends Store<Breadcrumb[]> implements OnDestroy {
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private authStore: AuthStore,
    private router: Router,
    private routingStore: RoutingStore,
    private projectsStore: ProjectsStore,
  ) {
    super(initialBreadcrumbState);
    this.monitorUrlChange();


    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        console.log('Route changed to:', event.url);
      });
  }

  /**
   * Subscribes to router events to always have the
   * currently selected URL. Also subscribes to
   * the projects store to get the name of the currently selected
   * project and to the auth store to get the name of the currently
   * selected tenant.
   *
   */
  monitorUrlChange() {
    combineLatest([
      this.router.events.pipe(
        filter(
          (event: Event): event is NavigationEnd =>
            event instanceof NavigationEnd,
        ),
        distinctUntilChanged(),
        map(({ urlAfterRedirects }) => urlAfterRedirects),
      ),
      this.projectsStore.projects$,
      this.authStore.state$.pipe(map(({ tenant }) => tenant)),
      this.routingStore.state$.pipe(map(({ projectId }) => projectId)),
    ]).pipe(
      map(([url, projects, tenant, projectId]) => ({
        url,
        projects,
        tenant,
        projectId,
      })),
      takeUntil(this.unsubscribe$),
    ).subscribe(this.handleUrlChange);
  }

  /**
   * Handles the changing of the URL and automatically builds
   * breadcrumb items from the given information.
   *
   * There are 4 levels:
   * - Tenant
   * - Project
   * - HDK | vECU AMI | vECU | Applications lists
   * - HDK | vECU AMI | vECU | Applications individual resources dynamicaly assigned
   *
   * If there is no project ID, then only the tenant breadcrumb will be shown.
   * In case we have the project ID, the breacrumbs will be built based on
   * the routing paths.
   */
  handleUrlChange = ({
    url,
    projects,
    tenant,
    projectId,
  }: {
    url: string;
    projects: Project[];
    tenant: string;
    projectId: string;
  }) => {
    const currentProjectId = projectId;

    const projectUrlId = getParamFromUrl(url, '/project/');
    const applicationId = getParamFromUrl(url, '/applications/');
    const hdkDevicesId = getParamFromUrl(url, '/hdk/');
    const vecuAmiDevicesId = getParamFromUrl(url, '/device-list/');

    const projectName = projects.find(({ id }) => id === projectUrlId)?.label || '';

    const tenantBreadcrumb: Breadcrumb = {
      url: 'dashboard/home',
      label: tenant,
    };
    const projectBreadcrumb: Breadcrumb = {
      url: `/dashboard/project/${currentProjectId}/overview`,
      label: projectName,
    };
    const applicationBreadcrumb: Breadcrumb = {
      url: this.getBreadcrumbUrl(url, applicationId),
      label: applicationId,
    };
    const hdkDevicesBreadcrumb: Breadcrumb = {
      url: this.getBreadcrumbUrl(url, hdkDevicesId),
      label: hdkDevicesId
    };
    const vecuAmiDevicesBreadcrumb: Breadcrumb = {
      url: this.getBreadcrumbUrl(url, vecuAmiDevicesId),
      label: vecuAmiDevicesId
    };

    const buildBreadcrumbs = (): Breadcrumb[] => {
      const staticUrlSegments: string[] = [
        DeviceBreadcrumbType.VECU_AMI,
        DeviceBreadcrumbType.HDK,
        DeviceBreadcrumbType.VECU,
        DeviceBreadcrumbType.APPLICATIONS,
      ];

      const urlSegments = this.router.url.split('/').map(segment => segment.split('?')[0]).filter(segment => segment);

      const urlVariables = urlSegments.filter((segment) => {
        return staticUrlSegments.includes(segment);
      });

      let breadcrumbs: Breadcrumb[] = urlVariables.map(
        (urlVariable: string) => {
          switch (urlVariable) {
            case DeviceBreadcrumbType.VECU_AMI:
            case DeviceBreadcrumbType.HDK:
            case DeviceBreadcrumbType.VECU:
            case DeviceBreadcrumbType.APPLICATIONS:
              return {
                url: this.getBreadcrumbUrl(url, urlVariable),
                label: deviceBreadcrumbMapping[urlVariable]
              };
            default: return {
              url: this.getBreadcrumbUrl(url, urlVariable),
              label: urlVariable
            };
          }
        }
      );

      breadcrumbs = [
        ...breadcrumbs,
        ...[
          applicationBreadcrumb,
          hdkDevicesBreadcrumb,
          vecuAmiDevicesBreadcrumb
        ].filter(val => val.url.length > 0 && val.label.length > 0)
      ];

      if (currentProjectId) {
        return [tenantBreadcrumb, projectBreadcrumb, ...breadcrumbs];
      }
      return [tenantBreadcrumb];
    };

    this.setState(buildBreadcrumbs());
  };

  /**
   * Gets the URL for a breadcrumb from a given URL
   * and id.
   * If no id is identified, the original string is returned.
   *
   * @param {string} url
   * @param {string} id
   * @returns {string}
   */
  getBreadcrumbUrl(url: string, id: string): string {
    const index = url.indexOf(id);
    if (index !== -1) {
      return url.slice(0, index + id.length);
    }
    return url;
  }

  /**
   * Gets the current breadcrumb items.
   *
   * @returns {Observable<Breadcrumb[]>}
   */
  get breadcrumbs$(): Observable<Breadcrumb[]> {
    return this.state$;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
