import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  interval,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { ApiService } from 'src/app/core/services/api/api.service';
import { Method } from 'src/app/shared/stores/config/models/method';
import { generatePath } from 'src/app/shared/utils/generatePath';
import {
  Instance,
  IWorkbenchDataType,
  RequestWorkspaceAccess,
  WorkspaceCreation,
} from 'src/app/shared/stores/workbenches/models/workbenches';
import { WorkbenchStore } from 'src/app/shared/stores/workbenches/workbenches.stores';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ResetWorkspaceComponent } from '../components/reset-workspace/reset-workspace.component';
import { DeleteWorkspaceDialogComponent } from '../components/delete-workspace-dialog/delete-workspace-dialog.component';
import { DeleteWorkspaceInstanceResponse } from '../models/delete-workspace-instance-response';
import { SnackbarService } from '../../../core/services/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ResetWorkbenchRequestBody } from '../models/reset-worspace-instance-request-body';
import { SetPasswordDialogComponent } from '../components/set-password-dialog/set-password-dialog.component';
import {
  ResetWorkspaceResponse,
  ResetWorkspaceStatus,
} from '../models/reset-workspace-response';
import { UpdateWorkspaceService } from './update-workspace.service';
import { filter } from 'rxjs/operators';
import { ResetBodyRequestBody } from '../models/reset-password-instace-request-body';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  interval = 60 * 1000;
  private deleteNotifier = new BehaviorSubject(false);
  deleteNotifier$ = this.deleteNotifier.asObservable();
  constructor(
    private apiService: ApiService,
    private workbenchStore: WorkbenchStore,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
    private updateWorkspaceService: UpdateWorkspaceService,
    private translate: TranslateService,
  ) {}

  getWorkspaceTypes(apiRecord: ApiRecord, userId: string) {
    return this.apiService
      .request<IWorkbenchDataType>({
        apiRecord: {
          method: Method.GET,
          url: generatePath(apiRecord.url, { userId: userId }),
        },
      })
      .pipe(
        tap((workbenchTypes: IWorkbenchDataType) => {
          workbenchTypes.WorkBenchTypes.forEach((type) => {
            type.enable = false;
          });
          this.workbenchStore.setState({
            ...this.workbenchStore.state,
            WorkbenchTypes: workbenchTypes,
            isLoading: false,
            hasError: false,
          });
        }),
        catchError(() => {
          this.workbenchStore.setState({
            ...this.workbenchStore.state,
            isLoading: false,
            hasError: true,
          });
          return of({ Project: { name: '' }, user: '', WorkBenchTypes: [] });
        }),
      );
  }

  createWorkspace(
    apiRecord: ApiRecord,
    userId: string,
    workbenchName: string,
  ): Observable<WorkspaceCreation> {
    return this.apiService.request<WorkspaceCreation>({
      apiRecord: {
        method: Method.GET,
        url: generatePath(apiRecord.url, { userId, workbenchName }),
      },
    });
  }

  getRequestWorkspaceAccess(
    apiRecord: ApiRecord,
    userId: string,
    workbenchName: string,
  ): Observable<RequestWorkspaceAccess> {
    return this.apiService.request<WorkspaceCreation>({
      apiRecord: {
        method: Method.POST,
        url: generatePath(apiRecord.url, { userId, workbenchName }),
      },
    });
  }

  triggerWorkbenchPolling(
    apiRecord: ApiRecord,
    userId: string,
  ): Observable<IWorkbenchDataType> {
    return interval(this.interval).pipe(
      startWith(0),
      switchMap(() => this.getWorkspaceTypes(apiRecord, userId)),
    );
  }

  openDeleteInstanceDialog(workspaceId: string, apiRecordDeleteWb: ApiRecord) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '460px';
    dialogConfig.height = '278px';
    dialogConfig.data = {
      workspaceId: workspaceId,
    };

    const dialogRef = this.dialog.open(
      DeleteWorkspaceDialogComponent,
      dialogConfig,
    );
    this.handleDeleteDialogActions(dialogRef, workspaceId, apiRecordDeleteWb);
  }

  private handleDeleteDialogActions(
    dialogRef: MatDialogRef<DeleteWorkspaceDialogComponent>,
    id: string,
    apiRecord: ApiRecord,
  ) {
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((isDeleteConfirmed) => {
          if (isDeleteConfirmed) {
            return this.deleteInstance(id, apiRecord);
          }
          return of(null);
        }),
      )
      .subscribe({
        next: (data: DeleteWorkspaceInstanceResponse | null) => {
          if (!data) return;
          this.snackbarService.notifySuccess(data.message);
          this.deleteNotifier.next(true);
        },
        error: () => {
          const errorMessage = this.translate.instant(
            'Workspaces.DeleteDialog.Failing',
          );
          this.snackbarService.notifyError(errorMessage);
        },
      });
  }

  private deleteInstance(
    workspaceId: string,
    apiRecord: ApiRecord,
  ): Observable<DeleteWorkspaceInstanceResponse> {
    return this.apiService.request<DeleteWorkspaceInstanceResponse>({
      apiRecord: {
        method: Method.DELETE,
        url: generatePath(apiRecord.url, { workspaceId }),
      },
    });
  }

  openUpdateInstanceDialog(
    instance: Instance,
    updateWorkbenchApiRecord: ApiRecord,
    unsubscribe$: Subject<void>,
  ): void {
    this.updateWorkspaceService.openUpdateInstanceDialog(
      instance,
      updateWorkbenchApiRecord,
      unsubscribe$,
    );
  }

  openResetWorkbenchDialog(
    workbenchId: string | undefined,
    resetWorkbenchApiRecord: ApiRecord,
    unsubscribe$: Subject<void>,
  ): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '460px';
    dialogConfig.height = '400px';
    dialogConfig.data = {
      workbenchId: workbenchId,
      resetWorkbenchApiRecord: resetWorkbenchApiRecord,
    };
    const dialogRef = this.dialog.open(ResetWorkspaceComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(
        filter((requestData) => !!requestData),
        switchMap(
          (requestData: {
            body: ResetWorkbenchRequestBody;
            retainUserData: boolean;
          }) => {
            return this.resetInstance(
              resetWorkbenchApiRecord,
              requestData.body,
            ).pipe(
              take(1),
              map(() => requestData),
            );
          },
        ),
        takeUntil(unsubscribe$),
      )
      .subscribe({
        next: (requestData) => {
          this.handleSuccessResponse(requestData.retainUserData);
        },
        error: () => {
          this.snackbarService.notifyError(
            this.translate.instant('Workspaces.ResetWorkspace.ErrorResponse'),
          );
        },
      });
  }

  handleSuccessResponse(retainUserData: boolean) {
    if (retainUserData) {
      this.snackbarService.notifySuccess(
        this.translate.instant('Workspaces.ResetWorkspace.ResetScheduled'),
      );
    } else {
      this.snackbarService.notifySuccess(
        this.translate.instant('Workspaces.ResetWorkspace.ResetInitialed'),
      );
    }
  }

  resetInstance(
    resetWorkbenchApiRecord: ApiRecord,
    requestBody: ResetWorkbenchRequestBody,
  ): Observable<ResetWorkspaceResponse> {
    return this.apiService.request<ResetWorkspaceResponse>({
      apiRecord: resetWorkbenchApiRecord,
      body: requestBody,
    });
  }

  openResetPasswordDialog(
    userId: string,
    resetWorkbenchPasswordApiRecord: ApiRecord,
    unsubscribe$: Subject<void>,
  ): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '460px';
    dialogConfig.height = '334px';
    dialogConfig.data = {
      userId: userId,
    };
    const dialogRef = this.dialog.open(
      SetPasswordDialogComponent,
      dialogConfig,
    );
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((newPassword) => {
          if (!newPassword) {
            return of(null);
          }
          return this.resetWorkspacePassword(
            resetWorkbenchPasswordApiRecord,
            newPassword,
            userId,
          );
        }),
        takeUntil(unsubscribe$),
      )
      .subscribe({
        next: (data: ResetWorkspaceResponse | null) => {
          if (data) {
            data.status === ResetWorkspaceStatus.SUCCESS
              ? this.snackbarService.notifySuccess(
                  this.translate.instant(
                    'Workspaces.ResetPassword.ResetPasswordRequestSuccess',
                  ),
                )
              : this.snackbarService.notifyError(data.message);
          }
        },
        error: () => {
          this.snackbarService.notifyError(
            this.translate.instant(
              'Workspaces.ResetPassword.ResetPasswordRequestFailed',
            ),
          );
        },
      });
  }

  resetWorkspacePassword(
    resetWorkbenchPasswordApiRecord: ApiRecord,
    newPassword: string,
    userId: string,
  ): Observable<ResetWorkspaceResponse> {
    const requestBody: ResetBodyRequestBody = {
      password: newPassword,
    };
    return this.apiService.request<any>({
      apiRecord: {
        method: Method.POST,
        url: generatePath(resetWorkbenchPasswordApiRecord.url, { userId }),
      },
      body: requestBody,
    });
  }
}
