<div class="application-container">
  <h4>{{ applicationName }}</h4>
  <p *ngIf="applicationRepo">
    {{
      applicationRepo.length > 50
        ? (applicationRepo | slice : 0 : 50) + "..."
        : applicationRepo
    }}
  </p>

  <app-chip
    class="app-type-chip"
    data-cy="app-type-chip"
    [label]="applicationType"
    [variant]="ChipVariant.NEUTRAL_WHITE"
  />
</div>
