<mat-select data-cy="language-selector" id="language-selector"
  hideSingleSelectionIndicator
  #langSelect
  class="language-picker"
  panelClass="language-panel"
  [value]="selectedLanguage$ | async"
  (selectionChange)="onChangeLanguage(langSelect.value)"
>
  <mat-option data-cy="language-option" id="language-mat-option" *ngFor="let lang of languages" [value]="lang">
    {{ languageMap[lang] }}
  </mat-option>
</mat-select>
