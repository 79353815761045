<div class="delete-container">
  <h2
    class="delete-header"
    data-cy="delete-workspace-title"
    mat-dialog-title
  >
    {{ title | translate }}
  </h2>
  <div class="header-subtitle" data-cy="delete-workspace-subtitle" mat-dialog-title>
    {{ message | translate }}
  </div>

  <app-dialog-hint [isWarning]="true" message="{{warningText}}"></app-dialog-hint>
</div>

<div class="workspace-delete-footer">
  <div class="actions" mat-dialog-actions align="end">

    <button class="cancel"  mat-flat-button mat-dialog-close>
      {{ "General.Cancel" | translate }}
    </button>
    <button
      data-cy="delete-wb-confirm-btn"
      class="delete-wb-confirm-btn"
      mat-flat-button
      type="submit"
      (click)="deleteWorkspaceInstance()"
      mat-dialog-close
    >
      {{ "Workspaces.DeleteDialog.DeleteConfirm" | translate }}
    </button>
  </div>
</div>
