<ng-container *ngIf="playlistDetails$ | async as playlistDetails">
<ng-container class="playlist-details-container" *ngIf="!loading; else loadingBar">

  <app-header-template [itemName]="playlistDetails.playlist_id"
    [showBackButton]="true" [useBrowserHistory]="true" [isRowDisplayed]="true" data-cy="playlist-details-overview">
  </app-header-template>


    <div class="playlist-details-fields">
      <div class="title-col">
        <div class="playlist-details-fields">
          <span class="title">{{ "Playlist.Details.Title" | translate }}</span>
        </div>
        <div class="playlist-details-fields">
          <span class="description">{{ "Playlist.Details.Subtitle" | translate }}</span>
        </div>
      </div>
    </div>
    <div class="playlist-details-fields">
      <span class="key">{{ "Playlist.Details.PlaylistId" | translate }} </span>
      <span class="value">{{ playlistDetails.playlist_id }}</span>
      <div class="value">
        <mat-icon *ngIf="playlistDetails.playlist_id.length > 0" class="copy-icon" matTooltip="Copy"
          matTooltipPosition="right" (click)="copyToClipboard(playlistDetails.playlist_id)">file_copy
        </mat-icon>
      </div>
    </div>
    <div class="playlist-details-fields">
      <span class="key">{{ "Playlist.Details.FileName" | translate }}</span>
      <span class="details-string">{{ playlistDetails.playlist_location
        }}</span>
      <div class="value">
        <mat-icon *ngIf="playlistDetails.playlist_location.length > 0" class="copy-icon" matTooltip="Copy"
          matTooltipPosition="right" (click)="copyToClipboard(playlistDetails.playlist_location)">file_copy
        </mat-icon>
      </div>
    </div>
    <div class="playlist-details-fields">
      <span class="key">{{ "Playlist.Details.Duration" | translate }}</span>
      <span class="value">{{ playlistDetails.counters.total_duration }}</span>
    </div>
    <div class="playlist-details-fields">
      <span class="key">{{ "Playlist.Details.Section" | translate }}</span>
      <span class="value">{{ playlistDetails.counters.sections_count }}</span>
    </div>
    <div class="playlist-details-fields">
      <span class="key">{{ "Playlist.Details.Type" | translate }}</span>
      <app-chip class="type-chip" [label]="filterStage(playlistDetails.stage.substr(playlistDetails.stage.length-1))"
        [variant]="ChipVariant.NEUTRAL_GRAY" />
    </div>
  </ng-container>

<ng-template #loadingBar>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
