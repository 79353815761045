<div class="set-password-dialogue-box" tabindex="0">
  <div class="dialogue-header" data-cy="set-password-title" mat-dialog-title>
    {{ "Workspaces.ResetPassword.ResetPasswordBtn" | translate }}
  </div>
  <form [formGroup]="passwordForm" (keyup.enter)="submitForm()" tabindex="0" role="form"  aria-label="Set password">
    <div class="content" mat-dialog-content>
      <mat-form-field
        class="caedge-form-field-outline new-password"
        ngDefaultControl
      >
        <mat-label>{{
          "Workspaces.ResetWorkspace.NewPassword" | translate
        }}</mat-label>
        <input
          type="password"
          formControlName="newPassword"
          data-cy="new-password"
          matInput
          (input)="checkPasswordStrength($event)"
        />
        <mat-hint>{{ passwordStrengthHint }}</mat-hint>
        <mat-error *ngIf="passwordForm.get('newPassword')?.invalid">
          {{ passwordStrengthHint }}
        </mat-error>
      </mat-form-field>
      <mat-form-field
        class="caedge-form-field-outline new-password"
        ngDefaultControl
      >
        <mat-label>{{
          "Workspaces.ResetWorkspace.ConfirmPassword" | translate
        }}</mat-label>
        <input
          type="password"
          formControlName="confirmPassword"
          data-cy="confirm-password"
          matInput
          (input)="checkConfirmPassword()"
        />
        <mat-error
          *ngIf="passwordForm.get('confirmPassword')?.errors?.['notSame']"
        >
          {{ passwordsMatchError }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div class="footer">
  <div class="actions" mat-dialog-actions align="end">
    <button
      data-cy="set-password-cancel-btn"
      class="cancel"
      mat-flat-button
      mat-dialog-close
    >
      {{ "General.Cancel" | translate }}
    </button>
    <button
      data-cy="set-password-confirm-btn"
      class="set-password-confirm-btn"
      mat-flat-button
      type="submit"
      (click)="submitForm()"
      (keyup.enter)="submitForm()"
      [disabled]="(formValidity$ | async) === false"
    >
      {{ "Workspaces.ResetPassword.ResetPasswordConfirmBtn" | translate }}
    </button>
  </div>
</div>
