<button [cdkMenuTriggerFor]="menu" class="select-menu-trigger">
  <ng-container *ngIf="selectedOption">
    {{ selectedOption.label }} ▾
  </ng-container>
</button>
<ng-template #menu>
  <div class="select-menu" cdkMenu data-cy="select-menu-item">
    <button
      *ngFor="let option of options"
      class="select-menu-item"
      cdkMenuItem
      (click)="setSelectedOption(option)"
    >
      {{ option.label }}
    </button>
  </div>
</ng-template>
