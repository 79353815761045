import { Pipe, PipeTransform } from '@angular/core';
import { WorkbenchType, WorkspaceState } from 'src/app/shared/stores/workbenches/models/workbenches';

@Pipe({
  name: 'connectBtn',
  standalone: true
})
export class connectBtnPipe implements PipeTransform {
  transform(workbenchType: WorkbenchType): boolean {
    return !!(
        workbenchType.instance?.actions?.connect?.url &&
        workbenchType.instance?.state !== WorkspaceState.PENDING &&
        workbenchType.instance?.state !== WorkspaceState.TERMINATING
      );
  }
}
