@if (isEmpty) {
  <app-empty-section
    [iconName]= EmptySectionIcon.COFFEE_MAKER
    [emptySectionScope]="EmptySectionScope.PAGE"
  >
    <p>{{ "Deployments.NoDeployment" | translate }}</p>
  </app-empty-section>
} @else {
  <mat-accordion multi>
    <ng-container class="deployment-list-container">
      <app-deployment-list-expansion-panel
        *ngFor="let version of versions; trackBy: onPropertyChanges"
        [version]="version"
        [application]="application"
      >
      </app-deployment-list-expansion-panel>
    </ng-container>
  </mat-accordion>
}
