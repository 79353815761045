interface ContactsTenantInfo {
    SecurityProjectOwner: ContactDetails;
    SecurityCentralOps: ContactDetails;
    Technical: ContactDetails;
    AccountOwner: ContactDetails;
    Billing: ContactDetails;
    AccountCoOwner: ContactDetails[],
}

interface ContactsProjectInfo {
    SecurityProjectOwner: ContactDetails;
    SecurityCentralOps: ContactDetails;
    Technical: ContactDetails;
    AccountOwner: ContactDetails;
    Billing: ContactDetails;
    ProjectCoOwner: ContactDetails[],
}

export type Contacts = ContactsTenantInfo | ContactsProjectInfo

export const ProjectAdminRightsRole = {
    ProjectOwner: 'ProjectOwner',
    ProjectCoOwner: 'ProjectCoOwner',
} as const;

export type ProjectAdminRightsRole = (typeof ProjectAdminRightsRole)[keyof typeof ProjectAdminRightsRole];

export interface ContactDetails {
    ContiUid: string;
    Email: string;
    Phone: string;
    Name: string;
}

export interface ContactRoles extends ContactDetails {
    role: ProjectAdminRightsRole | string;
}
