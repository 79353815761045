import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';

import { Quota } from 'src/app/core/models/interfaces/quota';

import {
  ChipComponent,
  ChipVariant,
} from 'src/app/shared/components/chip/chip.component';

@Component({
  selector: 'app-quota-bar',
  standalone: true,
  imports: [
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    CommonModule,
    ChipComponent,
  ],
  templateUrl: './quota-bar.component.html',
  styleUrl: './quota-bar.component.scss',
})
export class QuotaBarComponent {
  @Input() quota: Quota = {
    isLimited: false,
    projectQuotaMinutes: 0,
    userQuotaMinutes: 0,
    remainingUserQuotaPercentage: 0,
    isQuotaExceeded: false,
  };

  highQuotaThreshold: number = 50;
  mediumQuotaThreshold: number = 80;

  icon: string = 'schedule';

  ChipVariant = ChipVariant;
}
