export interface BackendDataSensorsStage1 {
    allowed_sensors: string[];
    allowed_functions: string[];
    front_sensors: string[];
    back_sensors: string[];
};

export interface AdjustedSensorsStage1 {
    allowedSensors: string[];
    allowedFunctions: string[];
    frontSensors: string[];
    backSensors: string[];
}

export class SensorsStage1 implements AdjustedSensorsStage1 {
    allowedSensors!: string[];
    allowedFunctions!: string[];
    frontSensors!: string[];
    backSensors!: string[];

    public static Factory(dataSensorCombination: BackendDataSensorsStage1): SensorsStage1 {
        const newSensorsStage1 = new SensorsStage1();
        newSensorsStage1.allowedSensors = dataSensorCombination.allowed_sensors;
        newSensorsStage1.allowedFunctions = dataSensorCombination.allowed_functions;
        newSensorsStage1.frontSensors = dataSensorCombination.front_sensors;
        newSensorsStage1.backSensors = dataSensorCombination.back_sensors;
        return newSensorsStage1;
    }
}
