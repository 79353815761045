<div class="view-toggle" *ngIf="theme$ | async as theme">
  <button
    data-cy="grid-view-button"
    [ngClass]="{ active: selectedView === 'grid' }"
    aria-label="grid-view"
    [matTooltip]="'General.GridView' | translate"
    [matTooltipDisabled]="!isTooltip"
    (click)="changeView('grid')"
  >
    <img [src]="getIcon(theme, 'grid')" alt="grid icon" />
  </button>
  <button
    data-cy="list-view-button"
    [ngClass]="{ active: selectedView === 'list' }"
    aria-label="list-view"
    [matTooltip]="'General.ListView' | translate"
    [matTooltipDisabled]="!isTooltip"
    (click)="changeView('list')"
  >
    <img [src]="getIcon(theme, 'list')" alt="list icon" />
  </button>
</div>
