import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AddStageStepOneComponent } from '../add-stage-step-one/add-stage-step-one.component';
import { AddStageStepTwoComponent } from '../add-stage-step-two/add-stage-step-two.component';
import { AddStagePlaylistFooterComponent } from '../add-stage-playlist-footer/add-stage-playlist-footer.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ApiRecord } from '../../../../shared/stores/config/models/apiRecord';
import { Observable, of, switchMap, takeUntil, Subject } from 'rxjs';
import { PlaylistApi } from '../../playlist.component';
import {
  AddStagePlaylistDialogService,
  StageNumber,
} from '../../services/add-stage-playlist-dialog.service';
import { AuthStore } from '../../../../shared/stores/auth/auth.store';
import { PlaylistMasterCollection } from '../../models/playlist-collections';
import { CommonModule } from '@angular/common';
import { RoutingStore } from 'src/app/shared/stores/config/routing.store';
import { StepOneData, StepTwoData } from '../../models/stage-data';
import { StageOneCreationPayload } from '../../models/stage-one-creation-payload';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { StageTwoCreationPayload } from '../../models/stage-two-creation-payload';
import { ProjectConfiguration } from '../../models/project-configuration';

export interface AddPlaylistData {
  stageNumber: StageNumber;
  projectId: string;
  apis: Record<PlaylistApi, ApiRecord>;
  unsubscribe$: Observable<void>;
}

@Component({
  selector: 'app-add-stage-playlist',
  standalone: true,
  imports: [
    AddStageStepOneComponent,
    AddStageStepTwoComponent,
    AddStagePlaylistFooterComponent,
    TranslateModule,
    MatDialogTitle,
    MatDialogActions,
    MatDialogContent,
    CommonModule,
  ],
  templateUrl: './add-stage-playlist.component.html',
  styleUrl: './add-stage-playlist.component.scss',
})
export class AddStagePlaylistComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();
  protected readonly steps = Steps;
  previousIndex: number = 0;
  currentIndex = 0;
  repeat: boolean = false;
  repo: string | null = '';
  enableSubmitButton: boolean = false;
  enableNextButton: boolean = false;
  apiKey: string = '';
  projectConfiguration: ProjectConfiguration = new ProjectConfiguration();
  masterCollection$: Observable<PlaylistMasterCollection[]> = this.getApiKey();
  StageNumber = StageNumber;
  projectName: string = '';
  stepOneData: StepOneData = { guids: [] };
  stepTwoData: StepTwoData = { sensors: [] };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddPlaylistData,
    private playlistService: AddStagePlaylistDialogService,
    private authStore: AuthStore,
    private routingStore: RoutingStore,
    private snackBarService: SnackbarService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<AddStagePlaylistComponent>,
  ) {}

  ngOnInit() {
    this.projectName = this.routingStore.state.projectId;
  }

  getStepOneNotification(event: StepOneData): void {
    this.stepOneData.guids = event.guids;
    if (
      this.stepOneData.guids === undefined ||
      this.stepOneData.guids.length === 0
    ) {
      this.enableNextButton = false;
    } else {
      this.enableNextButton = true;
    }
  }

  getStepTwoNotification(event: StepTwoData | undefined): void {
    if (event === undefined) {
      this.enableSubmitButton = false;
    } else {
      this.stepTwoData.sensors = event.sensors;
      this.stepTwoData.filterSections = event.filterSections;
      this.stepTwoData.sensorVersions = event.sensorVersions;
      this.enableSubmitButton = this.isStepValid();
    }
  }

  jumpToStep(index: Steps) {
    this.previousIndex = this.currentIndex;
    this.currentIndex = index;

    if (this.currentIndex < this.previousIndex) {
      this.resetTemplateValues();
    }

    switch (index) {
      case Steps.enterGuids:
        this.enableNextButton =
          this.stepOneData.guids?.length === 0 ||
          this.stepOneData.guids !== undefined;
        break;
      case Steps.selectSensors:
        this.enableNextButton = false;
        break;
    }
  }

  resetTemplateValues() {
    this.enableSubmitButton = false;
    this.enableNextButton = false;
  }

  generateStagePlaylist(): void {
    if (this.data.stageNumber === StageNumber.STAGE_ONE) {
      this.createStageOnePlaylistDataAndClose();
    } else {
      this.createStageTwoPlaylistDataAndClose();
    }
  }

  private isStepValid(): boolean {
    if (
      (this.stepTwoData.sensorVersions === '' ||
        this.stepTwoData.sensorVersions === undefined) &&
      this.data.stageNumber === StageNumber.STAGE_TWO
    ) {
      return false;
    } else {
      return true;
    }
  }

  private getApiKey(): Observable<PlaylistMasterCollection[]> {
    const userId: string = this.authStore.userId;
    if (userId === '') {
      this.snackBarService.notifySuccess(
        this.translateService.instant(
          'Playlists.AddStageDialogCommon.CannotGetUserId',
        ),
      );
      return of([]);
    }
    return this.playlistService
      .getApiKey(this.data.apis.getApiKey, userId)
      .pipe(
        switchMap((val) => {
          this.apiKey = val.api_key;
          return this.playlistService.getMasterCollections(
            this.data.apis.getMasterCollections,
            this.apiKey,
          );
        }),
        takeUntil(this.unsubscribe$),
      );
  }

  private createStageOnePlaylistDataAndClose() {
    const creationData: StageOneCreationPayload = {
      project: this.projectName,
      guids: this.stepOneData.guids,
      sensors: this.stepTwoData.sensors,
    };
    if (this.stepTwoData.filterSections) {
      creationData.sections_filter = this.stepTwoData.filterSections;
    }
    this.dialogRef.close(creationData);
  }

  private createStageTwoPlaylistDataAndClose() {
    const creationData: StageTwoCreationPayload = {
      project: this.projectName,
      guids: this.stepOneData.guids,
      sim1_version: this.stepTwoData.sensorVersions ?? '',
      sensors: 'ALL',
    };
    this.dialogRef.close(creationData);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export enum Steps {
  enterGuids,
  selectSensors,
}
