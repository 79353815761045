import { GeneralApi, GeneralFeatureConfig } from './generalApi';
import { environment } from '../../../../../environments/environment';


export const SECTION_LABEL = (options: GeneralFeatureConfig<GeneralApi>) => {
  return {
    label: options.label ? options.label : 'Data & simulation',
    type: 'FEATURE_SECTION',
    nonNavigatable: 'true',
    path: '',
  };
};

export const WORKSPACE_PAGE = (options: GeneralFeatureConfig<GeneralApi>) => {
  return {
    id: '000111111',
    featureId: 'WORKSPACE_PAGE',
    label: 'Workbenches',
    type: 'FEATURE',
    icon: 'folder',
    path: 'project/:projectId/workspace',
    API: {
      getWorkspaceTypes: {
        url: `${options.api.workspace}/listWorkbenchTypes?uid=:userId`,
        method: 'GET',
      },
      createWorkspace: {
        url: `${options.api.workspace}/createWorkbench?uid=:userId&workbenchType=:workbenchName`,
        method: 'GET',
      },
      requestWorkspaceAccess: {
        url: `${options.api.workspace}/email?uid=:userId&workbenchType=:workbenchName`,
        method: 'POST',
      },
      deleteWorkspaceInstance: {
        url: `${options.api.workspace}/deleteWorkbench?workbenchId=:workspaceId`,
        method: 'GET',
      },
      resetWorkspaceInstance: {
        url: `${options.api.workspace}/resetWorkbench`,
        method: 'POST',
      },
      updateWorkspaceInstance: {
        url: `${options.api.workspace}/updateWorkbench`,
        method: 'POST',
      },
      resetWorkspacePassword: {
        url: `${options.api.workspace}/password?uid=:userId`,
        method: 'POST',
      },
    },
  };
};

export const YIELD_REPORT_PAGE = (options: GeneralFeatureConfig<GeneralApi>) => {
  return {
    id: '000112233',
    featureId: 'YIELD_REPORT_PAGE',
    label: 'Yield report',
    type: 'FEATURE',
    icon: 'auto_awesome_motion',
    path: 'project/:projectId/yield_report',
    API: {
      downloadYieldReport: {
        url: `${options.api.yieldReport}/:projectName/reports/yield_report/:fileName`,
        method: 'GET',
      },
    },
  };
};

export const VIRTUAL_DEVELOPMENT_INFRASTRUCTURE = (
  options: GeneralFeatureConfig<GeneralApi>,
) => {
  return {
    id: '12312321',
    featureId: 'VIRTUAL_DEVELOPMENT_INFRASTRUCTURE',
    label: 'VDI',
    type: 'FEATURE',
    icon: 'settings_system_daydream',
    path: 'project/:projectId/vdi',
    API: {
      getVDIConfigs: {
        url: `${options.api.dev}/vdi/configs`,
        method: 'GET',
      },
      downloadMatchboxScript: {
        url: `${options.api.dev}/vdi/script`,
        method: 'GET',
      },
      getVDInstances: {
        url: `${options.api.dev}/vdi/instances`,
        method: 'GET',
      },
      delVDInstance: {
        url: `${options.api.dev}/vdi/instances`,
        method: 'DELETE',
      },
      putVDInstance: {
        url: `${options.api.dev}/vdi/instances`,
        method: 'PUT',
      },
    },
  };
};

export const PLAYLIST = (options: GeneralFeatureConfig<GeneralApi>) => {
  return {
    id: '123456789',
    featureId: 'PLAYLIST',
    label: 'Playlist',
    type: 'FEATURE',
    icon: 'playlist_play',
    path: 'project/:projectId/playlist',
    API: {
      getPlaylist: {
        url: `${options.api.playlist}/dev/v2/lookups?mode=list_playlists`,
        method: 'POST',
      },
      getProjectConfiguration: {
        url: `${options.api.playlist}/dev/v2/lookups?mode=get_project_config`,
        method: 'POST',
      },
      getMasterCollections: {
        url: `${options.api.falcon}/fetch_collections`,
        method: 'GET',
      },
      getChildCollections: {
        url: `${options.api.falcon}/fetch_collection_childs`,
        method: 'POST',
      },
      getChildCollectionRecordings: {
        url: `${options.api.falcon}/fetch_collection_recordings`,
        method: 'POST',
      },
      getApiKey: {
        url: `${options.api.falcon}/api_key`,
        method: 'POST',
      },
      createStageOne: {
        url: `${options.api.playlist}/dev/v2/stage1`,
        method: 'POST',
      },
      getSensorVersions: {
        url: `${options.api.playlist}/dev/v2/lookups?mode=distinct_versions`,
        method: 'POST',
      },
      createStageTwo: {
        url: `${options.api.playlist}/dev/v2/stage2`,
        method: 'POST',
      },
    },
    children: [
      {
        id: '1234567891',
        featureId: 'PLAYLIST_DETAILS',
        label: 'Playlist Details',
        type: 'FEATURE',
        icon: 'task_alt',
        path: ':playlistId',
        API: {
          getPlaylist: {
            url: `${options.api.playlist}/dev/v2/lookups?mode=list_playlists`,
            method: 'POST',
          },
          getPlaylistDetails: {
            url: `${options.api.playlist}/dev/v2/status/:playlistId`,
            method: 'GET',
          },
        },
      },
    ],
  };
};

export const SIMULATION_JOB_MONITORING = (
  options: GeneralFeatureConfig<GeneralApi>,
) => {
  return {
    id: '123456789',
    featureId: 'SIMULATION_JOB_MONITORING',
    label: 'Jobs',
    type: 'FEATURE',
    icon: 'task_alt',
    path: 'project/:projectId/jobs',
    API: {
      jobMonitoringSimulation: {
        url: `${options.api.dev}/information/api/v1/workflows/~sim/runs`,
        method: 'GET',
      },
      jobMonitoringReporting: {
        url: `${options.api.dev}/information/api/v1/workflows/~kpi/runs`,
        method: 'GET',
      },
      getProjects: {
        url: `${options.api.dev}/api/v1/project/`,
        method: 'GET',
      },
      getWorkflows: {
        url: `${options.api.dev}/api/v1/project/:projectName/workflows`,
        method: 'GET',
      },
      playlist: {
        url: `${options.api.playlist}/${environment.AWS_STAGE}/v2/lookups?mode=list_playlists`,
        method: 'POST',
      },
      getInputDataSets: {
        url: `${options.api.dataSets}/input_sets/names?input_set_root_url=/:projectName&with_leafs=true&no_cache=false`,
        method: 'GET',
      },
      getProjectConfig: {
        url: `${options.api.playlist}/${environment.AWS_STAGE}/v2/lookups?mode=get_project_config`,
        method: 'POST',
      },
      getParameterTemplates: {
        url: `${options.api.dev}/api/v1/project/:projectName/workflows/:id/templates`,
        method: 'GET',
      },
      createJob: {
        url: `${options.api.dev}/api/v1/project/:projectName/workflows/:workflow`,
        method: 'POST',
      },
    },
    children: [
      {
        id: '1234567891',
        featureId: 'SIMULATION_JOB_MONITORING_DETAILS',
        label: 'Job Details',
        type: 'FEATURE',
        icon: 'task_alt',
        path: ':jobId',
        API: {
          getJobExecutionDetailsReporting: {
            url: `${options.api.dev}/information/api/v1/workflows/~kpi/runs/:dagRunId`,
            method: 'GET',
          },
          getJobExecutionDetailsSimulation: {
            url: `${options.api.dev}/information/api/v1/workflows/~sim/runs/:dagRunId`,
            method: 'GET',
          },
          getChildJobsSimulation: {
            url: `${options.api.dev}/information/api/v1/workflows/srr-stage1/runs/:dagRunId/childjobs`,
            method: 'GET',
          },
          cancelJob: {
            url: `${options.api.dev}/api/v1/project//workflows//:run_id`,
            method: 'DELETE',
          },
          getChildJobSteps: {
            url: `${options.api.dev}/information/api/v1/workflows/srr-stage1/runs/:dagRunId/childjobs/:childJobId`,
            method: 'GET',
          },
          requestStepDownload: {
            url: `${options.api.dev}/logs/get_logs`,
            method: 'POST',
          },
          requestLogDownload: {
            url: `${options.api.prod}/logs/get_logs`,
            method: 'POST',
          },
          downloadConfigFile: {
            //ToDo: only for the moment - they will change the custom domain name suffix to tenant
            url: `${options.api.downloadJobConfig}/config-download`,
            method: 'POST',
          },
        },
      },
    ],
  };
};
