import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { AddJobFooterComponent } from '../add-job-footer/add-job-footer.component';
import { SelectWorkflowComponent } from '../select-workflow/select-workflow.component';
import { JobMonitoringService } from '../../services/job-monitoring.service';
import { SimulationPlaylistComponent } from '../simulation-playlist/simulation-playlist.component';
import { CurrentTab, JobMonitoringApi } from '../../job-monitoring.component';
import { ReportingTreeViewComponent } from '../reporting-tree-view/reporting-tree-view.component';
import { TreeNode } from '../../models/tree-node';
import { DefineParameterComponent } from '../define-parameter/define-parameter.component';
import { AddJobOverviewComponent } from '../add-job-overview/add-job-overview.component';
import { INewJob, NewJob } from '../../models/new-job';
import { EmptySectionComponent } from '../../../../shared/components/empty-section/empty-section.component';
import { SnackbarService } from '../../../../core/services/snackbar/snackbar.service';
import { ApiRecord } from '../../../../shared/stores/config/models/apiRecord';
import { FlatNode } from '../../models/flat-node';
import { TranslateService } from '@ngx-translate/core';

export interface CreateJobDialogData {
  currentTab: CurrentTab;
  apis: Record<JobMonitoringApi, ApiRecord>;
  project: string;
}

@Component({
  selector: 'app-add-job',
  standalone: true,
  imports: [
    CommonModule,
    AddJobFooterComponent,
    SelectWorkflowComponent,
    ReportingTreeViewComponent,
    SimulationPlaylistComponent,
    DefineParameterComponent,
    AddJobOverviewComponent,
    EmptySectionComponent,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
  ],
  templateUrl: './add-job.component.html',
  styleUrls: ['./add-job.component.scss'],
})
export class AddJobComponent {
  projectName = this.data.project;
  projectType = this.data.currentTab;
  apiRecords: Record<JobMonitoringApi, ApiRecord> = this.data.apis;

  newJob: NewJob = new NewJob();
  workflows: Promise<string[]> | undefined = this.getWorkflows();
  inputDataSet: Promise<TreeNode[] | undefined> = this.getInputDataSet();
  runId: string = '';
  stage: string | null = null;

  currentIndex = 0;
  repeat: boolean = false;
  repo: string | null = '';
  enableSubmitButton: boolean = false;
  enableNextButton: boolean = false;

  protected readonly steps = Steps;
  protected readonly CurrentTab = CurrentTab;
  protected previousIndex: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateJobDialogData,
    private jobMonitoringService: JobMonitoringService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
  ) {}

  getWorkflows(): Promise<string[]> | undefined {
    try {
      const apiRecord = this.apiRecords.getWorkflows;
      return this.jobMonitoringService.getWorkflows(
        apiRecord,
        this.projectType,
        this.projectName,
      );
    } catch (err) {
      console.error(err);
      return;
    }
  }

  async getInputDataSet(): Promise<TreeNode[] | undefined> {
    try {
      const apiRecord = this.apiRecords.getInputDataSets;
      return this.jobMonitoringService.getInputDataSets(
        apiRecord,
        this.projectName,
      );
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  async jumpToStep(index: Steps): Promise<void> {
    this.previousIndex = this.currentIndex;
    this.currentIndex = index;

    if (this.currentIndex < this.previousIndex) {
      this.resetTemplateValues();
    }

    switch (index) {
      case Steps.first:
        this.repeat = false;
        break;
      case Steps.second:
        this.enableNextButton = !this.newJob.input_location;
        break;
      case Steps.third:
        this.enableNextButton = !this.validateSimulationJobProperties(
          this.newJob,
        );
        break;
      case Steps.overview:
        await this.verifySubmit();
        break;
    }
  }

  resetTemplateValues() {
    this.enableSubmitButton = false;
    this.enableNextButton = false;
    this.repeat = false;
    this.runId = '';
  }

  /**
   * Checks all attributes, returns false if one of the attributes are null or invalid
   *
   * @returns {boolean}
   * @param{NewJob} newJob
   */
  validateSimulationJobProperties(newJob: INewJob): boolean {
    return Object.values(newJob).every((x) => !!x);
  }

  async verifySubmit(): Promise<void> {
    const outputLocation = await this.jobMonitoringService.getOutputValue(
      this.apiRecords.getProjectConfig,
      this.projectName,
    );
    this.newJob.output_location = `s3://${outputLocation}`;
    this.newJob.project = this.projectName;
    this.enableSubmitButton = this.validateSimulationJobProperties(this.newJob);
  }

  async submitCreateJobForms(): Promise<void> {
    try {
      const createdJob = await this.jobMonitoringService.createJob(
        this.apiRecords.createJob,
        this.projectName,
        this.newJob,
      );
      this.snackbarService.notifySuccess(
        this.translate.instant('Jobs.JobCreated'),
      );
      this.runId = createdJob.run_id;
      this.enableSubmitButton = true;
      this.repeat = true;
      this.newJob = new NewJob();
    } catch (err) {
      this.snackbarService.notifyError(
        this.translate.instant('Jobs.JobCreationFailed'),
      );
    }
  }

  setWorkflow(workflow: { selectedWorkflow: string; stage: string | null }) {
    this.newJob.workflow = workflow.selectedWorkflow;
    this.enableNextButton = !this.newJob.workflow;
    this.stage = workflow.stage;
  }

  // reporting - current tab
  setSelectedNodes(nodes: FlatNode[]): void {
    this.newJob.input_location = nodes
      .map((node) => node.name)
      .filter((name): name is string => name !== undefined);
    this.enableNextButton = this.newJob.input_location.length < 1;
  }

  // simulation - current tab
  setSelectedParams(params: DefineParameterEmitter) {
    this.newJob.workflow_configuration = params.values;
    this.enableNextButton = !params.validation;
  }

  setInputLocation(input: string) {
    this.enableNextButton = !input;
    this.newJob.input_location = input;
  }
}

export interface DefineParameterEmitter {
  values: { [key: string]: string }[];
  validation: boolean;
}

enum Steps {
  first,
  second,
  third,
  overview,
  last,
}
