import { Injectable } from '@angular/core';
import {
  catchError,
  lastValueFrom,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { CancelledJob, Job } from '../models/job';
import { ApiRecord } from '../../../shared/stores/config/models/apiRecord';
import {
  SYSDAQChildJob,
  SYSDAQChildJobsPagination,
  SYSDAQJobDetails,
  SYSDAQChildJobs,
  SYSDAQFlatChildJob,
} from '../models/jobDetails';
import { ApiService } from '../../../core/services/api/api.service';
import { generatePath } from '../../../shared/utils/generatePath';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChildJobStepsComponent } from '../components/child-job-steps/child-job-steps.component';
import {
  StepDownloadRequestPayload,
  StepDownloadResponse,
} from '../models/stepDownloadReuest';
import {
  logDownloadRequestPayload,
  logDownloadResponse,
} from '../models/logDownloadRequest';
import { configFileDownloadResponse } from '../models/configFileDownloadResposne';

@Injectable({
  providedIn: 'root',
})
export class SimulationJobMonitoringService {
  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
  ) {}

  getJobs(apiRecord: ApiRecord) {
    const response = this.apiService
      .request<Job>({ apiRecord })
      .pipe(map((result) => Job.Factory(result)));
    return lastValueFrom(response);
  }

  cancelJob(apiRecord: ApiRecord, jobIdToBeCanceled: string) {
    return this.apiService.request<CancelledJob>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { run_id: jobIdToBeCanceled }),
      },
    });
  }

  getJobDetails(
    apiRecord: ApiRecord,
    dagRunId: string,
  ): Observable<SYSDAQJobDetails> {
    return this.apiService
      .request<SYSDAQJobDetails>({
        apiRecord: {
          ...apiRecord,
          url: generatePath(apiRecord.url, { dagRunId }),
        },
      })
      .pipe(map((details) => SYSDAQJobDetails.Factory(details)));
  }

  getAllChildJobs(
    api: ApiRecord,
    dagRunId: string,
    lastKey?: number,
  ): Observable<SYSDAQChildJobs> {
    const apiRecord = {
      ...api,
      url: generatePath(
        api.url,
        { dagRunId },
        lastKey ? { last_key: lastKey } : undefined,
      ),
    };

    return this.apiService
      .request<SYSDAQChildJobsPagination>({ apiRecord })
      .pipe(
        switchMap((jobs) => {
          const childJobs = SYSDAQChildJobs.Factory(jobs);
          return jobs.last_key
            ? this.getAllChildJobs(api, dagRunId, jobs.last_key).pipe(
                map((nextJobs) => {
                  return {
                    ...childJobs,
                    Items: [...childJobs.Items, ...nextJobs.Items],
                  };
                }),
              )
            : of(childJobs);
        }),
      );
  }

  getChildJobSteps(apiRecord: ApiRecord, dagRunId: string, childJobId: string) {
    return this.apiService
      .request<SYSDAQChildJob>({
        apiRecord: {
          ...apiRecord,
          url: generatePath(apiRecord.url, {
            dagRunId,
            childJobId,
          }),
        },
      })
      .pipe(map((child) => SYSDAQChildJob.Factory(child)));
  }

  openStepsDialog(
    runID: string | undefined,
    childJobIndex: string,
    apiRecord: ApiRecord | undefined,
    stepDownload: ApiRecord | undefined,
  ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '980px';
    dialogConfig.maxHeight = '600px';
    dialogConfig.data = {
      runId: runID,
      jobIndex: childJobIndex,
      apiRecord: apiRecord,
      requestStepDownload: stepDownload,
    };
    this.dialog.open(ChildJobStepsComponent, dialogConfig);
  }

  requestStepDownloadLink(
    apiRecord: ApiRecord,
    payload: StepDownloadRequestPayload,
  ) {
    return this.apiService.request<StepDownloadResponse>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, {}),
      },
      body: payload,
    });
  }

  requestLogDownloadLink(
    apiRecord: ApiRecord,
    payload: logDownloadRequestPayload,
  ) {
    return this.apiService.request<logDownloadResponse>({
      apiRecord: apiRecord,
      body: payload,
    });
  }

  downloadConfigFile(apiRecord: ApiRecord, configFileName: string) {
    return this.apiService.request<configFileDownloadResponse>({
      apiRecord: apiRecord,
      body: { file_name: configFileName },
    });
  }

  fetchAndTransformChildJobs(
    api: ApiRecord,
    runID: string,
  ): Observable<SYSDAQFlatChildJob[]> {
    return this.getAllChildJobs(api, runID).pipe(
      map((jobs) => {
        let flatChildJobs: SYSDAQFlatChildJob[] = [];
        jobs.Items.forEach((item) => {
          flatChildJobs.push({
            RunId: item.RunId,
            ChildJobIndex: item.ChildJobIndex,
            ...item.ChildJobData,
            Status: item.Status,
          });
        });
        return flatChildJobs;
      }),
      catchError((err) => {
        return of(err);
      }),
    );
  }
}
