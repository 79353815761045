import { GeneralApi, GeneralFeatureConfig } from 'src/app/core/services/config/models/generalApi';
import { FeatureConfig } from 'src/app/shared/stores/config/models/featureConfig';
import { Method } from 'src/app/shared/stores/config/models/method';
import { FeatureId } from './feature-id';
import { AppConfigInstanceType } from 'src/app/shared/stores/config/models/appConfigInstanceType';

export const getHdkFeatureConfig = (
  options: GeneralFeatureConfig<GeneralApi>,
): Partial<FeatureConfig> => {
  return {
    id: '7878787878',
    label: 'HDK',
    icon: 'developer_board',
    featureId: FeatureId.HDK_DEVICE_LIST_PAGE,
    path: 'project/:projectId/hdk',
    API: {
      getDevDevices: {
        url: `${options.api.dev}/v3/devices`,
        method: Method.GET,
      },
      getQaDevices: {
        url: `${options.api.qa}/v3/devices`,
        method: Method.GET,
      },
      devDeviceActionRequest: {
        url: `${options.api.dev}/v3/devices/:hwName/state`,
        method: Method.PUT,
      },
      qaDeviceActionRequest: {
        url: `${options.api.qa}/v3/devices/:hwName/state`,
        method: Method.PUT,
      },
      createRealDevice: {
        url: `${options.api.toolchain}/v3/devices`,
        method: Method.POST,
      },
      deleteRealDevice: {
        url: `${options.api.toolchain}/v3/devices/:hwName`,
        method: Method.DELETE,
      },
      deleteSimulatedDeviceDev: {
        url: `${options.api.dev}/v3/devices/:hwName`,
        method: Method.DELETE,
      },
      deleteSimulatedDeviceQa: {
        url: `${options.api.qa}/v3/devices/:hwName`,
        method: Method.DELETE,
      },
      downloadDeviceScriptsDev: {
        url: `${options.api.dev}/v3/devices/:hwName/scripts`,
        method: Method.GET,
      },
      downloadDeviceScriptsQa: {
        url: `${options.api.qa}/v3/devices/:hwName/scripts`,
        method: Method.GET,
      },
      downloadVhpcDeviceScripts: {
        url: `${options.api.vhpc}/v1/vhpc/:hwName/scripts`,
        method: Method.GET,
      },
      getDevConnectionPackage: {
        url: `${options.api.dev}/v3/devices/:hwName/connection-package`,
        method: Method.GET,
      },
      getQaConnectionPackage: {
        url: `${options.api.qa}/v3/devices/:hwName/connection-package`,
        method: Method.GET,
      },
      getDevFwUpdates: {
        url: `${options.api.dev}/v3/devices/:hwName/firmware-update`,
        method: Method.GET,
      },
      getQaFwUpdates: {
        url: `${options.api.qa}/v3/devices/:hwName/firmware-update`,
        method: Method.GET,
      },
      updateFwVersionDev: {
        url: `${options.api.dev}/v3/devices/:hwName/firmware-update`,
        method: Method.POST,
      },
      updateFwVersionQa: {
        url: `${options.api.qa}/v3/devices/:hwName/firmware-update`,
        method: Method.POST,
      },
      getApplications: {
        method: Method.GET,
        url: `${options.api.toolchain}/v3/applications`,
      },
      getAppVersions: {
        url: `${options.api.toolchain}/v3/applications/:appName/versions`,
        method: Method.GET,
      },
      createDevDeployment: {
        url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
        method: Method.POST,
      },
      vHPCDeviceActionRequest: {
        url: `${options.api.vhpc}/v1/vhpc/:hwName/state`,
        method: Method.PUT,
      },
      createVhpcDevice: {
        url: `${options.api.vhpc}/v1/vhpc`,
        method: Method.POST,
      },
      deleteVhpcDevice: {
        url: `${options.api.vhpc}/v1/vhpc/:hwName`,
        method: Method.DELETE,
      },
      getVhpcReleases: {
        url: `${options.api.vhpc}/v1/vhpc-releases`,
        method: Method.GET,
      },
      downloadHwDeviceScript: {
        url: `${options.api.dev}/v3/devices/:hwName/scripts`,
        method: Method.GET,
      },
      getPresignedUrlForAudio: {
        url: `${options.api.observability}/devices/:id/android-audio-debug/url`,
        method: Method.GET,
      },
      getPresignedUrlForHdkTools: {
        url: `${options.api.vhpc}/v1/vhpc-tools`,
        method: Method.GET,
      },
      downloadVHPCInstaller: {
        url: 'https://artifactory.8675.ccp.continental.exchange/artifactory/refint-generic-local/tools/portable-apps/vecu-tools.zip',
        method: Method.GET,
      },
      getGrafanaDashboard: {
        url: `${options.api.grafana}/d/1/device-dashboard?var-aws_device=:deviceName`,
        method: Method.GET,
      },
      bulkDeleteRealDevices: {
        url: `${options.api.dev}/v3/devices`,
        method: Method.DELETE,
      },
      bulkDeleteVhpcDevices: {
        url: `${options.api.vhpc}/v1/vhpc`,
        method: Method.DELETE,
      },
    },
    children: [
      {
        id: '1234567891',
        featureId: FeatureId.HDK_DEVICE_DETAILS,
        label: 'Device Details',
        icon: 'task_alt',
        type: AppConfigInstanceType.FEATURE,
        path: ':hwName',
        isNested: true,
        API: {
          getDevice: {
            url: `${options.api.dev}/v3/devices/:deviceId`,
            method: Method.GET,
          },
          getDeviceApplications: {
            url: `${options.api.dev}/v3/devices/:hwName/deployments`,
            method: Method.GET,
          },
          getDevConnectionPackage: {
            url: `${options.api.dev}/v3/devices/:hwName/connection-package`,
            method: Method.GET,
          },
          getAppDetails: {
            url: `${options.api.toolchain}/v3/applications/:appName`,
            method: Method.GET,
          },
          getAppVersions: {
            method: Method.GET,
            url: `${options.api.toolchain}/v3/applications/:appName/versions`,
          },
          getDeploymentDev: {
            url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
            method: Method.GET,
          },
          getApplications: {
            method: Method.GET,
            url: `${options.api.toolchain}/v3/applications`,
          },
          createDevDeployment: {
            url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
            method: Method.POST,
          },
          deleteDevDeployment: {
            url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
            method: Method.DELETE,
          },
        },
      },
    ],
  };
};
