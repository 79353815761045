import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { HeaderTemplateComponent } from '../../shared/components/header/header-template.component';
import { SearchInputComponent } from '../../shared/components/search-input/search-input.component';
import { FormControl } from '@angular/forms';
import { FunctionBarComponent } from '../../shared/components/function-bar/function-bar.component';
import {
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  of,
  startWith,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { ContentWrapperComponent } from '../../shared/components/content-wrapper/content-wrapper.component';
import { PlaylistTableComponent } from './playlist-table/playlist-table.component';
import { IState, State } from '../../shared/stores/State';
import { PlaylistDataset } from '../job-monitoring/models/playlist-dataset';
import { FeatureComponent } from '../../core/models/classes/feature.component';
import { PlaylistSearchService } from './services/playlist-search.service';
import { PlaylistStoreService } from './services/playlist-store.service';
import { AsyncPipe } from '@angular/common';
import { PlaylistService } from './services/playlist.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { RoutingStore } from '../../shared/stores/config/routing.store';
import { SelectMenuComponent } from '../../shared/components/select-menu/select-menu.component';
import { Option } from '../../core/models/interfaces/option';
import { UrlParameterStateService } from 'src/app/shared/services/url-parameter-state/url-parameter-state.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CaeButtonComponent } from '../../shared/components/cae-button/cae-button.component';
import {
  AddStagePlaylistDialogService,
  StageNumber,
} from './services/add-stage-playlist-dialog.service';
import { MatButtonModule } from '@angular/material/button';
import { ApiRecord } from '../../shared/stores/config/models/apiRecord';

export type PlaylistApi =
  | 'getPlaylist'
  | 'getProjectConfiguration'
  | 'getMasterCollections'
  | 'getChildCollections'
  | 'getChildCollectionRecordings'
  | 'getApiKey'
  | 'createStageOne'
  | 'createStageTwo'
  | 'getSensorVersions';

@Component({
  selector: 'app-playlist',
  standalone: true,
  imports: [
    HeaderTemplateComponent,
    SearchInputComponent,
    FunctionBarComponent,
    ContentWrapperComponent,
    PlaylistTableComponent,
    AsyncPipe,
    SelectMenuComponent,
    CaeButtonComponent,
    TranslateModule,
    MatButtonModule,
  ],
  templateUrl: './playlist.component.html',
  styleUrl: './playlist.component.scss',
})
export class PlaylistComponent
  extends FeatureComponent<PlaylistApi>
  implements OnInit, OnDestroy
{
  searchFilterControl: FormControl = new FormControl('');
  private debounceTime: number = 400;
  private readonly unsubscribe$: Subject<void> = new Subject();
  searchInput = '';

  stageOptions = [
    {
      id: StageOptions.ALL.toString(),
      label: this.translateService.instant('Playlist.All'),
    },
    { id: StageOptions.STAGE_1.toString(), label: 'Stage 1' },
    { id: StageOptions.STAGE_2.toString(), label: 'Stage 2' },
  ];
  selectStageFilterOption = signal(this.stageOptions[0]);
  selectedOption$ = toObservable(this.selectStageFilterOption);

  searchFilter$: Observable<any> = this.getInitialSearchValue().pipe(
    tap((input) => this.updateUrlParams(input)),
    map((input) => this.setSearchFilterControlValue(input)),
  );

  filteredPlaylist$ = combineLatest([
    this.playlistStore.playlist$,
    this.searchFilter$,
    this.selectedOption$,
  ]).pipe(map(this.playlistSearchService.playlistMap));

  playlist$: Observable<IState<PlaylistDataset[]>> =
    this.playlistSearchService.combinePlaylistDatasetState$(
      this.filteredPlaylist$,
      this.playlistStore.isLoading$,
      this.playlistStore.hasError$,
    );

  constructor(
    private playlistStore: PlaylistStoreService,
    private playlistSearchService: PlaylistSearchService,
    private playlistService: PlaylistService,
    private routingStore: RoutingStore,
    private translateService: TranslateService,
    private urlParameterStateService: UrlParameterStateService,
    private addStagePlaylistService: AddStagePlaylistDialogService,
  ) {
    super();
  }

  ngOnInit() {
    this.fetchPlaylist().pipe(takeUntil(this.unsubscribe$)).subscribe();
    this.syncStageFilterWithUrlParams();
  }

  getInitialSearchValue(): Observable<any> {
    return this.searchFilterControl.valueChanges.pipe(
      startWith(this.searchParamValueFromUrl()),
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
    );
  }

  updateUrlParams(input: any): void {
    this.urlParameterStateService.updateParams({
      search: input,
    });
  }

  setSearchFilterControlValue(input: any): any {
    this.searchFilterControl.setValue(input);
    return input;
  }

  searchParamValueFromUrl(): string {
    let searchValue = '';
    this.urlParameterStateService.state$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        const savedSearch: string = state['search'];
        if (savedSearch?.length > 0) {
          searchValue = savedSearch;
        }
      });
    return searchValue;
  }

  syncStageFilterWithUrlParams(): void {
    // Listening to the entire state change
    this.urlParameterStateService.state$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        const savedStageId = state['stageId'];
        const savedStageLabel = state['stageLabel'];
        if (savedStageId && savedStageLabel) {
          this.selectStageFilterOption.set({
            id: savedStageId,
            label: savedStageLabel,
          });
        }
      });
  }

  initializePlaylistStore() {
    if (this.playlistStore.state.data.length === 0) {
      const initState = State.Factory([], true);
      this.playlistStore.setState(initState);
    }
  }

  fetchPlaylistFromService(api: ApiRecord) {
    const projectName = this.routingStore.state.projectId;
    return this.playlistService.getPlaylist(api, projectName);
  }

  handlePlaylistResponse(response: PlaylistDataset[]) {
    const state = State.Factory<PlaylistDataset[]>(response);
    this.playlistStore.setState(state);
  }

  handlePlaylistError(err: any) {
    this.playlistStore.setState(State.Factory([], false, true));
    return of(err);
  }

  fetchPlaylist() {
    if (!this.API) return of({});

    this.initializePlaylistStore();

    return this.fetchPlaylistFromService(this.API.getPlaylist).pipe(
      tap(this.handlePlaylistResponse.bind(this)),
      catchError(this.handlePlaylistError.bind(this)),
    );
  }

  stageFilterSelect(stage: Option<string>) {
    this.selectStageFilterOption.set(stage);
    this.urlParameterStateService.updateParams({
      stageId: stage.id,
      stageLabel: stage.label,
    });
  }

  addStageOne() {
    if (!this.API) return;
    this.addStagePlaylistService.openDialog(
      StageNumber.STAGE_ONE,
      this.API,
      this.unsubscribe$,
    );
  }

  addStageTwo() {
    if (!this.API) return;
    this.addStagePlaylistService.openDialog(
      StageNumber.STAGE_TWO,
      this.API,
      this.unsubscribe$,
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export enum StageOptions {
  ALL = 'all',
  STAGE_1 = 'stage1',
  STAGE_2 = 'stage2',
}
