<h2 mat-dialog-title data-cy="create-vecu-dialog-title">{{ 'VecuList.CreateDialog.Title' | translate }}</h2>
<div data-cy="create-new-vecu-dialog" mat-dialog-content class="content">
  <div class="inputs" [formGroup]="createVecuFormGroup">
    <app-dialog-hint
      data-cy="create-vecu-name-hint"
      [isWarning]="false"
      message="{{ 'VecuList.CreateDialog.VecuNameToBeUnique' | translate }}"
    />
    <mat-form-field class="caedge-form-field-outline">
      <mat-label>{{
        "VecuList.CreateDialog.DeviceName" | translate
      }}</mat-label>
      <input
        #vecuNameFieldViewChild
        (click)="displayRestrictor(vecuNameFieldRef)"
        data-cy="vecu-name-input"
        formControlName="vecuName"
        [errorStateMatcher]="errorMatcher"
        matInput
        maxlength="{{ maxVecuNameLength }}"
      />
      <mat-hint data-cy="create-vecu-dialog-name-hint" align="start">{{
        "VecuList.CreateDialog.NameRequirements" | translate
      }}</mat-hint>
      <mat-hint align="end">{{ vecuNameField.value.length }}/{{ maxVecuNameLength }}</mat-hint>
      
      @if(vecuNameField.hasError('nameRestrictorError')) {
        <mat-error data-cy="restricted-name-error">
          {{ 'General.UnallowedInput' | translate }}
        </mat-error>
      }

      @if(vecuNameField.hasError('similarNameRestrictorError')) {
        <mat-error data-cy="similar-name-error">
          {{ 'VecuList.CreateDialog.SimilarNameExists' | translate }}
        </mat-error>
      }

      @if(vecuNameField.hasError('required')) {
        <mat-error>
          {{ 'Project.AddProjectDialog.ErrorMessage.Required' | translate }}
        </mat-error>
      }
    </mat-form-field>
    @if (isVecuConfigFeatureEnabled()) {
      <mat-form-field class="caedge-form-field-outline">
        <mat-label>{{
          "Vecu.CreateVECU.DeviceConfiguration" | translate
        }}</mat-label>
        <mat-select
          formControlName="vecuConfiguration"
          data-cy="select-configuration"
          (selectionChange)="onReleaseSelected()"
        >
          @for (vEcuConfiguration of mapOfReleasesAndVersions | keyvalue; track $index) {
            <mat-option
              data-cy="vecu-configuration-option"
              [value]="vEcuConfiguration.key">
              {{ vEcuConfiguration.key }}
            </mat-option>
          }
        </mat-select>
        <mat-hint data-cy="create-vecu-configuration-hint" align="start">{{
          "Vecu.CreateVECU.DeviceConfigurationHint" | translate
        }}</mat-hint>
      </mat-form-field>
      <mat-form-field class="caedge-form-field-outline">
        <mat-label>{{
          "Vecu.CreateVECU.ConfigurationVersion" | translate
        }}</mat-label>
        <mat-select
          formControlName="vecuConfigurationVersion"
          data-cy="select-configuration-version"
          (selectionChange)="onVersionSelected()"
        >
          @for (vEcuReleaseVersion of mapOfVersionsAndDescriptions | keyvalue; track $index) {
            <mat-option
              data-cy="vecu-configuration-option"
              [value]="vEcuReleaseVersion.key">
              {{ vEcuReleaseVersion.key }}
            </mat-option>
          }
        </mat-select>
        <mat-hint data-cy="create-vecu-version-description-name-hint" align="start">{{
          "Vecu.CreateVECU.ConfigurationVersionHint" | translate
        }}</mat-hint>
      </mat-form-field>
      <app-dialog-hint
        data-cy="vecu-version-description-dialog-hint"
        [isWarning]="false"
        [showIcon]="false"
        [message]="currentSelectedVersionDescription()"
      />
    }
  </div>
</div>
<div mat-dialog-actions [align]="'end'">
  <button
    data-cy="cancel-vecu-button"
    mat-flat-button
    mat-dialog-close
  >
    {{ "General.Cancel" | translate }}
  </button>
  <button
    class="confirm-btn"
    data-cy="create-vecu-button"
    mat-flat-button
    [disabled]="!createVecuFormGroup.valid"
    (click)="handleSubmitClick()"
  >
    {{ "VecuList.CreateVecu" | translate }}
  </button>
</div>
