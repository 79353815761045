import { Injectable } from '@angular/core';
import { Option } from 'src/app/core/models/interfaces/option';
import { Vecu } from '../../models/vecu';
import {
  VECU_SORT_KEYS,
  VECU_STATUS_KEYS,
  VecuSortKey,
  VecuStatusKey,
} from '../../utils/selection-keys';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { vecuStatusLabelMap } from '../../models/vecu-status-label-map';

type VecuListSelectionData = {
  vEcuStatus: Option<VecuStatusKey>;
  sortBy: Option<VecuSortKey>;
};

@Injectable({
  providedIn: 'root',
})
export class VecuListService {
  constructor(private translateService: TranslateService) { }

  defaultFilterValues(): VecuListSelectionData {
    return {
      vEcuStatus: {
        id: VECU_STATUS_KEYS.ALL,
        label: this.translateService.instant(VECU_STATUS_KEYS.ALL),
      },
      sortBy: {
        id: VECU_SORT_KEYS.ALPHABETICAL,
        label: this.translateService.instant(VECU_SORT_KEYS.ALPHABETICAL),
      },
    };
  }

  deviceMap = ([devices, searchFilter, statusFilter, sortByOption]: [
    Vecu[],
    string,
    Option<VecuStatusKey>,
    Option<VecuSortKey>,
  ]) => {
    return devices
      .filter((device) => {
        const deviceSearchFilterCheck: boolean = device.displayName
          .toLowerCase()
          .includes(searchFilter.toLowerCase());

        const deviceStatusFilterCheck: boolean =
          statusFilter.id === VECU_STATUS_KEYS.ALL ||
          vecuStatusLabelMap[device.state.ui] === statusFilter.id;

        return deviceSearchFilterCheck && deviceStatusFilterCheck;
      }).sort(this.vecuSort(sortByOption.id));
  };

  vecuSort = (sortBy: VecuSortKey): ((a: Vecu, b: Vecu) => number) => {
    switch (sortBy) {
      case 'VecuList.SortKeys.Alphabetical':
        return (a: Vecu, b: Vecu) => a.displayName.localeCompare(b.displayName, 'en');

      case 'VecuList.SortKeys.CreationDate':
        return (a: Vecu, b: Vecu) => {
          const aDate = new Date(a.createdAt).getTime();
          const bDate = new Date(b.createdAt).getTime();
          return bDate - aDate;
        };
    }
  };

  getOptions<T extends string>(
    keys: readonly T[],
    optionKey: keyof VecuListSelectionData,
    setSelectedOption: (option: Option<T>) => void,
  ): Observable<Option<T>[]> {
    return this.translateService.stream([...keys]).pipe(
      map((translations: Record<T, string>) =>
        Object.entries(translations as Record<string, string>).map(
          ([sortKey, translation]: [string, string]): Option<T> => ({
            id: sortKey as T,
            label: translation,
          }),
        ),
      ),
      tap((options) => {
        const selectedOption = this.selectMenusSource.value[optionKey];
        setSelectedOption(
          options.find((option) => option.id === selectedOption.id)!,
        );
      }),
    );
  }

  get vEcuConnectionStatusOptions$(): Observable<Option<VecuStatusKey>[]> {
    return this.getOptions(
      [
        VECU_STATUS_KEYS.ALL,
        VECU_STATUS_KEYS.ACTIVE,
        VECU_STATUS_KEYS.INACTIVE,
        VECU_STATUS_KEYS.IN_PROGRESS,
        VECU_STATUS_KEYS.UNKNOWN,
        VECU_STATUS_KEYS.ERROR,
      ],
      'vEcuStatus',
      this.setSelectedDeviceConnectionStatus,
    );
  }

  get sortByOptions$(): Observable<Option<VecuSortKey>[]> {
    return this.getOptions(
      [VECU_SORT_KEYS.ALPHABETICAL, VECU_SORT_KEYS.CREATION_DATE],
      'sortBy',
      this.setSelectedSortBy,
    );
  }

  private selectMenusSource: BehaviorSubject<VecuListSelectionData> =
    new BehaviorSubject<VecuListSelectionData>(this.defaultFilterValues());

  get selectedVecuConnectionStatus$(): Observable<Option<VecuStatusKey>> {
    return this.selectMenusSource.pipe(map((value) => value.vEcuStatus));
  }

  setSelectedDeviceConnectionStatus = (vEcuStatus: Option<VecuStatusKey>) => {
    this.selectMenusSource.next({
      ...this.selectMenusSource.value,
      vEcuStatus,
    });
  };

  get selectedSortBy$(): Observable<Option<VecuSortKey>> {
    return this.selectMenusSource.pipe(map((value) => value.sortBy));
  }

  setSelectedSortBy = (sortBy: Option<VecuSortKey>) => {
    this.selectMenusSource.next({ ...this.selectMenusSource.value, sortBy });
  };

  checkActiveFilters() {
    const currentState = this.selectMenusSource.getValue();
    const defaultState = this.defaultFilterValues();
    return (
      currentState.vEcuStatus.id !== defaultState.vEcuStatus.id ||
      currentState.sortBy.id !== defaultState.sortBy.id
    );
  }

  clearActiveFilters() {
    this.selectMenusSource.next(this.defaultFilterValues());
  }
}
