import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash';

@Pipe({
  name: 'truncate',
  pure: false,
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value: string | number, limit: number): string {
    if (isNumber(value) || value.length <= limit) {
      return value.toString();
    }
    return value.substring(0, limit) + '...';
  }
}
