import { FeatureConfig } from './featureConfig';
import { FeatureSectionConfig } from './featureSectionConfig';
import { ProjectConfig } from './projectConfig';
import { Divider } from './divider';

export enum AppConfigInstanceType {
  FEATURE = 'FEATURE',
  PROJECT = 'PROJECT',
  FEATURE_SECTION = 'FEATURE_SECTION',
  DIVIDER = 'DIVIDER',
}

export type EnvConfigNode =
  | ProjectConfig
  | FeatureSectionConfig
  | FeatureConfig
  | Divider;
