import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { IWorkbenchDataType } from './models/workbenches';
import { WorkbenchTypeState } from './models/workbenchState';

const workbenchType: IWorkbenchDataType = {
  Project: {
    name: '',
  },
  user: '',
  WorkBenchTypes: [],
};
@Injectable({
  providedIn: 'root',
})
export class WorkbenchStore extends Store<WorkbenchTypeState> {
  constructor() {
    super({ WorkbenchTypes: workbenchType, isLoading: true, hasError: false });
  }

  get workbenchTypes$(): Observable<IWorkbenchDataType> {
    return this.state$.pipe(map((state) => state.WorkbenchTypes));
  }

  get isLoading$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.isLoading));
  }

  get hasError$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.hasError));
  }
}
