import { AwsStage } from '../app/core/models/enums/AwsStage';
import { cognitoConfig } from '../app/core/services/auth/cognitoConfig';
import { AwsOrg } from '../app/core/models/enums/AwsOrg';
import { FeatureConfig } from './featureConfig';

const features: FeatureConfig[] = [
  FeatureConfig.workbencheRequest,
  FeatureConfig.displayCaedgeDocs,
  FeatureConfig.languageSelection,
  FeatureConfig.downloadDevTools,
  FeatureConfig.partitionDropdown,
  FeatureConfig.bluePrintServiceReady,
  FeatureConfig.displayProjectTeamActions,
  FeatureConfig.quotaEndPointPolling,
  FeatureConfig.vecuActionFlag,
  FeatureConfig.vecuConfigurationEnabled
];

export const environment = {
  production: false,
  appVersion: require('../../package.json').version + '-e2e',
  AWS_STAGE: AwsStage.E2E,
  COGNITO_CONFIG: cognitoConfig[AwsOrg.PROD],
  enableGuard: false,
  TEST_EMAIL: 'uif97034@contiwan.com',
  TEST_USER_NAME: 'uif97034',
  features: features,
  projectId: 'dev-10020481',
};
