import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ProjectDetails } from '../models/project-details';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface ProjectDeleteResponse {
  project: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectDetailsService {

  private http = inject(HttpClient);
  private readonly dispatchApiBaseUrl = environment.COGNITO_CONFIG.BASE_DISPATCH_API_URL;

  getProjectDetailsById(id: string): Observable<ProjectDetails> {
    const url = this.dispatchApiBaseUrl + `projects/${id}`;
    return this.http
      .get<any>(url)
      .pipe(map((val) => ProjectDetails.Factory(val.project)));
  }

  deleteProjectById(projectId: string): Observable<ProjectDeleteResponse> {
    const deleteProjectUrl = this.dispatchApiBaseUrl + `projects/${projectId}/delete`;
    return this.http.delete<ProjectDeleteResponse>(deleteProjectUrl);
  };
}
