<form>
  <mat-form-field class="example-chip-list">
    <mat-label>{{autoLabel}}</mat-label>
    <mat-chip-grid #chipGrid aria-label="Role selection" [formControl]="selectedUserRoles">
      @for (role of roles(); track $index) {
        <mat-chip-row (removed)="remove(role)">
          {{role}}
          <button data-cy="remove-user-role-btn" matChipRemove [attr.aria-label]="'remove ' + role">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
    </mat-chip-grid>
    <input
      name="currentRole"
      placeholder="{{autoPlaceholder}}"
      [(ngModel)]="currentRole"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      @for (role of filteredRoles(); track role) {
        <mat-option data-cy="users-role-option" [value]="role">{{role}}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</form>
