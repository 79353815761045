<div
  class="checkbox"
  [matTooltip]="tooltip"
  [matTooltipPosition]="tooltipPosition"
>
  <label class="checkbox-container">
    <input
      type="checkbox"
      class="checkbox"
      [checked]="isSelected"
      (change)="checked.emit(!isSelected)"
      (click)="$event.stopPropagation()"
    />
    <span class="checkmark"></span>
  </label>
  <span class="text">
    {{ text }}
  </span>
</div>
