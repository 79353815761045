import { ProjectSimulationBucketProperties } from './project-simulation-bucket-properties';
import { BackendDataSimulationSensorsGenericNames, AdjustedSimulationSensorsGenericNames, SimulationSensorsGenericNames } from './simulation-sensors-generic-names';
import { BackendDataStage2SimulationSensorsCombinations, AdjustedStage2SimulationSensorsCombinations, Stage2SimulationSensorsCombinations } from './stage-two-simulation-sensors-combination';
import { BackendDataSensorCombination, AdjustedSensorCombination, SensorCombination } from './sensor-combination';
import { BackendDataDistributionOverlapSensorCombinations, AdjustedDistributionOverlapSensorCombinations, DistributionOverlapSensorCombinations } from './distribution-overlap-sensor-combination';
import { BackendDataSensorsStage1, AdjustedSensorsStage1, SensorsStage1 } from './sensors-stage-one';
import { BackendDataReportsConfiguration, ReportsConfiguration } from './reports-configuration';

type OverlapSensorCombination = string | string[];

export interface BackendDataProjectProperties {
    'simulation-enabled': boolean;
    'reports-configuration': BackendDataReportsConfiguration;
    'minimum-section-duration': number;
    sensors_stage1: BackendDataSensorsStage1;
    'allowed-sensor-combinations': [string[]];
    'overlap-sensor-combinations'?: [OverlapSensorCombination[]];
    'distribution-overlap-sensor-combinations'?: BackendDataDistributionOverlapSensorCombinations;
    'supported-tasks': string[];
    'simulation-sensors-combinations': [string[]];
    sim_results_combination: string[];
    'sensors-combinations': BackendDataSensorCombination;
    'stage2-simulation-sensors-combinations': BackendDataStage2SimulationSensorsCombinations;
    'simulation-sensors-generic_names': BackendDataSimulationSensorsGenericNames;
    'project-simulation-bucket-properties': ProjectSimulationBucketProperties;
}

export interface AdjustedProjectProperties {
    simulationEnabled: boolean;
    reportsConfiguration: ReportsConfiguration;
    minimumSectionDuration: number;
    sensorsStage1: SensorsStage1;
    allowedSensorCombinations: [string[]];
    overlapSensorCombinations?: [OverlapSensorCombination[]];
    distributionOverlapSensorCombinations?: AdjustedDistributionOverlapSensorCombinations;
    supportedTasks: string[];
    simulationSensorsCombinations: [string[]];
    simResultsCombination: string[];
    sensorsCombinations: AdjustedSensorCombination;
    stage2SimulationSensorsCombinations: AdjustedStage2SimulationSensorsCombinations;
    simulationSensorsGenericNames: AdjustedSimulationSensorsGenericNames;
    projectSimulationBucketProperties: ProjectSimulationBucketProperties;
}

export class ProjectProperties implements AdjustedProjectProperties {
    simulationEnabled!: boolean;
    reportsConfiguration!: ReportsConfiguration;
    minimumSectionDuration!: number;
    sensorsStage1!: AdjustedSensorsStage1;
    allowedSensorCombinations!: [string[]];
    overlapSensorCombinations?: [OverlapSensorCombination[]] | undefined;
    distributionOverlapSensorCombinations?: DistributionOverlapSensorCombinations | undefined;
    supportedTasks!: string[];
    simulationSensorsCombinations!: [string[]];
    simResultsCombination!: string[];
    sensorsCombinations!: SensorCombination;
    stage2SimulationSensorsCombinations!: Stage2SimulationSensorsCombinations;
    simulationSensorsGenericNames!: SimulationSensorsGenericNames;
    projectSimulationBucketProperties!: ProjectSimulationBucketProperties;

    public static Factory(dataProjectProperties: BackendDataProjectProperties): ProjectProperties {
        const newProjectProperties = new ProjectProperties();
        newProjectProperties.simulationEnabled = dataProjectProperties['simulation-enabled'];
        newProjectProperties.reportsConfiguration = ReportsConfiguration.Factory(dataProjectProperties['reports-configuration']);
        newProjectProperties.minimumSectionDuration = dataProjectProperties['minimum-section-duration'];
        newProjectProperties.sensorsStage1 = SensorsStage1.Factory(dataProjectProperties['sensors_stage1']);
        newProjectProperties.allowedSensorCombinations = dataProjectProperties['allowed-sensor-combinations'];
        newProjectProperties.overlapSensorCombinations = dataProjectProperties['overlap-sensor-combinations'];
        newProjectProperties.distributionOverlapSensorCombinations = DistributionOverlapSensorCombinations.Factory(dataProjectProperties['distribution-overlap-sensor-combinations']);
        newProjectProperties.supportedTasks = dataProjectProperties['supported-tasks'];
        newProjectProperties.simulationSensorsCombinations = dataProjectProperties['simulation-sensors-combinations'];
        newProjectProperties.simResultsCombination = dataProjectProperties['sim_results_combination'];
        newProjectProperties.sensorsCombinations = SensorCombination.Factory(dataProjectProperties['sensors-combinations']);
        newProjectProperties.stage2SimulationSensorsCombinations = Stage2SimulationSensorsCombinations.Factory(dataProjectProperties['stage2-simulation-sensors-combinations']);
        newProjectProperties.simulationSensorsGenericNames = SimulationSensorsGenericNames.Factory(dataProjectProperties['simulation-sensors-generic_names']);
        newProjectProperties.projectSimulationBucketProperties = dataProjectProperties['project-simulation-bucket-properties'];
        return newProjectProperties;
    };
}
