import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { Application, IDataApplication } from 'src/app/shared/stores/deployment/models/application';
import { Version } from 'src/app/shared/stores/deployment/models/version';
import { IDataVersion } from 'src/app/shared/stores/deployment/models/version';
import { generatePath } from 'src/app/shared/utils/generatePath';

@Injectable({
  providedIn: 'root'
})
export class ApplicationDashboardApiService {

  constructor(private apiService: ApiService) { }

  getApplicationVersions(
    apiRecord: ApiRecord,
    appName: string
  ): Observable<Version[]> {
    return this.apiService.request<IDataVersion[]>({
      apiRecord: {
        method: apiRecord.method,
        url: generatePath(apiRecord.url, { appName: appName })
      }
    }).pipe(
      map((versions) => versions.map(Version.Factory))
    );
  }

  getApplicationDetails(
    apiRecord: ApiRecord,
    appName: string
  ): Observable<Application> {
    return this.apiService.request<IDataApplication>({
      apiRecord: {
        method: apiRecord.method,
        url: generatePath(apiRecord.url, { appName: appName })
      }
    }).pipe(
      map((application) => Application.Factory(application))
    );
  }
}
