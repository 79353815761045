<div class="select-workflow-container">
  <p>Select a playlist-based workflow</p>
  <mat-radio-group
    class="workflow-radio-button"
    aria-label="Select an option"
    [(ngModel)]="selectedWorkflow"
    (change)="emitWorkflow(selectedWorkflow)"
  >
    <mat-radio-button *ngFor="let option of data" [value]="option">{{
      option
    }}</mat-radio-button>
  </mat-radio-group>
</div>
