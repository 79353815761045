<div class="update-version">
  <app-chip
    class="type-chip"
    data-cy="current-version-chip"
    [label]="currentImageVersion"
    [variant]="ChipVariant.ERROR"
    matTooltip="{{currentImageVersion}}"
  />
  <mat-icon class="update-version-icon" data-cy="update-version-icon">arrow_right</mat-icon>
  <app-chip
    class="type-chip"
    data-cy="updated-version-chip"
    [label]="updatedVersion"
    [variant]="ChipVariant.SECONDARY"
    matTooltip="{{updatedVersion}}">
  </app-chip>
</div>
