<form
  data-cy="add-new-application-dialog"
  class="form"
  [formGroup]="inputFormGroup"
  (ngSubmit)="submitForm()"
>

  <div>
    <h2 class="header" mat-dialog-title>
      {{ "Application.AddApplicationDialog.Title" | translate }}
    </h2>
  </div>

  <div class="content" mat-dialog-content>
    <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
      <mat-label>{{
        "Application.AddApplicationDialog.ApplicationName" | translate
      }}</mat-label>
      <input
        #applicationName
        (click)="displayResourceRestrictionHelper(appNameFieldRef)"
        data-cy="application-name-input"
        formControlName="applicationNameControl"
        matInput
        maxlength="15"
        [errorStateMatcher]="matcher"
      />
      <mat-hint align="end">{{ applicationNameControl.value.length }}/15</mat-hint>
      <mat-error *ngIf="applicationNameControl.hasError('required')">
        {{ 'General.ErrorMessage.Required' | translate }}
      </mat-error>
      <mat-error data-cy="application-name-pattern-msg"  *ngIf="applicationNameControl.hasError('pattern')">
        {{ 'General.ErrorMessage.Pattern' | translate }}
      </mat-error>
      <mat-error data-cy="application-name-error-msg" *ngIf="applicationNameControl.hasError('nameRestrictorError')">
        {{ 'General.UnallowedInput' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
      <mat-label>{{
        "Application.AddApplicationDialog.ApplicationType" | translate
      }}</mat-label>
      <mat-select
        data-cy="select-application-type"
        class="caedge-form-field interior"
        [formControl]="applicationTypeControl"
      >
        <mat-option
          data-cy="application-type-option"
          *ngFor="let appType of appTypes"
          [value]="appType.id"
        >
          {{ appType.label }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="applicationTypeControl.hasError('required')">
        {{ 'General.ErrorMessage.Required' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
      <mat-label data-cy="app-source-link-label">{{ sourceLinkPlaceholder }}</mat-label>
      <input
         data-cy="source-link-input"
         formControlName="sourceLinkControl"
         matInput
         maxlength=100
         [matAutocomplete]="auto"
      />
      <mat-hint align="end">{{ sourceLinkControl.value.length }}/100</mat-hint>
      <mat-error *ngIf="sourceLinkControl.hasError('pattern')">
        {{ "General.ErrorMessage.NoSpaces" | translate }}
      </mat-error>
      <mat-error *ngIf="sourceLinkControl.hasError('required')">
        {{ "General.ErrorMessage.Required" | translate }}
      </mat-error>
      <mat-autocomplete #auto="matAutocomplete">
          @for (option of filteredOptions | async; track option) {
            <mat-option [value]="option">{{option}}</mat-option>
          }
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
      <mat-label>{{
        "Application.AddApplicationDialog.ApplicationSource" | translate
      }}</mat-label>
      <mat-select data-cy="select-source-type" class="caedge-form-field" [formControl]="sourceTypeControl">
        <mat-option
          *ngFor="let sourceType of sourceTypes"
          [value]="sourceType.id"
        >
          {{ sourceType.label }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="sourceTypeControl.hasError('required')">
        {{ "General.ErrorMessage.Required" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field data-cy="select-package-type" class="caedge-form-field-outline" ngDefaultControl>
      <mat-label>{{
        "Application.AddApplicationDialog.ApplicationPackage" | translate
      }}</mat-label>
      <mat-select
       class="caedge-form-field" [formControl]="packageTypeControl">
        <mat-option
          *ngFor="let packageType of packageTypes"
          [value]="packageType.id"
        >
          {{ packageType.label }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="packageTypeControl.hasError('required')">
        {{ "General.ErrorMessage.Required" | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="actions" mat-dialog-actions align="end">
    <button
      data-cy="cancel-add-new-application"
      class="cancel"
      mat-flat-button
      mat-dialog-close
    >
      {{ "General.Cancel" | translate }}
    </button>
    <button
      data-cy="add-new-application-btn"
      class="add-application-btn"
      mat-flat-button
      type="submit"
      [disabled]="inputFormGroup.invalid"
    >
      {{ "Application.AddApplicationDialog.AddApplication" | translate }}
    </button>
  </div>
</form>
