<div class="root" *ngIf="project$ | async as project">
  <app-header-template
    translationKey="General.Applications"
    [showBackButton]="false"
    ><button
      data-cy="add-application-button"
      mat-flat-button
      color="primary"
      class="add-application-button"
      aria-label="Add new application"
      (click)="openAddApplicationDialog()"
    >
      {{ "Application.AddApplicationDialog.Title" | translate }}
    </button>
  </app-header-template>
  <div
    data-cy="project-dashboard-content"
    class="project-dashboard-content"
    *ngIf="project$ | async as project"
  >
    <app-function-bar
      [searchPlaceholder]="'Application.Search'"
      [searchFilterControl]="searchApplicationsFilterControl"
      [maxInputLength]="50"
    >
      <div class="sort-by" filters>
        <label for="device-type-select"
          >{{
            "ApplicationList.FilterOptions.ApplicationType" | translate
          }}:</label
        >
        <app-select-menu
          data-cy="device-type-menu"
          id="device-type-select"
          *ngIf="applicationTypeData$ | async as applicationTypeData"
          [options]="applicationTypeData.options"
          [selectedOption]="applicationTypeData.selectedOption"
          (optionSelected)="selectApplicationTypeOption($event)"
        ></app-select-menu>
        <label for="sort-select">{{ "General.SortBy" | translate }}</label>

        <app-select-menu
          id="sort-select"
          *ngIf="sortByData$ | async as sortByData"
          [options]="sortByData.options"
          [selectedOption]="sortByData.selectedOption"
          (optionSelected)="selectSortOption($event)"
        ></app-select-menu>
      </div>
      <div class="view-toggle" others>
        <app-list-grid-toggle
          [selectedView]="projectsView"
          (viewChanged)="projectsView = $event"
        ></app-list-grid-toggle>
      </div>
    </app-function-bar>
    <div class="list-view-section">
      <ng-container *ngIf="applicationsData$ | async as applicationsData">
        <app-content-wrapper
          [isError]="applicationsData.hasError"
          [isLoading]="applicationsData.isLoading"
          [errorStatusCode]="applicationsData.errorStatusCode"
          [emptySectionScope]="EmptySectionScope.PAGE"
        >
          <app-application-list
            [grid]="projectsView === 'grid'"
            [applications]="this.applicationsData.applications"
          >
          </app-application-list>
        </app-content-wrapper>
      </ng-container>
    </div>
  </div>
</div>
