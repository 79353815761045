import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, signal } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { switchMap, of, takeUntil, Subject, map, combineLatest, Observable, startWith } from 'rxjs';
import { HeaderTemplateComponent } from 'src/app/shared/components/header/header-template.component';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { CommonModule } from '@angular/common';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { VecuApiService } from './services/vecu-api-service/vecu-api.service';
import { Vecu, VecuCreateResponse } from './models/vecu';
import { CreateVecuDialogComponent } from './components/dialogs/create-vecu-dialog/create-vecu-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { EmptySectionScope } from 'src/app/shared/utils/emptySectionScope';
import { VecuListContentComponent } from './components/layout/vecu-list-content/vecu-list-content.component';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { DialogProps } from 'src/app/shared/components/dialog/models/dialogProps';
import { DialogType } from 'src/app/shared/components/dialog/models/dialogType';
import { SelectedVecuService } from './services/selected-vecu-service/selected-vecu.service';
import { CaeButtonComponent } from 'src/app/shared/components/cae-button/cae-button.component';
import { DeleteMultipleVecusComponent } from './components/layout/delete-multiple-vecus/delete-multiple-vecus.component';
import { VecuDeletionService } from './services/vecu-deletion/vecu-deletion.service';
import { FunctionBarComponent } from 'src/app/shared/components/function-bar/function-bar.component';
import { VecuListService } from './services/vecu-list-service/vecu-list.service';
import { VecuSortKey, VecuStatusKey } from './utils/selection-keys';
import { Option } from 'src/app/core/models/interfaces/option';
import { SelectMenuComponent } from 'src/app/shared/components/select-menu/select-menu.component';
import { FormControl } from '@angular/forms';
import { VecuStore } from './stores/vecus.store';
import { ContentWrapperComponent } from 'src/app/shared/components/content-wrapper/content-wrapper.component';
import { VecusState } from './models/vecus-state';
import { ActivatedRoute ,Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { VecuCreationDialogCloseParams } from './models/vecu-creation-dialog-close-params';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { QuotaBarComponent } from 'src/app/shared/components/quota-bar/quota-bar.component';
import { Quota } from 'src/app/core/models/interfaces/quota';
import { cloneDeep } from 'lodash';
import { UserQuotaService } from 'src/app/core/services/user-quota/user-quota.service';
import { FeatureConfig } from 'src/environments/featureConfig';
import { FeatureService } from 'src/app/core/services/config/feature.service';
import { IProjectQuota } from 'src/app/core/models/interfaces/quotaAPI';

export type VecuListApi =
  | 'getVecus'
  | 'getVecuConfig'
  | 'createVecu'
  | 'deleteVecu'
  | 'getVecuQuota'
  | 'actionVecu';

@Component({
  selector: 'app-vecu-list',
  standalone: true,
  imports: [
    TranslateModule,
    HeaderTemplateComponent,
    CommonModule,
    SelectMenuComponent,
    MatButtonModule,
    VecuListContentComponent,
    CaeButtonComponent,
    DeleteMultipleVecusComponent,
    FunctionBarComponent,
    ContentWrapperComponent,
    MatIconModule,
    QuotaBarComponent
  ],
  templateUrl: './vecu-list.component.html',
  styleUrl: './vecu-list.component.scss'
})
export class VecuListComponent extends FeatureComponent<VecuListApi> implements OnInit, OnDestroy {
  @ViewChild('deleteMultipleVecusComponent')
  deleteMultipleVecusComponent!: TemplateRef<DeleteMultipleVecusComponent>;
  private readonly gettingStartedPageKey = 'gettingStartedPageVisited';

  vecus: Vecu[] = [];
  EmptySectionScope = EmptySectionScope;
  config: DialogProps = {} as DialogProps;
  selectedVecus$ = this.selectedVecuService.vecus$;
  private readonly destroy$: Subject<void> = new Subject();
  checkActiveFiltersSignal = signal<boolean>(false);

  searchFilterControl: FormControl = new FormControl('');
  vecusData$: Observable<VecusState>;
  searchFilter$: Observable<string> =
    this.searchFilterControl.valueChanges.pipe(startWith(''));

  filteredDevices$ = combineLatest([
    this.vecuStore.vecus$,
    this.searchFilter$,
    this.vEcuListService.selectedVecuConnectionStatus$,
    this.vEcuListService.selectedSortBy$,
  ]).pipe(map(this.vEcuListService.deviceMap));

  deviceConnectionStatusData$ = combineLatest([
    this.vEcuListService.vEcuConnectionStatusOptions$,
    this.vEcuListService.selectedVecuConnectionStatus$,
  ]).pipe(
    map(
      ([options, selectedOption]): {
        options: Option<VecuStatusKey>[];
        selectedOption: Option<VecuStatusKey>;
      } => ({ options, selectedOption }),
    ),
  );

  sortByData$ = combineLatest([
      this.vEcuListService.sortByOptions$,
      this.vEcuListService.selectedSortBy$,
    ]).pipe(
      map(
        ([options, selectedOption]): {
          options: Option<VecuSortKey>[];
          selectedOption: Option<VecuSortKey>;
        } => ({ options, selectedOption }),
      ),
    );

  readonly defaultQuota: Quota = {
    isLimited: false,
    projectQuotaMinutes: 0,
    userQuotaMinutes: 0,
    remainingUserQuotaPercentage: 0,
    isQuotaExceeded: false,
  };

  quota = cloneDeep(this.defaultQuota);

  featureFlagShowQuotaBar = this.featureService.isFeatureEnabled(
    FeatureConfig.quotaEndPointPolling,
  );

  constructor(
    private vecuApiService: VecuApiService,
    private snackbarService: SnackbarService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private selectedVecuService: SelectedVecuService,
    private vecuDeletionService: VecuDeletionService,
    private vEcuListService: VecuListService,
    private vecuStore: VecuStore,
    private quotaService: UserQuotaService,
    private featureService: FeatureService,
  ) {
    super();
    this.vecusData$ = this.combineDevicesData$(
      this.filteredDevices$,
      this.vecuStore.isLoading$,
      this.vecuStore.hasError$,
      this.vecuStore.errorStatusCode$,
    );
  }

  ngOnInit(): void {
    this.getVecus();

    const localStorageEntry: string | null = localStorage.getItem(
      this.gettingStartedPageKey,
    );

    if (!localStorageEntry) {
      localStorage.setItem(
        this.gettingStartedPageKey,
        'true',
      );

      // redirect to getting started page if it is the first visit
      this.router.navigate(['getting-started'], {
        relativeTo: this.activatedRoute,
      });
    } 

    this.initQuota();
  }

  getVecus(): void {
    if (this.API) {
      this.vecuApiService
        .getVecusUsingActivePolling(this.API.getVecus)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (value: Vecu[]) => {
            if (value) {
              this.vecus = value;
            }
          },
          error: () => {
            this.snackbarService.notifyError(
              'VecuList.VecuFetchErrorMessage',
            );
          },
      });
    }
  }

  combineDevicesData$(
    vecus: Observable<Vecu[]>,
    isLoading: Observable<boolean>,
    hasError: Observable<boolean>,
    errorStatusCode: Observable<number | undefined>,
  ): Observable<VecusState> {
    return combineLatest([vecus, isLoading, hasError, errorStatusCode]).pipe(
      map(([vecus, isLoading, hasError, errorStatusCode]) => {
        return { vecus, isLoading, hasError, errorStatusCode };
      }),
    );
  }

  selectDeviceConnectionStatusOption(
    deviceConnectionStatus: Option<VecuStatusKey>,
  ): void {
    this.vEcuListService.setSelectedDeviceConnectionStatus(
      deviceConnectionStatus,
    );
    this.checkActiveFiltersSignal.set(
      this.vEcuListService.checkActiveFilters(),
    );
  }

  selectSortOption(option: Option<VecuSortKey>): void {
    this.vEcuListService.setSelectedSortBy(option);
    this.checkActiveFiltersSignal.set(
      this.vEcuListService.checkActiveFilters(),
    );
  }

  handleResetFilters() {
    this.vEcuListService.clearActiveFilters();
  }

  openBulkDeletionDialog() {
    const title: string = 'VecuList.DeleteDialog.Title';
    const message: string = 'VecuList.DeleteDialog.Message';
    const customButtonText: string = 'General.Delete';
    this.config = {
      disableConfirmButton: false,
      type: DialogType.CUSTOM,
      title: this.translateService.instant(title),
      message: this.translateService.instant(message),
      customButtonText: this.translateService.instant(customButtonText),
      width: '500px',
      onCustomButtonClick: () => this.handleDeviceDeletion(dialogRef),
      onCancel: () => this.selectedVecuService.clearDeleteVecuResponse(),
    };

    const dialogRef: MatDialogRef<DialogComponent> = this.dialogService.openDialogRef(
      this.config,
      this.deleteMultipleVecusComponent,
    );
  }

  handleDeviceDeletion(dialogRef: MatDialogRef<DialogComponent>) {
    this.vecuDeletionService.handleVecuDeletion(
      this.API
    );
    dialogRef.close();
  }

  openCreateVecuDialog() {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '600px';
    dialogConfig.data = {
      getVecus: this.API?.getVecus,
      getVecuConfig: this.API?.getVecuConfig
    };

    const dialogRef = this.dialog.open(CreateVecuDialogComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((vecuCreationDialogOutput: VecuCreationDialogCloseParams) => {
          if (!vecuCreationDialogOutput || !this.API?.createVecu) {
            return of(null);
          }
          return this.vecuApiService.createVecu(this.API.createVecu, vecuCreationDialogOutput);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (value: VecuCreateResponse | null) => {
          if (value) {
            this.snackbarService.notifyInfo(
              'VecuList.CreateDialog.SuccessMessage',
            );
          }
        },
        error: () => {
          this.snackbarService.notifyError(
            'VecuList.CreateDialog.ErrorMessage',
          );
        },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  showGettingStartedPage() {
    this.router.navigate(['getting-started'], {
      relativeTo: this.activatedRoute,
    });
  }

  initQuota() {
    if (!this.API) {
      return;
    }

    this.quotaService
      .getQuota<IProjectQuota>(this.API.getVecuQuota)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (quota: Quota) => {
          this.quota = quota;
        },
        error: () => {
          this.quota = this.defaultQuota;
        },
      });
  }
}
