import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Banner } from '../../layout/banner/models/banner';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  private bannersSubject = new BehaviorSubject<Banner[]>([]);
  banners$ = this.bannersSubject.asObservable();

  createBanner(banner: Banner) {
    const banners = this.bannersSubject.value;
    const bannerExists = banners.some(
      (existingBanner) =>
        JSON.stringify(existingBanner) === JSON.stringify(banner),
    );

    if (!bannerExists) {
      this.bannersSubject.next([...banners, banner]);
    }
  }

  clearBanner(index: number) {
    const banners = this.bannersSubject.value;
    banners.splice(index, 1);
    this.bannersSubject.next(banners);
  }
}
