<footer  [ngClass]="{'light-footer-background': isStartingPage }">
  <div class="footer-container">
    <span class="footer-text">
      CAEdge {{ appVersion }} © {{ year }} Continental AG
    </span>
    <div class="footer-links">
      <a data-cy="legal-notice-link" routerLink="/legal-notice">{{
        "LandingPage.LegalNotice" | translate
      }}</a>
      <a routerLink="/data-protection">{{
        "LandingPage.DataProtection" | translate
      }}</a>
      <a routerLink="/site-notice">{{
        "LandingPage.SiteNotice" | translate
      }}</a>
      <a routerLink="/cookie-policy">{{
        "LandingPage.CookiePolicy" | translate
      }}</a>
    </div>
  </div>
  <div class="continental-logo">
    <div class="logo"></div>
  </div>
  <ng-template #cookieInformationDialogContent>
    <app-cookie-information-dialog-content />
  </ng-template>
</footer>
