import { JobDetailsComponent } from '../components/simulation-job-monitoring/components/job-details/job-details.component';
import { YieldReportComponent } from '../components/yield-report/yield-report.component';

export enum FeatureId {
    SIMULATION_JOB_MONITORING_DETAILS = 'SIMULATION_JOB_MONITORING_DETAILS',
    YIELD_REPORT_PAGE = 'YIELD_REPORT_PAGE',
};

export const dataAndSimulationComponentMap: Partial<Record<FeatureId, any>> = {
    [FeatureId.YIELD_REPORT_PAGE]: YieldReportComponent,
    [FeatureId.SIMULATION_JOB_MONITORING_DETAILS]: JobDetailsComponent,
};
