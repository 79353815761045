import { Injectable } from '@angular/core';
import { Option } from 'src/app/core/models/interfaces/option';
import {
  BehaviorSubject,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SORT_KEYS, SortKey } from 'src/app/shared/utils/sortKeys';
import { Project } from 'src/app/shared/stores/projects/models/project';
import { AddProjectDialogComponent } from '../../components/add-project-dialog/add-project-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectApiService } from '../projects/project-api.service';
import { SnackbarService } from '../../../../core/services/snackbar/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  defaultFilterValues: Option<SortKey> = {
    id: SORT_KEYS[0],
    label: this.translate.instant(SORT_KEYS[0]),
  };

  selectedSortBySource: BehaviorSubject<Option<SortKey>> = new BehaviorSubject<
    Option<SortKey>
  >(this.defaultFilterValues);

  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    private projectApiService: ProjectApiService,
    private snackbarService: SnackbarService,
  ) {}

  get sortByOptions$(): Observable<Option<SortKey>[]> {
    return this.translate.stream([...SORT_KEYS]).pipe(
      map((translations: Record<SortKey, string>) =>
        this.mapTranslations(translations),
      ),
      tap((options) => {
        const selectedSortByOption = this.selectedSortBySource.value;
        this.setSelectedSortBy(
          options.find((option) => option.id === selectedSortByOption.id)!,
        );
      }),
    );
  }

  get selectedSortBy$(): Observable<Option<SortKey>> {
    return this.selectedSortBySource.asObservable();
  }

  projectMap = ([projects, searchFilter, sortByOption]: [
    Project[],
    string,
    Option<SortKey>,
  ]) => {
    return projects
      .filter((project) => this.projectFilter(project, searchFilter))
      .sort(this.projectSort(sortByOption.id));
  };

  projectFilter = (project: Project, searchFilter: string) => {
    return (
      project.label.toLowerCase().includes(searchFilter.toLowerCase()) ||
      project.description.toLowerCase().includes(searchFilter.toLowerCase())
    );
  };

  projectSort = (sortBy: SortKey): ((a: Project, b: Project) => number) => {
    switch (sortBy) {
      case 'SortKeys.Alphabetical':
        return (a: Project, b: Project) =>
          !!a.label && b.label ? a.label.localeCompare(b.label, 'en') : -1;
      case 'SortKeys.AscendingDate':
        return (a: Project, b: Project) =>
          !!a.receivedAt && b.receivedAt
            ? -a.receivedAt.localeCompare(b.receivedAt)
            : -1;
      case 'SortKeys.DescendingDate':
        return (a: Project, b: Project) =>
          !!a.receivedAt && b.receivedAt
            ? a.receivedAt.localeCompare(b.receivedAt)
            : -1;
    }
  };

  mapTranslations = (translations: Record<SortKey, string>) => {
    return Object.entries(translations).map(
      ([sortKey, translation]: [string, string]): Option<SortKey> => ({
        id: sortKey as SortKey,
        label: translation,
      }),
    );
  };

  setSelectedSortBy(option: Option<SortKey>) {
    this.selectedSortBySource.next(option);
  }

  clearActiveFilters() {
    this.selectedSortBySource.next(this.defaultFilterValues);
  }

  addProjectDialog(tenantId: string, unsubscribe$: Subject<void>) {
    const dialogRef = this.dialog.open(AddProjectDialogComponent, {
      data: {
        tenantId: tenantId,
      },
      width: '811px',
      height: '625px',
      autoFocus: false,
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((response) => {
          if (!response) return of('');
          return this.projectApiService.createProject(response);
        }),
        takeUntil(unsubscribe$),
      )
      .subscribe({
        next: (message) => {
          if (message) {
            this.snackbarService.notifyInfo(message.project);
          }
        },
        error: () => {
          this.snackbarService.notifyError(
            this.translate.instant(
              'Project.AddProjectDialog.ErrorMessage.NotCreated',
            ),
          );
        },
      });
  }
}
