import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { FirmwareVersion } from '../fwVersions/models/fwVersion';
import { FwVersionsState } from './models/fwVersionsState';

@Injectable({
  providedIn: 'root',
})
export class FwVersionsStore extends Store<FwVersionsState> {
  constructor() {
    super({
      fwVersions: [],
      isLoading: true,
      hasError: false,
    });
  }

  get fwVersions$(): Observable<FirmwareVersion[]> {
    return this.state$.pipe(map((state) => state.fwVersions));
  }

  get isLoading$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.isLoading));
  }

  get hasError$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.hasError));
  }
}
