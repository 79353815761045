import { AbstractControl } from '@angular/forms';
import { restrictedNames } from '../../components/restricted-input-display/models/restricted-names';

interface Validator {
  name: string,
  value: boolean
}

enum Regex {
  cannotStartWithNumber,
  cannotStartWithDash,
  canContainLettersAndNumbersAndDash,
  canContainLettersAndNumbersAndUnderscore,
  canContainLettersAndNumbers,
}

export function nameRestrictor(
  control: AbstractControl,
): { nameRestrictorError: boolean } | null {
  const inputValue: string = (control.value as string).toLowerCase();
  const regex: RegExp =
    /^(?!adf|aws|caedge|conti|continental|landingzone|stacksets).*$/;

  if (!regex.test(inputValue)) {
    return { nameRestrictorError: true };
  }
  return null;
}

export function nameRestrictorWords(control: AbstractControl): string | null {
  const inputValue: string = (control.value as string).toLowerCase();
  const restrictedName = restrictedNames.filter((el) =>
    inputValue.includes(el),
  );

  if (restrictedName.length != 0) {
    return restrictedName[0];
  }
  return null;
}

export function applicationPattern(
  control: AbstractControl,
): Validator[] {
  const applicationPatternValidator: Validator[] = [
    {
      name: 'InputRestrictions.Restricted.CannotStartWithNumber',
      value: false,
    },
    {
      name: 'InputRestrictions.Restricted.CannotStartWithDash',
      value: false,
    },
    {
      name: 'InputRestrictions.Allowed.LowerCaseLettersAndNumbers',
      value: false,
    },
    {
      name: 'InputRestrictions.Allowed.Underscore',
      value: false,
    },
  ];
  const inputValue: string = control.value as string;

  applicationPatternValidator[0].value = regexValidator(
    inputValue,
    Regex.cannotStartWithNumber,
  );
  applicationPatternValidator[1].value = regexValidator(
    inputValue,
    Regex.cannotStartWithDash,
  );
  applicationPatternValidator[2].value = regexValidator(
    inputValue,
    Regex.canContainLettersAndNumbersAndUnderscore,
  );

  return applicationPatternValidator;
}

export function devicePattern(
  inputValue: string,
): Validator[] {
  const devicePatternValidator: Validator[] = [
    {
      name: 'InputRestrictions.Restricted.CannotStartWithNumber',
      value: false,
    },
    {
      name: 'InputRestrictions.Restricted.CannotStartWithDash',
      value: false,
    },
    {
      name: 'InputRestrictions.Allowed.LowerCaseLettersAndNumbers',
      value: false,
    },
    {
      name: 'InputRestrictions.Allowed.Dash',
      value: false,
    },
  ];

  devicePatternValidator[0].value = regexValidator(
    inputValue,
    Regex.cannotStartWithNumber,
  );
  devicePatternValidator[1].value = regexValidator(
    inputValue,
    Regex.cannotStartWithDash,
  );
  devicePatternValidator[2].value = regexValidator(
    inputValue,
    Regex.canContainLettersAndNumbersAndDash,
  );

  return devicePatternValidator;
}

export function versionPattern(
  inputValue: string,
): Validator[] {
  const versionPatternValidator: Validator[] = [
    {
      name: 'InputRestrictions.Allowed.LowerCaseLettersAndNumbers',
      value: false,
    },
    {
      name: 'InputRestrictions.Restricted.CannotStartWithNumber',
      value: false,
    },
  ];

  versionPatternValidator[0].value = regexValidator(
    inputValue,
    Regex.canContainLettersAndNumbers,
  );
  versionPatternValidator[1].value = regexValidator(
    inputValue,
    Regex.cannotStartWithNumber,
  );

  return versionPatternValidator;
}

function regexValidator(inputValue: string, regex: Regex): boolean {
  //regex to exlude words that start with a number
  const regexCannotStartWithNumber: RegExp = /\b\d\w*\b/;
  //regex to identify words that start with a dash
  const regexCannotStartWithDash: RegExp = /^[^-\s].*/;
  //regex for lower case and numbers and underscore
  const regexLettersAndNumbersAndUnderscore: RegExp = RegExp('[^a-z0-9_]+');
  //regex for lower case and numbers and dash
  const regexLettersAndNumbersAndDash: RegExp = RegExp('[^a-z0-9-]+');
  //regex for lower case and numbers
  const regexLettersAndNumbers: RegExp = RegExp('[^a-z0-9]+');

  switch (regex) {
    case Regex.cannotStartWithDash:
      return !regexCannotStartWithDash.test(inputValue);
    case Regex.cannotStartWithNumber:
      return regexCannotStartWithNumber.test(inputValue);
    case Regex.canContainLettersAndNumbersAndDash:
      return regexLettersAndNumbersAndDash.test(inputValue);
    case Regex.canContainLettersAndNumbersAndUnderscore:
      return regexLettersAndNumbersAndUnderscore.test(inputValue);
    case Regex.canContainLettersAndNumbers:
      return regexLettersAndNumbers.test(inputValue);
  }
}
