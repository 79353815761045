import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiRecord } from '../../../../shared/stores/config/models/apiRecord';
import {
  JobMonitoringService,
} from '../../services/job-monitoring.service';
import { ProjectNameMapping } from 'src/app/shared/utils/projectNameMapping';
import { generatePath } from '../../../../shared/utils/generatePath';
import { CurrentTab } from '../../job-monitoring.component';
import { MatInputModule } from '@angular/material/input';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import {
  JobParameterTemplateType,
  Step,
  Template,
  WorkflowConfiguration,
} from '../../models/workflow-configuration';
import { startWith, Subject, takeUntil } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { UploadFilesComponent } from '../upload-files/upload-files.component';
import { ParameterTemplateConfigs } from '../../models/paramter-template-configs';
import { DefineParameterEmitter } from '../add-job/add-job.component';

@Component({
  selector: 'app-define-parameter',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    FormsModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonModule,
    UploadFilesComponent,
  ],
  templateUrl: './define-parameter.component.html',
  styleUrls: ['./define-parameter.component.scss'],
})
export class DefineParameterComponent implements OnChanges, OnDestroy {
  @Input({ required: true }) api!: ApiRecord;
  @Input({ required: true }) currentTab!: CurrentTab;
  @Input({ required: true }) workflow!: string;
  @Input({ required: true }) projectName!: string;

  @Output() selectedParams = new EventEmitter<DefineParameterEmitter>();

  dynamicForms: FormGroup = new FormGroup({});
  configuration: WorkflowConfiguration = {} as WorkflowConfiguration;
  type = JobParameterTemplateType;

  unsubscribe$ = new Subject();

  constructor(private jobMonitoringService: JobMonitoringService) {}

  ngOnChanges(changes: SimpleChanges) {
    const workflow = changes['workflow'];

    if (workflow.currentValue === workflow.previousValue) return;

    const input: ParameterTemplateConfigs = {
      api: this.api,
      currentTab: this.currentTab,
      playlistId: this.workflow,
      projectName: this.projectName,
    };

    this.getParameterTemplate(input).then((response) => {
      this.configuration = response;
      this.initDynamicControls(response);
    });
  }

  /**
   * Http method to fetch the dynamic parameter templates
   *
   * @returns { Promise<WorkflowConfiguration> }
   * @param { ParameterTemplateConfigs } input
   */
  private async getParameterTemplate(
    input: ParameterTemplateConfigs,
  ): Promise<WorkflowConfiguration> {
    try {
      if (!input.playlistId) {
        return {} as WorkflowConfiguration;
      }
      const name =
        'rad6base' === input.projectName
          ? 'rad6base'
          : ProjectNameMapping[input.projectName] || input.projectName;
      const url: string = generatePath(input.api.url, {
        projectName: name,
        id: input.playlistId,
      });
      const apiRecord: ApiRecord = { ...input.api, url };
      return this.jobMonitoringService.getParameterTemplate(apiRecord);
    } catch (err) {
      console.error(err);
      return {} as WorkflowConfiguration;
    }
  }

  /**
   * Update the dynamicForms with controls from the config param and
   * check emit values to the parent component on changes
   *
   * @param { WorkflowConfiguration } configs
   */
  initDynamicControls(configs: WorkflowConfiguration): void {
    configs.workflow_configuration.steps.map((step: Step): void => {
      step.template
        .filter(
          (input: Template) =>
            input.type === this.type.DROPDOWN ||
            input.type === this.type.FORWARD ||
            input.type === this.type.TEXT_FIELD,
        )
        .map((inputTemplate: Template) => {
          const firstInputValue: number = 0;
          const value: string | string[] =
            inputTemplate.type === this.type.DROPDOWN
              ? inputTemplate.values[firstInputValue]
              : inputTemplate.values;
          const control = new FormControl(value, Validators.required);
          this.dynamicForms.addControl(inputTemplate.name, control);
        });
    });
    this.emitValueOnFormGroupChange();
  }

  emitValueOnFormGroupChange() {
    this.dynamicForms.valueChanges
      .pipe(
        startWith(this.dynamicForms.getRawValue()),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((forms) => {
        forms.repo = this.configuration.workflow_configuration.steps[0].repo;
        forms.step = this.configuration.workflow_configuration.steps[0].step;
        this.selectedParams.emit({
          values: [forms],
          validation: this.dynamicForms.valid,
        });
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
