<div class="root">
  <div class="sticky-header">
    <div class="myheader">
      <app-header-template
        class="my-header"
        [showBackButton]="false"
        itemName="Versions and deployments"
      >
        <div class="buttons">
          <button
            data-cy="add-version-button"
            mat-flat-button
            class="add-version-btn"
            aria-label="Add new version"
            (click)="openAddVersionDialog()"
          >
            {{ "Versions.AddNew" | translate }}
          </button>
          <button
            data-cy="add-deployment-button"
            mat-flat-button
            class="add-version-btn"
            aria-label="Start deployment"
            (click)="eventEmitter.emit()"
            [disabled]="(deployment$ | async)?.data | disableDeploymentButton"
          >
            {{ "HDK.StartDeployment" | translate }}
          </button>
        </div>
      </app-header-template>
    </div>
    <app-function-bar
      class="search-bar"
      data-cy="deployment-search"
      [searchPlaceholder]="'Deployments.Search'"
      [searchFilterControl]="searchFilterControl"
    >
    </app-function-bar>
  </div>
  <ng-container *ngIf="deployment$ | async as deployment">
    <app-content-wrapper
      data-cy="deployment-list-container"
      [isError]="deployment.hasError"
      [isLoading]="deployment.isLoading"
      [errorMessage]="'DeviceList.DeviceListError' | translate"
    >
      <app-hdk-deployment-list-content
        [versions]="deployment.data"
        [application]="application"
      ></app-hdk-deployment-list-content>
    </app-content-wrapper>
  </ng-container>
</div>
