import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { BannerService } from '../../services/banner/banner.service';
import { CommonModule } from '@angular/common';
import { Banner } from './models/banner';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { WindowResizeService } from '../../../core/services/windowResize/window-resize.service';

@Component({
  selector: 'app-banner',
  standalone: true,
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  imports: [CommonModule, MatIconButton, MatIcon],
})
export class BannerComponent implements OnInit, OnDestroy {
  banners: Banner[] = [];
  private unsubscribe$: Subject<void> = new Subject<void>();
  private screenHeight: number = window.innerHeight;
  constructor(
    private bannerService: BannerService,
    private resizeService: WindowResizeService,
  ) {}

  ngOnInit() {
    this.bannerService.banners$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((banners) => {
        this.banners = banners;
        if (banners.length > 0) {
          this.updateHeaderHeight();
        }
      });

    this.resizeService
      .getHeight()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((height) => {
        this.screenHeight = height;
        this.updateHeaderHeight();
      });
  }

  private updateHeaderHeight() {
    const numBunners = this.banners.length;
    const bannerHeight = 72;
    const headerHeight = 92;
    const contentHeight =
      this.screenHeight - numBunners * bannerHeight - headerHeight;
    document.getElementById('app-content')!.style.height = `${contentHeight}px`;
  }

  closeBanner(index: number) {
    this.bannerService.clearBanner(index);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
