<app-header-template data-cy="yield-report-page-title" translationKey="YieldReport.Title" [showBackButton]="false">
</app-header-template>
<div class="download-reports-container">
  <div class="download-reports-header">
    <p class="title">{{ "YieldReport.Title" | translate }}</p>
    <p class="subtitle">{{ "General.NotifyUs" | translate }}</p>
    <div class="download-input-button">
      <div class="date-picker-input">
        <mat-form-field class="caedge-form-field-outline">
          <mat-label>{{ "YieldReport.Download.ReportOf" | translate }}</mat-label>
          <input matInput [(ngModel)]="selectedDate" [readonly]="true" [matDatepicker]="picker"/>
          <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <button mat-raised-button (click)="downloadReport()">
        <mat-icon>download</mat-icon>
        {{ "YieldReport.Download.DownloadReport" | translate }}
      </button>
    </div>
  </div>
</div>
