enum Constants {
  MILLISECONDS_IN_ONE_SECOND = 1000,
  HEXADECIMAL_RADIX = 16,
  LAST_TWO_CHARACTERS = -2,
  CHAR_PREFIX = '00',
}

export class JwtDecoder {
  public decodeJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((character) => {
          return (
            '%' +
            (
              Constants.CHAR_PREFIX +
              character.charCodeAt(0).toString(Constants.HEXADECIMAL_RADIX)
            ).slice(Constants.LAST_TWO_CHARACTERS)
          );
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}
