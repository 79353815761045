<div class="header-sticky">
  <app-header-template
    itemName="Dashboard"
    [showBackButton]="false"
    data-cy="project-details-overview"
  >
  </app-header-template>
</div>
@defer (on idle) {
  @if (combinedData$ | async; as combinedData) {
    @if (combinedData.projectDetails) {
      <app-details-expansion
        data-cy="api-project-details"
        title="{{ 'ProjectDetails.Title' | translate }}"
        description="{{ 'ProjectDetails.EditByAdmin' | translate }}"
        [hideToggle]="true"
        [menuItems]="menuItems"
      >
        <app-project-display-details
          [projectDetails]="combinedData.projectDetails"
        ></app-project-display-details>
      </app-details-expansion>
      @if(isBlueprintEnabled) {
        <div class="myheader">
            <app-project-services-list [projectId]="combinedData.projectDetails.skey+'-'+combinedData.projectDetails.pkey">
            </app-project-services-list>
        </div>
        <mat-divider></mat-divider>
      }
      <div class="myheader">
        <app-header-template
          [showBackButton]="false"
          [isRowDisplayed]="false"
          translationKey="ProjectDetails.ProjectAdminsTitle"
          description="ProjectDetails.ProjectAdminsDescription"
        ></app-header-template>
      </div>
      <app-project-table
        data-cy="project-admins-list"
        [data]="combinedData.projectDetails.transformedContacts"
      ></app-project-table>
    } @else {
      <app-details-expansion
        data-cy="local-project-details"
        title="{{ 'ProjectDetails.Title' | translate }}"
        description="{{ 'ProjectDetails.EditByAdmin' | translate }}"
        [hideToggle]="true"
        [menuItems]="menuItems"
      >
        <app-display-details
          data-cy="project-details"
          translationKey="Project"
          [data]="project$ | async"
        ></app-display-details>
      </app-details-expansion>
    }
  }
 } 
 @loading (after 1ms; minimum 500ms) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
 }
