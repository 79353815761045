import { DEVICE_STATUS_KEYS } from 'src/app/features/device-list/utils/selectionKeys';
import { DeviceStatus } from './deviceStatus';

export const deviceStatusLabelMap = {
  [DeviceStatus.CREATING]: DEVICE_STATUS_KEYS.CREATING,
  [DeviceStatus.CONNECTING]: DEVICE_STATUS_KEYS.CONNECTING,
  [DeviceStatus.CONNECTED]: DEVICE_STATUS_KEYS.CONNECTED,
  [DeviceStatus.DISCONNECTED]: DEVICE_STATUS_KEYS.DISCONNECTED,
  [DeviceStatus.DELETING]: DEVICE_STATUS_KEYS.DELETING,
  [DeviceStatus.FAILED]: DEVICE_STATUS_KEYS.FAILED,
  [DeviceStatus.STARTING]: DEVICE_STATUS_KEYS.STARTING,
  [DeviceStatus.STOPPING]: DEVICE_STATUS_KEYS.STOPPING,
  [DeviceStatus.ERROR]: DEVICE_STATUS_KEYS.ERROR,
  [DeviceStatus.OFFLINE]: DEVICE_STATUS_KEYS.OFFLINE,
  [DeviceStatus.UNDEFINED]: DEVICE_STATUS_KEYS.UNDEFINED,
};
