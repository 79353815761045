import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeviceType } from '../../../../shared/stores/devices/models/deviceType';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  devicePattern,
  nameRestrictor,
  nameRestrictorWords,
} from '../../../../shared/utils/name-restriction-validator/name-restriction-validator';
import {
  AsyncPipe,
  JsonPipe,
  KeyValuePipe,
  NgForOf,
  NgIf,
  NgTemplateOutlet,
} from '@angular/common';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { GenericStateMatcher } from '../../../../shared/utils/genericStateMatcher';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { DeviceListApiService } from '../../services/device-list-api/device-list-api.service';
import { map, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { ApiRecord } from '../../../../shared/stores/config/models/apiRecord';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VhpcRelease } from '../../models/vhpcRelease';
import { Vhpc } from '../../models/vhpc';
import { Instance } from '../../models/vhpcConfig';
import { AuthStore } from '../../../../shared/stores/auth/auth.store';
import { PopoverService } from 'src/app/core/services/popover/popover.service';
import { ResourceRestrictionHelperService } from 'src/app/shared/utils/resource-restriction-helper-service/resource-restriction-helper.service';
import {
  Language,
  LanguageService,
} from 'src/app/core/services/language/language.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { RestrictedInputDisplayComponent } from 'src/app/shared/components/restricted-input-display/restricted-input-display.component';
import { RestrictedInputData } from 'src/app/shared/components/restricted-input-display/models/restricted-input-data';
import {
  FilterByRegionPipe,
  RegionSelectAll,
} from '../../../../core/pipes/filter-by-region.pipe';
import { ConfigService } from '../../../../core/services/config/config.service';

export interface CreateVhpcDeviceDialogData {
  apiRecords: Record<'releases' | 'create', ApiRecord>;
}

@Component({
  selector: 'app-create-vhpc-device-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    TranslateModule,
    MatDialogContent,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    NgIf,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgTemplateOutlet,
    MatOptionModule,
    MatSelectModule,
    NgForOf,
    AsyncPipe,
    MatCheckboxModule,
    KeyValuePipe,
    JsonPipe,
    MatIconModule,
    MatTooltipModule,
    FilterByRegionPipe,
  ],
  templateUrl: './create-vhpc-device-dialog.component.html',
  styleUrl: './create-vhpc-device-dialog.component.scss',
})
export class CreateVhpcDeviceDialogComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('nameInput', { read: ElementRef<MatFormField> })
  vhpcDeviceNameFieldRef!: ElementRef<MatFormField>;

  regions: string[] = [];
  releases: Observable<VhpcRelease[]> = this.deviceApiService
    .getReleases(this.data.apiRecords.releases)
    .pipe(
      map((releases) =>
        // sort releases alphabetically
        releases.sort((a, b) =>
          a.vhpcReleaseName > b.vhpcReleaseName ? 1 : -1,
        ),
      ),
      tap((sortedReleases) => {
        const allRegions: string[] = [];
        // push region values to the regions array
        sortedReleases.forEach((release) =>
          allRegions.push(release.vhpcConfig.region),
        );
        // remove duplicates
        this.regions = Array.from(new Set(allRegions));
      }),
    );

  partitions: Instance[] = [];
  partitionsRequiredError: boolean = false;
  selectedPartitions: Instance[] = [];

  createDeviceFormGroup: FormGroup = new FormGroup({
    deviceName: new FormControl('', [
      Validators.required,
      //Only lower case letters, numbers and "-" allowed, but the first char can't be a number
      Validators.pattern(/^[a-z]+[a-z0-9-]*$/),
      nameRestrictor,
    ]),
    region: new FormControl(RegionSelectAll),
    release: new FormControl('', [Validators.required]),
  });

  errorMatcher = new GenericStateMatcher();
  currentStepIndex = 0;
  errorCodeRequired = 'required';

  readonly steps = [
    {
      title: this.translate.instant('DeviceList.CreateDialog.TitleVECU'),
    },
    {
      title: this.translate.instant('DeviceList.CreateDialog.TitleVECU'),
    },
  ];
  protected readonly DeviceType = DeviceType;
  protected readonly RegionSelectAll = RegionSelectAll;
  private unsubscribe$: Subject<void> = new Subject();
  isPlayGroundTenant = false;
  readonly playgroundTenant = 'T010';

  constructor(
    private translate: TranslateService,
    private deviceApiService: DeviceListApiService,
    @Inject(MAT_DIALOG_DATA) public data: CreateVhpcDeviceDialogData,
    private formBuilder: FormBuilder,
    private authStore: AuthStore,
    private popoverService: PopoverService,
    private resourceRestrictionHelperService: ResourceRestrictionHelperService,
    private languageService: LanguageService,
    public dialogRef: MatDialogRef<CreateVhpcDeviceDialogComponent>,
    private configService: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.initReleases();
    this.configService
      .getTenantId()
      .pipe(
        take(1),
        map((tenantId) => {
          this.isPlayGroundTenant = tenantId === this.playgroundTenant;
          if (this.isPlayGroundTenant) {
            this.dialogRef.updateSize('815px', '700px');
          }
          return tenantId;
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  private initReleases() {
    // get partitions when release selection changed
    this.createDeviceFormGroup
      .get('release')
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(this.handleReleaseChange.bind(this));
  }

  private handleReleaseChange(vhpcRelease: VhpcRelease) {
    this.partitions = vhpcRelease.vhpcConfig.instances;
    this.selectedPartitions = this.partitions;
    this.updatePartitions();
  }

  private updatePartitions() {
    this.resetPartitionsSelectionControl();
    this.addChangeListenerToPartitionsControl();
    this.initSelectedPartitions();
  }

  private resetPartitionsSelectionControl() {
    this.createDeviceFormGroup.removeControl('selectedPartitions');
    this.createDeviceFormGroup.addControl(
      'selectedPartitions',
      this.formBuilder.array(Object.keys(this.partitions).map(() => true)),
    );
  }

  private addChangeListenerToPartitionsControl() {
    const control = this.createDeviceFormGroup.get('selectedPartitions');
    control?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      // update selectedPartitions based on the checkboxes in the form
      this.selectedPartitions = this.partitions.filter(
        (_, index) => control.value[index],
      );

      // check if at least one partition is selected
      this.partitionsRequiredError = this.selectedPartitions.length <= 0;
    });
  }

  private initSelectedPartitions() {
    this.selectedPartitions = this.partitions;
    this.createDeviceFormGroup
      .get('selectedPartitions')
      ?.updateValueAndValidity();
  }

  // Sets the current step to the next step, in case it is possible
  nextStep() {
    if (this.currentStepIndex === this.steps.length - 1) {
      return;
    }

    if (this.isStepOneValid()) {
      this.currentStepIndex++;
    }
  }

  private isStepOneValid() {
    this.createDeviceFormGroup.get('deviceName')?.markAsTouched();
    this.createDeviceFormGroup.get('release')?.markAsTouched();
    return (
      this.createDeviceFormGroup.get('deviceName')?.valid &&
      this.createDeviceFormGroup.get('release')?.valid
    );
  }

  // Sets the current step to the previous step, in case it is possible
  prevStep() {
    if (this.currentStepIndex === 0) {
      return;
    }
    this.currentStepIndex--;
  }

  handleSubmitClick() {
    const result = this.getNewVhpc();
    this.dialogRef.close(result);
  }

  private getNewVhpc(): Vhpc {
    const newVhpcConfig: VhpcRelease = this.releaseFormControl;

    newVhpcConfig.vhpcConfig.instances = this.selectedPartitions;

    return {
      vhpcName: this.deviceNameFormControl?.value,
      createdBy: this.authStore.userId,
      vhpcConfig: newVhpcConfig.vhpcConfig,
    };
  }

  trackByPartitionName(index: number, partition: Instance): string {
    return partition.name;
  }

  trackByReleaseName(index: number, release: VhpcRelease): string {
    return release.vhpcReleaseName;
  }

  displayRestrictor(fieldRef: ElementRef<MatFormField>): void {
    const selectedLanguage: Language =
      this.languageService.getCurrentLanguageValue();
    let positionConfig: ConnectedPosition = {
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'bottom',
    };

    if (selectedLanguage === 'en') {
      positionConfig.offsetX = 310;
      positionConfig.offsetY = 180;
    } else {
      positionConfig.offsetX = 335;
      positionConfig.offsetY = 180;
    }

    this.updateDisplayRestrictorData();
    this.createDeviceFormGroup
      .get('deviceName')
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.updateDisplayRestrictorData();
      });
    this.popoverService.open<RestrictedInputDisplayComponent>(
      fieldRef,
      RestrictedInputDisplayComponent,
      positionConfig,
    );
  }

  private updateDisplayRestrictorData(): void {
    const displayRestrictionData: RestrictedInputData = {
      fieldValue: this.createDeviceFormGroup.get('deviceName')?.value,
      hasDevicePatternFailed: this.createDeviceFormGroup
        .get('deviceName')
        ?.hasError('pattern'),
      hasNameRestrictionFailed: this.createDeviceFormGroup
        .get('deviceName')
        ?.hasError('nameRestrictorError'),
      nameRestrictor: nameRestrictorWords(
        this.createDeviceFormGroup.get('deviceName')!,
      ),
      devicePattern: devicePattern(
        this.createDeviceFormGroup.get('deviceName')?.value,
      ),
    };
    this.resourceRestrictionHelperService.updateData(displayRestrictionData);
  }

  get regionFormControl() {
    return this.createDeviceFormGroup.get('region');
  }

  get deviceNameFormControl() {
    return this.createDeviceFormGroup.get('deviceName');
  }

  get releaseFormControl() {
    return this.createDeviceFormGroup.get('release')?.value as VhpcRelease;
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
