@if (quota.isLimited) {
  <div class="quota-bar">
    <div class="user-quota-info-bar">
      <mat-icon
        data-cy="quota-icon"
        aria-label="user quota"
        [matTooltip]="('General.QuotaIconTooltip' | translate)"
      >
        {{ icon }}
      </mat-icon>
      <div class="quota-headline">{{ "General.Quota" | translate }}</div>

      @if (!quota.isQuotaExceeded) {
        <app-chip class="quota-percentage"
                  [label]="quota.remainingUserQuotaPercentage + '%'"
                  [variant]="quota.remainingUserQuotaPercentage < highQuotaThreshold? ChipVariant.ERROR : (quota.remainingUserQuotaPercentage >= highQuotaThreshold && quota.remainingUserQuotaPercentage < mediumQuotaThreshold)?ChipVariant.WARN : ChipVariant.SUCCESS"
        />

      } @else {
        <div class="quota-warning">
          <app-chip class="quota-error-chip" data-cy="quota-error-detail-chip"
                    [label]="quota.remainingUserQuotaPercentage + '% – ' + ('General.QuotaExceeded' | translate)"
                    [variant]="ChipVariant.ERROR" [icon]="['info', 'right']"
          />
        </div>
      }

      <div class="quota-hours">
        {{ quota.userQuotaMinutes }}h / {{ quota.projectQuotaMinutes }}h
      </div>

    </div>
  </div>
}
