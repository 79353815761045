import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { EmptySectionIcon } from './models/empty-section';
import { EmptySectionScope } from 'src/app/shared/utils/emptySectionScope';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-empty-section',
  templateUrl: './empty-section.component.html',
  styleUrls: ['./empty-section.component.scss'],
  imports: [
    MatIconModule,
    CommonModule
  ]
})
export class EmptySectionComponent {
  @Input() iconName: EmptySectionIcon | undefined ;
  @Input() emptySectionScope?: EmptySectionScope = EmptySectionScope.TABLE;

  SectionScope = EmptySectionScope;
}
