import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Device } from '../../../../../../../shared/stores/devices/models/device/device';
import {
  DeploymentStatus,
  DeploymentStatusMap,
} from '../../../../../../../shared/stores/deployment/models/deployment';
import {
  ChipComponent,
  ChipVariant,
} from '../../../../../../../shared/components/chip/chip.component';
import { DeviceConnectionStatus } from 'src/app/shared/stores/devices/models/deviceConnectionStatus';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { DeployedApplication } from '../../../models/deviceApplications';
import { ConvertToTypeLabelsPipe } from 'src/app/shared/pipes/convert-to-type-labels.pipe';
import { AsyncPipe, CommonModule } from '@angular/common';
import { FeatureComponent } from '../../../../../../../core/models/classes/feature.component';
import { Observable, of } from 'rxjs';
import { Application } from '../../../../../../../shared/stores/deployment/models/application';
import { DeleteDeploymentDialogComponent } from '../../../../../../vecu-ami/components/deployment-list/components/delete-deployment-dialog/delete-deployment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationListApiService } from 'src/app/core/components/project-dashboard/components/application-list/services/application-list-api.service';
import { RoutingStore } from 'src/app/shared/stores/config/routing.store';

export type DeviceDetailsDeploymentsAPI =
  | 'getAppVersions'
  | 'getAppDetails'
  | 'deleteDevDeployment';

@Component({
  selector: 'app-device-deployment-detail',
  standalone: true,
  imports: [
    ChipComponent,
    AsyncPipe,
    CommonModule,
    MatIcon,
    MatTooltip,
    TranslateModule,
    ConvertToTypeLabelsPipe,
  ],
  templateUrl: './device-deployment-detail.component.html',
  styleUrl: './device-deployment-detail.component.scss',
})
export class DeviceDeploymentDetailComponent
  extends FeatureComponent<DeviceDetailsDeploymentsAPI>
  implements OnInit, OnChanges {
  @Input() device: Device = {} as Device;
  @Input({ required: true }) deployment: DeployedApplication =
    {} as DeployedApplication;
  readonly DeploymentStatus = DeploymentStatus;
  deploymentStatusLabel = '';
  deploymentStatusVariant = ChipVariant.SECONDARY;

  protected readonly DeviceConnectionStatus = DeviceConnectionStatus;
  protected readonly ChipVariant = ChipVariant;
  readonly DeploymentStatusMap = DeploymentStatusMap;
  application$: Observable<Application> = of({} as Application);

  private variantMap: { [key: string]: ChipVariant } = {
    failed: ChipVariant.ERROR,
    success: ChipVariant.SUCCESS,
    default: ChipVariant.SECONDARY,
  };

  constructor(
    private appService: ApplicationListApiService,
    private routingStore: RoutingStore,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['deployment'] && changes['deployment'].currentValue) {
      this.deployment = changes['deployment'].currentValue;
      this.updateDeploymentStatus();
    }
  }

  private initData() {
    if (!this.API) return;
    this.updateDeploymentStatus();
    this.application$ = this.appService.getAppDetails(
      this.API.getAppDetails,
      this.deployment.appId,
    );
  }

  private updateDeploymentStatus() {
    this.deploymentStatusLabel =
      DeploymentStatusMap[this.deployment.deploymentState];
    this.deploymentStatusVariant =
      this.variantMap[this.deploymentStatusLabel] || this.variantMap['default'];
  }

  openApplicationPage() {
    this.routingStore.navigateToApplication(this.deployment.appId);
  }

  openDeleteDeploymentDialog() {
    this.dialog.open(DeleteDeploymentDialogComponent, {
      width: '400px',
      data: {
        isForcedDeletion: false,
        hwName: this.device.deviceId,
        deleteDeployment: this.API?.deleteDevDeployment!,
        appName: this.deployment.appId,
        versionId: this.deployment.versionId,
        partitionName: this.deployment.partitionName,
      },
    });
  }
}
