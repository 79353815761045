<table mat-table matSort [dataSource]="dataSource" (matSortChange)="setColumnSortingParams($event)">
  <!-- ID column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell mat-sort-header="id" *matHeaderCellDef># ID</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="'Copy'"
      matTooltipPosition="right"
      (click)="copyToClipboard(element.id)"
    >
      {{ element.id }}
    </td>
  </ng-container>

  <!-- Playlist ID column -->
  <ng-container matColumnDef="playlist_id">
    <th mat-header-cell mat-sort-header="playlist_id" *matHeaderCellDef>
      Playlist ID
    </th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="'Copy'"
      matTooltipPosition="right"
      (click)="copyToClipboard(element.playlist_id)"
    >
      {{ element.playlist_id }}
    </td>
  </ng-container>

  <!-- Name column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell mat-sort-header="name" *matHeaderCellDef>Name</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="'Copy'"
      matTooltipPosition="right"
      (click)="copyToClipboard(element.name)"
    >
      {{ element.name }}
    </td>
  </ng-container>

  <!-- Type column -->
  <ng-container matColumnDef="type">
    <th mat-header-cell mat-sort-header="type" *matHeaderCellDef>Stage type</th>
    <td mat-cell *matCellDef="let element">{{ element.type }}</td>
  </ng-container>

  <!-- Status column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">{{ element.status }}</td>
  </ng-container>

  <!-- Actions column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element"></td>
  </ng-container>

  <!-- Details column -->
  <ng-container matColumnDef="details">
    <th mat-header-cell *matHeaderCellDef></th>
    <td
    (click)="navigateToDetailsPage(element.playlist_id)"
      mat-cell
      *matCellDef="let element"
      [matTooltip]="'Details'"
      matTooltipPosition="right"
    >
      <mat-icon >navigate_next</mat-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <!-- No data row -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [colSpan]="displayedColumns.length">
      <app-empty-section> No data found </app-empty-section>
    </td>
  </tr>
</table>
<mat-paginator
  *ngIf="dataSource.data.length !== 0"
  (page)="setPageSizeInLocalStorage($event)"
  [pageSize]="defaultPageSize"
  [pageSizeOptions]="pageSizeOptions"
  showFirstLastButtons
  aria-label="Select page of periodic elements"
>
</mat-paginator>
