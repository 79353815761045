import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderTemplateComponent } from '../../../../shared/components/header/header-template.component';
import { CommonModule, NgIf } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FeatureComponent } from '../../../../core/models/classes/feature.component';
import { catchError, combineLatest, Observable, of, Subject, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { IPlaylistDetails } from '../../models/playlist-details';
import { PlaylistService } from '../../services/playlist.service';
import { ChipComponent, ChipVariant } from 'src/app/shared/components/chip/chip.component';
import { MatIconModule } from '@angular/material/icon';
import { CopyToClipboardService } from 'src/app/shared/utils/copy-to-clipboard.service';
import { MatTooltipModule } from '@angular/material/tooltip';

export type PlaylistDetailsApi = 'getPlaylistDetails';
export enum StageValue{
  'Stage 1',
  'Stage 2',
  'Stage 3'
}
@Component({
  selector: 'app-playlist-details',
  standalone: true,
  imports: [
    CommonModule, 
    MatTooltipModule,
    ChipComponent, 
    HeaderTemplateComponent,
    MatIconModule,
    NgIf,
    MatProgressBarModule,
    TranslateModule,
  ],
  templateUrl: './playlist-details.component.html',
  styleUrl: './playlist-details.component.scss',
})

export class PlaylistDetailsComponent
  extends FeatureComponent<PlaylistDetailsApi>
  implements OnInit, OnDestroy
{
  loading = false;
  playlistId = '';
  unsubscribe$: Subject<void> = new Subject<void>();
  playlistDetails$: Observable<IPlaylistDetails | null> = of(null);

  protected readonly ChipVariant = ChipVariant;


  constructor(private playlistService: PlaylistService,
    private copyToClipboardService: CopyToClipboardService,
    ) {
    super();
  }

  ngOnInit(): void {
    this.initRouteListener();    
  }

  private initRouteListener() {
    combineLatest(
      [this.route.params, this.route.queryParams],
      (params, qparams) => ({ params, qparams }),
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((ap) => {
        this.playlistId = ap.params['playlistId'];
        this.fetchPlaylistDetailsData();
      });
  }

  private fetchPlaylistDetailsData() {
    if (!this.API) return;
    this.loading = true;
    const playlistApi = this.API.getPlaylistDetails;
    this.playlistDetails$ = this.playlistService
      .getPlaylistDetails(playlistApi, this.playlistId)
      .pipe(
        catchError((err) => {
          this.loading = false;
          return of(err);
        }),
      );
    this.loading = false;
  }

  copyToClipboard(data: string) {
    this.copyToClipboardService.copyToClipboard(data);
  }

  filterStage(value: string){
      return StageValue[Number(value)-1];
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
