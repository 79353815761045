<p>Enter parameters</p>
@if (configuration.workflow_configuration) {
  @for (step of configuration.workflow_configuration.steps; track step.step) {
    @for (template of step.template; track template.name) {
      <form [formGroup]="dynamicForms">
        <mat-form-field
          class="caedge-form-field-fill"
          *ngIf="template.type === type.DROPDOWN"
        >
          <mat-label>{{ template.name }}</mat-label>
          <mat-select [formControlName]="template.name">
            <mat-option
              *ngFor="let element of template.values"
              [value]="element"
            >
              {{ element }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="caedge-form-field-fill"
          *ngIf="template.type === type.TEXT_FIELD"
          ngDefaultControl
        >
          <mat-label>{{ template.name }}</mat-label>
          <input [formControlName]="template.name" matInput />
        </mat-form-field>
      </form>
      @if (template.type === type.FILE) {
        <app-upload-files [config]="template"></app-upload-files>
      }
    }
  }
}
