export const generatePath = (
  routePath: string,
  params: Record<string, string | number>,
  queryParams?: Record<string, string | number>,
): string => {
  const pathWithParams = Object.entries(params).reduce(
    (path, [key, value]) => path.replace(`:${key}`, String(value)),
    routePath,
  );

  if (!queryParams) {
    return pathWithParams;
  }

  const queryString = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${String(value)}`)
    .join('&');


  return `${pathWithParams}?${queryString}`;
};
