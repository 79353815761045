export interface BackendDataSimulationSensorsGenericNames {
    FL_ALL: [string[]];
    FR_ALL: [string[]];
    REAR: [string[]];
    RL: [string[]];
    RR: [string[]];
};

export interface AdjustedSimulationSensorsGenericNames {
    flAll: [string[]];
    frAll: [string[]];
    rear: [string[]];
    rl: [string[]];
    rr: [string[]];
};

export class SimulationSensorsGenericNames implements AdjustedSimulationSensorsGenericNames {
    flAll!: [string[]];
    frAll!: [string[]];
    rear!: [string[]];
    rl!: [string[]];
    rr!: [string[]];

    public static Factory(dataSimulationSensorsGenericNames: BackendDataSimulationSensorsGenericNames): SimulationSensorsGenericNames {
        const newSimulationSensorsGenericNames = new SimulationSensorsGenericNames();
        newSimulationSensorsGenericNames.flAll = dataSimulationSensorsGenericNames['FL_ALL'];
        newSimulationSensorsGenericNames.frAll = dataSimulationSensorsGenericNames['FR_ALL'];
        newSimulationSensorsGenericNames.rear = dataSimulationSensorsGenericNames['REAR'];
        newSimulationSensorsGenericNames.rl = dataSimulationSensorsGenericNames['RL'];
        newSimulationSensorsGenericNames.rr = dataSimulationSensorsGenericNames['RR'];
        return newSimulationSensorsGenericNames;
    };
};
