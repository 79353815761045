import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { IState } from '../State';
import { Application } from './models/application';

@Injectable({
  providedIn: 'root',
})
export class AddVersionStore extends Store<IState<Application>> {
  constructor() {
    super({
      data: new Application(),
      isLoading: true,
      hasError: false,
    });
  }

  get application$(): Observable<Application> {
    return this.state$.pipe(map((state) => state.data));
  }

  get isLoading$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.isLoading));
  }

  get hasError$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.hasError));
  }
}
