import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DisplayDetailComponent } from '../../../application-dashboard/components/application-details/display-detail.component';
import {TranslateModule} from '@ngx-translate/core';
import {VdiInstance} from '../../models/vdi-instance';

@Component({
  selector: 'app-vdi-request',
  standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        DisplayDetailComponent,
        TranslateModule,
    ],
  templateUrl: './vdi-request.component.html',
  styleUrls: ['./vdi-request.component.scss'],
})
export class VdiRequestComponent {
  @Input() request: string | null = null;
  @Input({ required: true }) instance!: VdiInstance | undefined;
  @Input({ required: true }) isLoading!: boolean;
}
