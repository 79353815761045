<div class="root">
  @if (users.length === 0) {
    <app-empty-section
      [iconName]="EmptySectionIcon.COOKIE"
      [emptySectionScope]="EmptySectionScope.PAGE"
    >
      {{ "Project.ProjectUsers.EmptySectionMessage" | translate }}
    </app-empty-section>
  } @else {
    <div [ngClass]="{ grid }" [@listAnimation]="users.length">
      @for (user of users; track user) {
        <app-users-list-item
          [user]="user"
          data-cy="project-users-list"
          (removeUserFromProject)="removeUserFromProject.emit($event)"
          (editUserRole)="editUserRole.emit($event)"
        ></app-users-list-item>
      }
    </div>
  }
</div>
