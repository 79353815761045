import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { Theme, ThemeService } from 'src/app/core/services/theme/theme.service';
import { Observable } from 'rxjs';
import { TabletModeService } from 'src/app/core/services/tablet-mode/tablet-mode.service';

export type View = 'list' | 'grid';

@Component({
  selector: 'app-list-grid-toggle[selectedView][viewChanged]',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, TranslateModule],
  templateUrl: './list-grid-toggle.component.html',
  styleUrls: ['./list-grid-toggle.component.scss'],
})
export class ListGridToggleComponent implements OnInit {
  @Input() selectedView = 'grid';
  @Output() viewChanged = new EventEmitter<View>();
  theme$: Observable<Theme> = this.themeService.getTheme();
  isTooltip = true;

  constructor(
    private themeService: ThemeService,
    private tabletModeService: TabletModeService,
  ) { }

  ngOnInit(): void {
    this.isTooltip = !this.tabletModeService.isTabletModeActive();
  }

  changeView(view: View) {
    this.viewChanged.emit(view);
  }

  getIcon(theme: Theme, view: View) {
    return `assets/images/${view}-${theme}.svg`;
  }
}
