import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { ApplicationsState } from './models/applicationsState';
import { Application } from '../deployment/models/application';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsStore extends Store<ApplicationsState> {
  constructor() {
    super({
      applications: [],
      isLoading: true,
      hasError: false,
      errorMessage: '',
      errorStatusCode: 0
    });
  }

  get applications$(): Observable<Application[]> {
    return this.state$.pipe(map((state) => state.applications));
  }

  get isLoading$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.isLoading));
  }

  get isError$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.hasError));
  }

  get errorMessage$(): Observable<string | undefined> {
    return this.state$.pipe(map((state) => state.errorMessage));
  }

  get errorStatusCode$(): Observable<number | undefined> {
    return this.state$.pipe(map((state) => state.errorStatusCode));
  }
}
