import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [TranslateModule, MatTooltipModule],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
})
export class CheckboxComponent {
  @Input() tooltip: string = '';
  @Input() tooltipPosition: TooltipPosition = 'below';
  @Input() text: string = '';
  @Input() dataCy: string = '';
  @Output() checked: EventEmitter<boolean> = new EventEmitter();
  isSelected: boolean = false;
}
