<h2 mat-dialog-title data-cy="device-request-dialog-title">{{ titleKey | translate }}</h2>
<div data-cy="create-new-device-dialog" mat-dialog-content class="content">
  <p data-cy="create-vhpc-dialog-title" *ngIf="data.deviceType === DeviceType.VHPC">
    {{ 'DeviceList.CreateDialog.YouCanAddVHPC' | translate }}
  </p>
  <div *ngIf="data.deviceType === DeviceType.REAL">
    <p>
      {{ "DeviceList.CreateDialog.PleaseCreate" | translate }}
    </p>
  </div>
  <div class="inputs" [formGroup]="createDeviceFormGroup">
    <mat-form-field class="caedge-form-field-outline">
      <mat-label>{{
        "DeviceList.CreateDialog.DeviceName" | translate
      }}</mat-label>
      <input
        #deviceNameField
        (click)="displayRestrictor(deviceNameFieldRef)"
        data-cy="device-name-input"
        formControlName="deviceName"
        [errorStateMatcher]="errorMatcher"
        matInput
        maxlength="15"
      />
      <mat-hint data-cy="create-device-dialog-name-hint" align="start">{{
        "DeviceList.CreateDialog.NameRequirements" | translate
      }}</mat-hint>
      <mat-hint align="end">{{ deviceName.length }}/15</mat-hint>
      <mat-error
        data-cy="device-name-caedge-name-error"
        *ngIf="createDeviceFormGroup.get('deviceName')?.hasError('nameRestrictorError')"
      >
      {{ 'General.UnallowedInput' | translate }}
      </mat-error>
      <mat-error
        *ngIf="createDeviceFormGroup.get('deviceName')?.hasError('required')"
      >
        {{ 'Project.AddProjectDialog.ErrorMessage.Required' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions [align]="'end'">
  <button
    data-cy="cancel-device-button"
    mat-flat-button
    mat-dialog-close
  >
    {{ "General.Cancel" | translate }}
  </button>
  <button
    class="confirm-btn"
    data-cy="create-device-button"
    mat-flat-button
    [disabled]="!canSubmit()"
    (click)="handleSubmitClick()"
  >
    {{
      data.deviceType === DeviceType.VHPC
        ? ("DeviceList.CreateDialog.CreateDevice" | translate)
        : ("DeviceList.CreateDialog.RequestDevice" | translate)
    }}
  </button>
</div>
