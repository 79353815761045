import { Billing } from 'src/app/shared/models/billing';
import { ContactDetails, ContactRoles, Contacts } from 'src/app/shared/models/contacts';
import { ApprovalStatus, ProjectStatus } from 'src/app/shared/stores/projects/models/project';

export interface ProjectInfos {
  UpdatedAt: string;
  pkey: string;
  StatusApprovalCreation: ApprovalStatus;
  StatusApprovalDeletion: ApprovalStatus;
  Billing: Billing | Billing[];
  Contacts: Contacts;
  Description: string;
  Status: ProjectStatus;
  Type: string;
  skey: string;
  Name: string;
}

export class ProjectDetails {
  UpdatedAt?: string;
  pkey?: string;
  StatusApprovalCreation?: ApprovalStatus;
  StatusApprovalDeletion?: ApprovalStatus;
  Billing?: Billing | Billing[];
  Contacts?: Contacts;
  Description?: string;
  Status?: ProjectStatus;
  Type?: string;
  skey?: string;
  Name?: string;
  transformedContacts?: ContactRoles[];

  public static Factory(projectDetails: ProjectInfos): ProjectDetails {
    const newProjectDetails = new ProjectDetails();
    Object.assign(newProjectDetails, projectDetails);
    newProjectDetails.transformedContacts = this.transformContacts(
      newProjectDetails.Contacts,
    );
    newProjectDetails.transformedContacts.sort((a, b) =>
      a.Name.localeCompare(b.Name),
    );
    return newProjectDetails;
  }

  private static transformContacts(
    contacts: Contacts | undefined,
  ): ContactRoles[] {
    if (!contacts) return [];
    const newContacts = [];
    for (const [key, value] of Object.entries(contacts as Object)) {
      const projectOwner = 'ProjectCoOwner';
      if (key === projectOwner) {
        value.map((val: ContactDetails) => {
          newContacts.push({
            role: projectOwner,
            ...val,
          });
        });
      } else {
        newContacts.push({
          role: key,
          ...value,
        });
      }
    }
    return newContacts;
  }
}
