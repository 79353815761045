<h2 mat-dialog-title data-cy="download-hdk-tools-title">{{ "DeviceList.DownloadDevToolsDialog.GetDevTools" | translate }}</h2>
<div data-cy="download-hdk-tools" mat-dialog-content class="content">
  <p>
    {{ "DeviceList.DownloadDevToolsDialog.PleaseDownload" | translate }}
  </p>
  <p>
    {{ "DeviceList.DownloadDevToolsDialog.PleaseDownloadExplanation" | translate }}
  </p>
</div>
<div mat-dialog-actions [align]="'end'">
  <button
    data-cy="cancel-download-hdk-tools-button"
    mat-flat-button
    (click)="closeDownloadDevTools()"
  >
    {{ "General.Cancel" | translate }}
  </button>
  <button
    class="confirm-btn"
    data-cy="download-dev-tools-btn"
    mat-flat-button
    (click)="downloadDevTools()"
  >
  {{ "DeviceList.DownloadDevToolsDialog.DownloadDevTools" | translate }}
  </button>
</div>
