import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { GeneralInformationComponent } from './steps/general-information/general-information.component';
import { ContactInformationComponent } from './steps/contact-information/contact-information.component';
import { BillingInformationComponent } from './steps/billing-information/billing-information.component';
import { StepComponent } from './models/stepComponent';
import { CreateProjectRequestBody } from './models/createProjectRequestBody';
import { BreadcrumbsStore } from 'src/app/shared/stores/config/breadcrumbs.store';
import { isEmpty } from 'lodash';
import { UserTenantListService } from 'src/app/shared/services/user-tenant-list/user-tenant-list.service';
import { TenantUsersStore } from '../../../../../shared/stores/tenant-user-list/tenantUsers.store';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { User } from '../../../../../shared/models/tenantUsers';

export interface AddProjectData {
  tenantId: string;
}

/**
 * Component handling the project creation dialog.
 *
 * @export
 * @class AddProjectDialogComponent
 */
@Component({
  selector: 'app-add-project-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    GeneralInformationComponent,
    ContactInformationComponent,
    BillingInformationComponent,
    MatProgressSpinnerModule,
  ],
  templateUrl: './add-project-dialog.component.html',
  styleUrls: ['./add-project-dialog.component.scss'],
})
export class AddProjectDialogComponent implements OnInit, OnDestroy {
  @ViewChildren('stepComponent') stepComponents!: QueryList<StepComponent>;
  public tenantUsers$: Observable<User[]> = this.tenantUserStore.tenantUsers$;
  public isTenantUsersLoading$: Observable<boolean> =
    this.tenantUserStore.isLoading$;
  public currentStepIndex = 0;
  public currentStepValid = false;
  numCharsTenant = 0;
  public readonly steps = [
    {
      title: this.translate.instant(
        'Project.AddProjectDialog.GeneralInformation',
      ),
    },
    {
      title: this.translate.instant(
        'Project.AddProjectDialog.ContactInformation',
      ),
    },
    {
      title: this.translate.instant(
        'Project.AddProjectDialog.BillingInformation',
      ),
    },
  ];
  private readonly unsubscribe$: Subject<void> = new Subject();
  private tenant = '';
  private readonly breadcrumbs$ = this.breadcrumbStore.breadcrumbs$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddProjectData,
    private translate: TranslateService,
    private breadcrumbStore: BreadcrumbsStore,
    public dialogRef: MatDialogRef<AddProjectDialogComponent>,
    private userTenantService: UserTenantListService,
    private tenantUserStore: TenantUsersStore,
  ) {}

  ngOnInit(): void {
    this.breadcrumbs$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((breadcrumbs) => {
        this.tenant = breadcrumbs[0].label;
        this.numCharsTenant = this.tenant.length;
      });
    this.getTenantUserData();
  }

  // Sets the current step to the next step, in case it is possible
  nextStep() {
    if (this.isLastStep()) {
      return;
    }
    this.currentStepIndex++;
    this.isCurrentStepValid(this.stepComponents, this.currentStepIndex);
  }

  // Sets the current step to the previous step, in case it is possible
  prevStep() {
    if (this.isFirstStep()) {
      return;
    }
    this.currentStepIndex--;
    this.isCurrentStepValid(this.stepComponents, this.currentStepIndex);
  }

  /**
   * Checks if the first step is active.
   *
   * @return true if the first step is active, otherwise false.
   */
  isFirstStep(): boolean {
    return this.currentStepIndex === 0;
  }

  /**
   * Checks if the last step is active.
   *
   * @return true if the last step is active, otherwise false.
   */
  isLastStep(): boolean {
    return this.currentStepIndex === this.steps.length - 1;
  }

  isCurrentStepValid(
    stepComponents: QueryList<StepComponent>,
    currentStepIndex: number,
  ): void {
    const stepComponentsArray = stepComponents?.toArray();
    if (!stepComponentsArray) {
      this.currentStepValid = false;
      return;
    }
    const currentStepComponent = stepComponentsArray[currentStepIndex];
    if (!currentStepComponent) {
      this.currentStepValid = false;
      return;
    }
    if (!currentStepComponent.formGroup) {
      this.currentStepValid = false;
      return;
    }
    this.currentStepValid = currentStepComponent.stepValid;
  }

  /**
   * Checks if the every form entry is valid.
   *
   * @return true if the form is valid, otherwise false.
   */
  isValid(): boolean {
    if (this.stepComponents) {
      return this.stepComponents.toArray().every((step) => step.stepValid);
    }
    return false;
  }

  submitForm(): void {
    const result = this.getRequestData();
    this.dialogRef.close(result);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getTenantUserData() {
    this.userTenantService
      .getTenantUserList(this.data.tenantId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (tenantUsers) => {
          this.tenantUserStore.setState({
            tenantUsers: tenantUsers.Users,
            isLoading: false,
            hasError: false,
          });
        },
        error: () => {
          this.tenantUserStore.setState({
            tenantUsers: [],
            isLoading: false,
            hasError: true,
          });
        },
      });
  }

  private getRequestData(): CreateProjectRequestBody {
    const dataToUpdate: any = this.stepComponents
      .toArray()
      .reduce((result, step) => {
        if (!this.isContactInformationComponent(step)) {
          return Object.assign(result, step.dataToUpdate);
        }
        return result;
      }, {});

    const contactComponents: StepComponent[] = this.stepComponents.filter(
      (element) => this.isContactInformationComponent(element),
    );

    contactComponents.forEach((component) => {
      dataToUpdate['Contacts'] = {
        ...dataToUpdate['Contacts'],
        ...component.dataToUpdate['Contacts'],
      };
    });

    const requestBody: CreateProjectRequestBody = {
      TenantId: this.data.tenantId,
      Description: '',
      Name: '',
      Billing: [
        {
          LegalEntity: '',
          ControllingContact: '',
          WBSElement: '',
          OrgMapping: {
            CostCenter: '',
            CostCenterOwner: '',
          },
          Percentage: '',
        },
      ],
      Contacts: {
        Billing: {
          ContiUid: '',
          Email: '',
          Name: '',
          Phone: '',
        },
        ProjectOwner: {
          ContiUid: '',
          Email: '',
          Name: '',
          Phone: '',
        },
        ProjectCoOwner: [],
        SecurityCentralOps: {
          ContiUid: '',
          Email: '',
          Name: '',
          Phone: '',
        },
        SecurityProjectOwner: {
          ContiUid: '',
          Email: '',
          Name: '',
          Phone: '',
        },
        Technical: {
          ContiUid: '',
          Email: '',
          Name: '',
          Phone: '',
        },
      },
    };

    Object.assign(requestBody, dataToUpdate);
    return this.removeUnsetFields(requestBody);
  }

  private removeUnsetFields(requestBody: CreateProjectRequestBody) {
    const emptyContactValue = '-';
    if (
      requestBody.Contacts.ProjectCoOwner &&
      requestBody.Contacts.ProjectCoOwner[0].Email === emptyContactValue
    ) {
      delete requestBody.Contacts.ProjectCoOwner;
    }

    requestBody.Billing.forEach((billing) => {
      if (isEmpty(billing.WBSElement)) {
        delete billing.WBSElement;
      }
    });

    return requestBody;
  }

  private isContactInformationComponent(component: StepComponent) {
    return component instanceof ContactInformationComponent;
  }
}
