import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbsStore } from '../../stores/config/breadcrumbs.store';
import { Observable, map, of } from 'rxjs';
import { Tenant } from 'src/app/core/models/enums/Tenant';
import { FeatureConfig } from '../../../../environments/featureConfig';
import { FeatureService } from '../../../core/services/config/feature.service';

@Component({
  standalone: true,
  selector: 'app-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss'],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    MatDividerModule,
  ],
})
export class HelpMenuComponent implements OnInit {
  readonly hondaSupport =
    'https://support.8675.ccp.continental.exchange/servicedesk/customer/portal/2';
  readonly paccarSupport =
    'https://jira.6543.ccp.continental.exchange/plugins/servlet/desk/portal/16';

  tenants = Tenant;
  currentTenant: string = '';
  currentTenant$: Observable<string> = of('');

  private readonly breadcrumbs$ = this.breadcrumbStore.breadcrumbs$;
  displayCaedgeDocs = this.featureService.isFeatureEnabled(
    FeatureConfig.displayCaedgeDocs,
  );

  constructor(
    private breadcrumbStore: BreadcrumbsStore,
    private featureService: FeatureService,
  ) {}

  ngOnInit(): void {
    this.initBreadcrumbs();
  }

  private initBreadcrumbs() {
    this.currentTenant$ = this.breadcrumbs$.pipe(
      map((breadcrumbs) => breadcrumbs[0].label),
    );
  }

  setCurrentTenant(tenant: string) {
    this.currentTenant = tenant;
    this.currentTenant$ = of(tenant);
  }

  getSupportLink(): string {
    return this.currentTenant === 'PACCAR'
      ? this.paccarSupport
      : this.hondaSupport;
  }
}
