import { Pipe, PipeTransform } from '@angular/core';
import { Application } from '../stores/deployment/models/application';
import { convertToAppTypeLabel } from '../utils/convertToAppTypeLabels';

@Pipe({
  name: 'convertToTypeLabels',
  standalone: true,
})
export class ConvertToTypeLabelsPipe implements PipeTransform {
  transform(app: Application) {
    const appTypeLabel = { appType: convertToAppTypeLabel(app.appType) };
    return { ...app, ...appTypeLabel };
  }
}
