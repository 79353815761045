import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import {
  LANGUAGES,
  LANGUAGE_LABEL_MAP,
  Language,
  LanguageService,
} from 'src/app/core/services/language/language.service';

@Component({
  standalone: true,
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  imports: [CommonModule, MatSelectModule],
})
export class LanguagePickerComponent {
  selectedLanguage$ = this.languageService.getCurrentLanguage();
  languageMap = LANGUAGE_LABEL_MAP;
  languages = LANGUAGES;

  constructor(private languageService: LanguageService) {}

  onChangeLanguage(lang: Language) {
    this.languageService.setLanguage(lang);
  }
}
