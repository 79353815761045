export class TreeNode {
  id!: number;
  root_url?: string;
  url?: string;
  is_leaf?: boolean;
  sets?: ITreeNode[];
  selected?: boolean;

  public static Factory(node: ITreeNode) {
    const transformedData: TreeNode[] = [
      {
        id: node.id,
        root_url: node.root_url,
        selected: false,
        is_leaf: node.is_leaf,
        sets: node.sets
          ? node.sets.map((set) => ({
              id: set.id,
              url: set.url,
              is_leaf: set.is_leaf,
              selected: false,
            }))
          : [],
      },
    ];
    return transformedData;
  }
}

export interface ITreeNode {
  id: number;
  root_url?: string;
  url?: string;
  is_leaf?: boolean;
  sets?: ITreeNode[];
  selected?: boolean;
}
