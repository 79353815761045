import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingTypes } from './models/loading-type';
import { EmptySectionComponent } from '../empty-section/empty-section.component';
import { EmptySectionIcon } from '../empty-section/models/empty-section';
import { EmptySectionScope } from 'src/app/shared/utils/emptySectionScope';

@Component({
  selector: 'app-content-wrapper',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule, MatProgressSpinnerModule, TranslateModule, EmptySectionComponent],
  templateUrl: './content-wrapper.component.html',
  styleUrls: ['./content-wrapper.component.scss'],
})
export class ContentWrapperComponent {
  @Input({ required: true }) isLoading: boolean = true;
  @Input({ required: true }) isError: boolean = false;
  @Input() loadingType: LoadingTypes = LoadingTypes.BAR;
  @Input() errorMessage?: string;
  @Input() errorStatusCode?: number;
  @Input() spinnerDiameter: number = 40;
  @Input() emptySectionScope?: EmptySectionScope = EmptySectionScope.TABLE;

  EmptySectionIcon = EmptySectionIcon;
  SectionScope = EmptySectionScope;

  LoadingTypes = LoadingTypes;
}
