import { Injectable } from '@angular/core';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { AwsOrg } from 'src/app/core/models/enums/AwsOrg';
import { Tenant } from 'src/app/core/models/enums/Tenant';
import { Environment } from 'src/app/core/models/interfaces/environmentState';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentStore extends Store<Environment> {
  constructor() {
    const initialState: Environment = {
      AWS_ORG: AwsOrg.PROD,
      AWS_STAGE: environment.AWS_STAGE,
      TENANT: Tenant.NO_TENANT,
    };
    super(initialState);
  }
}
