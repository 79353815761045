import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { forkJoin, of } from 'rxjs';
import { DeviceListApiService } from '../device-list-api/device-list-api.service';
import { SelectedDeviceService } from '../selected-device/selected-device.service';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { DeviceType } from 'src/app/shared/stores/devices/models/deviceType';
import { DialogProps } from 'src/app/shared/components/dialog/models/dialogProps';
import { DeviceListApi } from '../../device-list.component';
import { ApiRecord } from '../../../../shared/stores/config/models/apiRecord';

@Injectable({
  providedIn: 'root',
})
export class DeviceDeletionService {
  constructor(
    private deviceApiService: DeviceListApiService,
    private selectedDeviceService: SelectedDeviceService,
    private snackbarService: SnackbarService,
  ) {}

  handleDeviceDeletion(
    dialogRef: MatDialogRef<any>,
    config: DialogProps,
    API: Record<DeviceListApi, ApiRecord> | undefined,
  ) {
    if (!API) return;

    const [vhpcDeletion$, realDeletion$] = this.createDeletionObservables(API);

    this.selectedDeviceService.setLoading(true);

    forkJoin([vhpcDeletion$, realDeletion$]).subscribe({
      next: ([vhpcResponse, realResponse]) => {
        const combinedResponse = [
          ...vhpcResponse.bulkDeletionStatus,
          ...realResponse.bulkDeletionStatus,
        ];
        this.selectedDeviceService.setDeleteDeviceResponse(combinedResponse);
        this.updateDialogRef(dialogRef, config);
      },
      error: () => {
        this.snackbarService.notifyError('Failed to delete some devices.');
        this.selectedDeviceService.setLoading(false);
      },
      complete: () => {
        this.selectedDeviceService.setLoading(false);
      },
    });
  }

  private createDeletionObservables(API: Record<DeviceListApi, ApiRecord>) {
    const vhpcDevices = this.filterDeviceByTypes(DeviceType.VHPC);
    const realDevices = this.filterDeviceByTypes(DeviceType.REAL);

    const vhpcDeletion$ = vhpcDevices.length
      ? this.deviceApiService.deleteMultipleVhpcDevices(
          API.bulkDeleteVhpcDevices,
          vhpcDevices,
        )
      : of({
          bulkDeletionStatus: [],
        });

    const realDeletion$ = realDevices.length
      ? this.deviceApiService.deleteMultipleRealDevices(
          API.bulkDeleteRealDevices,
          realDevices,
        )
      : of({ bulkDeletionStatus: [] });

    return [vhpcDeletion$, realDeletion$];
  }

  private updateDialogRef(dialogRef: MatDialogRef<any>, config: DialogProps) {
    if (dialogRef) {
      dialogRef.componentInstance.updateData({
        ...config,
        customButtonText: 'Close',
        onCustomButtonClick: () => {
          dialogRef.close();
          this.clearSelection();
        },
      });
    }
  }

  clearSelection() {
    this.selectedDeviceService.clearSelectedDevices();
  }

  filterDeviceByTypes(type: DeviceType) {
    const selectedDevices = this.selectedDeviceService.selectedDevices;
    return selectedDevices.filter((device) => device.instanceType === type);
  }
}
