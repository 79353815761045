import { GeneralApi, GeneralFeatureConfig } from './generalApi';

export const SECTION_LABEL = (options: GeneralFeatureConfig<GeneralApi>) => {
  return {
    label: options.label ? options.label : 'Data & simulation',
    type: 'FEATURE_SECTION',
    nonNavigatable: 'true',
    path: '',
  };
};

export const WORKSPACE_PAGE = (options: GeneralFeatureConfig<GeneralApi>) => {
  return {
    id: '000111111',
    featureId: 'WORKSPACE_PAGE',
    label: 'Workbenches',
    type: 'FEATURE',
    icon: 'folder',
    path: 'project/:projectId/workspace',
    API: {
      getWorkspaceTypes: {
        url: `${options.api.workspace}/listWorkbenchTypes?uid=:userId`,
        method: 'GET',
      },
      createWorkspace: {
        url: `${options.api.workspace}/createWorkbench?uid=:userId&workbenchType=:workbenchName`,
        method: 'GET',
      },
      requestWorkspaceAccess: {
        url: `${options.api.workspace}/email?uid=:userId&workbenchType=:workbenchName`,
        method: 'POST',
      },
      deleteWorkspaceInstance: {
        url: `${options.api.workspace}/deleteWorkbench?workbenchId=:workspaceId`,
        method: 'GET',
      },
      resetWorkspaceInstance: {
        url: `${options.api.workspace}/resetWorkbench`,
        method: 'POST',
      },
      updateWorkspaceInstance: {
        url: `${options.api.workspace}/updateWorkbench`,
        method: 'POST',
      },
      resetWorkspacePassword: {
        url: `${options.api.workspace}/password?uid=:userId`,
        method: 'POST',
      },
    },
  };
};



export const VIRTUAL_DEVELOPMENT_INFRASTRUCTURE = (
  options: GeneralFeatureConfig<GeneralApi>,
) => {
  return {
    id: '12312321',
    featureId: 'VIRTUAL_DEVELOPMENT_INFRASTRUCTURE',
    label: 'VDI',
    type: 'FEATURE',
    icon: 'settings_system_daydream',
    path: 'project/:projectId/vdi',
    API: {
      getVDIConfigs: {
        url: `${options.api.dev}/vdi/configs`,
        method: 'GET',
      },
      downloadMatchboxScript: {
        url: `${options.api.dev}/vdi/script`,
        method: 'GET',
      },
      getVDInstances: {
        url: `${options.api.dev}/vdi/instances`,
        method: 'GET',
      },
      delVDInstance: {
        url: `${options.api.dev}/vdi/instances`,
        method: 'DELETE',
      },
      putVDInstance: {
        url: `${options.api.dev}/vdi/instances`,
        method: 'PUT',
      },
    },
  };
};

export const PLAYLIST = (options: GeneralFeatureConfig<GeneralApi>) => {
  return {
    id: '123456789',
    featureId: 'PLAYLIST',
    label: 'Playlist',
    type: 'FEATURE',
    icon: 'playlist_play',
    path: 'project/:projectId/playlist',
    API: {
      getPlaylist: {
        url: `${options.api.playlist}/dev/v2/lookups?mode=list_playlists`,
        method: 'POST',
      },
      getProjectConfiguration: {
        url: `${options.api.playlist}/dev/v2/lookups?mode=get_project_config`,
        method: 'POST',
      },
      getMasterCollections: {
        url: `${options.api.falcon}/fetch_collections`,
        method: 'GET',
      },
      getChildCollections: {
        url: `${options.api.falcon}/fetch_collection_childs`,
        method: 'POST',
      },
      getChildCollectionRecordings: {
        url: `${options.api.falcon}/fetch_collection_recordings`,
        method: 'POST',
      },
      getApiKey: {
        url: `${options.api.falcon}/api_key`,
        method: 'POST',
      },
      createStageOne: {
        url: `${options.api.playlist}/dev/v2/stage1`,
        method: 'POST',
      },
      getSensorVersions: {
        url: `${options.api.playlist}/dev/v2/lookups?mode=distinct_versions`,
        method: 'POST',
      },
      createStageTwo: {
        url: `${options.api.playlist}/dev/v2/stage2`,
        method: 'POST',
      },
    },
    children: [
      {
        id: '1234567891',
        featureId: 'PLAYLIST_DETAILS',
        label: 'Playlist Details',
        type: 'FEATURE',
        icon: 'task_alt',
        path: ':playlistId',
        API: {
          getPlaylist: {
            url: `${options.api.playlist}/dev/v2/lookups?mode=list_playlists`,
            method: 'POST',
          },
          getPlaylistDetails: {
            url: `${options.api.playlist}/dev/v2/status/:playlistId`,
            method: 'GET',
          },
        },
      },
    ],
  };
};


