<div class="footer">
  <div class="pagination">{{ currentStepIndex + 1 }}/{{ steps.length }}</div>
  @if (repeat) {
    <div class="actions" mat-dialog-actions align="end">
      <button class="cancel" mat-flat-button mat-dialog-close>
        {{ "General.Cancel" | translate }}
      </button>
      <button
        class="pagination-button"
        (click)="returnToFirstStep()"
        mat-flat-button
      >
        {{ "General.SubmitAnotherJob" | translate }}
      </button>
    </div>
  } @else {
    <div class="actions" mat-dialog-actions align="end">
      <button data-cy="cancel-job-btn" class="cancel" mat-flat-button mat-dialog-close>
        {{ "General.Cancel" | translate }}
      </button>
      <button
        class="pagination-button"
        *ngIf="!isFirstStep()"
        (click)="prevStep()"
        mat-flat-button
        data-cy="job-back-btn"
      >
        {{ "General.Back" | translate }}
      </button>
      <button
        class="pagination-button"
        *ngIf="!isLastStep()"
        (click)="nextStep()"
        [disabled]="enableNextButton"
        mat-flat-button
        data-cy="job-next-btn"
      >
        {{ "General.Next" | translate }}
      </button>
      <button
        class="add-project-btn"
        mat-flat-button
        type="submit"
        (click)="submitForm()"
        [disabled]="!isValid()"
         data-cy="job-submit-btn"
      >
        {{ "General.Create" | translate }}
      </button>
    </div>
  }
</div>
