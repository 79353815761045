import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';

export interface HttpRequestOptions<T> {
  apiRecord: ApiRecord;
  body?: T;
  headers?: HttpHeaders;
  params?: HttpParams;
}

@Injectable({
  providedIn: 'root',
})

/**
 * This API Service is a wrapper over the Angular
 * HttpClient.request method
 */
export class ApiService {
  constructor(private http: HttpClient) {}

  /**
   * This method consumes ApiRecord objects which are specified
   * in the config file for a given FeatureConfig and sends
   * HTTP Requests.
   *
   * @template U - [Type of the HTTP Response coming from the backend]
   * @template T - [Type of the body sent to the backend with a POST or PUT request]
   * @param {HttpRequestOptions<T>}
   * @param apiRecord - [ApiRecord from the config file]
   * @param body - [optional body]
   * @param headers - [optional HttpHeaders]
   * @param params - [optional HttpParams]
   * @returns {Observable<U>} [Observable containing HTTP Response]
   */
  request<U, T = any>({
    apiRecord,
    body,
    headers,
    params,
  }: HttpRequestOptions<T>): Observable<U> {
    return this.http.request<U>(apiRecord.method, apiRecord.url, {
      body,
      headers,
      params,
    });
  }
}
