<div class="header">
  <span class="title">{{ title | translate }}</span>
  <span class="subtitle">{{ subtitle | translate }}</span>
</div>
<ng-content> </ng-content>
<div class="action-array">
  <div *ngFor="let button of actions">
    <app-cae-button
      [buttonText]="button.buttonText"
      [disable]="button.disabled"
      (click)="clickEvent.emit(button.command)"
      [color]="isDarkButton?'tertiary':'secondary'"
      [icon]="button.icon"
    ></app-cae-button>
  </div>
</div>
