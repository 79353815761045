import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogModule,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PackageType } from './models/packageType';
import { SourceType } from './models/sourceType';
import { ReactiveFormsModule } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Option } from 'src/app/core/models/interfaces/option';
import { ApplicationType } from 'src/app/shared/stores/applications/models/applicationType';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { CreateAppRequestBody } from './models/createAppRequestBody';
import { ApplicationListApiService } from '../application-list/services/application-list-api.service';
import { GenericStateMatcher } from '../../../../shared/utils/genericStateMatcher';
import { ApplicationTypeLabels } from './models/applicationTypeLabels';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { SourceTypeMapping } from './models/sourceTypeMapping';
import { ProviderType } from './models/providerTypes';
import {
  applicationPattern,
  nameRestrictor,
  nameRestrictorWords,
} from 'src/app/shared/utils/name-restriction-validator/name-restriction-validator';
import { RestrictedInputDisplayComponent } from 'src/app/shared/components/restricted-input-display/restricted-input-display.component';
import { ResourceRestrictionHelperService } from 'src/app/shared/utils/resource-restriction-helper-service/resource-restriction-helper.service';
import { PopoverService } from 'src/app/core/services/popover/popover.service';
import { MatFormField } from '@angular/material/form-field';
import { RestrictedInputData } from 'src/app/shared/components/restricted-input-display/models/restricted-input-data';
import {
  Language,
  Languages,
  LanguageService,
} from 'src/app/core/services/language/language.service';
import { ConnectedPosition } from '@angular/cdk/overlay';

export interface AddApplicationData {
  createApplicationApiRecord: ApiRecord;
  getApplicationsApiRecord: ApiRecord;
  getRepositoryProviderApiRecord: ApiRecord;
  getProviderRepositoriesApiRecord: ApiRecord;
  unsubscribe$: Observable<void>;
}

@Component({
  standalone: true,
  selector: 'app-add-application-dialog',
  templateUrl: './add-application-dialog.component.html',
  styleUrls: ['./add-application-dialog.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    CommonModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    RestrictedInputDisplayComponent,
  ],
})
export class AddApplicationDialogComponent implements OnInit, OnDestroy {
  @ViewChild('applicationName', { read: ElementRef<MatFormField> })
  appNameFieldRef!: ElementRef<MatFormField>;
  
  private readonly unsubscribe$: Subject<void> = new Subject();
  
  readonly GITHUB_LABEL = this.translate.instant('ApplicationDetails.GithubName');
  readonly ARTIFACTORY_LABEL = this.translate.instant('ApplicationDetails.Artifactory');
  sourceLinkPlaceholder = this.translate.instant('Application.AddApplicationDialog.SourceLink');

  applicationType: string = '';
  filteredOptions!: Observable<string[]>; // filtering for packages
  items: string[] = []; // retrieved packages items

  matcher = new GenericStateMatcher();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddApplicationData,
    private applicationListApiService: ApplicationListApiService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private popoverService: PopoverService,
    private resourceRestrictionHelperService: ResourceRestrictionHelperService,
    private languageService: LanguageService,
    public dialogRef: MatDialogRef<AddApplicationDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.onChangedApplicationType();

    this.filteredOptions = this.sourceLinkControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '')),
    );

    const responseObservable =
      this.applicationListApiService.requestRepositories(
        this.data.getRepositoryProviderApiRecord,
      );

    if (responseObservable !== undefined) {
      responseObservable.pipe(takeUntil(this.unsubscribe$)).subscribe();
    }
  }

  appTypes: Option<string>[] = [
    {
      id: ApplicationType.CLASSIC_AUTOSAR_IMAGE,
      label: ApplicationTypeLabels.CLASSIC_AUTOSAR,
    },
    {
      id: ApplicationType.ANDROID,
      label: ApplicationTypeLabels.ANDROID,
    },
    {
      id: ApplicationType.SINGLE_SERVICE,
      label: ApplicationTypeLabels.LINUX_CONTAINER,
    },
    {
      id: ApplicationType.LINUX_NATIVE,
      label: ApplicationTypeLabels.LINUX_NATIVE,
    },
    {
      id: ApplicationType.QNX,
      label: ApplicationTypeLabels.QNX,
    },
    {
      id: ApplicationType.ADAPTIVE_AUTOSAR,
      label: ApplicationTypeLabels.ADAPTIVE_AUTOSAR_NEW,
    },
  ];

  sourceTypes: Option<string>[] = [
    {
      id: SourceType.ARTIFACTORY,
      label: 'Artifactory',
    },
    {
      id: SourceType.GITHUB,
      label: 'GitHub',
    },
  ];

  packageTypes: Option<string>[] = [
    {
      id: PackageType.SOURCE_CODE,
      label: 'Source Code',
    },
    {
      id: PackageType.CONAN,
      label: 'Conan',
    },
    {
      id: PackageType.FIRMWARE_IMAGE,
      label: 'Firmware image',
    },
    {
      id: PackageType.UCM,
      label: 'UCM',
    },
    {
      id: PackageType.APK,
      label: 'APK',
    },
  ];

  inputFormGroup: FormGroup = this.fb.group({
    applicationNameControl: [
      '',
      [Validators.required, Validators.pattern('[a-z0-9_]*'), nameRestrictor],
    ],
    applicationTypeControl: ['', [Validators.required]],
    sourceLinkControl: ['', [Validators.required, Validators.pattern('\\S+')]],
    sourceTypeControl: [
      {
        value: '',
        disabled: true,
      },
      [Validators.required],
    ],
    packageTypeControl: [
      {
        value: '',
        disabled: true,
      },
      [Validators.required],
    ],
  });

  readonly applicationNameControl: FormControl = this.inputFormGroup.get(
    'applicationNameControl',
  ) as FormControl;

  readonly applicationTypeControl: FormControl = this.inputFormGroup.get(
    'applicationTypeControl',
  ) as FormControl;

  readonly sourceLinkControl: FormControl = this.inputFormGroup.get(
    'sourceLinkControl',
  ) as FormControl;

  readonly sourceTypeControl: FormControl = this.inputFormGroup.get(
    'sourceTypeControl',
  ) as FormControl;

  readonly packageTypeControl: FormControl = this.inputFormGroup.get(
    'packageTypeControl',
  ) as FormControl;

  private getApplicationType(application_type: string) {
    let os_type: string;

    switch (application_type) {
      case ApplicationType.ADAPTIVE_AUTOSAR:
        os_type = SourceTypeMapping.ADAPTIVE_AUTOSAR;
        break;

      case ApplicationType.CLASSIC_AUTOSAR_IMAGE:
        os_type = SourceTypeMapping.CLASSIC_AUTOSAR;
        break;

      case ApplicationType.QNX:
        os_type = SourceTypeMapping.QNX;
        break;

      case ApplicationType.ANDROID:
        os_type = SourceTypeMapping.ANDROID;
        break;

      case ApplicationType.SINGLE_SERVICE:
        os_type = SourceTypeMapping.LINUX_CONTAINER;
        break;

      default:
        os_type = SourceTypeMapping.UNKNOWN;
        break;
    }

    return os_type;
  }

  displayResourceRestrictionHelper(fieldRef: ElementRef<MatFormField>): void {
    const selectedLanguage: Language =
      this.languageService.getCurrentLanguageValue();
    let positionConfig: ConnectedPosition = {
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'bottom',
    };

    if (selectedLanguage === Languages.en) {
      positionConfig.offsetX = 325;
      positionConfig.offsetY = 168;
    } else {
      positionConfig.offsetX = 360;
      positionConfig.offsetY = 168;
    }

    this.updateRestrictionHelperData();
    this.applicationNameControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.updateRestrictionHelperData();
      });
    this.popoverService.open<RestrictedInputDisplayComponent>(
      fieldRef,
      RestrictedInputDisplayComponent,
      positionConfig,
    );
  }

  private updateRestrictionHelperData(): void {
    const displayRestrictionData: RestrictedInputData = {
      fieldValue: this.applicationNameControl.value,
      hasApplicationPatternFailed:
        this.applicationNameControl.hasError('pattern'),
      hasNameRestrictionFailed: this.applicationNameControl.hasError(
        'nameRestrictorError',
      ),
      nameRestrictor: nameRestrictorWords(this.applicationNameControl),
      applicationPattern: applicationPattern(this.applicationNameControl),
    };
    this.resourceRestrictionHelperService.updateData(displayRestrictionData);
  }

  private requestPackages(appType: string) {
    let type = this.getApplicationType(appType);

    const responseObservable =
      this.applicationListApiService.requestProviderPackages(
        this.data.getProviderRepositoriesApiRecord,
        type,
        ProviderType.ARTIFACTORY,
      );

    if (responseObservable !== undefined) {
      responseObservable
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data) => {
          if (Array.isArray(data)) {
            this.items = data;
          } else {
            console.error('The data is not an array.');
          }
        });
    }
  }

  private handleAppTypeSelection(appType: string) {
    // clear autocomplete array and input value
    this.items = [];
    this.sourceLinkControl.setValue('');

    switch (appType) {
      case ApplicationType.SINGLE_SERVICE:
        this.setSourceLinkPlaceholderAndDisabledFields(
          SourceType.GITHUB,
          this.GITHUB_LABEL,
          PackageType.SOURCE_CODE
        );
        break;

      case ApplicationType.CLASSIC_AUTOSAR_IMAGE:
        this.setSourceLinkPlaceholderAndDisabledFields(
          SourceType.ARTIFACTORY,
          this.ARTIFACTORY_LABEL,
          PackageType.FIRMWARE_IMAGE
        );
        this.requestPackages(this.applicationType);
        break;

      case ApplicationType.ANDROID:
        this.setSourceLinkPlaceholderAndDisabledFields(
          SourceType.ARTIFACTORY,
          this.ARTIFACTORY_LABEL,
          PackageType.APK
        );
        this.requestPackages(this.applicationType);
        break;

      case ApplicationType.ADAPTIVE_AUTOSAR:
        this.setSourceLinkPlaceholderAndDisabledFields(
          SourceType.ARTIFACTORY,
          this.ARTIFACTORY_LABEL,
          PackageType.UCM
        );
        this.requestPackages(this.applicationType);
        break;

      case ApplicationType.LINUX_NATIVE:
        this.setSourceLinkPlaceholderAndDisabledFields(
          SourceType.ARTIFACTORY,
          this.ARTIFACTORY_LABEL,
          PackageType.CONAN
        );
        break;

      case ApplicationType.QNX:
        this.setSourceLinkPlaceholderAndDisabledFields(
          SourceType.ARTIFACTORY,
          this.ARTIFACTORY_LABEL,
          PackageType.CONAN
        );
        break;
    }
  }

  onChangedApplicationType() {
    this.applicationTypeControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.applicationType = result;
        this.handleAppTypeSelection(result);
      });
  }

  setSourceLinkPlaceholderAndDisabledFields(
    sourceType: SourceType,
    sourceLinkPlaceholder: string,
    packageType: PackageType
  ) {
    this.sourceTypeControl.setValue(sourceType);
    this.sourceLinkPlaceholder = sourceLinkPlaceholder;
    this.packageTypeControl.setValue(packageType);
  }

  submitForm(): void {
    const requestBody: CreateAppRequestBody = {
      appId: this.applicationNameControl.value,
      appType: this.applicationTypeControl.value,
      appReference: this.sourceLinkControl.value,
      versionId: 'empty',        // Currently not used
      versionReference: 'empty'  // Currently not used
    };
    this.dialogRef.close(requestBody);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.items
      .filter((option) => option.toLowerCase().includes(filterValue))
      .sort();
  }
}
