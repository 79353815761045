import { ApplicationType } from 'src/app/shared/stores/applications/models/applicationType';

export const APPLICATION_TYPE_KEYS = [
  'ApplicationList.FilterKeys.Type.All',
  'ApplicationList.FilterKeys.Type.Android',
  'ApplicationList.FilterKeys.Type.LinuxContainer',
  'ApplicationList.FilterKeys.Type.LinuxNative',
  'ApplicationList.FilterKeys.Type.ClassicAutosarImage',
  'ApplicationList.FilterKeys.Type.QNX',
  'ApplicationList.FilterKeys.Type.AdaptiveAutosarNew',
] as const;

export type ApplicationTypeKey = (typeof APPLICATION_TYPE_KEYS)[number];

export const filterKeyToApplicationPropertyMap = {
  'ApplicationList.FilterKeys.Type.Android': ApplicationType.ANDROID,
  'ApplicationList.FilterKeys.Type.QNX': ApplicationType.QNX,
  'ApplicationList.FilterKeys.Type.LinuxContainer':
    ApplicationType.SINGLE_SERVICE,
  'ApplicationList.FilterKeys.Type.ClassicAutosarImage':
    ApplicationType.CLASSIC_AUTOSAR_IMAGE,
  'ApplicationList.FilterKeys.Type.LinuxNative': ApplicationType.LINUX_NATIVE,
  'ApplicationList.FilterKeys.Type.AdaptiveAutosarNew':
    ApplicationType.ADAPTIVE_AUTOSAR,
};
