<div class="header-element">
  <div
    class="title-col"
    [class.row]="isRowDisplayed"
    *ngIf="breadcrumbs$ | async as breadcrumbs"
  >
    <mat-icon
      *ngIf="showBackButton"
      [matTooltip]="'Back'"
      (click)="
        goBack(
          backUrl === '' ? breadcrumbs[breadcrumbs.length - 2].url : backUrl
        )
      "
    >
      arrow_back
    </mat-icon>
    <p
      data-cy="header-text"
      class="header-text"
      *ngIf="translationKey; else name"
    >
      {{ translationKey | translate }}
    </p>
    <ng-template #name>
      <p
        data-cy="header-text"
        class="header-text"
        [ngClass]="{
          'aligned-header': itemName === 'Versions and deployments'
        }"
        *ngIf="itemName"
      >
        {{ itemName }}
      </p>
    </ng-template>
    @if (description) {
      <span class="description">{{ description | translate }}</span>
    }
  </div>
  <div class="actions-col">
    <ng-content></ng-content>
  </div>
</div>
