import { Component, ElementRef, OnDestroy, ViewChild, Inject, OnInit } from '@angular/core';
import { 
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { devicePatternK8S } from '../../../../../shared/utils/name-restriction-validator/name-restriction-validator';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { PopoverService } from 'src/app/core/services/popover/popover.service';
import { Subject, take, takeUntil } from 'rxjs';
import { MatFormField } from '@angular/material/form-field';
import {
  Language,
  Languages,
  LanguageService
} from 'src/app/core/services/language/language.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { RestrictedInputDisplayComponent } from 'src/app/shared/components/restricted-input-display/restricted-input-display.component';
import { RestrictedInputData } from 'src/app/shared/components/restricted-input-display/models/restricted-input-data';
import { ResourceRestrictionHelperService } from 'src/app/shared/utils/resource-restriction-helper-service/resource-restriction-helper.service';
import { GenericStateMatcher } from 'src/app/shared/utils/genericStateMatcher';
import { CommonModule } from '@angular/common';
import { DialogHintComponent } from 'src/app/shared/components/dialog-hint/dialog-hint.component';
import { VecuApiService } from '../../../services/vecu-api-service/vecu-api.service';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { Vecu } from '../../../models/vecu';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { similarVecuNameValidator } from '../../../utils/validators/similar-vecu-name-validator';
import { MatButtonModule } from '@angular/material/button';

export interface CreateVecuDialogData {
  apiRecord: ApiRecord;
}

@Component({
  selector: 'app-create-vecu-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    DialogHintComponent
  ],
  templateUrl: './create-vecu-dialog.component.html',
  styleUrl: './create-vecu-dialog.component.scss'
})
export class CreateVecuDialogComponent implements OnInit, OnDestroy {
  @ViewChild('vecuNameFieldViewChild', { read: ElementRef<MatFormField> })
  vecuNameFieldRef!: ElementRef<MatFormField>;
  createVecuFormGroup: FormGroup = new FormGroup({
    vecuName: new FormControl('', [
      Validators.required,
      // This regex ensures that the string starts with at least one character and contains
      // only letters (a-z, A-Z), digits (0-9), hyphens (-), underscores (_), periods (.), and tildes (~).
      Validators.pattern(/^[a-zA-Z0-9-_.~]+$/),
    ]),
  });

  private readonly destroy$: Subject<void> = new Subject();
  private readonly enOffsetX = 330;
  private readonly deOffsetX = 370;
  private readonly offsetY = 120;
  errorMatcher = new GenericStateMatcher();
  maxVecuNameLength: number = 25;
  listOfAlreadyExistingVecuNames: string[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateVecuDialogData,
    private popoverService: PopoverService,
    private languageService: LanguageService,
    private resourceRestrictionHelperService: ResourceRestrictionHelperService,
    private vecuApiService: VecuApiService,
    private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<CreateVecuDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.fetchVecus();
    this.vecuNameField?.valueChanges
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      this.updateDisplayRestrictorData();
    });
  }

  fetchVecus(): void {
    this.vecuApiService.getVecus(this.data.apiRecord)
    .pipe(take(1), takeUntil(this.destroy$))
    .subscribe({
      next: (value: Vecu[]) => {
        if (value) {
          this.listOfAlreadyExistingVecuNames = value.map((individualVecu) => individualVecu.displayName);
          this.vecuNameField?.addValidators(similarVecuNameValidator(this.listOfAlreadyExistingVecuNames));
        }
      },
      error: () => {
        this.snackbarService.notifyError(
          'VecuList.VecuFetchErrorMessage',
        );
      },
    });
  }

  displayRestrictor(fieldRef: ElementRef<MatFormField>): void {
    const selectedLanguage: Language = this.languageService.getCurrentLanguageValue();
    
    let positionConfig: ConnectedPosition = {
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'bottom',
    };

    if (selectedLanguage === Languages.en) {
      positionConfig.offsetX = this.enOffsetX;
      positionConfig.offsetY = this.offsetY;
    } else {
      positionConfig.offsetX = this.deOffsetX;
      positionConfig.offsetY = this.offsetY;;
    }

    this.updateDisplayRestrictorData();
    
    this.popoverService.open<RestrictedInputDisplayComponent>(fieldRef, RestrictedInputDisplayComponent, positionConfig);
  }

  private updateDisplayRestrictorData(): void {
    const displayRestrictionData: RestrictedInputData = {
      fieldValue: this.vecuNameField?.value,
      hasDevicePatternFailed: this.vecuNameField?.hasError('pattern'),
      devicePattern: devicePatternK8S(this.vecuNameField?.value,),
    };
    this.resourceRestrictionHelperService.updateData(displayRestrictionData);
  }

  get vecuNameField(): AbstractControl | null {
    return this.createVecuFormGroup.get('vecuName');
  }

  handleSubmitClick() {
    this.dialogRef.close(this.vecuNameField?.value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
