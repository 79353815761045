<div class="workbench-header">
  <app-header-template
    translationKey="Workspaces.Title"
    [showBackButton]="false"
    data-cy="project-details-overview"
  >
  </app-header-template>
  <button
    data-cy="reset-password-btn"
    mat-flat-button
    class="reset-password-button"
    aria-label="Reset password"
    (click)="resetPasswordDialog()"
  >
    {{ "Workspaces.ResetWorkspace.ResetPassword" | translate }}
  </button>
</div>

<div
  *ngIf="!loading; else loadingBar"
  class="ws-container"
  data-cy="ws-container"
>
  <ng-container *ngIf="workspaceTypes$ | async as workbenchType">
    <div
      class="workspace-container"
      *ngFor="let workbenchType of workbenchType.WorkBenchTypes"
    >
      <div class="start-col">
        {{ workbenchType.name }}
        <div class="chip" *ngIf="workbenchType.instance?.currentImageVersion">
          <app-chip
            class="workbench-chip"
            data-cy="workbench-chip"
            [label]="workbenchType.instance?.currentImageVersion || ''"
            [variant]="
              workbenchType.instance?.update
                ? ChipVariant.ERROR
                : ChipVariant.NEUTRAL_WHITE
            "
          ></app-chip>
        </div>
      </div>
      <div
        data-cy="backup"
        class="mid-col"
        *ngIf="workbenchType.instance?.id !== ''"
      >
        {{ "Workspaces.LastBackup" | translate }}:
        {{ workbenchType.instance?.backup }}
      </div>
      <div class="end-col">
        <ng-container
          *ngIf="
            (workbenchType | connectBtn) &&
              !workbenchType.instance?.actions?.request;
            else createOrUpdateBtn
          "
        >
          <a
            [href]="workbenchType.instance?.actions?.connect?.url"
            (click)="disableConnectButton($event, workbenchType)"
          >
            <button
              class="connect-btn"
              data-cy="connect-btn"
              mat-flat-button
              [disabled]="workbenchType.enable"
            >
              <span>{{ "Workspaces.Connect" | translate }}</span>
            </button>
          </a>
        </ng-container>
        <ng-template #createOrUpdateBtn>
          @if (
            workbenchType.instance?.actions?.request && isRequestButtonActive
          ) {
            <button
              data-cy="request-workspace-btn"
              mat-flat-button
              class="request-workspace-btn"
              aria-label="Request Workspace Access"
              (click)="requestWorkspaceAccess(workbenchType.name)"
            >
              {{ "Workspaces.Request" | translate }}
            </button>
          } @else {
            <div
              *ngIf="
                (!(workbenchType | connectBtn) &&
                  !workbenchType.instance?.actions?.request) ||
                !isRequestButtonActive
              "
              class="end-col"
              [matTooltipDisabled]="!workbenchType.enable"
              matTooltip="{{ 'Workspaces.CreationTime.Tooltip' | translate }}"
            >
              <button
                data-cy="create-workspace-btn"
                mat-flat-button
                class="create-workspace-btn"
                aria-label="Create Workspace"
                (click)="
                  createWorkspace(workbenchType.name);
                  workbenchType.enable = true
                "
                [disabled]="
                  workbenchType.enable ||
                  workbenchType.instance?.state === WorkspaceState.PENDING ||
                  workbenchType.instance?.state === WorkspaceState.TERMINATING
                "
              >
                @if (
                  workbenchType.enable ||
                  workbenchType.instance?.state === WorkspaceState.PENDING
                ) {
                  <div class="loading-state">
                    <mat-spinner diameter="14"></mat-spinner>
                    {{ "Workspaces.Pending" | translate }}
                  </div>
                } @else if (
                  workbenchType.instance?.state === WorkspaceState.TERMINATING
                ) {
                  <div class="loading-state">
                    <mat-spinner diameter="14"></mat-spinner>
                    {{ "Workspaces.Terminating" | translate }}
                  </div>
                } @else {
                  {{ "Workspaces.Create" | translate }}
                }
              </button>
            </div>
          }
        </ng-template>
        <div class="menu-col">
          <mat-icon
            data-cy="update-indicator"
            class="update-indicator"
            *ngIf="workbenchType.instance?.update"
            >circle</mat-icon
          >
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Menu trigger"
            data-cy="workbench-menu-btn"
            [disabled]="!workbenchType.instance?.id"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu
            class="caedge-menu"
            #menu="matMenu"
            data-cy="workbench-menu-box"
          >
            <button
              data-cy="update-workbench-btn"
              mat-menu-item
              class="caedge-menu-item"
              (click)="openUpdateInstanceOptions(workbenchType.instance)"
              [disabled]="!workbenchType.instance?.update"
            >
              <mat-icon
                data-cy="update-indicator-inside"
                class="update-indicator-inside"
                *ngIf="workbenchType.instance?.update"
                >circle</mat-icon
              >
              <span>{{ "Workspaces.Update" | translate }}</span>
            </button>

            <button
              data-cy="reset-workbench-btn"
              mat-menu-item
              class="caedge-menu-item"
              (click)="resetWorkbenchDialog(workbenchType.instance?.id)"
              [disabled]="false"
            >
              <span>{{ "Workspaces.Reset" | translate }}</span>
            </button>

            <button
              data-cy="delete-workbench-btn"
              mat-menu-item
              class="caedge-menu-item"
              *ngIf="workbenchType.instance?.id"
              (click)="openDeleteInstanceDialog(workbenchType.instance?.id)"
            >
              <span>{{ "Workspaces.Delete" | translate }}</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #loadingBar>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
