<div class="device-item-first-row">
  <div class="start-col">
    <div class="checkmark-icon" data-cy="bulk-deletion-checkmark">
      <mat-checkbox aria-label="Select device for deletion"
        [checked]="isDeviceSelectedSignal()"
        (change)="selectDeviceToDelete(device)">
      </mat-checkbox>
    </div>
    <div class="text-container">
      <h4>{{ device.deviceId }}</h4>
      <p data-cy="device-status">
        {{ deviceStatusChipLabelSignal() | translate }}
      </p>
    </div>
  </div>

  <div class="device-creator-col" *ngIf="userId$ | async as userId">
    @if (device.createdBy === userId) {
    <div class="device-creator" data-cy="device-creator"
      matTooltip="{{ 'HDK.CreatedBy' | translate }}"
      *ngIf="userFullName$ | async as userFullName">
      {{ userFullName | truncate: 20 }}
    </div>
    }
  </div>

  <div class="mid-items">
    <app-chip class="type-chip" data-cy="device-type"
      [label]="getDeviceTypeLabel(device)"
      [variant]="ChipVariant.NEUTRAL_WHITE"
      [matTooltip]="deviceTypeTooltipSignal() | translate" />
    <app-chip class="deployment-chip" data-cy="deployment-chip"
      [label]="device.numOfDeployments.toString()"
      [variant]="ChipVariant.NEUTRAL_WHITE" [icon]="['subscriptions', 'right']"
      [matTooltip]="device.numOfDeployments.toString() + ' ' + runningDeviceLabel"/>
    <div class="fw-chip-container chip-container">
      <app-chip class="fw-chip" data-cy="device-fw-version"
        [label]="device.fwVersion!" [variant]="
            device.instanceConnectionStatus === DeviceConnectionStatus.CONNECTED
              ? getFwChipVariant()
              : ChipVariant.DISABLED
          " [matTooltip]="
            (device.instanceConnectionStatus === DeviceConnectionStatus.CONNECTED
              ? 'DeviceList.FwUpdates.FwVersion'
              : 'DeviceList.FwUpdates.LastReportedVersion'
            ) | translate
          " />
    </div>
  </div>

  <div class="end-items">
    @if (device.ui.message) {
    <app-chip class="device-error-chip" data-cy="device-error-detail-chip"
      [label]="'General.ErrorDetail' | translate"
      [variant]="ChipVariant.ERROR" [icon]="['info', 'right']"
      (click)="navigateToDeviceDetailsPage(device.deviceId)" />
    }
    <div class="start-deployment-col">
      <button *ngIf="!isBeingCreatedSignal() && !isInactive" mat-flat-button
        class="add-deployment-btn"
        data-cy="add-deployment-button"
        aria-label="Start deployment"
        [disabled]="isAddDeploymentDisabledSignal()"
        (click)="startDeploymentClick()">
        <mat-icon>add</mat-icon>
        {{ "HDK.Deployment" | translate }}
      </button>
    </div>

    <div class="menu-col">
      <mat-icon class="menu-update-indicator"
        data-cy="device-fw-update-indicator"
        *ngIf="device.fwUpdate">
        circle
      </mat-icon>
      <button mat-icon-button [matMenuTriggerFor]="menu"
        aria-label="Menu trigger"
        data-cy="device-menu-btn"
       >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu class="caedge-menu" #menu="matMenu" data-cy="device-menu-box">
        <app-hdk-fw-updates-actions *ngIf="device.instanceType === 'REAL'"
          [device]="device"
          [getVersionsApiRecordsMap]="getVersionsApiRecordsMap"
          [updateFwVersionsApiRecordsMap]="updateFwVersionsApiRecordsMap" />
        <button data-cy="download-script-btn" mat-menu-item
          class="caedge-menu-item"
          [disabled]="isBeingCreatedSignal() || isBeingDeletedSignal()"
          (click)="downloadDeviceZipFile(device)">
          <span>{{ "DeviceList.DownloadScript" | translate }}</span>
        </button>
        <button data-cy="download-audio-btn" mat-menu-item
          class="caedge-menu-item" [disabled]="isBeingDeleted"
          (click)="downloadAudio()">
          <span>{{ "DeviceList.DownloadAudio" | translate }}</span>
        </button>
        <button *ngIf="
              device.instanceStatus !==
                DeviceInstanceStatus.CREATE_IN_PROGRESS &&
              device.instanceType === DeviceType.REAL
            " mat-menu-item class="caedge-menu-item"
          data-cy="download-connection-package-btn" [attr.aria-label]="
              'DeviceList.DownloadConnectionPackage' | translate
            " (click)="downloadConnectionPackage()">
          {{ "DeviceList.DownloadConnectionPackage" | translate }}
        </button>
        <button data-cy="show-dashboard-btn" mat-menu-item
          class="caedge-menu-item" (click)="showDashboard(device)">
          <span>{{ "DeviceList.GrafanaDashboard" | translate }}</span>
        </button>
        <button data-cy="delete-device-btn" mat-menu-item
          class="caedge-menu-item"
          (click)="openDeleteDeviceDialog()"
          [disabled]="isBeingCreatedSignal() || isBeingDeleted">
          <span>{{ "DeviceList.DeleteDialog.Confirm" | translate }}</span>
        </button>
      </mat-menu>
    </div>
    <mat-icon class="navigate-arrow"
      (click)="navigateToDeviceDetailsPage(device.deviceId)"
      matTooltip="{{ 'DeviceDetails.SectionTitle' | translate }}"
      matTooltipPosition="below">
      navigate_next
    </mat-icon>
  </div>
</div>

<div class="device-item-second-row">
  <div>&nbsp;</div>
  <app-chip
    class="chip-column"
    [matTooltip]="'DeviceList.FilterKeys.DateOptions.CreationDate' | translate"
    data-cy="device-item-creation-date"
    [variant]="ChipVariant.NEUTRAL_WHITE"
    [icon]="['event', 'left']"
    [label]="device.creationDate | customFormatDate" />

  @if (device.lastUsedDate) {
  <app-chip
    class="chip-column"
    [matTooltip]="'DeviceList.FilterKeys.DateOptions.LastUseDate' | translate"
    data-cy="device-item-last-used-date"
    [variant]="ChipVariant.NEUTRAL_WHITE"
    [icon]="['history', 'left']"
    [label]="device.lastUsedDate | customFormatDate: true" />
  }

  @if (device.scheduledDeletionDate) {
  <app-chip
    class="chip-column"
    [matTooltip]="'DeviceList.FilterKeys.DateOptions.DeletionScheduleDate' | translate"
    data-cy="device-item-schedule-for-deletion-date"
    [variant]="ChipVariant.NEUTRAL_WHITE"
    [icon]="['auto_delete', 'left']"
    [label]="device.scheduledDeletionDate| customFormatDate" />
  }
</div>
