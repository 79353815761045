import { Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Vecu } from '../../../models/vecu';
import { VecuListItemComponent } from '../vecu-list-item/vecu-list-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { EmptySectionComponent } from 'src/app/shared/components/empty-section/empty-section.component';
import { EmptySectionScope } from 'src/app/shared/utils/emptySectionScope';
import { EmptySectionIcon } from 'src/app/shared/components/empty-section/models/empty-section';
import { VecuColorStateMapping } from 'src/app/features/vecu/utils/vecu-color-state-mapping';
import { SelectedVecuService } from 'src/app/features/vecu/services/selected-vecu-service/selected-vecu.service';

@Component({
  selector: 'app-vecu-list-content',
  standalone: true,
  imports: [
    CommonModule,
    VecuListItemComponent,
    TranslateModule,
    EmptySectionComponent
  ],
  templateUrl: './vecu-list-content.component.html',
  styleUrl: './vecu-list-content.component.scss'
})
export class VecuListContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input({ required: true }) vecus: Vecu[] = [];

  readonly EmptySectionIcon = EmptySectionIcon;
  readonly EmptySectionScope = EmptySectionScope;
  readonly VecuColorStateMapping = VecuColorStateMapping;

  private readonly selectedVecuService = inject(SelectedVecuService);

  ngOnInit(): void {
    this.selectedVecuService.clearSelectedVecus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['vecus']) {
      this.selectedVecuService.loadSelectedVecus(this.vecus);
    }
  }

  ngOnDestroy(): void {
    this.selectedVecuService.clearSelectedVecus();
    this.selectedVecuService.clearDeleteVecuResponse();
  }
}
