import { Injectable } from '@angular/core';
import { FileService } from '../../../core/services/file/file.service';
import { SnackbarService } from '../../../core/services/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CsvDataConvertorService {
  constructor(
    private fileService: FileService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
  ) {}

  createCsvData(
    headerColumns: string[],
    tableData: any[],
    displayedColumns?: string[],
  ): string {
    let csvHeader: string;

    if (!displayedColumns || displayedColumns.length === 0) {
      csvHeader = headerColumns.join(',') + '\n';
    } else {
      csvHeader = displayedColumns.join(',') + '\n';
    }

    const csvRows = tableData
      .map((row) => {
        return headerColumns
          .map((header) => {
            return row[header] === undefined ? '' : row[header].toString();
          })
          .join(',');
      })
      .join('\n');

    return csvHeader + csvRows;
  }

  downloadCsv(headerColumns: string[], data: any[], fileName: string) {
    const csvData = this.createCsvData(headerColumns, data);
    const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

    this.snackbarService.notifySuccess(
      this.translate.instant('General.DownloadingFile'),
    );
    this.fileService.downloadBlob(csvBlob, fileName);
  }
}
