<div mat-dialog-title>
  <span class="title" data-cy="general-dialog-title">{{ data.title }}</span>
</div>
<div mat-dialog-content>
  <p *ngIf="data.message">{{ data.message }}</p>
  <p class="secondary-text" *ngIf="data.secondaryMessage">
    {{ data.secondaryMessage }}
  </p>
  <ng-container *ngTemplateOutlet="customContent"></ng-container>
</div>
<div mat-dialog-actions [align]="data.alignButtons || 'end'">
  @if (data.showCloseButton === undefined || data.showCloseButton) {
    <button
      (click)="data.onCancel ? data.onCancel() : undefined"
      class="cancel-btn"
      mat-dialog-close
      data-cy="dialog-close-button"
    >
      {{ data.closeText || ("General.Cancel" | translate) }}
    </button>
  }
  <button
    (click)="data.onConfirm ? data.onConfirm() : undefined"
    *ngIf="data.type === DialogType.CONFIRM"
    [mat-dialog-close]="true"
    [disabled]="(confirmButtonEnabled$ | async) !== true"
    [ngClass]="
      data.delete
        ? 'error-btn'
        : (confirmButtonEnabled$ | async) !== true
          ? 'disabled-btn'
          : !data.showCloseButton
            ? 'single-btn'
            : ''
    "
    class="primary-btn"
    data-cy="dialog-confirm-button"
  >
    {{ data.confirmText || ("General.Confirm" | translate) }}
  </button>
  <button
    *ngIf="data.type === DialogType.CUSTOM"
    (click)="data.onCustomButtonClick ? data.onCustomButtonClick() : undefined"
    class="primary-btn"
    data-cy="dialog-custom-button"
  >
    {{ data.customButtonText }}
  </button>
</div>
