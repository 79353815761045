import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { StepComponent } from '../../models/stepComponent';
import { Contact } from '../../models/contact';
import { User } from '../../../../../../../shared/models/tenantUsers';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  filterContacts,
  getUserMail,
  mapUserToContact,
  ValidateUser,
} from '../../utils/utils';

/**
 * `StepComponent` implementation for the contact information step of the project creation process.
 *
 * @export
 * @class ContactInformationComponent
 */
@Component({
  selector: 'app-contact-information',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
  ],
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss'],
})
export class ContactInformationComponent implements StepComponent {
  @Input() tenantUsers: User[] | null = [];

  @Output() validationChange = new EventEmitter<any>();

  private isValid = false;

  formGroup: FormGroup;

  filteredUsers: User[] | undefined;
  selectedCoOwners: User[] = [];

  constructor(private fb: FormBuilder) {
    this.filteredUsers = [];
    this.formGroup = this.fb.group({
      projectOwner: ['', [ValidateUser]],
      securityCentral: ['', [ValidateUser]],
      projectCoOwner: [''],
      securityProjectOwner: ['', [ValidateUser]],
      technicalContact: ['', [ValidateUser]],
    });
  }

  get projectCoOwnerControl() {
    return this.formGroup.get('projectCoOwner') as FormControl;
  }

  get stepValid(): boolean {
    if(this.formGroup.valid !== this.isValid) {
      this.isValid = this.formGroup.valid;
      this.validationChange.emit();
    }
    return this.isValid;
  }

  get dataToUpdate() {
    const formValue = this.formGroup.getRawValue();
    return {
      Contacts: {
        ProjectOwner: mapUserToContact(formValue.projectOwner),
        ProjectCoOwner: this.getCoOwnerContacts(this.selectedCoOwners),
        SecurityCentralOps: mapUserToContact(formValue.securityCentral),
        SecurityProjectOwner: mapUserToContact(formValue.securityProjectOwner),
        Technical: mapUserToContact(formValue.technicalContact),
      },
    };
  }

  private getCoOwnerContacts(formValues: User[]): Contact[] {
    if (formValues.length === 0) {
      return [
        {
          ContiUid: '-',
          Email: '-',
          Name: '-',
          Phone: '-',
        },
      ];
    }
    const contacts: Contact[] = [];
    formValues.forEach((user) => {
      contacts.push(mapUserToContact(user));
    });
    return contacts;
  }

  /**
   * Removes already selected co-owner.
   *
   * @param contact - the co owner to be removed
   */
  removeCoOwner(contact: User): void {
    const currentContacts: User[] = this.projectCoOwnerControl.value;
    const index = currentContacts.indexOf(contact);
    if (index >= 0) {
      currentContacts.splice(index, 1);
      this.projectCoOwnerControl.setValue(currentContacts);
    }
    this.selectedCoOwners = [...currentContacts];
    this.formGroup.updateValueAndValidity();
  }

  getUserMail(option: User) {
    return getUserMail(option);
  }

  filter(inputField: HTMLInputElement): void {
    this.filteredUsers = filterContacts(inputField, this.tenantUsers);
  }

  toggleSelection(user: User) {
    if (!this.selectedCoOwners.includes(user)) {
      this.selectedCoOwners.push(user);
    } else {
      const i = this.selectedCoOwners.findIndex(
        (value) => value.userid === user.userid,
      );
      this.selectedCoOwners.splice(i, 1);
    }
    this.projectCoOwnerControl.setValue(this.selectedCoOwners);
    this.selectedCoOwners = [...this.selectedCoOwners];
    this.projectCoOwnerControl.updateValueAndValidity();
  }
}