import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderTemplateComponent } from '../../../../shared/components/header/header-template.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SYSDAQFlatChildJob } from '../../models/jobDetails';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { takeUntil } from 'rxjs/operators';
import {
  ChipComponent,
  ChipVariant,
} from '../../../../shared/components/chip/chip.component';
import { MatIconModule } from '@angular/material/icon';
import { SimulationJobMonitoringService } from '../../services/simulation-job-monitoring.service';
import { FeatureComponent } from '../../../../core/models/classes/feature.component';
import { EmptySectionComponent } from 'src/app/shared/components/empty-section/empty-section.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CaeButtonComponent } from 'src/app/shared/components/cae-button/cae-button.component';
import { CsvDataConvertorService } from 'src/app/shared/utils/csv-data-convertor/csv-data-convertor.service';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { EmptySectionIcon } from 'src/app/shared/components/empty-section/models/empty-section';

export type ChildJobApi =
  | 'getChildJobsSimulation'
  | 'getChildJobSteps'
  | 'requestStepDownload';

export enum ChildJobFilterStatusLabel {
  ALL = 'All',
  SUCCEEDED = 'Succeeded',
  FAILED = 'Failed',
  RUNNING = 'Running',
  PENDING = 'Pending',
  STARTING = 'Starting',
  RUNNABLE = 'Runnable',
  SUBMITTED = 'Submitted',
  TERMINATING = 'Terminating',
  CANCELING = 'Canceling',
}

@Component({
  selector: 'app-child-jobs',
  standalone: true,
  imports: [
    CommonModule,
    HeaderTemplateComponent,
    TranslateModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    ChipComponent,
    MatIconModule,
    EmptySectionComponent,
    MatProgressBarModule,
    MatSelectModule,
    MatSortModule,
    MatTooltipModule,
    CaeButtonComponent,
  ],
  templateUrl: './child-jobs.component.html',
  styleUrls: ['./child-jobs.component.scss'],
})
export class ChildJobsComponent
  extends FeatureComponent<ChildJobApi>
  implements OnChanges, OnDestroy
{
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort) set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @Input() runID: string | undefined = undefined;
  protected readonly ChipVariant = ChipVariant;

  loading = false;

  displayedColumns: string[] = [
    'ChildJobIndex',
    'RECID',
    'InputFileName',
    'SectionStart',
    'SectionEnd',
    'JobExitCode',
    'StepNames',
    'Status',
    'link',
  ];

  statusOptions = Object.values(ChildJobFilterStatusLabel).map((value) => {
    return { value: value, viewValue: value };
  });

  EmptySectionIcon = EmptySectionIcon;
  selectedStatus = this.statusOptions[0].value;
  dataSource = new MatTableDataSource<SYSDAQFlatChildJob>([]);
  pageSizeOptions = [5, 10, 20];
  pageSize = 10;

  private destroy$ = new Subject<void>();

  constructor(
    private simulationJobMonitoringService: SimulationJobMonitoringService,
    private csvDataConvertorService: CsvDataConvertorService,
    private snackbarService: SnackbarService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const runIdCurrent = changes['runID']?.currentValue;
    const runIdPrevious = changes['runID']?.previousValue;

    if (runIdCurrent && runIdPrevious === null) {
      this.fetchChildJobs();
    }
  }

  fetchChildJobs() {
    if (!this.runID || !this.API) return;
    this.loading = true;

    this.simulationJobMonitoringService
      .fetchAndTransformChildJobs(this.API.getChildJobsSimulation, this.runID)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (childJobs) => {
          this.dataSource.data = childJobs;
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  filterChildJobs(value: ChildJobFilterStatusLabel) {
    if(value === ChildJobFilterStatusLabel.ALL){
      this.dataSource.filter = '';
      return;
    }
    this.dataSource.filter = value;
  }

  openStepsDialog(childJobIndex: string) {
    this.simulationJobMonitoringService.openStepsDialog(
      this.runID,
      childJobIndex,
      this.API?.getChildJobSteps,
      this.API?.requestStepDownload,
    );
  }

  downloadChildJobCsv() {
    if (!this.dataSource.data || this.dataSource.data.length === 0) {
      this.snackbarService.notifyWarn('General.NoDataExport');
      return;
    }

    const tableColumns: string[] = this.displayedColumns.slice(0, -1);
    this.csvDataConvertorService.downloadCsv(
      tableColumns,
      this.dataSource.data,
      'child-jobs-export',
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
