import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { StageNumber } from '../../services/add-stage-playlist-dialog.service';
import { CommonModule } from '@angular/common';
import { StepTwoData } from '../../models/stage-data';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { PlaylistApi } from '../../playlist.component';
import { AddStagePlaylistDialogService } from '../../services/add-stage-playlist-dialog.service';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { Subject, takeUntil } from 'rxjs';
import { ProjectConfiguration, BackendDataProjectConfiguration } from '../../models/project-configuration';
import { ProjectNames } from 'src/app/shared/utils/project-names';
import { FilterSections, Rad6baseSensors, DisplayedSensors, Sensors } from 'src/app/features/playlist/models/playlist-sensors-values';

const sensorMapping = {
  [Sensors.SRR_ALL]: [Sensors.SRR_RR, Sensors.SRR_RL, Sensors.SRR_FR, Sensors.SRR_FL],
  [Sensors.SRR_REAR]: [Sensors.SRR_RR, Sensors.SRR_RL],
  [Rad6baseSensors.ARS]: ['ARS620']
};

@Component({
  selector: 'app-add-stage-step-two',
  standalone: true,
  imports: [
    TranslateModule,
    MatSelectModule,
    CommonModule
  ],
  templateUrl: './add-stage-step-two.component.html',
  styleUrl: './add-stage-step-two.component.scss'
})
export class AddStageStepTwoComponent implements OnInit, OnDestroy {
  @Input() stageNumber: StageNumber = StageNumber.STAGE_ONE;
  @Input({ required: true }) projectName: string = '';
  @Input({ required: true }) api!: Record<PlaylistApi, ApiRecord>;
  @Input() guids: string[] = [];

  @Output() stepTwoEmitter = new EventEmitter<StepTwoData>();

  projectConfiguration: ProjectConfiguration = new ProjectConfiguration();
  allSensors: string[] = [];
  sensorVersions: string[] = [];
  filterSections: string[] = Object.values(FilterSections);
  sensorCombinationList: string[] = [];
  selectedSensor: string | undefined = undefined;
  selectedFilterSection: string = '';
  selectedSensorVersion: string = '';
  selectedSensorCombination: string = '';
  StageNumber = StageNumber;
  Sensors = Sensors;
  stepData: StepTwoData = { sensors: [] };
  ProjectNames = ProjectNames;
  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    private addStagePlaylistDialogService: AddStagePlaylistDialogService,
    private snackBarService: SnackbarService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.getProjectConfiguration();
    if (this.projectName === 'rad6base') {
      this.allSensors = Object.values(Rad6baseSensors);
    } else {
      this.allSensors = Object.values(DisplayedSensors);
    }
  }

  getProjectConfiguration() {
    this.addStagePlaylistDialogService
      .getProjectConfig(this.api.getProjectConfiguration, this.projectName)
      .pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: (rawProjectConfiguration: BackendDataProjectConfiguration) => {
          this.projectConfiguration = ProjectConfiguration.Factory(rawProjectConfiguration);
          this.sensorCombinationList = this.projectConfiguration.properties.sensorsStage1.allowedFunctions;
        },
        error: () => {
          this.snackBarService.notifyError(
            this.translateService.instant('Playlists.AddStageDialogCommon.CannotGetProjectConfiguration'));
        }
      });
  }

  private getSensorVersions(): void {
    this.addStagePlaylistDialogService.getListOfStageOneSimulatedSensorVersions(
      this.api.getSensorVersions,
      this.projectName,
      this.guids,
      this.stepData.sensors
    ).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: (sensorVersions) => this.handleReceivedSensorVersion(sensorVersions),
      error: () => {
        this.snackBarService.notifyError(
          this.translateService.instant('Playlists.AddStageDialogCommon.SensorVersionFail'));
      },
    });
  }

  onSensorSelected(selectedSensor: string): void {
    if (selectedSensor) {
      this.stepData.sensors = this.getSensorValueBasedOnSelection(selectedSensor);
      this.stepTwoEmitter.emit(this.stepData);
    } else {
      this.stepTwoEmitter.emit(undefined);
    }

    if (this.stageNumber === StageNumber.STAGE_TWO) {
      this.getSensorVersions();
    }

    this.selectedSensorCombination = '';
    this.selectedFilterSection = '';
  }

  private handleReceivedSensorVersion(receivedSensorVersions: string[]): void {
    if (receivedSensorVersions.length > 0) {
      this.sensorVersions = receivedSensorVersions;
    } else {
      this.snackBarService.notifyError(
        this.translateService.instant('Playlists.AddStageDialogCommon.NoSensorVersions'));
    }
  }

  private getSensorValueBasedOnSelection(selectedSensor: string): string[] {
    return sensorMapping[selectedSensor as keyof typeof sensorMapping] || [selectedSensor];
  }

  onFilterSectionSelected(selectedFilterSection: string): void {
    if (selectedFilterSection) {
      this.selectedFilterSection = selectedFilterSection;
      this.stepData.filterSections = this.convertFilterSectionString(selectedFilterSection);
    } else {
      this.stepData.filterSections = undefined;
    }
    this.stepTwoEmitter.emit(this.stepData);
  }

  private convertFilterSectionString(filterSection: string): string {
    const filterToLowerCase: string = filterSection.toLowerCase();
    const filterWithUnderscores: string = filterToLowerCase.replaceAll(' ', '_');
    return filterWithUnderscores;
  }

  onSensorVersionSelected(selectedSensorVersion: string): void {
    if (selectedSensorVersion) {
      this.selectedSensorVersion = selectedSensorVersion;
      this.stepData.sensorVersions = selectedSensorVersion;
    } else {
      this.stepData.sensorVersions = undefined;
    }
    this.stepTwoEmitter.emit(this.stepData);
  }

  onSensorCombinationSelected(selectedSensorCombination: string): void {
    const lengthCondition: number = this.selectedSensor === Sensors.SRR_REAR ? 2 : 1;
    if (this.stepData.sensors.length > lengthCondition) {
      this.stepData.sensors.pop();
    }
    
    if (selectedSensorCombination) {
      this.stepData.sensors.push(selectedSensorCombination);
    }
    
    this.stepTwoEmitter.emit(this.stepData);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
