<div class="reset-dialogue-box">
  <ng-container>
    <div
      class="dialogue-header"
      data-cy="reset-workspace-title"
      mat-dialog-title
    >
      {{ "Workspaces.ResetWorkspace.Title" | translate }}
    </div>
    <div class="reset-conformation" data-cy="reset-workspace-conformation">
      {{ "Workspaces.ResetWorkspace.ConfirmationText" | translate }}
    </div>
    <div class="dialogue-subtitle" data-cy="reset-workspace-subtitle">
      {{ "Workspaces.ResetWorkspace.SubTitle" | translate }}

      <div class="reset-description" data-cy="reset-workspace-description">
        {{ "Workspaces.ResetWorkspace.Description" | translate }}
      </div>

      <div class="checkbox-container">
        <mat-checkbox class="mat-checkbox" (change)="onCheckboxChange($event)">
          <span class="checkbox-text" data-cy="reset-workspace-checkbox">{{
            "Workspaces.ResetWorkspace.CheckBoxText" | translate
          }}</span>
        </mat-checkbox>
      </div>

      <app-dialog-hint
        data-cy="reset-workspace-warning"
        [isWarning]="true"
        [message]="'Workspaces.ResetWorkspace.Warning' | translate"
      ></app-dialog-hint>
    </div>

    <div class="reset-footer" mat-dialog-actions mat-dialog-close align="end">
      <button
        data-cy="reset-workbench-cancel-btn"
        class="cancel"
        mat-flat-button
        mat-dialog-close
      >
        {{ "General.Cancel" | translate }}
      </button>

      <button
        data-cy="reset-workbench-button"
        class="reset-btn"
        mat-flat-button
        type="submit"
        (click)="resetWorkbench(); $event.stopPropagation()"
      >
        {{ "Workspaces.ResetWorkspace.ResetBtn" | translate }}
      </button>
    </div>
  </ng-container>
</div>
