import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PlaylistDataset } from '../../job-monitoring/models/playlist-dataset';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { EmptySectionComponent } from '../../../shared/components/empty-section/empty-section.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';
import { TruncatePipe } from '../../../core/pipes/truncate.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CopyToClipboardService } from '../../../shared/utils/copy-to-clipboard.service';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlParameterStateService } from 'src/app/shared/services/url-parameter-state/url-parameter-state.service';
import { Subject, take, takeUntil } from 'rxjs';
import { compare } from 'src/app/shared/components/job-table/job-table.component';

@Component({
  selector: 'app-playlist-table',
  standalone: true,
  imports: [
    EmptySectionComponent,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,
    NgIf,
    TruncatePipe,
    MatTooltipModule,
    MatIconModule,
    MatSortModule,
  ],
  templateUrl: './playlist-table.component.html',
  styleUrl: './playlist-table.component.scss',
})
export class PlaylistTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() playlist: PlaylistDataset[] = [];
  @Input() searchInput: string = '';
  unsubscribe$: Subject<void> = new Subject<void>();

  defaultPageSize = 10;
  pageSizeOptions = [5, 10, 50, 100];

  localStorageId = 'playlistPageSize';

  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort) set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  dataSource = new MatTableDataSource<PlaylistDataset>([]);
  displayedColumns: string[] = [
    'id',
    'playlist_id',
    'name',
    'type',
    'status',
    'actions',
    'details',
  ];

  constructor(
    private copyToClipBoardService: CopyToClipboardService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlParameterStateService: UrlParameterStateService,
  ) {}

  ngOnInit(): void {
    const savedPageSize = localStorage.getItem(this.localStorageId);
    const defaultPageSize = 10;
    this.defaultPageSize = savedPageSize
      ? parseInt(savedPageSize)
      : defaultPageSize;
  }

  ngOnChanges(): void {
    this.dataSource.data = this.playlist;
    this.applyFilter(this.searchInput);
    this.initSorting(this.playlist);
  }

  applyFilter(searchInput: string) {
    this.dataSource.filter = searchInput.trim().toLowerCase();
  }

  setPageSizeInLocalStorage(event: PageEvent) {
    localStorage.setItem(this.localStorageId, event.pageSize.toString());
  }

  copyToClipboard(value: string) {
    this.copyToClipBoardService.copyToClipboard(value);
  }

  initSorting(playlist: PlaylistDataset[]) {
    this.urlParameterStateService.state$
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        const sortOrder = state['sort'];
        const sortedColumn = state['sortedColumn'];
        this.dataSource.data = this.sortTableDataBasedOnUrl(
          playlist,
          sortOrder,
          sortedColumn,
        );
      });
  }

  setColumnSortingParams(sort: Sort): void {
    this.urlParameterStateService.updateParams({
      sort: sort.direction,
      sortedColumn: sort.active,
    });
  }

  sortTableDataBasedOnUrl(
    playlist: PlaylistDataset[],
    sortDirection: string,
    sortedByColumn: string,
  ): PlaylistDataset[] {
    return playlist.sort((a: PlaylistDataset, b: PlaylistDataset) => {
      const attribute = sortedByColumn as keyof PlaylistDataset;
      const values =
        sortDirection === 'asc'
          ? { first: a[attribute], second: b[attribute] }
          : { first: b[attribute], second: a[attribute] };
      return compare(values.first, values.second);
    });
  }

  navigateToDetailsPage(playlistId: string) {
    this.router.navigate([`./${playlistId}`], {
      relativeTo: this.activatedRoute,
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
