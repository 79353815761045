import { Pipe, PipeTransform } from '@angular/core';
import { ChildJobStatus } from '../../features/simulation-job-monitoring/models/jobDetails';
import { ChipVariant } from '../../shared/components/chip/chip.component';

@Pipe({
  name: 'customVariantColorPipe',
  pure: false,
  standalone: true,
})
export class CustomVariantColorPipe implements PipeTransform {
  transform(status: ChildJobStatus): ChipVariant {
    switch (status) {
      case ChildJobStatus.SUCCEEDED: {
        return ChipVariant.SUCCESS;
      }
      case ChildJobStatus.FAILED: {
        return ChipVariant.ERROR;
      }
      case ChildJobStatus.REJECTED: {
        return ChipVariant.ERROR;
      }
      case ChildJobStatus.RUNNING: {
        return ChipVariant.SECONDARY;
      }
      default: {
        return ChipVariant.NEUTRAL_GRAY;
      }
    }
  }
}
