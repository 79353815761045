import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  VdiExtensionParams,
  VdiRequestService,
} from '../../services/vdi-request.service';
import { Option } from 'src/app/core/models/interfaces/option';
import { Subject, takeUntil } from 'rxjs';
import { OsType } from '../../models/os-type';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';

export interface RequestVdiDialogData {
  putVdiApiRecord: ApiRecord;
  projectName: string;
}

@Component({
  selector: 'app-request-vdi-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    TranslateModule,
  ],
  templateUrl: './request-vdi-dialog.component.html',
  styleUrl: './request-vdi-dialog.component.scss',
})
export class RequestVdiDialogComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();

  osType: Option<OsType>[] = [
    { id: OsType.WINDOWS, label: 'Windows' },
    { id: OsType.LINUX, label: 'Linux' },
  ];

  visualizationType: Option<string>[] = [
    { id: 'rdp', label: 'RDP' },
    { id: 'nice_dcv', label: 'NICE DCV' },
  ];

  requestVdiFormGroup: FormGroup = this.fb.group({
    osType: [
      {
        value: OsType.WINDOWS,
        disabled: true,
      },
      [Validators.required],
    ],
    visualizationType: [null, [Validators.required]],
  });

  readonly osTypeControl = this.requestVdiFormGroup.get(
    'osType',
  ) as FormControl;
  readonly visualizationTypeControl = this.requestVdiFormGroup.get(
    'visualizationType',
  ) as FormControl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RequestVdiDialogData,
    public dialogRef: MatDialogRef<RequestVdiDialogComponent>,
    private fb: FormBuilder,
    private vdiRequestService: VdiRequestService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.onOsSelection();
  }

  onOsSelection(): void {
    this.osTypeControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        switch (res) {
          case OsType.LINUX:
            this.visualizationTypeControl.disable();
            this.visualizationTypeControl.reset();
            break;

          case OsType.WINDOWS:
            this.visualizationTypeControl.enable();
            break;
        }
      });
  }

  async requestVdi(): Promise<void> {
    let reqParams: VdiExtensionParams = {
      projectName: this.data.projectName,
      osType: this.osTypeControl.value,
      visualizationType: this.visualizationTypeControl.value,
    };

    try {
      this.dialogRef.close(true);
      await this.vdiRequestService.putVdiInstanceExtension(
        this.data.putVdiApiRecord,
        reqParams,
      );
      this.snackbarService.notifyInfo(
        this.translate.instant('VDI.RequestSend'),
      );
    } catch (error) {
      this.snackbarService.notifyError(
        this.translate.instant('VDI.RequestFailed'),
      );
      this.dialogRef.close(false);
    }
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
