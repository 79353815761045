import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { BlueprintDeploymentState } from 'src/app/shared/stores/blueprint-deployment/models/blueprint-deployment-state';
import { BlueprintDeployment } from 'src/app/shared/stores/config/models/blueprintDeployments';

@Injectable({
  providedIn: 'root',
})
export class BlueprintDeploymentStore extends Store<BlueprintDeploymentState> {
  constructor() {
    super({
      blueprintDeployments: [],
      errorStatusCode: 0,
      hasInconsistentBlueprintDeployments: false
    });
  }

  get blueprintDeployment$(): Observable<BlueprintDeployment[]> {
    return this.state$.pipe(map((state) => state.blueprintDeployments));
  }

  get errorMessage$(): Observable<string | undefined> {
    return this.state$.pipe(map((state) => state.errorMessage));
  }

  get errorStatusCode$(): Observable<number | undefined> {
    return this.state$.pipe(map((state) => state.errorStatusCode));
  }

  get hasInconsistentBlueprintDeployments$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.hasInconsistentBlueprintDeployments));
  }

  findDeploymentByProjectId( projectId: string ): BlueprintDeployment[] {
    return this.state.blueprintDeployments.filter(blueprintDeployment => projectId === blueprintDeployment.ProjectId);
  }

  findDeploymentByProjectId$( projectId: string ): Observable<BlueprintDeployment[]> {
    return this.state$.pipe(map((state) => state.blueprintDeployments.filter(blueprintDeployment => projectId === blueprintDeployment.ProjectId)));
  }
}
