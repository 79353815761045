import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Vecu } from '../../models/vecu';

@Injectable({
  providedIn: 'root'
})
export class SelectedVecuService {
  private readonly localStorageKey = 'selectedVecuIds';
  private vecus = new BehaviorSubject<Vecu[]>([]);
  private loading = new BehaviorSubject<boolean>(false);
  private deleteVecuResponse = new BehaviorSubject<any[]>([]);

  get vecus$() {
    return this.vecus.asObservable();
  }

  get loading$() {
    return this.loading.asObservable();
  }

  get deleteVecuResponse$() {
    return this.deleteVecuResponse.asObservable();
  }

  loadSelectedVecus(vecus: Vecu[]) {
    const savedVecuIds: string | null = localStorage.getItem(this.localStorageKey);
    if (savedVecuIds) {
      const vecuIds = JSON.parse(savedVecuIds);
      const selectedVecus: Vecu[] = vecus.filter((vecu) =>
        vecuIds.includes(vecu.vecuId),
      );
      this.vecus.next(selectedVecus);
    }
  }

  isSelected(vecu: Vecu): boolean {
    return this.vecus.getValue().includes(vecu);
  }

  selectVecu(vecu: Vecu) {
    const currentDevices: Vecu[] = this.vecus.getValue();
    const index: number = currentDevices.indexOf(vecu);
    if (index === -1) {
      this.vecus.next([...currentDevices, vecu]);
    } else {
      this.vecus.next(currentDevices.filter((d) => d !== vecu));
    }
    this.saveSelectedVecus();
  }

  clearSelectedVecus() {
    this.vecus.next([]);
    this.loading.next(false);
    this.deleteVecuResponse.next([]);
    localStorage.removeItem(this.localStorageKey);
  }

  clearDeleteVecuResponse() {
    this.deleteVecuResponse.next([]);
    this.loading.next(false);
  }

  get selectedVecus(): Vecu[] {
    return this.vecus.getValue();
  }

  private saveSelectedVecus() {
    const vecuIds: string[] = this.vecus.getValue().map((vecu) => vecu.vecuId);
    localStorage.setItem(this.localStorageKey, JSON.stringify(vecuIds));
  }

}
