import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SideNavigationComponent } from '../../../shared/layout/side-navigation/side-navigation.component';

@Component({
  standalone: true,
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  imports: [RouterModule, SideNavigationComponent],
})
export class DashboardComponent {
  constructor() {}
}
