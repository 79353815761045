<div
  class="app-container"
  data-cy="application-list-item"
  [ngClass]="{ grid }"
  [@listAnimation]="applications.length"
  *ngIf="applications.length > 0; else emptyAppList"
>
  <ng-container
    *ngFor="let app of applications; trackBy: trackByApplicationName"
  >
    <app-application-list-item
      class="app-list-item"
      *ngIf="app.appType"
      (click)="navigateToApp(app.appName, app.appType)"
      [applicationName]="app.appName"
      [applicationType]="app.appType"
      [applicationRepo]="app.codeRepoName"
    ></app-application-list-item>
  </ng-container>
</div>
<ng-template #emptyAppList>
  <app-empty-section 
    [iconName]=EmptySectionIcon.COFFEE_MAKER
    [emptySectionScope]="EmptySectionScope.PAGE"
  >
    {{ "Application.NoApplicationFound" | translate }}
  </app-empty-section>
</ng-template>
