import { ProjectDashboardComponent } from 'src/app/core/components/project-dashboard/project-dashboard.component';
import { FeatureId } from 'src/app/shared/stores/config/models/featureId';
import { ProjectDashboardDetailsComponent } from '../../../../core/components/project-dashboard/project-dashboard-details/project-dashboard-details.component';
import { VirtualDevelopmentInfrastructureComponent } from '../../../../features/virtual-development-infrastructure/virtual-development-infrastructure.component';
import { WorkspacePageComponent } from '../../../../features/workspace-page/workspace-page.component';
import { JobMonitoringComponent } from '../../../../features/job-monitoring/job-monitoring.component';
import { PlaylistComponent } from '../../../../features/playlist/playlist.component';
import { PlaylistDetailsComponent } from '../../../../features/playlist/components/playlist-details/playlist-details.component';
import { vEcuComponentMap } from 'src/app/features/vecu/config/feature-id';
import { applicationComponentMap } from 'src/app/features/application/config/feature-id';
import { dataAndSimulationComponentMap } from 'src/app/features/data-and-simulation/config/feature-id';
import { vEcuAmiComponentMap } from 'src/app/features/vecu-ami/config/feature-id';
import { hdkComponentMap } from 'src/app/features/hdk/config/feature-id';
import { projectUserComponentMap } from 'src/app/core/components/project-users/config/feature-id';

export const featureComponentMap: Partial<Record<FeatureId, any>> = {
  [FeatureId.PROJECT_DASHBOARD_PAGE]: ProjectDashboardComponent,
  [FeatureId.PROJECT_DASHBOARD_DETAILS_PAGE]: ProjectDashboardDetailsComponent,
  ...projectUserComponentMap,
  ...applicationComponentMap,
  ...hdkComponentMap,
  ...vEcuAmiComponentMap,
  ...vEcuComponentMap,
  [FeatureId.SIMULATION_JOB_MONITORING]: JobMonitoringComponent,
  [FeatureId.VIRTUAL_DEVELOPMENT_INFRASTRUCTURE]:
    VirtualDevelopmentInfrastructureComponent,
  [FeatureId.WORKSPACE_PAGE]: WorkspacePageComponent,
  ...dataAndSimulationComponentMap,
  [FeatureId.PLAYLIST]: PlaylistComponent,
  [FeatureId.PLAYLIST_DETAILS]: PlaylistDetailsComponent,
};
