import { Injectable } from '@angular/core';
import { generatePath } from 'src/app/shared/utils/generatePath';

@Injectable({
  providedIn: 'root'
})
export class YieldReportService {

  getYieldReport(projectName: string, fileName: string, cloudFrontUrl: string): void {
    let myUrl = generatePath(cloudFrontUrl, {
      projectName,
      fileName
    });
    window.open(myUrl, '_blank');
  }
}
