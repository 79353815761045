export interface BackendDataStage2SimulationSensorsCombinations {
    SRR_ALL: [string[]];
    SRR_REAR: [string[]];
    SRR_RL: [string[]];
    SRR_RR: [string[]];
};

export interface AdjustedStage2SimulationSensorsCombinations {
    srrAll: [string[]];
    srrRear: [string[]];
    srrRl: [string[]];
    srrRr: [string[]];
}

export class Stage2SimulationSensorsCombinations implements AdjustedStage2SimulationSensorsCombinations {
    srrAll!: [string[]];
    srrRear!: [string[]];
    srrRl!: [string[]];
    srrRr!: [string[]];

    public static Factory(dataStage2SimulationSensorsCombinations: BackendDataStage2SimulationSensorsCombinations): Stage2SimulationSensorsCombinations {
        const newStage2SimulationSensorsCombinations = new Stage2SimulationSensorsCombinations();
        newStage2SimulationSensorsCombinations.srrAll = dataStage2SimulationSensorsCombinations['SRR_ALL'];
        newStage2SimulationSensorsCombinations.srrRear = dataStage2SimulationSensorsCombinations['SRR_REAR'];
        newStage2SimulationSensorsCombinations.srrRl = dataStage2SimulationSensorsCombinations['SRR_RL'];
        newStage2SimulationSensorsCombinations.srrRr = dataStage2SimulationSensorsCombinations['SRR_RR'];
        return newStage2SimulationSensorsCombinations;
    };
}
