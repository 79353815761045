import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationType } from 'src/app/shared/stores/applications/models/applicationType';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { CommonModule } from '@angular/common';
import { AddVersionStore } from 'src/app/shared/stores/deployment/add-version.store';
import { ContentWrapperComponent } from 'src/app/shared/components/content-wrapper/content-wrapper.component';
import { Application } from 'src/app/shared/stores/deployment/models/application';
import { GenericStateMatcher } from 'src/app/shared/utils/genericStateMatcher';
import {
  nameRestrictor,
  nameRestrictorWords,
  versionPattern,
} from 'src/app/shared/utils/name-restriction-validator/name-restriction-validator';
import { MatFormField } from '@angular/material/form-field';
import { ResourceRestrictionHelperService } from 'src/app/shared/utils/resource-restriction-helper-service/resource-restriction-helper.service';
import { PopoverService } from 'src/app/core/services/popover/popover.service';
import { RestrictedInputDisplayComponent } from 'src/app/shared/components/restricted-input-display/restricted-input-display.component';
import { RestrictedInputData } from 'src/app/shared/components/restricted-input-display/models/restricted-input-data';
import {
  Language,
  Languages,
  LanguageService,
} from 'src/app/core/services/language/language.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { ApplicationListApiService } from 'src/app/core/components/project-dashboard/components/application-list/services/application-list-api.service';

export interface AddVersionDialogData {
  appName: string;
  getAppDetailsApiConfig: ApiRecord;
}

export interface AddVersionDialogResponse {
  versionId: string;
  source: string;
}

@Component({
  selector: 'app-hdk-add-version-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    ContentWrapperComponent,
  ],
  templateUrl: './add-version-dialog.component.html',
  styleUrls: ['./add-version-dialog.component.scss'],
})
export class AddVersionDialogComponent implements OnInit, OnDestroy {
  @ViewChild('versionIdInput', { read: ElementRef<MatFormField> })
  versionIdField!: ElementRef<MatFormField>;
  isArtifactoryAppSrc: boolean = true;
  matcher = new GenericStateMatcher();

  addVersionFormGroup: FormGroup = new FormGroup({
    source: new FormControl('', [Validators.required, nameRestrictor]),
    versionId: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-z][a-z0-9]{0,24}$/),
      nameRestrictor,
    ]),
  });

  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddVersionDialogData,
    public addVersionStore: AddVersionStore,
    private applicationListApi: ApplicationListApiService,
    private popoverService: PopoverService,
    private resourceRestrictionHelperService: ResourceRestrictionHelperService,
    private languageService: LanguageService,
    public dialogRef: MatDialogRef<AddVersionDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.fetchAppDetails();
  }

  get source(): string {
    return this.addVersionFormGroup.get('source')?.value;
  }

  get versionId(): string {
    return this.addVersionFormGroup.get('versionId')?.value;
  }

  fetchAppDetails() {
    this.applicationListApi
      .getAppDetails(this.data.getAppDetailsApiConfig, this.data.appName)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((application) => {
        this.addVersionStore.setState({
          data: application,
          isLoading: false,
          hasError: false,
        });
        this.isArtifactoryAppSource(application);
      });
  }

  private isArtifactoryAppSource(application: Application) {
    this.isArtifactoryAppSrc =
      application.appType !== ApplicationType.SINGLE_SERVICE;
  }

  canSubmit() {
    return this.addVersionFormGroup.valid;
  }

  handleSubmitClick() {
    const data: AddVersionDialogResponse = {
      versionId: this.versionId,
      source: this.source,
    };
    this.dialogRef.close(data);
  }

  displayResourceRestrictionHelper(fieldRef: ElementRef<MatFormField>): void {
    const selectedLanguage: Language =
      this.languageService.getCurrentLanguageValue();
    let positionConfig: ConnectedPosition = {
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'bottom',
    };

    if (selectedLanguage === Languages.en) {
      positionConfig.offsetX = 310;
      positionConfig.offsetY = 240;
    } else {
      positionConfig.offsetX = 330;
      positionConfig.offsetY = 215;
    }

    this.updateRestrictorData();
    this.addVersionFormGroup
      .get('versionId')
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.updateRestrictorData();
      });
    this.popoverService.open<RestrictedInputDisplayComponent>(
      fieldRef,
      RestrictedInputDisplayComponent,
      positionConfig,
    );
  }

  private updateRestrictorData(): void {
    const displayRestrictionData: RestrictedInputData = {
      fieldValue: this.addVersionFormGroup.get('versionId')?.value,
      hasVersionPatternFailed: this.addVersionFormGroup
        .get('versionId')
        ?.hasError('pattern'),
      hasNameRestrictionFailed: this.addVersionFormGroup
        .get('versionId')
        ?.hasError('nameRestrictorError'),
      nameRestrictor: nameRestrictorWords(
        this.addVersionFormGroup.get('versionId')!,
      ),
      versionPattern: versionPattern(
        this.addVersionFormGroup.get('versionId')?.value,
      ),
    };
    this.resourceRestrictionHelperService.updateData(displayRestrictionData);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
