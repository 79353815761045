<app-header-template translationKey="VDI.Title" [showBackButton]="false" data-cy="project-details-overview">
</app-header-template>
<div class="vdi-container">
  <div class="my-vdi-container">
    <app-cae-card title="VDI.Instance.Title" subtitle="VDI.Instance.Subtitle"
      [isDarkButton]="vdiInstance() !== undefined"
      [actions]="loadingInstance() ? [] :(vdiInstance() === undefined ? requestAction : deleteAction)"
      (clickEvent)="handleEvent($event)">
      <app-vdi-request class="vdi-request" [instance]="vdiInstance()" [isLoading]="loadingInstance()">
      </app-vdi-request>
    </app-cae-card>
  </div>
  <div class="vdi-server-url-container">
    <app-cae-card title="VDI.Server.Title" [actions]="actionsConfigs" (clickEvent)="handleEvent($event)">
      <div class="vdi-config">
        <ng-container *ngIf="!loadingConfig(); else configIsLoading">
          <div *ngIf="vdiConfig() as config" class="vdi-config">
            <div class="config-text">
              <div>{{ "VDI.Server.Content1" | translate }}</div>
              <div>{{ "VDI.Server.Content2" | translate }}</div>
            </div>
            <div class="config-url">
              <a target="_blank" href="{{config.elasticBeanstalkHostedUrl}}">
                {{ config.elasticBeanstalkHostedUrl }}
              </a>
            </div>
          </div>
        </ng-container>
        <ng-template #configIsLoading>
          <mat-spinner diameter="42"></mat-spinner>
          <span>{{ "General.Loading" | translate }}</span>
        </ng-template>
      </div>
    </app-cae-card>
  </div>
  <div class="vdi-setup-guide">
    <app-cae-card title="VDI.Setup.Title" subtitle="VDI.Setup.Subtitle" (clickEvent)="handleEvent($event)">
      <div class="instructions">
        <mat-vertical-stepper class="expanded-stepper no-click">
          <mat-step [editable]="false">
            <ng-template matStepLabel>{{ "General.Step" | translate }}</ng-template>
            <div class="step-content">{{ "VDI.Setup.Step1" | translate }}</div>
          </mat-step>
          <mat-step class="no-click" [editable]="false">
            <ng-template matStepLabel>
              {{ "General.Step" | translate }}
            </ng-template>
            <div class="step-content">
              {{ "VDI.Setup.Step2" | translate }}
            </div>
            <app-cae-button buttonText="VDI.Download" (click)="handleEvent(ActionCommand.DOWNLOAD)">
            </app-cae-button>
          </mat-step>
        </mat-vertical-stepper>
      </div>
      <span class="information-link">{{ "VDI.Setup.Link" | translate }}
        <a target="_blank"
          href="https://confluence.8675.ccp.continental.exchange/confluence/pages/viewpage.action?spaceKey=Help&title=VDI">
          {{ "VDI.Setup.LinkHere" | translate }}
        </a>
      </span>
    </app-cae-card>
  </div>
</div>
