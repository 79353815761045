export interface IWorkbenchDataType {
  // coming from BE this way
  Project: {
    name: string;
  };
  user: string;
  WorkBenchTypes: WorkbenchType[];
}

export interface WorkbenchType {
  name: string;
  instance?: Instance;
  enable: boolean;
}

export interface Instance {
  id: string;
  state: string;
  update: boolean;
  backup: string;
  updatedVersion: string;
  currentImageVersion: string;
  actions: {
    connect: {
      url: string | null;
    };
    start: string | null;
    stop: string | null;
    delete: string | null;
    request: boolean;
  };
}

export interface WorkspaceCreation {
  url: string;
  registration_code: string;
  status: string;
  message: string;
}

export interface RequestWorkspaceAccess {
  message: string;
}

export interface UpdateWorkspace {
  status: string;
  message: string;
}

export enum CreateWorkspaceStatus {
  SUCCESS = 'Pending',
  ERROR = 'Failed',
}

export enum RequestActionStatus {
  ACTIVE = 'True',
  INACTIVE = 'False',
}

export enum WorkspaceState {
  PENDING = 'PENDING',
  AVAILABLE = 'AVAILABLE',
  STOPPED = 'STOPPED',
  TERMINATING = 'TERMINATING',
}
