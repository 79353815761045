export interface DevicePartition {
  name: string;
  os_type: DeviceOsType;
  fw_version?: string | null;
  user?: string;
  hostname?: string;
  address: string; //ipv4
  system_capabilities?: {
    cores: number;
    cpu_arch: string;
    storage: string;
    memory: string;
  };
  observability: deviceObservability[];
  status: PartitionStatus;
}

export interface deviceObservability {
  type: DeviceObservabilityStatus;
  ecuid: string;
  port: number;
  'use-for-log-collection': boolean;
}

export enum DeviceOsType {
  LINUX = 'linux',
  QNX = 'qnx',
  ANDROID = 'android',
  AAR = 'aar',
  CAR = 'car',
}

export enum DeviceObservabilityStatus {
  DLT = 'dlt',
}

export enum PartitionReporting {
  UP_TO_DATE = 'UP_TO_DATE',
  NONE_OR_OUTDATED = 'NONE_OR_OUTDATED',
}

export enum PartitionStatus {
  OFFLINE = 'offline',
  ONLINE = 'online',
}
