import { Injectable } from '@angular/core';
import {
  ProjectConfig,
  ServiceConfig,
} from '../../../shared/stores/config/models/projectConfig';
import { AppConfig } from '../../../shared/stores/config/models/appConfig';
import { ConfigMap } from './models/featureMap';
import {
  Divider,
  ProjectDashboardDetailsPage,
  ProjectUserListPage,
} from './models/general';
import { FeatureSectionConfig } from '../../../shared/stores/config/models/featureSectionConfig';
import { FeatureConfig } from '../../../shared/stores/config/models/featureConfig';

@Injectable({
  providedIn: 'root',
})
export class ParseFeaturesFromJson {
  /**
   * This function is used to map the JSON config structure
   * required to build the routes and sidebar items present
   * in the defined projects. Config files are tenant specific,
   * and the projectId and applicationId need to be passed down
   * as arguments to be able to build the sidebar item urls.
   *
   * @param {AppConfig} appConfig - [Config of the project]
   * @returns {ProjectConfig[]} [Array containing mapped feature depending on the service Id]
   */
  populateWithFeatures(appConfig: AppConfig): ProjectConfig[] {
    const projects = appConfig.projects;
    return projects.map((projectConfig: ProjectConfig) => {
      const project = projectConfig;
      const serviceConfigs = project.children as ServiceConfig[];
      const features = this.getFeaturesFromMaps(serviceConfigs);

      project.children = [
        ProjectDashboardDetailsPage as FeatureConfig,
        ProjectUserListPage as FeatureConfig,
        Divider as FeatureConfig,
        ...features,
      ];

      return project;
    });
  }

  /**
   * Map the id to as service Map
   *
   * @param {ServiceConfig[]} serviceConfigs - [Config of the project]
   * @returns {(FeatureSectionConfig | FeatureConfig)[]}
   * [Array containing mapped features or feature sections depending on the service ID]
   */
  private getFeaturesFromMaps(
    serviceConfigs: ServiceConfig[],
  ): (FeatureSectionConfig | FeatureConfig)[] {
    if (!serviceConfigs) {
      return [];
    }

    return serviceConfigs
      .map((service: ServiceConfig) => {
        const featureMap = ConfigMap as any;
        return (
          featureMap(
            {
              api: service.api,
              features: service.features || [],
              label: service.label,
            },
            service.serviceId,
          )?.features || []
        );
      })
      .flat();
  }
}
