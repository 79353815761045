import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dialog-hint',
  standalone: true,
  imports: [MatIconModule, TranslateModule, CommonModule],
  templateUrl: './dialog-hint.component.html',
  styleUrl: './dialog-hint.component.scss',
})
export class DialogHintComponent {
  @Input() message: string = '';
  @Input() secondMessage: string = '';
  @Input() isWarning: boolean = true;
  @Input() showIcon: boolean = true;
}
