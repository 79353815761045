@if(data.stageNumber === StageNumber.STAGE_ONE) {
  <h2 mat-dialog-title>{{ "Playlists.AddStageOneTitle" | translate }}</h2>
} @else {
  <h2 mat-dialog-title>{{ "Playlists.AddStageTwoTitle" | translate }}</h2>
}
<div mat-dialog-content data-cy="playlist-gen-dialog-content">

  <app-add-stage-step-one
    [masterCollections] = "masterCollection$ | async"
    [hidden]="(currentIndex === steps.selectSensors)"
    [api]="data.apis"
    [apiKey]="apiKey"
    (stepOneEmitter)="getStepOneNotification($event)"
  ></app-add-stage-step-one>

  <app-add-stage-step-two
    [hidden]="(currentIndex === steps.enterGuids)"
    [stageNumber]="data.stageNumber"
    [projectName]="projectName"
    [api]="data.apis"
    [guids]="stepOneData.guids"
    (stepTwoEmitter)="getStepTwoNotification($event)"
  ></app-add-stage-step-two>

</div>

<app-add-stage-playlist-footer
  mat-dialog-actions
  [stageNumber]="data.stageNumber"
  [enableSubmitButton]="enableSubmitButton"
  [enableNextButton]="enableNextButton"
  (next)="jumpToStep($event)"
  (previous)="jumpToStep($event)"
  (submitValues)="generateStagePlaylist()"
></app-add-stage-playlist-footer>
