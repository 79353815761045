import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { FeatureConfig } from 'src/environments/featureConfig';
import { FeatureService } from '../config/feature.service';

export const LANGUAGES = ['en', 'de'] as const;
export enum Languages {
  en = 'en',
  de = 'de',
}
export type Language = (typeof LANGUAGES)[number];

export const LANGUAGE_LABEL_MAP: Record<Language, string> = {
  en: 'EN',
  de: 'DE',
};

function isSupportedLanguage(value: string): value is Language {
  return LANGUAGES.includes(value as Language);
}

@Injectable({ providedIn: 'root' })
export class LanguageService {
  constructor(
    private translate: TranslateService,
    private featureService: FeatureService,
  ) {
    this.translate.addLangs(Object.keys(LANGUAGE_LABEL_MAP));
  }

  init(): void {
    let storedLanguage = localStorage.getItem('language');
    if (storedLanguage !== null && isSupportedLanguage(storedLanguage)) {
      this.setLanguage(storedLanguage);
      return;
    }

    const browserLang = this.translate.getBrowserLang();

    if (
      browserLang !== undefined &&
      isSupportedLanguage(browserLang) &&
      this.featureService.isFeatureEnabled(FeatureConfig.languageSelection)
    ) {
      this.setLanguage(browserLang);
      return;
    }

    this.translate.setDefaultLang('en');
    this.setLanguage('en');
  }

  setLanguage(language: Language): void {
    this.translate.use(language);
    localStorage.setItem('language', language);
  }

  getCurrentLanguage(): Observable<Language> {
    return of(this.translate.currentLang as Language);
  }

  getCurrentLanguageValue(): Language {
    return this.translate.currentLang as Language;
  }
}
