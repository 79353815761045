import { Application } from '../../deployment/models/application';
import { Contact } from '../../../../features/home/components/add-project-dialog/models/contact';

export interface ProjectsByTenant {
  projects: ProjectByTenant[];
  count?: number;
}

export interface ProjectByTenant {
  //values from api:
  UpdatedAt: string;
  ReceivedAt?: string;
  pkey: string;
  StatusApprovalCreation: ApprovalStatus;
  StatusApprovalDeletion?: ApprovalStatus;
  Billing: {
    LegalEntity: string;
    ControllingContact: string;
    OrgMapping: {
      CostCenter: string;
      CostCenterOwner: string;
    };
  };
  Contacts: {
    SecurityProjectOwner: Contact;
    SecurityCentralOps: Contact;
    Technical: Contact;
    ProjectOwner?: Contact;
    AccountOwner?: Contact;
    Billing: Contact;
  };
  Description: string;
  Status: ProjectStatus;
  Type: string;
  skey: string;
  Name: string;
}

export interface Project {
  id: string;
  label: string;
  abbreviation?: string;
  description: string;
  createdDate?: number;
  receivedAt?: string;
  applications?: Application[];
  creatorName?: string;
}

export enum ApprovalStatus {
  INITIATED = 'INITIATED',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
}

export enum ProjectStatus {
  CREATE_INITIATED = 'CREATE_INITIATED',
  APPROVAL_REQUESTED = 'APPROVAL_REQUESTED',
  IN_PROGRESS = 'IN_PROGRESS',
  READY = 'READY',
  DELETED = 'DELETED',
  CANCELED = 'CANCELED',
}
