<form class="form" [formGroup]="formGroup">
  <div class="content" mat-dialog-content>
    <mat-form-field
      class="caedge-form-field-outline project-name"
      ngDefaultControl
    >
      <mat-label>{{
        "Project.AddProjectDialog.ProjectName" | translate
        }}</mat-label>
      <input
        data-cy="add-project-name"
        formControlName="projectName"
        matInput
        maxlength="projectNameMaxLength - this.minCharsWB"
        (input)="this.updateCharsAvailable()"
      />
      <mat-hint align="end"
      >{{ projectNameControl.value.length }}/{{
        projectNameMaxLength - this.minCharsWB
        }}</mat-hint
      >
      <mat-hint
        *ngIf="
          !projectNameControl?.invalid &&
          charsAvailable() > 0
        "
        align="start"
      >{{ charsAvailable() }} characters
        left for the workbench name. Please consider this!
      </mat-hint>
      <mat-error data-cy="add-project-name-invalid">
        {{ getErrorMessage(projectNameControl) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
      <mat-label>{{
        "Project.AddProjectDialog.ProjectDescription" | translate
        }}</mat-label>
      <textarea
        matInput
        formControlName="projectDescription"
        rows="5"
        maxlength="100"
      >
      </textarea>
      <mat-hint align="end">
        {{ this.formGroup.get('projectDescription')?.value?.length }}/100
      </mat-hint>
    </mat-form-field>

    <div class="info-container" data-cy="permission-information">
      <mat-icon  class="info-icon">info</mat-icon>
      <p class="info-text">
        {{"Project.AddProjectDialog.PermissionInformation"| translate}}
      </p>
    </div>
  </div>
</form>



