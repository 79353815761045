import { ApiRecord } from '../stores/config/models/apiRecord';

export interface DeploymentDeletionData {
  appName: string;
  versionId: string;
  deviceName: string;
  apiRecord: ApiRecord;
  isForcedDeletion: boolean;
  partitionName: string;
}

export interface DeploymentActionData {
  appName: string;
  versionId: string;
  deviceName: string;
  partitionName: string;
  apiRecord: ApiRecord;
}

export enum DeploymentActionCommand {
  START = 'start',
  STOP = 'stop',
}
