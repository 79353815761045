import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthServiceTest } from '../services/authTest.service';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardTest implements CanActivate {
  constructor(private authService: AuthServiceTest, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.userIsAuthenticated$.pipe(
      tap((isAuthenticated) => {
        const redirectUrl = state.url;
        if (!isAuthenticated) {
          if (redirectUrl !== '/login') {
            localStorage.setItem('redirectUrl', redirectUrl);
          }
          this.router.navigate(['/login']);
        }
      })
    );
  }
}
