import { Pipe, PipeTransform } from '@angular/core';
import { Device } from '../../shared/stores/devices/models/device/device';

@Pipe({
  name: 'filterByDeviceCreator',
  standalone: true,
})
export class FilterByDeviceCreatorPipe implements PipeTransform {
  transform(devices: Device[], createdBy: string): Device[] {
    if (!devices || !createdBy) {
      return devices;
    }
    return devices.filter((device) => device.createdBy === createdBy);
  }
}
