import { Pipe, PipeTransform } from '@angular/core';
import { Version } from '../../shared/stores/deployment/models/version';
import { PipelineStatus } from 'src/app/shared/stores/deployment/models/pipeline';

@Pipe({
  name: 'disableDeploymentButton',
  standalone: true,
})
export class DisableDeploymentButtonPipe implements PipeTransform {

  transform(versions: Version[] | undefined): boolean {
    return !(versions?.some(version =>
      version.executionStatus === PipelineStatus.PIPELINE_BUILD_SUCCESS ||
      version.executionStatus === PipelineStatus.PIPELINE_CREATED
    ));
  }

}
