import {
  ConnectedPosition,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

const OVERLAY_CONFIG: Partial<OverlayConfig> = {
  hasBackdrop: true,
  disposeOnNavigation: true,
  backdropClass: 'cdk-overlay-transparent-backdrop',
};
const ESCAPE = 'Escape';

@Injectable({ providedIn: 'root' })
export class PopoverService implements OnDestroy {
  overlayRef: OverlayRef;
  private subscription = new Subscription();

  constructor(private overlay: Overlay) {
    this.overlayRef = this.overlay.create(OVERLAY_CONFIG);
  }

  ngOnDestroy() {
    this.dispose();
  }

  /**
   * Open a given component in an overlay and position it based on a given origin element.
   * @param origin: Element marking the origin of the overlay
   * @param component: Component to be displayed in the overlay
   * @param config: Overlay config
   * @param position: Position the component
   */
  open<T>(
    origin: ElementRef,
    component: ComponentType<T>,
    position: ConnectedPosition = {
      originX: 'start',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'top',
    },
    config?: Partial<OverlayConfig>
  ): OverlayRef {
    const overlayConfig = new OverlayConfig({
      ...OVERLAY_CONFIG,
      ...config,
    });

    overlayConfig.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPositions([position]);

    this.overlayRef = this.overlay.create(overlayConfig);
    const componentPortal = new ComponentPortal(component);

    this.overlayRef.attach(componentPortal);

    this.subscribeToOverlayClose();

    return this.overlayRef;
  }

  subscribeToOverlayClose(): void {
    const escapePressed$ = this.overlayRef
      .keydownEvents()
      .pipe(filter((event: KeyboardEvent) => event.key === ESCAPE));

    this.subscription = merge(
      this.overlayRef.backdropClick(),
      escapePressed$
    ).subscribe(() => {
      this.dispose();
    });
  }

  dispose(): void {
    this.subscription.unsubscribe();
    this.overlayRef.detach();
    this.overlayRef.dispose();
  }
}
