export interface WorkflowConfiguration {
  workflow: string;
  workflow_configuration: {
    steps: Step[];
  };
}

export interface Step {
  step: string;
  repo: string;
  template: Template[];
}

export interface Template {
  name: string;
  type: JobParameterTemplateType;
  values: string[];
}

export enum JobParameterTemplateType {
  DROPDOWN = 'dropdown',
  FORWARD = 'forward',
  TEXT_FIELD = 'textfield',
  FILE = 'file',
}
