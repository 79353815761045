export interface User {
  userid: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface ProjectUser extends User {
  serviceRoles: string[];
  infraRoles: string[];
}

export enum ServiceRole {
  READ = 'read',
  WRITE = 'write',
  OWNER = 'owner',
}

export function isProjectUser(user: ProjectUser | User): user is ProjectUser {
  return (user as ProjectUser).serviceRoles !== undefined;
}
