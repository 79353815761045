<div class="device-item-first-row">
  <div class="start-col">
    <div class="checkmark-icon" data-cy="bulk-deletion-checkmark">
      <mat-checkbox aria-label="Select device for deletion"
        [checked]="isDeviceSelectedSignal()"
        (change)="selectDeviceToDelete(device)">
      </mat-checkbox>
    </div>
    <div class="text-container">
      <h4>{{ device.deviceId }}</h4>
      <p data-cy="device-status">
        {{ deviceStatusChipLabelSignal() | translate }}
      </p>
    </div>
  </div>

  @if(userId$ | async; as userId) {
    <div class="device-creator-col">
      @if (
        device.createdBy === userId &&
        (userFullName$ | async); as userFullName
      ) {
        <div
          class="device-creator" 
          data-cy="device-creator"
          matTooltip="{{ 'HDK.CreatedBy' | translate }}">
          {{ userFullName | truncate: 20 }}
        </div>
      }
    </div>
  }

  <div class="mid-items">
    <app-chip class="type-chip" data-cy="device-type"
      [label]="getDeviceTypeLabel(device)"
      [variant]="ChipVariant.NEUTRAL_WHITE"
      [matTooltip]="deviceTypeTooltipSignal() | translate"
    />
    <app-chip class="deployment-chip" data-cy="deployment-chip"
      [label]="device.numOfDeployments.toString()"
      [variant]="ChipVariant.NEUTRAL_WHITE" [icon]="['subscriptions', 'right']"
      [matTooltip]="device.numOfDeployments.toString() + ' ' + runningDeviceLabel"
    />
  </div>

  <div class="end-items">
    @if (device.ui.message) {
      <app-chip class="device-error-chip" data-cy="device-error-detail-chip"
        [label]="'General.ErrorDetail' | translate"
        [variant]="ChipVariant.ERROR" [icon]="['info', 'right']"
        (click)="navigateToDeviceDetailsPage(device.deviceId)" 
      />
    }
    @if(deviceActionApiConfigMap) {
      <div class="control-btns">
        <app-simulated-action-buttons data-cy="update-fw-mat-menu"
          [device]="device"
          [quota]="quota"
          [isConnecting]="isConnectingSignal()"
          [isBeingCreated]="isBeingCreatedSignal()"
          [isStopping]="isDeviceStoppingSignal()"
          [deviceActionApiConfigMap]="deviceActionApiConfigMap" />
      </div>
    }
    @if(!isBeingCreatedSignal() && !isInactive) {
      <div class="start-deployment-col">
        <button
          mat-flat-button
          class="add-deployment-btn"
          data-cy="add-deployment-button"
          aria-label="Start deployment"
          [disabled]="isAddDeploymentDisabledSignal() || quota.isQuotaExceeded"
          (click)="startDeploymentClick()">
          <mat-icon>add</mat-icon>
          {{ "HDK.Deployment" | translate }}
        </button>
      </div>
    }

    <div class="menu-col">
      @if(device.fwUpdate) {
        <mat-icon 
          class="menu-update-indicator"
          data-cy="device-fw-update-indicator">
          circle
        </mat-icon>
      }
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Menu trigger"
        data-cy="device-menu-btn">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu class="caedge-menu" #menu="matMenu" data-cy="device-menu-box">
        <button
          mat-menu-item
          data-cy="download-script-btn" 
          class="caedge-menu-item"
          [disabled]="isBeingCreatedSignal() || isBeingDeletedSignal()"
          (click)="downloadDeviceZipFile(device)">
          <span>{{ "DeviceList.DownloadScript" | translate }}</span>
        </button>
        <button
          mat-menu-item
          data-cy="download-audio-btn"
          class="caedge-menu-item"
          [disabled]="isBeingDeleted"
          (click)="downloadAudio()">
          <span>{{ "DeviceList.DownloadAudio" | translate }}</span>
        </button>
        <button
          mat-menu-item
          data-cy="show-dashboard-btn"
          class="caedge-menu-item" 
          (click)="showDashboard(device)">
          <span>{{ "DeviceList.GrafanaDashboard" | translate }}</span>
        </button>
        <button 
          mat-menu-item
          data-cy="delete-device-btn" 
          class="caedge-menu-item"
          (click)="openDeleteDeviceDialog()"
          [disabled]="isBeingCreatedSignal() || isBeingDeleted">
          <span>{{ "DeviceList.DeleteDialog.Confirm" | translate }}</span>
        </button>
      </mat-menu>
    </div>
    <mat-icon 
      class="navigate-arrow"
      (click)="navigateToDeviceDetailsPage(device.deviceId)"
      matTooltip="{{ 'DeviceDetails.SectionTitle' | translate }}"
      matTooltipPosition="below">
      navigate_next
    </mat-icon>
  </div>
</div>
<div class="device-item-second-row">
  <div>&nbsp;</div>
  <app-chip
    class="chip-column"
    [matTooltip]="'DeviceList.FilterKeys.DateOptions.CreationDate' | translate"
    data-cy="device-item-creation-date"
    [variant]="ChipVariant.NEUTRAL_WHITE"
    [icon]="['event', 'left']"
    [label]="device.creationDate | customFormatDate"
  />
  @if (device.lastUsedDate) {
    <app-chip
      class="chip-column"
      [matTooltip]="'DeviceList.FilterKeys.DateOptions.LastUseDate' | translate"
      data-cy="device-item-last-used-date"
      [variant]="ChipVariant.NEUTRAL_WHITE"
      [icon]="['history', 'left']"
      [label]="device.lastUsedDate | customFormatDate: true"
    />
  }
  @if (device.scheduledDeletionDate) {
    <app-chip
      class="chip-column"
      [matTooltip]="'DeviceList.FilterKeys.DateOptions.DeletionScheduleDate' | translate"
      data-cy="device-item-schedule-for-deletion-date"
      [variant]="ChipVariant.NEUTRAL_WHITE"
      [icon]="['auto_delete', 'left']"
      [label]="device.scheduledDeletionDate| customFormatDate"
    />
  }
</div>
