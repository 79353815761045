import {
  ProjectUser,
  User,
} from 'src/app/shared/components/user-list-components/models/user';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ChipComponent, ChipVariant } from '../../chip/chip.component';
import { FeatureConfig } from '../../../../../environments/featureConfig';
import { FeatureService } from '../../../../core/services/config/feature.service';

@Component({
  selector: 'app-users-list-item',
  standalone: true,
  imports: [
    ChipComponent,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
  ],
  templateUrl: './users-list-item.component.html',
  styleUrl: './users-list-item.component.scss',
})
export class UsersListItemComponent implements OnInit, OnChanges {
  @Input({ required: true }) user!: ProjectUser | User;
  @Output() removeUserFromProject: EventEmitter<ProjectUser | User> =
    new EventEmitter();
  @Output() editUserRole: EventEmitter<ProjectUser | User> = new EventEmitter();
  protected readonly ChipVariant = ChipVariant;
  serviceRoles: string[] = [];
  featureDisplayProjectTeam = this.featureService.isFeatureEnabled(
    FeatureConfig.displayProjectTeamActions,
  );

  fullName = signal<string>('');

  constructor(private featureService: FeatureService) {}

  ngOnInit() {
    this.setFullName();
    if (this.isProjectUser(this.user)) {
      this.serviceRoles = this.user.serviceRoles;
    }
  }

  isProjectUser(user: any): user is ProjectUser {
    return user.serviceRoles !== undefined;
  }

  setFullName() {
    this.fullName.set(`${this.user.firstName} ${this.user.lastName}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentDevice = changes['user'].currentValue;
    const previousDevice = changes['user'].previousValue;

    if (currentDevice !== previousDevice) {
      this.setFullName();
    }
  }
}
