import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';

/**
 * Base class for FeatureComponents. It should be used
 * for components shown in the main content view.
 *
 * It gives access to ApiRecord objects specified in the
 * config file with the help of the 'API' property
 *
 * Usage: Extend from this class in your component.
 * Optionally pass a string union of ApiRecord object names
 *
 * @template T - [Union of strings representing the object names of ApiRecords]
 */
export class FeatureComponent<T extends string = string> {
  route = inject(ActivatedRoute);
  API?: Record<T, ApiRecord> = this.route.snapshot.data['API'];
}
