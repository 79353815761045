import { Injectable } from '@angular/core';
import { RecentProject } from '../../../shared/models/recentlyUsedProjects';

@Injectable({
  providedIn: 'root',
})
export class RecentlyUsedProjectsService {
  readonly recentlyUsedProjectsKey = 'recentlyUsedProjects';

  constructor() {}

  updateRecentlyFetchedProjects(projectId: string): void {
    const localStorageEntry: string | null = localStorage.getItem(
      this.recentlyUsedProjectsKey,
    );
    let recentlyUsedProjects: RecentProject[] = [];

    if (!localStorageEntry) {
      this.addCurrentEntry(recentlyUsedProjects, projectId);
      return;
    }

    recentlyUsedProjects = JSON.parse(localStorageEntry) as RecentProject[];
    const currentProjectIndex = recentlyUsedProjects.findIndex(
      (project) => project.projectId === projectId,
    );
    if (currentProjectIndex > -1) {
      recentlyUsedProjects[currentProjectIndex].lastUsedDate =
        new Date().getTime();
      localStorage.setItem(
        this.recentlyUsedProjectsKey,
        JSON.stringify(recentlyUsedProjects),
      );
      return;
    }
    this.addCurrentEntry(recentlyUsedProjects, projectId);
  }

  private addCurrentEntry(
    recentlyUsedProjects: RecentProject[],
    projectId: string,
  ) {
    recentlyUsedProjects.push({
      projectId: projectId,
      lastUsedDate: new Date().getTime(),
    });
    localStorage.setItem(
      this.recentlyUsedProjectsKey,
      JSON.stringify(recentlyUsedProjects),
    );
  }

  getProjects(): RecentProject[] {
    const localStorageEntry: string | null = localStorage.getItem(
      this.recentlyUsedProjectsKey,
    );
    let recentlyUsedProjects: RecentProject[] = [];

    if (localStorageEntry) {
      recentlyUsedProjects = JSON.parse(localStorageEntry) as RecentProject[];
      recentlyUsedProjects =
        this.checkForOutdatedProjects(recentlyUsedProjects);
      recentlyUsedProjects = recentlyUsedProjects.sort(
        (projectA, projectB) =>
          new Date(projectB.lastUsedDate).getTime() -
          new Date(projectA.lastUsedDate).getTime(),
      );
    }
    return recentlyUsedProjects;
  }

  checkForOutdatedProjects(recentlyUsedProjects: RecentProject[]) {
    const recentProjectThresholdDays: number = 60;
    const expirationDate = new Date();
    expirationDate.setDate(
      expirationDate.getDate() - recentProjectThresholdDays,
    );

    recentlyUsedProjects = recentlyUsedProjects.filter(
      (project) => project.lastUsedDate > expirationDate.getTime(),
    );

    localStorage.setItem(
      this.recentlyUsedProjectsKey,
      JSON.stringify(recentlyUsedProjects),
    );
    return recentlyUsedProjects;
  }
}
