import { FeatureConfig } from 'src/app/shared/stores/config/models/featureConfig';
import { FeatureId } from './feature-id';
import { AppConfigInstanceType } from 'src/app/shared/stores/config/models/appConfigInstanceType';

export const ProjectUserListPage: Partial<FeatureConfig> = {
  id: '222333',
  featureId: FeatureId.PROJECT_USER_LIST,
  label: 'Project team',
  type: AppConfigInstanceType.FEATURE,
  icon: 'supervised_user_circle',
  path: 'project/:projectId/project-team',
  API: {},
};