import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ProjectUser,
  User,
} from 'src/app/shared/components/user-list-components/models/user';
import { listAnimation } from 'src/app/core/animations/listAnimation';
import { UsersListItemComponent } from 'src/app/shared/components/user-list-components/users-list-item/users-list-item.component';
import { EmptySectionComponent } from 'src/app/shared/components/empty-section/empty-section.component';
import { EmptySectionIcon } from 'src/app/shared/components/empty-section/models/empty-section';
import { TranslateModule } from '@ngx-translate/core';
import { EmptySectionScope } from 'src/app/shared/utils/emptySectionScope';

@Component({
  selector: 'app-users-list',
  standalone: true,
  imports: [
    CommonModule,
    EmptySectionComponent,
    TranslateModule,
    UsersListItemComponent,
  ],
  templateUrl: './users-list.component.html',
  styleUrl: './users-list.component.scss',
  animations: [listAnimation],
})
export class UsersListComponent {
  @Input() users: ProjectUser[] | User[] = [];
  @Input() grid: boolean = false;

  @Output() removeUserFromProject: EventEmitter<ProjectUser | User> =
    new EventEmitter();
  @Output() editUserRole: EventEmitter<ProjectUser | User> = new EventEmitter();

  EmptySectionIcon = EmptySectionIcon;
  EmptySectionScope = EmptySectionScope;
}
