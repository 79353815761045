
import { Billing } from 'src/app/shared/models/billing';
import { ContactDetails, ContactRoles, Contacts } from 'src/app/shared/models/contacts';
import { TenantInfo } from 'src/app/shared/models/tenants';

export class TenantDetails {
  Description?: string;
  OpenCaedgeDomainName?: string;
  Type?: string;
  skey?: string;
  pkey?: string;
  CCPTenantName?: string;
  AccountNumberLatest?: number;
  WorkbenchNumberLatest?: number;
  Billing?: Billing | Billing[];
  Contacts?: Contacts;
  ProjectNumberLatest?: number;
  CCPRealmId?: number;
  ArbitaryOtherAttributes?: string;
  Name?: string;
  transformedContacts?: ContactRoles[];

  public static Factory(tenantDetails: TenantInfo): TenantDetails {
  
    const newTenantDetails = new TenantDetails();

    Object.assign(newTenantDetails, tenantDetails);

    newTenantDetails.transformedContacts = this.transformContacts(
      newTenantDetails.Contacts,
    );

    newTenantDetails.transformedContacts.sort((a, b) =>
      a.Name.localeCompare(b.Name),
    );

    return newTenantDetails;
  }

  private static transformContacts(
    contacts: Contacts | undefined,
  ): ContactRoles[] {

    if (!contacts) return [];

    const newContacts = [];

    for (const [key, value] of Object.entries(contacts as Object)) {
        const accountCoOwner = 'AccountCoOwner'; 

        if (key === accountCoOwner) {
          value.map((val: ContactDetails) => {
            newContacts.push({
              role: accountCoOwner,
              ...val,
            });
          });
        } else {
            newContacts.push({
              role: key,
              ...value,
            });
          }
    }
    return newContacts;
  }

}
  