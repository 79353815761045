import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ProjectsByTenant } from '../../../../shared/stores/projects/models/project';
import { TenantsByUser } from '../../../../shared/stores/projects/models/tenants';
import { environment } from '../../../../../environments/environment';
import {
  BlueprintDeployment,
  BlueprintDeploymentResponse,
  BlueprintProduct,
  BlueprintProductsResponse,
} from '../../../../shared/stores/config/models/blueprintDeployments';
import { FeatureConfig } from '../../../../../environments/featureConfig';
import { FeatureService } from '../../config/feature.service';
import { BlueprintDeploymentStore } from 'src/app/shared/stores/blueprint-deployment/blueprint-deployment.store';
import { BlueprintProductsStore } from 'src/app/shared/stores/blueprint-products/blueprintProducts.store';
import { DeployBlueprintApiService } from 'src/app/features/project-dashboard-details/components/add-blueprint-dialog/services/deploy-blueprint-api.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectsApiService {
  isBluePrintServiceReady: boolean;
  constructor(
    private http: HttpClient,
    private featureService: FeatureService,
    private blueprintDeploymentStore: BlueprintDeploymentStore,
    private blueprintService: DeployBlueprintApiService,
    private blueprintProductsStore: BlueprintProductsStore,
  ) {
    this.isBluePrintServiceReady = this.featureService.isFeatureEnabled(
      FeatureConfig.bluePrintServiceReady,
    );
  }

  getTenantsFromJWT(): Observable<TenantsByUser> {
    const configUrl =
      environment.COGNITO_CONFIG.BASE_DISPATCH_API_URL +
      'tenants/from_jwt_token';
    return this.http.get(configUrl) as Observable<TenantsByUser>;
  }

  getProjects(tenant_id: string) {
    // For PG Tenant only the projects from the user should be shown
    const allProjects = `tenants/${tenant_id}/projects`;
    const playGroundProjects = 'projects/my_projects';
    const configUrl =
      environment.COGNITO_CONFIG.BASE_DISPATCH_API_URL +
      (tenant_id === 'T010' ? playGroundProjects : allProjects);
    return this.http.get(configUrl) as Observable<ProjectsByTenant>;
  }

  getProjectBlueprintDeployments(
    projectId: string,
  ): Observable<BlueprintDeployment[]> {
    if (!this.isBluePrintServiceReady) {
      return throwError(
        () =>
          new Error(
            'Backend not ready error - feature will be implemented soon',
          ),
      );
    }

    return this.http
      .get<BlueprintDeploymentResponse>(
        // '../../../../assets/dummyData/blueprintDeployment.json'
        `${environment.COGNITO_CONFIG.BASE_DISPATCH_API_URL}projects/${projectId}/blueprints?stage=${environment.AWS_STAGE}`,
      )
      .pipe(
        map((response) => response.BlueprintDeployments),
        tap((blueprintDeployments: BlueprintDeployment[]) => {
          this.blueprintService.filterAndStoreBlueprintDeployments(blueprintDeployments);
        }),
        catchError((err) => {
          console.error(err);
          this.blueprintDeploymentStore.setState({
            blueprintDeployments: [
              ...this.blueprintDeploymentStore.state.blueprintDeployments,
            ],
            hasInconsistentBlueprintDeployments: false,
            errorStatusCode: err.status,
            errorMessage: err.message,
          });
          return of([] as BlueprintDeployment[]);
        }),
      );
  }

  getBlueprintProducts(): Observable<BlueprintProduct[]> {
    return this.http
      .get<BlueprintProductsResponse>(
        // '../../../../assets/dummyData/blueprintProducts.json'
        `${environment.COGNITO_CONFIG.BASE_BP_PRODUCT_URL}blueprintproducts?stage=${environment.AWS_STAGE}`,
      )
      .pipe(
        map((response) => response.BlueprintProducts),
        tap((blueprintProducts) => {
          this.blueprintProductsStore.setState({
            blueprintProducts,
            isLoading: false,
            hasError: false,
          });
        }),
        catchError((err) => {
          this.blueprintProductsStore.setState({
            blueprintProducts: [],
            isLoading: false,
            hasError: true,
            errorStatusCode: err.status,
            errorMessage: err.message,
          });
          return of(err);
        }),
      );
  }
}
