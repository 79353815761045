import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddJobComponent } from '../components/add-job/add-job.component';
import { CurrentTab, JobMonitoringApi } from '../job-monitoring.component';
import { RoutingStore } from '../../../shared/stores/config/routing.store';
import { ApiRecord } from '../../../shared/stores/config/models/apiRecord';

@Injectable({
  providedIn: 'root',
})
export class AddJobDialogService {
  constructor(
    public dialog: MatDialog,
    private routingStore: RoutingStore,
  ) {}

  openDialog(
    currentTab: CurrentTab,
    apis: Record<JobMonitoringApi, ApiRecord>,
  ) {
    const project = this.routingStore.state.projectId;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '811px';
    dialogConfig.height = '600px';
    dialogConfig.data = {
      currentTab,
      project,
      apis,
    };
    this.dialog.open(AddJobComponent, dialogConfig);
  }
}
