import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-select-workflow',
  standalone: true,
  imports: [CommonModule, MatRadioModule, FormsModule],
  templateUrl: './select-workflow.component.html',
  styleUrls: ['./select-workflow.component.scss'],
})
export class SelectWorkflowComponent implements OnChanges {
  @Input({ required: true }) data: string[] = [];
  @Input() description: string = '';
  @Output() workflowEventEmitter = new EventEmitter<{
    selectedWorkflow: string;
    stage: string | null;
  }>();
  selectedWorkflow: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    const currentValue = changes['data'].currentValue;
    const firstItem = 0;

    if (currentValue === '') return;

    this.selectedWorkflow = this.data[firstItem];
    this.emitWorkflow(this.selectedWorkflow);
  }

  protected emitWorkflow(selectedWorkflow: string) {
    const stage = this.parseStageFromWorkflow(selectedWorkflow);
    const workflow = {
      selectedWorkflow: selectedWorkflow,
      stage: stage ? stage[0] : null,
    };
    this.workflowEventEmitter.emit(workflow);
  }

  private parseStageFromWorkflow(
    selectedWorkflow: string,
  ): RegExpMatchArray | null {
    const matchPattern = new RegExp(/stage1|stage2/);
    return selectedWorkflow.match(matchPattern);
  }
}
