import { Component, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { DialogType } from 'src/app/shared/components/dialog/models/dialogType';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { FwVersionsStore } from 'src/app/shared/stores/fwVersions/fwVersions.store';
import { combineLatest, map, Subject, takeUntil } from 'rxjs';
import { ContentWrapperComponent } from 'src/app/shared/components/content-wrapper/content-wrapper.component';
import { DeviceFwStatus } from 'src/app/shared/stores/devices/models/deviceFwStatus';
import { FirmwareVersion } from 'src/app/shared/stores/fwVersions/models/fwVersion';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { LoadingTypes } from 'src/app/shared/components/content-wrapper/models/loading-type';
import { sortVersions } from 'src/app/shared/utils/sortVersions';
import { DeviceConnectionStatus } from 'src/app/shared/stores/devices/models/deviceConnectionStatus';
import { HdkDeviceListApiService } from '../../../../services/hdk-device-list-api/hdk-device-list-api.service';

@Component({
  selector: 'app-hdk-fw-updates-actions',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    ContentWrapperComponent,
  ],
  templateUrl: './fw-updates-actions.component.html',
  styleUrls: ['./fw-updates-actions.component.scss'],
})
export class FwUpdatesActionsComponent implements OnInit, OnDestroy {
  @Input() device!: Device;
  @Input() getVersionsApiRecordsMap?: Record<'Simulated', ApiRecord>;
  @Input() updateFwVersionsApiRecordsMap?: Record<'Simulated', ApiRecord>;

  unsubscribe$ = new Subject<void>();

  isUpdatingSignal = signal<boolean>(false);
  hasUpdateFailedSignal = signal<boolean>(false);
  canDoFwUpdateSignal = signal<boolean>(false);

  selectedVersion: string = '';
  LoadingTypes = LoadingTypes;

  constructor(
    private dialogService: DialogService,
    private translate: TranslateService,
    private hdkDevicesApiService: HdkDeviceListApiService,
    private fwVersionsStore: FwVersionsStore,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.isUpdatingSignal.set(this.isUpdating);
    this.hasUpdateFailedSignal.set(this.hasUpdateFailed);
    this.canDoFwUpdateSignal.set(this.canDoFwUpdate);
  }

  private get isUpdating() {
    return this.device.lastFwUpdate?.status === DeviceFwStatus.IN_PROGRESS;
  }

  private get hasUpdateFailed() {
    return this.device.lastFwUpdate?.status === DeviceFwStatus.FAILED;
  }

  private get canDoFwUpdate() {
    return this.isUpdatingSignal() || !this.device.fwUpdate || this.device.instanceConnectionStatus !== DeviceConnectionStatus.CONNECTED;
  }

  fwUpdatesData$ = combineLatest([
    this.fwVersionsStore.fwVersions$,
    this.fwVersionsStore.isLoading$,
    this.fwVersionsStore.hasError$,
  ]).pipe(
    map(([versions, isLoading, hasError]) => {
      const nonCurrentVersions = versions.filter(
        (version) => version.fwVersion !== this.device.fwVersion,
      );
      return { nonCurrentVersions, isLoading, hasError };
    }),
  );

  openUpdateDeviceDialog() {
    this.dialogService.openDialog({
      type: DialogType.CONFIRM,
      title: this.translate.instant('DeviceList.FwUpdates.Dialog.Title'),
      message: this.translate.instant('DeviceList.FwUpdates.Dialog.Text'),
      secondaryMessage: this.translate.instant(
        'DeviceList.FwUpdates.Dialog.Note',
      ),
      confirmText: this.translate.instant(
        'DeviceList.FwUpdates.Dialog.Confirm',
      ),
      width: '400px',
      onConfirm: () => this.updateDeviceClick(),
    });
  }

  updateDeviceClick() {
    if (!this.updateFwVersionsApiRecordsMap) {
      return;
    }
    this.hdkDevicesApiService
      .updateFwVersion(
        this.device.deviceId,
        this.selectedVersion,
        this.updateFwVersionsApiRecordsMap.Simulated,
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () =>
          this.snackbarService.notifyInfo(
            this.translate.instant(
              'DeviceList.FwUpdates.Dialog.VersionUpdatedRequested',
            ),
          ),
        error: () =>
          this.snackbarService.notifyError(
            this.translate.instant(
              'DeviceList.FwUpdates.Dialog.VersionUpdatedFailed',
            ),
          ),
      });
  }

  fetchFwVersions() {
    if (!this.getVersionsApiRecordsMap) {
      return;
    }
    this.hdkDevicesApiService
      .getFwUpdates(this.device.deviceId, this.getVersionsApiRecordsMap.Simulated)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (versions) =>
          this.fwVersionsStore.setState({
            fwVersions: sortVersions(versions),
            isLoading: false,
            hasError: false,
          }),
        error: () =>
          this.fwVersionsStore.setState({
            fwVersions: [],
            isLoading: false,
            hasError: true,
          }),
      });
  }

  setSelectedVersion(version: FirmwareVersion) {
    this.selectedVersion = version.fwVersion;
  }

  /**
   * The material menu emits the "close" event when the menu is about to be closed instead of after.
   * One possible workaround is to add a timeout to achieve better UX.
   * The timeout can be removed if Angular material add something like "afterClosed" event.
   */
  onMenuClose() {
    this.fwVersionsStore.setState({
      fwVersions: [],
      isLoading: true,
      hasError: false,
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
