import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PipelineStatus } from '../../shared/stores/deployment/models/pipeline';

@Pipe({
  name: 'pipelineStatus',
  standalone: true,
})
export class PipelineStatusPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(status: PipelineStatus): string {
    const translationKey = `Deployments.PipeLineStatus.${status}`;
    const translation = this.translate.instant(translationKey);
    return `Pipeline ${translation}`;
  }
}
