export interface BackendDataSensorCombination {
    SRR_ALL: string[];
    SRR_REAR: string[];
    SRR_RL: string[];
    SRR_RR: string[];
};

export interface AdjustedSensorCombination {
    srrAll: string[];
    srrRear: string[];
    srrRl: string[];
    srrRr: string[];
};

export class SensorCombination implements AdjustedSensorCombination {
    srrAll!: string[];
    srrRear!: string[];
    srrRl!: string[];
    srrRr!: string[];

    public static Factory(dataSensorCombination: BackendDataSensorCombination): SensorCombination {
        const newSensorCombination = new SensorCombination();
        newSensorCombination.srrAll = dataSensorCombination.SRR_ALL;
        newSensorCombination.srrRear = dataSensorCombination.SRR_REAR;
        newSensorCombination.srrRl = dataSensorCombination.SRR_RL;
        newSensorCombination.srrRr = dataSensorCombination.SRR_RR;
        return newSensorCombination;
    }
}
