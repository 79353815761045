import { FeatureId } from '../../../../shared/stores/config/models/featureId';
import { Method } from '../../../../shared/stores/config/models/method';
import { FeatureSectionConfig } from '../../../../shared/stores/config/models/featureSectionConfig';
import {
  Divider,
  ProjectDashboardDetailsPage,
} from './general';
import { GeneralApi, GeneralFeatureConfig } from './generalApi';
import { ApplicationDashboard } from 'src/app/features/application/config/application-config';
import { vEcusAmi } from 'src/app/features/vecu-ami/config/vecu-ami-config';
import { getHdkFeatureConfig } from 'src/app/features/hdk/config/hdk-config';
import { ProjectUserListPage } from 'src/app/core/components/project-users/config/project-users-config';

export const HdkConfigMap = (options: GeneralFeatureConfig<GeneralApi>) => {
  const hdkConfig: any = {
    features: [
      HdkSection,
      {
        id: '12312321',
        featureId: FeatureId.PROJECT_DASHBOARD_PAGE,
        label: 'Applications',
        type: 'FEATURE',
        icon: 'apps',
        path: 'project/:projectId/applications',
        API: {
          getApplications: {
            method: Method.GET,
            url: `${options.api.toolchain}/v3/applications`,
          },
          createApplication: {
            method: Method.POST,
            url: `${options.api.toolchain}/v3/applications`,
          },
          deleteApplication: {
            method: Method.DELETE,
            url: `${options.api.toolchain}/v3/applications`,
          },
          getRepositoryProviderList: {
            url: `${options.api.toolchain}/v3/repositories`,
            method: Method.GET,
          },
          getProviderRepositories: {
            method: Method.GET,
            url: `${options.api.toolchain}/v3/repositories/:provider`,
          },
        },
        children: [
          ProjectDashboardDetailsPage,
          ProjectUserListPage,
          Divider,
          HdkSection,
          ApplicationDashboard(options),
          vEcusAmi(options),
        ],
      },
      vEcusAmi(options),
      getHdkFeatureConfig(options)
    ],
  };
  return hdkConfig;
};

const HdkSection: Partial<FeatureSectionConfig> = {
  label: 'HDK deployments',
  nonNavigatable: true,
  path: '',
};
