/**
 * Extracts a Route id from a URL which is preceded
 * by a given prefix.
 * If no matching Route id can be found, returns empty string.
 *
 * @param {string} url
 * @param {string} prefix
 * @returns {string} [Route id preceded by given prefix]
 */
export const getParamFromUrl = (url: string, prefix: string): string => {
  const startIndex = url.indexOf(prefix);

  if (startIndex === -1) {
    return '';
  }

  const endIndex = url.indexOf('/', startIndex + prefix.length);
  return endIndex === -1
    ? url.slice(startIndex + prefix.length)
    : url.slice(startIndex + prefix.length, endIndex);
};
