export interface SYSDAQJobDetails {
  RunId: string;
  SubmissionDateTime: string;
  StartDateTime: string;
  EndDateTime: string;
  WorkflowName: string;
  Status: ChildJobStatus;
  Caller: string;
  Cost: string;
  RunData: {
    RECIDS: string[];
    output_location: string;
    input_location: string;
    JobStates: {
      ChildJobCount?: number;
      FailedJobs?: number;
      FinishedChildJobs?: number;
      JobCount?: number;
    };
    workflow_configuration: Record<string, string>[];
  };
}

export interface SYSDAQChildJobsPagination {
  last_key?: number;
  page_size?: number;
  Count?: number;
  Items: SYSDAQChildJob[];
}

export interface SYSDAQChildJob {
  RunId: string;
  ChildJobIndex: string;
  Status: ChildJobStatus;
  ChildJobData: ChildJobData;
}

export interface SYSDAQFlatChildJob {
  RunId: string;
  ChildJobIndex: string;
  RECID: string;
  InputFileName: string;
  Status: ChildJobStatus;
  SectionEnd: string;
  SectionStart: string;
  JobExitCode?: string;
  StepNames: string[];
  [key: string]: any;
}

export interface ChildJobData {
  RECID: string;
  InputFileName: string;
  SectionEnd: string;
  SectionStart: string;
  JobExitCode?: string;
  StepNames: string[];
  [key: string]: any;
}

export enum ChildJobStatus {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  RUNNING = 'RUNNING',
  PENDING = 'PENDING',
  STARTING = 'STARTING',
  RUNNABLE = 'RUNNABLE',
  SUBMITTED = 'SUBMITTED',
  TERMINATING = 'TERMINATING',
  CANCELING = 'CANCELING',
  REJECTED = 'REJECTED',
  QUEUED = 'QUEUED',
}

export class SYSDAQJobDetails {
  public static Factory(job: SYSDAQJobDetails) {
    const newJob = new SYSDAQJobDetails();
    Object.assign(newJob, job);
    return newJob;
  }
}

export class SYSDAQChildJobs implements SYSDAQChildJobsPagination {
  last_key: number | undefined;
  page_size: number | undefined;
  Count: number | undefined;
  Items!: SYSDAQChildJob[];

  public static Factory(job: SYSDAQChildJobsPagination) {
    const newJob = new SYSDAQChildJobs();
    Object.assign(newJob, job);
    return newJob;
  }
}

export class SYSDAQChildJob {
  public static Factory(step: SYSDAQChildJob) {
    const newStep = new SYSDAQChildJob();
    Object.assign(newStep, step);
    return newStep;
  }
}
