import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { ListItemComponent } from '../list-item/list-item.component';
import { Version } from '../../../../../../shared/stores/deployment/models/version';
import { DeploymentService } from '../../../../../../core/services/deployment-list/deployment.service';
import { PipelineStatus } from '../../../../../../shared/stores/deployment/models/pipeline';
import { ChipComponent } from '../../../../../../shared/components/chip/chip.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import {
  BehaviorSubject,
  catchError,
  forkJoin,
  map,
  Observable,
  of,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { FeatureComponent } from '../../../../../../core/models/classes/feature.component';
import { DeploymentListApi } from '../../deployment-list.component';
import { DeviceListApiService } from '../../../../../vecu-ami/components/device-list/services/device-list-api/device-list-api.service';
import { ApiRecord } from '../../../../../../shared/stores/config/models/apiRecord';
import { generatePath } from '../../../../../../shared/utils/generatePath';
import { Deployment } from '../../../../../../shared/stores/deployment/models/deployment';
import { HdkEnvironment } from '../../../../../../core/models/interfaces/hdkEnvironment';
import { Method } from '../../../../../../shared/stores/config/models/method';
import {
  Device,
  IDataDevice,
} from '../../../../../../shared/stores/devices/models/device/device';
import { ContentWrapperComponent } from '../../../../../../shared/components/content-wrapper/content-wrapper.component';
import { State } from '../../../../../../shared/stores/State';
import { DeploymentsStore } from '../../../../../../shared/stores/deployment/deployments.store';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { DialogType } from 'src/app/shared/components/dialog/models/dialogType';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { StartDeploymentService } from 'src/app/core/services/start-deployment/start-deployment.service';
import { Application } from 'src/app/shared/stores/deployment/models/application';
import { ApplicationType } from 'src/app/shared/stores/applications/models/applicationType';
import { DeviceConnectionStatus } from '../../../../../../shared/stores/devices/models/deviceConnectionStatus';
import { DeviceType } from '../../../../../../shared/stores/devices/models/deviceType';
import { PipelineStatusPipe } from 'src/app/shared/pipes/pipeline-status.pipe';
import { PipelineEnvironmentStatusPipe } from 'src/app/shared/pipes/pipeline-environment-status.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export interface IAllEnvironment<T> {
  dev: T;
}

@Component({
  selector: 'app-deployment-list-expansion-panel',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    ListItemComponent,
    ChipComponent,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    ContentWrapperComponent,
    PipelineStatusPipe,
    PipelineEnvironmentStatusPipe,
    MatProgressSpinnerModule,
  ],
  templateUrl: './deployment-list-expansion-panel.component.html',
  styleUrls: ['./deployment-list-expansion-panel.component.scss'],
})
export class DeploymentListExpansionPanelComponent
  extends FeatureComponent<DeploymentListApi>
  implements OnChanges, OnDestroy
{
  @Input() version!: Version;
  @Input() appType: string = '';
  @Input({ required: true }) application!: Application;

  env = HdkEnvironment;
  pipelineStatus = PipelineStatus;
  ApplicationType = ApplicationType;
  loadingSpinnerDiameter: number = 16;

  onDeployedDevice: BehaviorSubject<State<Device[]>> = new BehaviorSubject<
    State<Device[]>
  >({
    data: [],
    isLoading: true,
    hasError: false,
  });

  deployments: IAllEnvironment<Deployment[]> = {
    dev: [],
  };

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private deviceListApiService: DeviceListApiService,
    private deploymentService: DeploymentService,
    private deploymentsStore: DeploymentsStore,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private startDeploymentService: StartDeploymentService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentVersion = changes['version'].currentValue;
    const previousVersion = changes['version'].previousValue;
    if (currentVersion !== previousVersion) {
      this.fetchData(currentVersion, this.application.appName);
    }
  }

  fetchData(version: Version, appName: string) {
    if (version && appName && this.API) {
      this.deploymentService
        .getDeployments(appName, version.versionId, this.API.getDeploymentDev)
        .pipe(
          map((dev) => {
            return { dev: dev };
          }),
          takeUntil(this.unsubscribe$),
        )
        .subscribe((response) => {
          this.deploymentsStore.setState({
            data: response,
            isLoading: false,
            hasError: false,
          });
          this.deployments = response;
          this.loadDeviceData();
        });
    }
  }

  loadDeviceData() {
    const devDeviceList: string[] = this.extractHardwareName(
      this.deployments.dev,
    );

    this.getDevices(devDeviceList)
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.onDeployedDevice.next({
          data: data,
          isLoading: false,
          hasError: false,
        });
      });
  }

  getDevices(deviceNames: string[]): Observable<Device[]> {
    const apiRecord: ApiRecord = this.getAPIRecord();
    if (deviceNames.length === 0) {
      return of([]);
    }

    const devicesData$: Observable<Device>[] = deviceNames.map((deviceName) => {
      const getDevice: ApiRecord = {
        ...apiRecord,
        url: generatePath(apiRecord.url, {
          hwName: deviceName,
        }),
      };
      return this.deviceListApiService.getData<IDataDevice>(getDevice).pipe(
        map((device) => {
          return Device.Factory(device, HdkEnvironment.DEVELOPMENT);
        }),
        catchError((err) => {
          if (err.error.message === 'ERROR: Hardware Name not found!') {
            return of(this.getErrorDevice(err.url));
          }
          return of(err);
        }),
      );
    });

    return forkJoin(devicesData$).pipe(
      catchError((err) => {
        return of(err);
      }),
    );
  }

  private getErrorDevice(errorUrl: string): Device {
    const device = new Device();
    device.instanceConnectionStatus = DeviceConnectionStatus.UNKNOWN_DEVICE;
    device.deviceId = errorUrl.substring(
      errorUrl.lastIndexOf('/') + 1,
      errorUrl.length,
    );
    device.instanceType = DeviceType.UNKNOWN;
    return device;
  }

  versionHasDeployments() {
    return this.deployments.dev.some(
      (deployment) => deployment.versionId === this.version.versionId,
    );
  }

  extractHardwareName(deployments: Deployment[]): string[] {
    return deployments?.map((data) => data.hwTarget);
  }

  pipelineBuildRequest = (event: Event, buildRequest: boolean): void => {
    event.stopPropagation();
    if (this.API) {
      this.deploymentService
        .pipelineBuildRequest(
          this.application.appName,
          this.version.versionId,
          buildRequest,
          this.API.pipelineBuildRequest,
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.snackbarService.notifyInfo(
              this.translate.instant('Deployments.PipelineRequestSuccessfully'),
            );
          },
          error: () => {
            this.snackbarService.notifyError(
              this.translate.instant('Deployments.PipelineRequestFailed'),
            );
          },
        });
    }
  };

  deleteVersionClick(): void {
    if (this.API) {
      this.deploymentService
        .deleteVersion(
          this.application.appName,
          this.version.versionId,
          this.API.deleteVersion,
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.snackbarService.notifyInfo(
              this.translate.instant(
                'Deployments.DeleteVersionDialog.DeletionInitiated',
              ),
            );
          },
          error: () => {
            this.snackbarService.notifyError(
              this.translate.instant(
                'Deployments.DeleteVersionDialog.DeletionFailed',
              ),
            );
          },
        });
    }
  }

  openDeleteVersionDialog() {
    this.dialogService.openDialog({
      type: DialogType.CONFIRM,
      title: this.translate.instant('Deployments.DeleteVersionDialog.Title'),
      message: this.translate.instant('Deployments.DeleteVersionDialog.Text'),
      confirmText: this.translate.instant(
        'Deployments.DeleteVersionDialog.Confirm',
      ),
      width: '400px',
      onConfirm: () => this.deleteVersionClick(),
    });
  }

  startDeploymentClick(event: Event) {
    event.stopPropagation();
    if (this.API) {
      this.startDeploymentService.openStartDeploymentDialog(
        this.application.appName,
        {
          createDevDeployment: this.API.createDevDeployment,
          getDevDevices: this.API.getDevDevices,
          getApplicationDetails: this.API.getAppDetails,
        },
        { versionId: this.version.versionId },
        this.unsubscribe$,
      );
    }
  }

  getAPIRecord(): ApiRecord {
    if (!this.API) {
      return {
        url: '',
        method: Method.GET,
      };
    }

    return this.API.getDevHardware;
  }

  trackByName(index: number, device: Device): string {
    return device.deviceId;
  }

  disableDeployment(dev: PipelineStatus): boolean {
    return !(dev === this.pipelineStatus.PIPELINE_BUILD_SUCCESS);
  }

  expandListItem(dev: Deployment[]): boolean {
    return dev.length > 0;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
