import { Component, Inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { SnackbarService } from '../../../../../../core/services/snackbar/snackbar.service';
import { ApiRecord } from '../../../../../../shared/stores/config/models/apiRecord';
import { DeploymentService } from '../../../../../../core/services/deployment-list/deployment.service';
import { DeploymentDeletionData } from '../../../../../../shared/models/deployment';
import { MatTooltipModule } from '@angular/material/tooltip';
import { WindowResizeService } from '../../../../../../core/services/windowResize/window-resize.service';
import { Subject, take, takeUntil } from 'rxjs';
import { CheckboxComponent } from '../../../../../../shared/components/checkbox/checkbox.component';

export interface DialogData {
  appName: string;
  versionId: string;
  isForcedDeletion: boolean;
  hwName: string;
  partitionName: string;
  deleteDeployment: ApiRecord;
}

@Component({
  selector: 'app-delete-hdk-deployment-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    CheckboxComponent,
  ],
  templateUrl: './delete-deployment-dialog.component.html',
  styleUrls: ['./delete-deployment-dialog.component.scss'],
})
export class DeleteDeploymentDialogComponent implements OnDestroy {
  isSelected: boolean = false;
  showInfo = this.windowService.isMobile() || this.windowService.isTablet();
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private deploymentService: DeploymentService,
    private snackbarService: SnackbarService,
    private windowService: WindowResizeService,
    private translate: TranslateService,
  ) {}

  deleteDeployment(): void {
    const data: DeploymentDeletionData = {
      apiRecord: this.data.deleteDeployment,
      appName: this.data.appName,
      isForcedDeletion: this.isSelected,
      deviceName: this.data.hwName,
      versionId: this.data.versionId,
      partitionName: this.data.partitionName,
    };

    this.deploymentService
      .deleteDeployment(data)
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.snackbarService.notifyInfo(
            this.translate.instant(
              'Deployments.DeleteDeploymentDialog.DeletionInitiated',
            ),
          );
        },
        error: () => {
          this.snackbarService.notifyError(
            this.translate.instant(
              'Deployments.DeleteDeploymentDialog.DeletionFailed',
            ),
          );
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
