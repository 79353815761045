import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { DialogProps } from './models/dialogProps';
import { DialogType } from './models/dialogType';
import { DialogContentService } from './services/dialog-content.service';
import { Observable, Subject, of } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    CommonModule,
  ],
})
export class DialogComponent implements OnInit, OnDestroy {
  confirmButtonEnabled$: Observable<boolean> = of(false);
  DialogType = DialogType;
  customContent: TemplateRef<any> | null = null;
  private unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogProps,
    private dialogContent: DialogContentService,
  ) {}

  ngOnInit(): void {
    this.confirmButtonEnabled$ =
      this.dialogContent.dialogContentValidObservable();

    if (this.data.disableConfirmButton) {
      this.dialogContent.makeDialogContentValid(
        !this.data.disableConfirmButton,
      );
    }
  }

  updateData(newData: Partial<DialogProps>) {
    this.data = { ...this.data, ...newData };
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
