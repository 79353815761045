import { Injectable } from '@angular/core';
import { ApiRecord } from '../../../shared/stores/config/models/apiRecord';
import { PlaylistDataset } from '../../job-monitoring/models/playlist-dataset';
import { interval, map, Observable, startWith, switchMap } from 'rxjs';
import { ApiService } from '../../../core/services/api/api.service';
import { environment } from '../../../../environments/environment';
import { IPlaylist } from '../../job-monitoring/services/job-monitoring.service';
import { IPlaylistDetails } from '../models/playlist-details';
import { generatePath } from 'src/app/shared/utils/generatePath';
import { ProjectNameMapping } from 'src/app/shared/utils/projectNameMapping';

@Injectable({
  providedIn: 'root',
})
export class PlaylistService {
  private interval = 30000;
  constructor(private apiService: ApiService) {}

  getPlaylist(
    apiRecord: ApiRecord,
    projectName: string,
  ): Observable<PlaylistDataset[]> {
    const body = {
      project: ProjectNameMapping[projectName] || projectName,
      playlist_bucket: `dpr-etl-${environment.AWS_STAGE}-playlist-bucket`,
      mode: 'list_playlists',
    };
    return interval(this.interval).pipe(
      startWith([]),
      switchMap(() => {
        return this.apiService
          .request<IPlaylist>({ apiRecord, body })
          .pipe(map((res: IPlaylist) => res.datasets));
      }),
    );
  }

  getPlaylistDetails(apiRecord: ApiRecord, playlistId: string) {
    return this.apiService
      .request<IPlaylistDetails>({
        apiRecord: {
          ...apiRecord,
          url: generatePath(apiRecord.url, { playlistId }),
        },
      })
      .pipe(map((res: IPlaylistDetails) => res));
  }
}
