import { GeneralApi, GeneralFeatureConfig } from 'src/app/core/services/config/models/generalApi';
import { FeatureConfig } from 'src/app/shared/stores/config/models/featureConfig';
import { Method } from 'src/app/shared/stores/config/models/method';
import { FeatureId } from './feature-id';

export const ApplicationDashboard = (
  options: GeneralFeatureConfig<GeneralApi>,
): Partial<FeatureConfig> => {
  return {
    id: '123123',
    featureId: FeatureId.APPLICATION_DASHBOARD_PAGE,
    label: 'Applications',
    icon: 'apps',
    isNested: false,
    path: ':appName',
    API: {
      getApplicationDetails: {
        method: Method.GET,
        url: `${options.api.toolchain}/v3/applications/:appName`,
      },
      deleteApplication: {
        method: Method.DELETE,
        url: `${options.api.toolchain}/v3/applications/:appName`,
      },
      getAppVersions: {
        method: Method.GET,
        url: `${options.api.toolchain}/v3/applications/:appName/versions`,
      },
      createDevDeployment: {
        url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
        method: Method.POST,
      },
      getDevDevices: {
        url: `${options.api.dev}/v3/devices`,
        method: Method.GET,
      },
      getQaDevices: {
        url: `${options.api.qa}/v3/devices`,
        method: Method.GET,
      },
      getAppDetails: {
        url: `${options.api.toolchain}/v3/applications/:appName`,
        method: Method.GET,
      },
      addVersion: {
        url: `${options.api.toolchain}/v3/applications/:appName/versions`,
        method: Method.POST,
      },
      getDeploymentDev: {
        url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
        method: Method.GET,
      },
      getDevHardware: {
        url: `${options.api.dev}/v3/devices/:hwName`,
        method: Method.GET,
      },
      getQaHardware: {
        url: `${options.api.qa}/v3/devices/:hwName`,
        method: Method.GET,
      },
      pipelineBuildRequest: {
        url: `${options.api.toolchain}/v3/applications/:appName/versions/:versionId`,
        method: Method.PUT,
      },
      deleteVersion: {
        url: `${options.api.toolchain}/v3/applications/:appName/versions/:versionId`,
        method: Method.DELETE,
      },
      deleteDevDeployment: {
        url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
        method: Method.DELETE,
      },
      startDevQNXDeployment: {
        url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
        method: Method.PUT,
      },
      stopDevQNXDeployment: {
        url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
        method: Method.PUT,
      },
    },
  };
};

