import { Store } from '../../../core/models/classes/abstract.store';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { User } from '../../models/tenantUsers';
import { TenantUsersState } from './model/tenantUsersState';

@Injectable({
  providedIn: 'root',
})
export class TenantUsersStore extends Store<TenantUsersState> {
  constructor() {
    super({ tenantUsers: [], isLoading: true, hasError: false });
  }

  get tenantUsers$(): Observable<User[]> {
    return this.state$.pipe(map((state) => state.tenantUsers));
  }

  get isLoading$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.isLoading));
  }

  get hasError$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.hasError));
  }
}
