import {
  Component,
  OnDestroy,
  OnInit,
  signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { SelectMenuComponent } from 'src/app/shared/components/select-menu/select-menu.component';
import {
  DEVICE_SORT_KEYS,
  DEVICE_STATUS_KEYS,
  DeviceCreatorKey,
  DeviceSortEnum,
  DeviceSortKey,
  DeviceStatusKey,
} from '../../../../shared/utils/device/selectionKeys';
import { HdkDeviceListService } from './services/hdk-device-list/hdk-device-list.service';
import { FunctionBarComponent } from 'src/app/shared/components/function-bar/function-bar.component';
import { FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DeviceStore } from 'src/app/shared/stores/devices/devices.store';
import { DevicesState } from 'src/app/shared/stores/devices/models/devicesState';
import { ContentWrapperComponent } from 'src/app/shared/components/content-wrapper/content-wrapper.component';
import { DeviceListContentComponent } from './components/device-list-content/device-list-content.component';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { Option } from 'src/app/core/models/interfaces/option';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { HeaderTemplateComponent } from 'src/app/shared/components/header/header-template.component';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { DeviceType } from 'src/app/shared/stores/devices/models/deviceType';
import { EmptySectionScope } from 'src/app/shared/utils/emptySectionScope';
import { SnackbarService } from '../../../../core/services/snackbar/snackbar.service';
import { CaeButtonComponent } from '../../../../shared/components/cae-button/cae-button.component';
import { DialogService } from '../../../../core/services/dialog/dialog.service';
import { DeleteMultipleDevicesComponent } from './components/delete-multiple-devices/delete-multiple-devices.component';
import { DialogProps } from '../../../../shared/components/dialog/models/dialogProps';
import { DialogType } from '../../../../shared/components/dialog/models/dialogType';
import { SelectedHdkDeviceService } from './services/selected-hdk-device-service/selected-hdk-device.service';
import { HdkDeviceDeletionService } from './services/hdk-device-deletion/hdk-device-deletion.service';
import { HdkDeviceListApiService } from './services/hdk-device-list-api/hdk-device-list-api.service';
import {
  CreateDeviceDialogComponent,
  CreateDeviceRequestBody
} from './components/create-device-dialog/create-device-dialog.component';
import { UrlParameterStateService } from 'src/app/shared/services/url-parameter-state/url-parameter-state.service';
import { deviceStatusLabelMap } from 'src/app/shared/stores/devices/models/deviceStatusLabelMap';
import { DeviceStatus } from 'src/app/shared/stores/devices/models/deviceStatus';

export type DeviceListApi =
  | 'getDevDevices'
  | 'devDeviceActionRequest'
  | 'vHPCDeviceActionRequest'
  | 'createRealDevice'
  | 'deleteRealDevice'
  | 'deleteSimulatedDeviceDev'
  | 'getDevConnectionPackage'
  | 'getDevFwUpdates'
  | 'updateFwVersionDev'
  | 'getApplications'
  | 'getAppVersions'
  | 'createDevDeployment'
  | 'downloadDeviceScriptsDev'
  | 'downloadVhpcDeviceScripts'
  | 'createVhpcDevice'
  | 'deleteVhpcDevice'
  | 'getVhpcReleases'
  | 'downloadHwDeviceScript'
  | 'getPresignedUrlForAudio'
  | 'getPresignedUrlForHdkTools'
  | 'bulkDeleteRealDevices'
  | 'bulkDeleteVhpcDevices'
  | 'getGrafanaDashboard'
  | 'downloadVHPCInstaller';

@Component({
  standalone: true,
  selector: 'app-hdk-device-list',
  templateUrl: './hdk-device-list.component.html',
  styleUrls: ['./hdk-device-list.component.scss'],
  imports: [
    TranslateModule,
    SelectMenuComponent,
    FunctionBarComponent,
    CommonModule,
    ContentWrapperComponent,
    DeviceListContentComponent,
    HeaderTemplateComponent,
    MatButtonModule,
    CaeButtonComponent,
    DeleteMultipleDevicesComponent,
  ],
})
export class HdkDeviceListComponent
  extends FeatureComponent<DeviceListApi>
  implements OnInit, OnDestroy
{
  @ViewChild('deleteMultipleDevicesComponent')
  deleteMultipleDevicesComponent!: TemplateRef<DeleteMultipleDevicesComponent>;
  
  devicesData$: Observable<DevicesState>;
  DeviceType = DeviceType;
  checkActiveFiltersSignal = signal<boolean>(false);
  EmptySectionScope = EmptySectionScope;

  selectedDevices$ = this.selectedHdkDeviceService.devices$;
  config: DialogProps = {} as DialogProps;
  createDeviceApiRecords = {
    toolchain: this.API?.createRealDevice,
    vHPC: this.API?.createVhpcDevice,
  };
  searchFilterControl: FormControl = new FormControl('');
  private debounceTime: number = 400;
  defaultSearchText: string = '';

  searchFilter$: Observable<string> = this.getInitialSearchValue().pipe(
    map(() => this.defaultSearchText),
    tap((input) => this.updateUrlParams(input)),
    map((input) => this.setSearchFilterControlValue(input)),
  );
  filteredDevices$ = combineLatest([
    this.deviceStore.devices$,
    this.searchFilter$,
    this.hdkDeviceListService.selectedDeviceConnectionStatus$,
    this.hdkDeviceListService.selectedSortBy$,
    this.hdkDeviceListService.selectedDeviceCreator$,
  ]).pipe(map(this.hdkDeviceListService.deviceMap));
  deviceCreatorData$ = combineLatest([
    this.hdkDeviceListService.DeviceCreatorOptions$,
    this.hdkDeviceListService.selectedDeviceCreator$,
  ]).pipe(
    map(
      ([options, selectedOption]): {
        options: Option<DeviceCreatorKey>[];
        selectedOption: Option<DeviceCreatorKey>;
      } => ({ options, selectedOption }),
    ),
  );
  deviceConnectionStatusData$ = combineLatest([
    this.hdkDeviceListService.deviceConnectionStatusOptions$,
    this.hdkDeviceListService.selectedDeviceConnectionStatus$,
  ]).pipe(
    map(
      ([options, selectedOption]): {
        options: Option<DeviceStatusKey>[];
        selectedOption: Option<DeviceStatusKey>;
      } => ({ options, selectedOption }),
    ),
  );
  sortByData$ = combineLatest([
    this.hdkDeviceListService.sortByOptions$,
    this.hdkDeviceListService.selectedSortBy$,
  ]).pipe(
    map(
      ([options, selectedOption]): {
        options: Option<DeviceSortKey>[];
        selectedOption: Option<DeviceSortKey>;
      } => ({ options, selectedOption }),
    ),
  );
  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    private hdkDeviceListService: HdkDeviceListService,
    private deviceStore: DeviceStore,
    private hdkDeviceListApiService: HdkDeviceListApiService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private selectedHdkDeviceService: SelectedHdkDeviceService,
    private hdkDeviceDeletionService: HdkDeviceDeletionService,
    private translate: TranslateService,
    private urlParameterStateService: UrlParameterStateService,
  ) {
    super();
    this.syncStageFilterWithUrlParams();
    this.devicesData$ = this.combineDevicesData$(
      this.filteredDevices$,
      this.deviceStore.isLoading$,
      this.deviceStore.hasError$,
      this.deviceStore.errorStatusCode$,
    );
  }

  ngOnInit(): void {
    this.fetchDeviceList();
  }

  getInitialSearchValue(): Observable<any> {
    return this.searchFilterControl.valueChanges.pipe(
      tap((val) => (this.defaultSearchText = val)),
      startWith(this.defaultSearchText),
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
    );
  }

  syncStageFilterWithUrlParams(): void {
    this.urlParameterStateService.state$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        const creator: string = state['creator'];
        const status: string = state['status'];
        const sort: string = state['sort'];
        const search: string = state['search'];

        if (search && search !== '') {
          this.searchFilterControl.setValue(search);
          this.defaultSearchText = search;
          this.searchFilterControl.updateValueAndValidity();
        }

        if (status) {
          this.selectDeviceConnectionStatusOption({
            label: status,
            id:
              status === 'All'
                ? DEVICE_STATUS_KEYS.ALL
                : deviceStatusLabelMap[status as DeviceStatus],
          });
        }
        if (creator) {
          this.selectDeviceCreatorOption({
            label: creator,
            id: `DeviceList.FilterKeys.CreatedBy.${creator}` as DeviceCreatorKey,
          });
        }
        if (sort) {
          if (
            sort === DeviceSortEnum.ALPHABETICAL ||
            sort === DeviceSortEnum.DATE
          ) {
            this.selectSortOption({
              label: sort,
              id: `SortKeys.${sort}` as DeviceSortKey,
            });
          } else {
            this.selectSortOption({
              label: sort,
              id:
                sort === DeviceSortEnum.LAST_USED_DATE
                  ? DEVICE_SORT_KEYS.LAST_USED_DATE
                  : DEVICE_SORT_KEYS.SCHEDULED_DELETION_DATE,
            });
          }
        }
      });
  }

  selectDeviceCreatorOption(
    deviceCreatorOption: Option<DeviceCreatorKey>,
  ): void {
    this.hdkDeviceListService.selectedDeviceCreator(deviceCreatorOption);
    this.checkActiveFiltersSignal.set(
      this.hdkDeviceListService.checkActiveFilters(),
    );
    this.urlParameterStateService.updateParams({
      creator: deviceCreatorOption.label,
    });
  }

  selectDeviceConnectionStatusOption(
    deviceConnectionStatus: Option<DeviceStatusKey>,
  ): void {
    this.hdkDeviceListService.setSelectedDeviceConnectionStatus(
      deviceConnectionStatus,
    );
    this.checkActiveFiltersSignal.set(
      this.hdkDeviceListService.checkActiveFilters(),
    );
    this.urlParameterStateService.updateParams({
      status: deviceConnectionStatus.label.split('.').join(''),
    });
  }

  selectSortOption(option: Option<DeviceSortKey>): void {
    this.hdkDeviceListService.setSelectedSortBy(option);
    this.checkActiveFiltersSignal.set(
      this.hdkDeviceListService.checkActiveFilters(),
    );
    this.urlParameterStateService.updateParams({
      sort: option.label,
    });
  }

  combineDevicesData$(
    devices: Observable<Device[]>,
    isLoading: Observable<boolean>,
    hasError: Observable<boolean>,
    errorStatusCode: Observable<number | undefined>,
  ): Observable<DevicesState> {
    return combineLatest([devices, isLoading, hasError, errorStatusCode]).pipe(
      map(([devices, isLoading, hasError, errorStatusCode]) => {
        return { devices, isLoading, hasError, errorStatusCode };
      }),
    );
  }

  fetchDeviceList() {
    if (this.API) {
      this.hdkDeviceListApiService
        .getDevices(this.API.getDevDevices)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe();
    }
  }

  openCreateDeviceDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    dialogConfig.data = {
      deviceType: DeviceType.REAL,
      apiRecords: this.createDeviceApiRecords,
    };
    const dialogRef = this.dialog.open(
      CreateDeviceDialogComponent,
      dialogConfig,
    );
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((data: CreateDeviceRequestBody) => {
          if (!data) {
            return of(null);
          }
          return this.hdkDeviceListApiService.createDevice(
            data.deviceName,
            data.api,
            data.tokenAttribute,
          );
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (value) => {
          if (value) {
            this.snackbarService.notifyInfo(
              'DeviceList.CreateDialog.SuccessMessage',
            );
          }
        },
        error: () => {
          this.snackbarService.notifyError(
            'DeviceList.CreateDialog.ErrorMessage',
          );
        },
      });
  }

  handleResetFilters() {
    this.hdkDeviceListService.clearActiveFilters();
  }

  openBulkDeletionDialog() {
    this.config = {
      disableConfirmButton: false,
      type: DialogType.CUSTOM,
      title: this.translate.instant('DeviceList.DeleteDialog.RequestDeletion'),
      message: this.translate.instant('DeviceList.DeleteDialog.Message'),
      customButtonText: this.translate.instant('General.Delete'),
      width: '500px',
      onCustomButtonClick: () => this.handleDeviceDeletion(dialogRef),
      onCancel: () => this.selectedHdkDeviceService.clearDeleteDeviceResponse(),
    };

    const dialogRef = this.dialogService.openDialogRef(
      this.config,
      this.deleteMultipleDevicesComponent,
    );
  }

  handleDeviceDeletion(dialogRef: MatDialogRef<any>) {
    this.hdkDeviceDeletionService.handleDeviceDeletion(
      dialogRef,
      this.config,
      this.API,
    );
  }

  updateUrlParams(input: string): void {
    this.urlParameterStateService.updateParams({
      search: input,
    });
  }
  setSearchFilterControlValue(input: any): any {
    this.searchFilterControl.setValue(input);
    return input;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
