<div class="project-user-container" data-cy="user-list-content">
  <div class="project-user-data-container">
    <p class="project-user-name">{{ fullName() }}</p>
    <p class="project-user-email">{{ user.email }}</p>
  </div>
  <div class="project-user-roles">
    @for (serviceRole of serviceRoles; track $index) {
      <app-chip
        data-cy="user-service-role-chip"
        label="{{serviceRole}}"
        [variant]="ChipVariant.INFO"
      />
    }
  </div>
  @if (featureDisplayProjectTeam ) {
    <button data-cy="user-list-menu" class="popup-menu" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  }
</div>
<mat-menu class="caedge-menu" #menu="matMenu">
  <!-- disabled for tenant user at the moment -->
  <button
    class="caedge-menu-item"
    data-cy="remove-user-btn"
    mat-menu-item
    [disabled]="!isProjectUser(user)"
    (click)="removeUserFromProject.emit(user)"
  >
    {{ "Project.ProjectUsers.DeleteUser" | translate }}
  </button>
  <!-- disabled for tenant user at the moment -->
  <button
    class="caedge-menu-item"
    data-cy="edit-user-role-btn"
    mat-menu-item
    [disabled]="!isProjectUser(user)"
    (click)="editUserRole.emit(user)"
  >
    {{ "Project.ProjectUsers.EditUserRole" | translate }}
  </button>
</mat-menu>
