import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { GenericStateMatcher } from 'src/app/shared/utils/genericStateMatcher';
import { DeviceType } from 'src/app/shared/stores/devices/models/deviceType';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { AuthStore } from 'src/app/shared/stores/auth/auth.store';
import { JwtDecoder } from 'src/app/shared/utils/jwtDecoder';
import { nameRestrictor, nameRestrictorWords, devicePattern } from 'src/app/shared/utils/name-restriction-validator/name-restriction-validator';
import { PopoverService } from 'src/app/core/services/popover/popover.service';
import { ResourceRestrictionHelperService } from 'src/app/shared/utils/resource-restriction-helper-service/resource-restriction-helper.service';
import { MatFormField } from '@angular/material/form-field';
import { RestrictedInputData } from 'src/app/shared/components/restricted-input-display/models/restricted-input-data';
import { RestrictedInputDisplayComponent } from 'src/app/shared/components/restricted-input-display/restricted-input-display.component';
import {
  Language,
  Languages,
  LanguageService,
} from 'src/app/core/services/language/language.service';
import { ConnectedPosition } from '@angular/cdk/overlay';

export interface CreateDeviceDialogData {
  deviceType: DeviceType;
  apiRecords: Record<'simulated' | 'toolchain' | 'vHPC', ApiRecord>;
}

export interface CreateDeviceRequestBody {
  deviceName: string;
  api: ApiRecord;
  tokenAttribute: any;
}

@Component({
  selector: 'app-create-device-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './create-device-dialog.component.html',
  styleUrls: ['./create-device-dialog.component.scss'],
})
export class CreateDeviceDialogComponent implements OnDestroy {
  createDeviceFormGroup: FormGroup = new FormGroup({
    deviceName: new FormControl('', [
      Validators.required,
      //Only lower case letters, numbers and "-" allowed, but the first char can't be a number
      Validators.pattern(/^[a-z]+[a-z0-9-]*$/),
      nameRestrictor,
    ]),
  });

  DeviceType = DeviceType;
  errorMatcher = new GenericStateMatcher();
  decoder = new JwtDecoder();

  @ViewChild('deviceNameField', { read: ElementRef<MatFormField> })
  deviceNameFieldRef!: ElementRef<MatFormField>;
  private readonly destroy$: Subject<void> = new Subject();

  jwtToken = this.authStore.state.tokens?.accessToken;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateDeviceDialogData,
    private authStore: AuthStore,
    private popoverService: PopoverService,
    private resourceRestrictionHelperService: ResourceRestrictionHelperService,
    private languageService: LanguageService,
    public dialogRef: MatDialogRef<CreateDeviceDialogComponent>,
  ) {}

  displayRestrictor(fieldRef: ElementRef<MatFormField>): void {
    const selectedLanguage: Language =
      this.languageService.getCurrentLanguageValue();
    let positionConfig: ConnectedPosition = {
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'bottom',
    };

    if (selectedLanguage === Languages.en) {
      positionConfig.offsetX = 310;
      positionConfig.offsetY = 288;
    } else {
      positionConfig.offsetX = 334;
      positionConfig.offsetY = 275;
    }

    this.updateDisplayRestrictorData();
    this.createDeviceFormGroup
      .get('deviceName')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateDisplayRestrictorData();
      });
    this.popoverService.open<RestrictedInputDisplayComponent>(
      fieldRef,
      RestrictedInputDisplayComponent,
      positionConfig,
    );
  }

  private updateDisplayRestrictorData(): void {
    const displayRestrictionData: RestrictedInputData = {
      fieldValue: this.createDeviceFormGroup.get('deviceName')?.value,
      hasDevicePatternFailed: this.createDeviceFormGroup
        .get('deviceName')
        ?.hasError('pattern'),
      hasNameRestrictionFailed: this.createDeviceFormGroup
        .get('deviceName')
        ?.hasError('nameRestrictorError'),
      nameRestrictor: nameRestrictorWords(
        this.createDeviceFormGroup.get('deviceName')!,
      ),
      devicePattern: devicePattern(
        this.createDeviceFormGroup.get('deviceName')?.value,
      ),
    };
    this.resourceRestrictionHelperService.updateData(displayRestrictionData);
  }

  get deviceName(): string {
    return this.createDeviceFormGroup.get('deviceName')?.value;
  }

  get titleKey() {
    let dialogTitle = 'DeviceList.CreateDialog.';

    switch (this.data.deviceType) {
      case DeviceType.REAL:
        dialogTitle += 'TitleReal';
        break;
      case DeviceType.VHPC:
        dialogTitle += 'TitleVECU';
        break;
    }

    return dialogTitle;
  }

  canSubmit() {
    return this.createDeviceFormGroup.valid;
  }

  private apiRecordSelector(deviceType: DeviceType): ApiRecord {
    switch (deviceType) {
      case DeviceType.REAL:
        return this.data.apiRecords.toolchain;
      case DeviceType.VHPC:
        return this.data.apiRecords.vHPC;
      default:
        return this.data.apiRecords.vHPC;
    }
  }

  handleSubmitClick() {
    if (!this.jwtToken) {
      return;
    }

    const tokenSubAttribute = this.decoder.decodeJwt(this.jwtToken).sub;
    const requestBody: CreateDeviceRequestBody = {
      deviceName: this.deviceName,
      api: this.apiRecordSelector(this.data.deviceType),
      tokenAttribute: tokenSubAttribute,
    };
    this.dialogRef.close(requestBody);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
