import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  signal,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceType } from '../../../../../../shared/stores/devices/models/deviceType';
import {
  ChipComponent,
  ChipVariant,
} from 'src/app/shared/components/chip/chip.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { Device } from '../../../../../../shared/stores/devices/models/device/device';
import {
  Deployment,
  DeploymentStatus,
  DeploymentStatusMap,
} from '../../../../../../shared/stores/deployment/models/deployment';
import { DeviceConnectionStatus } from '../../../../../../shared/stores/devices/models/deviceConnectionStatus';
import { IAllEnvironment } from '../deployment-list-expansion-panel/deployment-list-expansion-panel.component';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { DeploymentListApi } from '../../deployment-list.component';
import { DeploymentService } from 'src/app/core/services/deployment-list/deployment.service';
import { Subject, takeUntil } from 'rxjs';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { Version } from 'src/app/shared/stores/deployment/models/version';
import { getDeviceTypeTooltipKey } from 'src/app/shared/utils/device/helpers';
import { ApplicationType } from '../../../../../../shared/stores/applications/models/applicationType';
import { DeploymentErrorStatusCheckPipe } from 'src/app/shared/pipes/deployment-error-status.pipe';
import { DeleteDeploymentDialogComponent } from '../delete-deployment-dialog/delete-deployment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CopyToClipboardService } from '../../../../../../shared/utils/copy-to-clipboard.service';
import { DeploymentActionData } from '../../../../../../shared/models/deployment';
import { RoutingStore } from 'src/app/shared/stores/config/routing.store';

@Component({
  selector: 'app-hdk-list-item',
  standalone: true,
  imports: [
    CommonModule,
    ChipComponent,
    TranslateModule,
    MatTooltipModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    DeploymentErrorStatusCheckPipe,
  ],
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent
  extends FeatureComponent<DeploymentListApi>
  implements OnInit, OnChanges, OnDestroy {
  @Input() device: Device = {} as Device;
  @Input() deployments: IAllEnvironment<Deployment[]> = {
    dev: [],
  };
  @Input() version!: Version;
  @Input() appName: string = '';
  readonly DeploymentStatus = DeploymentStatus;
  dateFormat: string = 'dd-MM-yy, hh:MM';
  deploymentStatusTooltip: string = '';
  deploymentStatus: DeploymentStatus = DeploymentStatus.DELETION_IN_PROGRESS;
  DeviceConnectionStatus = DeviceConnectionStatus;
  isAndroidOrQnxOrLinuxSignal = signal<boolean>(false);
  artifactIdTooltip = '';
  deviceLabel = '';
  protected readonly deviceConnectionStatus = DeviceConnectionStatus;
  protected readonly ChipVariant = ChipVariant;
  private readonly unsubscribe$ = new Subject<void>();
  private readonly emptyDeployment: Deployment = {
    hwTarget: '',
    deploymentStatus: DeploymentStatus.UNKNOWN,
    deploymentDetail: '',
    commitId: '',
    versionId: '',
    partitionName: '',
  };
  currentDeployment = this.emptyDeployment;

  constructor(
    private translate: TranslateService,
    private deploymentService: DeploymentService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private copyToClipboardService: CopyToClipboardService,
    private translateService: TranslateService,
    private routingStore: RoutingStore,
  ) {
    super();
  }

  get deviceTypeTooltipKey() {
    return getDeviceTypeTooltipKey(this.device);
  }

  ngOnInit(): void {
    this.deploymentStatusTooltip = this.getDeploymentStatus(this.device);
    this.checkIfAndroidOrQnxOrLinuxApplication();

    this.createArtifactTooltip();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['device'] && changes['device'].currentValue) {
      this.device = changes['device'].currentValue;
      this.currentDeployment = this.getCurrentDeployment();
      this.deviceLabel = this.getDeviceTypeLabel(this.device);
    }

    if (changes['deployments'] && changes['deployments'].currentValue) {
      this.getDeploymentStatus(this.device);
    }
  }

  checkIfAndroidOrQnxOrLinuxApplication() {
    const applicationType = localStorage.getItem('selectedApplicationType');

    if (
      applicationType === ApplicationType.QNX ||
      applicationType === ApplicationType.ANDROID ||
      applicationType === ApplicationType.LINUX_NATIVE
    ) {
      this.isAndroidOrQnxOrLinuxSignal.set(true);
    } else {
      this.isAndroidOrQnxOrLinuxSignal.set(false);
    }
  }

  isDeviceConnected(device: Device): boolean {
    return device.instanceConnectionStatus === DeviceConnectionStatus.CONNECTED;
  }

  getDeploymentStatus(hardwareDevice: Device): string {
    const dev = this.deployments.dev.length > 0;
    const hardware = hardwareDevice.environments?.length > 0;

    if (!dev && !hardware) {
      return DeploymentStatusMap[DeploymentStatus.UNKNOWN];
    }

    const deployments = this.deployments.dev;
    const deployment = deployments.find(
      (deployment) => deployment.hwTarget === hardwareDevice.deviceId,
    );
    const status = deployment
      ? deployment.deploymentStatus
      : DeploymentStatus.DEPLOYMENT_IN_CREATION;

    const translationKey = `Deployments.DeploymentStatus.${status}`;
    const translation = this.translate.instant(translationKey);

    this.deploymentStatus = status;
    return translation;
  }

  getDeviceTypeLabel(device: Device): string {
    switch (device.instanceType) {
      case DeviceType.REAL:
        return 'REAL';
      case DeviceType.VHPC:
        return 'vECU';
      default:
        return this.translate.instant(
          'DeviceList.FilterKeys.ConnectionStatus.Unknown',
        );
    }
  }

  openDeleteDeploymentDialog() {
    this.dialog.open(DeleteDeploymentDialogComponent, {
      width: '400px',
      data: {
        isForcedDeletion: false,
        hwName: this.device.deviceId,
        deleteDeployment: this.API?.deleteDevDeployment!,
        appName: this.appName,
        versionId: this.version.versionId,
        partitionName: this.currentDeployment.partitionName,
      },
    });
  }

  startQNXDeployment() {
    if (this.API) {
      this.deploymentService
        .startQNXDeployment(this.getCurrentDeploymentData())
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.snackbarService.notifyInfo(
              this.translateService.instant('Deployments.Start'),
            );
          },
          error: () => {
            this.snackbarService.notifyError(
              this.translateService.instant('Deployments.StartFailed'),
            );
          },
        });
    }
  }

  stopQNXDeployment() {
    if (this.API) {
      this.deploymentService
        .stopQNXDeployment(this.getCurrentDeploymentData())
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.snackbarService.notifyInfo(
              this.translateService.instant('Deployments.Stop'),
            );
          },
          error: () => {
            this.snackbarService.notifyError(
              this.translateService.instant('Deployments.StopFailed'),
            );
          },
        });
    }
  }

  private getCurrentDeploymentData(): DeploymentActionData {
    return {
      appName: this.appName,
      versionId: this.version.versionId,
      deviceName: this.device.deviceId,
      partitionName: this.currentDeployment.partitionName,
      apiRecord: this.API?.stopDevQNXDeployment!,
    };
  }

  copyToClipboard(value: string) {
    this.copyToClipboardService.copyToClipboard(value);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getCurrentDeployment(): Deployment {
    const deployments = this.deployments.dev;
    const deployment = deployments.find(
      (deployment) => deployment.hwTarget === this.device.deviceId,
    );
    return deployment ? deployment : this.emptyDeployment;
  }

  /**
   * Depending on the current app type of this deployment the tooltip text will be generated.
   * The tooltip text follows this schema Artifact id: <commit_id> <description>
   */
  private createArtifactTooltip() {
    const app_type = localStorage.getItem('selectedApplicationType');

    this.artifactIdTooltip =
      'Artifact id: ' + this.currentDeployment.commitId + ' ';

    if (app_type !== null) {
      switch (app_type) {
        case ApplicationType.LINUX_NATIVE:
        case ApplicationType.SINGLE_SERVICE:
          this.artifactIdTooltip += this.translate.instant(
            'Deployments.ArtifactID_Tooltips.Github',
          );
          break;

        case ApplicationType.ANDROID:
        case ApplicationType.CLASSIC_AUTOSAR_IMAGE:
          this.artifactIdTooltip += this.translate.instant(
            'Deployments.ArtifactID_Tooltips.Artifactory',
          );
          break;

        case ApplicationType.QNX:
          this.artifactIdTooltip += this.translate.instant(
            'Deployments.ArtifactID_Tooltips.Conan_QNX',
          );
          break;
      }
    }
  }

  navigateToDevice(deviceId: string) {
    this.routingStore.navigateToHdkDeviceDetails(deviceId);
  }
}
