import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule, Location } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreadcrumbsStore } from '../../stores/config/breadcrumbs.store';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-header-template',
  templateUrl: './header-template.component.html',
  styleUrls: ['./header-template.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
  ],
})
export class HeaderTemplateComponent {
  @Input() translationKey?: string;
  @Input() itemName?: string;
  @Input() showBackButton: boolean = true;
  @Input() backUrl = '';
  @Input() useBrowserHistory: boolean = false;
  @Input() description?: string = '';
  @Input() isRowDisplayed = false;

  breadcrumbs$ = this.breadcrumbsStore.breadcrumbs$;
  constructor(
    private router: Router,
    private breadcrumbsStore: BreadcrumbsStore,
    private location: Location,
  ) {}

  goBack(url: string): void {
    if (this.useBrowserHistory) {
      this.location.back();
    } else {
      this.router.navigate([url]);
    }
  }
}
