import { DeploymentListComponent } from '../components/deployment-list/deployment-list.component';
import { DeviceListItemDetailsComponent } from '../components/hdk-device-list/components/device-list-item-details/device-list-item-details/device-list-item-details.component';
import { HdkDeviceListComponent } from '../components/hdk-device-list/hdk-device-list.component';

export enum FeatureId {
    HDK_DEVICE_LIST_PAGE = 'HDK_DEVICE_LIST_PAGE',
    HDK_DEVICE_DETAILS = 'HDK_DEVICE_DETAILS',
    HDK_DEPLOYMENTS_LIST_PAGE = 'HDK_DEPLOYMENTS_LIST_PAGE',
};

export const hdkComponentMap: Partial<Record<FeatureId, any>> = {
    [FeatureId.HDK_DEVICE_LIST_PAGE]: HdkDeviceListComponent,
    [FeatureId.HDK_DEVICE_DETAILS]: DeviceListItemDetailsComponent,
    [FeatureId.HDK_DEPLOYMENTS_LIST_PAGE]: DeploymentListComponent,
};
