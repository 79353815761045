import { Injectable } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { Version } from '../../../shared/stores/deployment/models/version';
import { State } from '../../../shared/stores/State';

@Injectable({
  providedIn: 'root',
})
export class DeploymentSearchService {

  constructor() { }

  combineVersionData$(
    filteredVersions$: Observable<Version[]>,
    isLoading: Observable<boolean>,
    hasError: Observable<boolean>
  ): Observable<State<Version[]>> {
    return combineLatest([filteredVersions$, isLoading, hasError]).pipe(
      map(([versions, isLoading, hasError]) => {
        return { data: versions, isLoading, hasError };
      })
    );
  }

  versionMap = ([versions, searchFilter]: [Version[], string]) => {
    return versions.filter((version) => {
      const id = version.versionId.toLowerCase();
      const search = searchFilter.toLowerCase();
      const branchName = version.versionReference.toLowerCase();
      return (
        id.includes(search) ||
        branchName.includes(search)
      );
    });
  };
}
