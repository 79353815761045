import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StageNumber } from '../../services/add-stage-playlist-dialog.service';

@Component({
  selector: 'app-add-stage-playlist-footer',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule, TranslateModule],
  templateUrl: './add-stage-playlist-footer.component.html',
  styleUrl: './add-stage-playlist-footer.component.scss'
})
export class AddStagePlaylistFooterComponent {
  @Input() enableSubmitButton: boolean = false;
  @Input() enableNextButton: boolean = false;
  @Input() stageNumber: StageNumber = StageNumber.STAGE_ONE;

  @Output() next: EventEmitter<number> = new EventEmitter();
  @Output() previous: EventEmitter<number> = new EventEmitter();
  @Output() submitValues: EventEmitter<boolean> = new EventEmitter();
  StageNumber = StageNumber;

  public currentStepIndex = 0;
  public steps: { title: string }[] = [
    {
      title: this.translate.instant(
        'Project.AddProjectDialog.GeneralInformation',
      ),
    },
    {
      title: this.translate.instant(
        'Project.AddProjectDialog.ContactInformation',
      ),
    },
  ];

  constructor(private translate: TranslateService) {}

  prevStep() {
    if (this.isFirstStep()) return;
    this.currentStepIndex--;
    this.previous.emit(this.currentStepIndex);
  }

  /**
   * Checks if the first step is active.
   *
   * @return true if the first step is active, otherwise false.
   */
  isFirstStep(): boolean {
    return this.currentStepIndex === 0;
  }

  /**
   * Checks if the last step is active.
   *
   * @return true if the last step is active, otherwise false.
   */
  isLastStep(): boolean {
    return this.currentStepIndex === this.steps.length - 1;
  }

  nextStep() {
    if (this.isLastStep()) {
      return;
    }
    this.currentStepIndex++;
    this.next.emit(this.currentStepIndex);
  }

  submitForm() {
    this.submitValues.emit();
  }

  isValid() {
    return this.enableSubmitButton;
  }
}
