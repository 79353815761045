import { Component, Input } from '@angular/core';
import { NgForOf } from '@angular/common';
import { DeploymentListExpansionPanelComponent } from '../deployment-list-expansion-panel/deployment-list-expansion-panel.component';
import { Version } from '../../../../../../shared/stores/deployment/models/version';
import { MatExpansionModule } from '@angular/material/expansion';
import { EmptySectionComponent } from '../../../../../../shared/components/empty-section/empty-section.component';
import { TranslateModule } from '@ngx-translate/core';
import { Application } from 'src/app/shared/stores/deployment/models/application';
import { EmptySectionIcon } from 'src/app/shared/components/empty-section/models/empty-section';
import { EmptySectionScope } from 'src/app/shared/utils/emptySectionScope';

@Component({
  standalone: true,
  selector: 'app-deployment-list-content',
  templateUrl: './deployment-list-content.component.html',
  styleUrls: ['./deployment-list-content.component.scss'],
  imports: [
    DeploymentListExpansionPanelComponent,
    NgForOf,
    MatExpansionModule,
    EmptySectionComponent,
    TranslateModule,
  ],
})
export class DeploymentListContentComponent {
  @Input() versions: Version[] = [];
  @Input({ required: true }) application!: Application;

  EmptySectionIcon = EmptySectionIcon;
  EmptySectionScope = EmptySectionScope;

  constructor() {}
  get isEmpty() {
    return !this.versions.length;
  }

  onPropertyChanges(index: number, item: Version) {
    return `${item.versionId}${item.executionStatus}${item.lastExecutionDate}`;
  }
}
