import { Pipe, PipeTransform } from '@angular/core';
import { DeploymentStatus } from 'src/app/shared/stores/deployment/models/deployment';

@Pipe({
  name: 'deploymentErrorStatusCheck',
  standalone: true
})
export class DeploymentErrorStatusCheckPipe implements PipeTransform {
  transform(deploymentStatus: DeploymentStatus): boolean {
    return (
      deploymentStatus === DeploymentStatus.DELETION_FAILED ||
      deploymentStatus === DeploymentStatus.DELETION_NOT_POSSIBLE ||
      deploymentStatus === DeploymentStatus.DEPLOYMENT_EXECUTION_CANCELED ||
      deploymentStatus === DeploymentStatus.DEPLOYMENT_EXECUTION_FAILED ||
      deploymentStatus === DeploymentStatus.DEPLOYMENT_EXECUTION_REJECTED ||
      deploymentStatus === DeploymentStatus.DEPLOYMENT_EXECUTION_REMOVED ||
      deploymentStatus === DeploymentStatus.DEPLOYMENT_EXECUTION_TIMED_OUT ||
      deploymentStatus === DeploymentStatus.DEPLOYMENT_NOT_POSSIBLE
    );
  }
}
