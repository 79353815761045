import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { Application } from '../deployment/models/application';
import { ApplicationState } from './models/applicationState';
import { Version } from '../deployment/models/version';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStore extends Store<ApplicationState> {
  constructor() {
    super({
      application: new Application(),
      versions: [],
      isApplicationLoading: true,
      isVersionsLoading: true,
      hasError: false,
    });
  }

  get application$(): Observable<Application> {
    return this.state$.pipe(map((state) => state.application));
  }

  get versions$(): Observable<Version[]> {
    return this.state$.pipe(map((state) => state.versions));
  }

  get isLoading$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.isApplicationLoading || state.isVersionsLoading));
  }
  
  get hasError$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.hasError));
  }
}
