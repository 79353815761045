import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  signal,
  SimpleChanges,
} from '@angular/core';
import {
  getDeviceTypeTooltipKey,
  hasDeviceFwUpdateFailed,
  isAddDeploymentCheckDisabled,
  isDeviceBeingCreated,
} from '../../utils/helpers';
import { MatButtonModule } from '@angular/material/button';
import {
  ChipComponent,
  ChipVariant,
} from 'src/app/shared/components/chip/chip.component';
import { DeviceType } from 'src/app/shared/stores/devices/models/deviceType';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { DialogType } from 'src/app/shared/components/dialog/models/dialogType';
import { DeviceListApiService } from '../../services/device-list-api/device-list-api.service';
import { Subject, map, take, takeUntil } from 'rxjs';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { DeviceInstanceStatus } from 'src/app/shared/stores/devices/models/deviceInstanceStatus';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { Method } from 'src/app/shared/stores/config/models/method';
import { DeviceInstanceState } from 'src/app/shared/stores/devices/models/deviceInstanceState';
import { StartDeploymentService } from 'src/app/core/services/start-deployment/start-deployment.service';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { DeviceListApi } from '../../device-list.component';
import { StartDeploymentStore } from 'src/app/shared/stores/start-deployment/start-deployment.store';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { SimulatedActionButtonsComponent } from './components/simulated-action-buttons/simulated-action-buttons.component';

import { FwUpdatesActionsComponent } from './components/fw-updates-actions/fw-updates-actions.component';
import { RoutingStore } from 'src/app/shared/stores/config/routing.store';
import { FileService } from 'src/app/core/services/file/file.service';
import { DeviceConnectionStatus } from 'src/app/shared/stores/devices/models/deviceConnectionStatus';
import { AuthStore } from 'src/app/shared/stores/auth/auth.store';
import { TruncatePipe } from 'src/app/core/pipes/truncate.pipe';
import { DeviceFwStatus } from 'src/app/shared/stores/devices/models/deviceFwStatus';
import { generatePath } from 'src/app/shared/utils/generatePath';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { CustomFormatDatePipe } from 'src/app/core/pipes/custom-format-date.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectedDeviceService } from 'src/app/features/device-list/services/selected-device/selected-device.service';
import { deviceStatusLabelMap } from 'src/app/shared/stores/devices/models/deviceStatusLabelMap';
import { Quota } from 'src/app/core/models/interfaces/quota';

@Component({
  standalone: true,
  selector: 'app-device-list-item',
  templateUrl: './device-list-item.component.html',
  styleUrls: ['./device-list-item.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    ChipComponent,
    MatExpansionModule,
    CustomFormatDatePipe,
    TranslateModule,
    MatTooltipModule,
    CommonModule,
    SimulatedActionButtonsComponent,
    FwUpdatesActionsComponent,
    TruncatePipe,
    MatCheckboxModule,
  ],
})
export class DeviceListItemComponent
  extends FeatureComponent<DeviceListApi>
  implements OnInit, OnDestroy, OnChanges
{
  @Input() device!: Device;
  @Input() isInactive: boolean = false;
  @Input() quota: Quota = {
    isLimited: false,
    projectQuotaMinutes: 0,
    userQuotaMinutes: 0,
    remainingUserQuotaPercentage: 0,
    isQuotaExceeded: false,
  };

  ChipVariant = ChipVariant;
  DeviceFwStatus = DeviceFwStatus;
  private readonly unsubscribe$: Subject<void> = new Subject();

  appName = '';
  deviceTypeTooltipSignal = signal<string>('');
  deviceStatusChipLabelSignal = signal<string>('');
  isAddDeploymentDisabledSignal = signal<boolean>(false);
  isDeviceStoppingSignal = signal<boolean>(false);
  isBeingDeletedSignal = signal<boolean>(false);
  isBeingCreatedSignal = signal<boolean>(false);
  isConnectingSignal = signal<boolean>(false);
  isDeviceSelectedSignal = signal<boolean>(false);

  runningDeviceLabel = '';

  userFullName$ = this.authStore.userFullName$;
  userId$ = this.authStore.userId$;

  deviceActionApiConfigMap: Record<'Simulated' | 'Vhpc', ApiRecord> | undefined;
  deleteDeviceApiConfigMap:
    | Record<'Simulated' | 'Toolchain' | 'Vhpc', ApiRecord>
    | undefined;
  getConnectionPackageApiConfigMap: Record<'Simulated', ApiRecord> | undefined;
  getVersionsApiRecordsMap: Record<'Simulated', ApiRecord> | undefined;
  updateFwVersionsApiRecordsMap: Record<'Simulated', ApiRecord> | undefined;

  downloadScriptsApiRecordsMap: Partial<Record<DeviceType, ApiRecord>> = {
    [DeviceType.VHPC]: this.API?.downloadVhpcDeviceScripts,
    [DeviceType.REAL]: this.API?.downloadHwDeviceScript,
  };

  readonly DeviceInstanceStatus = DeviceInstanceStatus;
  readonly DeviceType = DeviceType;
  readonly DeviceConnectionStatus = DeviceConnectionStatus;

  constructor(
    private dialogService: DialogService,
    private deviceListApiService: DeviceListApiService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private startDeploymentService: StartDeploymentService,
    private startDeploymentStore: StartDeploymentStore,
    private routingStore: RoutingStore,
    private fileService: FileService,
    private authStore: AuthStore,
    private selectedDeviceService: SelectedDeviceService,
  ) {
    super();
    if (this.API) {
      this.deviceActionApiConfigMap = {
        Simulated: this.API.devDeviceActionRequest,
        Vhpc: this.API.vHPCDeviceActionRequest,
      };

      this.deleteDeviceApiConfigMap = {
        Toolchain: this.API.deleteRealDevice,
        Vhpc: this.API.deleteVhpcDevice,
        Simulated: this.API.deleteSimulatedDeviceDev,
      };

      this.getConnectionPackageApiConfigMap = {
        Simulated: this.API.getDevConnectionPackage,
      };

      this.getVersionsApiRecordsMap = {
        Simulated: this.API.getDevFwUpdates,
      };

      this.updateFwVersionsApiRecordsMap = {
        Simulated: this.API.updateFwVersionDev,
      };
    }
  }

  ngOnInit(): void {
    getDeviceTypeTooltipKey(this.device);
    this.isDeviceSelectedSignal.set(
      this.selectedDeviceService.isSelected(this.device),
    );
    this.routingStore.state$
      .pipe(
        take(1),
        map((state) => state.applicationId),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((appName) => (this.appName = appName));
  }

  selectDeviceToDelete(device: Device): void {
    this.selectedDeviceService.selectDevice(device);
    this.isDeviceSelectedSignal.set(
      this.selectedDeviceService.isSelected(device),
    );
  }

  getDeviceEnvLabel(device: Device) {
    if (device.connectedEnvironments.length !== 0) {
      return device.connectedEnvironments
        .map((env) => {
          return `DeviceList.FilterKeys.EnvironmentShort.${env}`;
        })
        .join('/');
    }
    return '';
  }

  getRunningDeploymentsLabel(device: Device): string {
    return device.numOfDeployments > 1
      ? this.translate.instant('DeviceList.RunningDeployments')
      : this.translate.instant('DeviceList.RunningDeployment');
  }

  getDeviceTypeLabel(device: Device) {
    switch (device.instanceType) {
      case DeviceType.REAL:
        return 'REAL';
      case DeviceType.VHPC:
        return 'vECU';
      default:
        return 'unknown device';
    }
  }

  getDeleteDeviceApiRecord(device: Device): ApiRecord {
    if (!this.deleteDeviceApiConfigMap) {
      return {
        url: '',
        method: Method.DELETE,
      };
    }
    switch (device.instanceType) {
      case DeviceType.REAL:
        return this.deleteDeviceApiConfigMap.Toolchain;
      case DeviceType.VHPC:
        return this.deleteDeviceApiConfigMap.Vhpc;
      default:
        return this.deleteDeviceApiConfigMap.Vhpc;
    }
  }

  deleteDeviceClick(device: Device) {
    this.deviceListApiService
      .deleteDevice(device.deviceId, this.getDeleteDeviceApiRecord(device))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.snackbarService.notifyInfo(
            'DeviceList.DeleteDialog.DeletionInitiated',
          );
        },
        error: () => {
          this.snackbarService.notifyError(
            'DeviceList.DeleteDialog.DeletionFailed',
          );
        },
      });
  }

  downloadConnectionPackage() {
    if (!this.API) {
      return;
    }

    this.deviceListApiService.downloadConnectionPackage(
      this.unsubscribe$,
      this.device.deviceId,
      this.API.getDevConnectionPackage,
    );
  }

  openDeleteDeviceDialog() {
    this.dialogService.openDialog({
      type: DialogType.CONFIRM,
      title: this.translate.instant('DeviceList.DeleteDialog.Title'),
      message: this.translate.instant('DeviceList.DeleteDialog.Text'),
      confirmText: this.translate.instant('DeviceList.DeleteDialog.Confirm'),
      width: '400px',
      onConfirm: () => this.deleteDeviceClick(this.device),
    });
  }

  downloadDeviceZipFile(device: Device): void {
    const fileNameOffSet = 1;
    const downloadRecord: ApiRecord | undefined =
      this.downloadScriptsApiRecordsMap[device.instanceType];

    if (!this.API) {
      return;
    }

    if (!downloadRecord) {
      return;
    }
    this.snackbarService.notifyInfo(
      this.translate.instant('DeviceList.Downloading'),
    );
    this.deviceListApiService
      .getDeviceScripts(this.device.deviceId, downloadRecord)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data) => {
          const fileName: string = data.headers['Content-Disposition'];
          this.fileService.downloadBlob(
            this.fileService.b64toBlob(data.body, data.headers['Content-Type']),
            fileName.substring(
              fileName.indexOf('"') + fileNameOffSet,
              fileName.lastIndexOf('"'),
            ),
          );
          this.snackbarService.notifySuccess(
            this.translate.instant('DeviceList.DownloadSuccess'),
          );
        },
        error: () => {
          this.snackbarService.notifyError(
            this.translate.instant('DeviceList.DownloadFailed'),
          );
        },
      });
  }

  downloadAudio(): void {
    if (!this.API) {
      return;
    }
    this.deviceListApiService
      .getPresignedUrlForDownload(
        this.device.deviceId,
        this.API?.getPresignedUrlForAudio,
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data) => this.downloadAudioFileFromUrl(data.presignedURL),
        error: (presignedUrlError: HttpErrorResponse) =>
          this.handlePresignedUrlError(presignedUrlError),
      });
  }

  private handlePresignedUrlError(presignedUrlError: HttpErrorResponse): void {
    if (presignedUrlError.status === HttpStatusCode.NotFound) {
      this.snackbarService.notifyError(
        this.translate.instant('DeviceList.NoFileFound'),
      );
    } else {
      this.snackbarService.notifyError(
        this.translate.instant('DeviceList.DownloadFailed'),
      );
    }
  }

  private downloadAudioFileFromUrl(audioFileUrl: string) {
    if (audioFileUrl === 'none') {
      this.snackbarService.notifyError(
        this.translate.instant('DeviceList.NoFileFound'),
      );
    } else {
      this.snackbarService.notifyInfo(
        this.translate.instant('DeviceList.Downloading'),
      );
      this.fileService.downloadFile(audioFileUrl, 'android-debug-file-name');
      this.snackbarService.notifySuccess(
        this.translate.instant('DeviceList.DownloadSuccess'),
      );
    }
  }

  openUrlInNewTab(url: string) {
    window.open(url, '_blank');
  }

  showDashboard(device: Device): void {
    if (!this.API) return;

    this.openUrlInNewTab(
      generatePath(this.API.getGrafanaDashboard.url, {
        deviceName: device.deviceId,
      }),
    );
  }

  startDeploymentClick(): void {
    if (!this.API) return;
    this.startDeploymentService.openStartDeploymentDialog(
      this.appName,
      {
        createDevDeployment: this.API.createDevDeployment,
        getApplications: this.API.getApplications,
        getVersions: this.API.getAppVersions,
        getDevDevices: this.API.getDevDevices,
      },
      {
        device: this.device,
      },
      this.unsubscribe$,
    );
  }

  getFwChipVariant(): ChipVariant {
    if (this.hasUpdateFailed) {
      return ChipVariant.FW_ERROR;
    } else return ChipVariant.NEUTRAL_WHITE;
  }

  get isConnecting(): boolean {
    return (
      this.device.instanceConnectionStatus === DeviceConnectionStatus.CONNECTING
    );
  }

  get isStopping(): boolean {
    return this.device.instanceState === DeviceInstanceState.STOPPING;
  }

  get isBeingDeleted(): boolean {
    return (
      this.device.instanceStatus === DeviceInstanceStatus.DELETE_IN_PROGRESS
    );
  }

  get hasUpdateFailed(): boolean {
    return hasDeviceFwUpdateFailed(this.device);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['device']) {
      const currentDevice = changes['device'].currentValue;
      const previousDevice = changes['device'].previousValue;
      if (currentDevice !== previousDevice) {
        this.deviceTypeTooltipSignal.set(getDeviceTypeTooltipKey(this.device));
        this.deviceStatusChipLabelSignal.set(
          deviceStatusLabelMap[this.device.ui.status],
        );
        this.isDeviceStoppingSignal.set(this.isStopping);
        this.isAddDeploymentDisabledSignal.set(
          isAddDeploymentCheckDisabled(this.device),
        );
        this.isBeingDeletedSignal.set(this.isBeingDeleted);
        this.isBeingCreatedSignal.set(isDeviceBeingCreated(this.device));
        this.isConnectingSignal.set(this.isConnecting);
        this.runningDeviceLabel = this.getRunningDeploymentsLabel(this.device);
      }
    }
  }

  navigateToDeviceDetailsPage(deviceId: string) {
    this.routingStore.navigateToDeviceDetails(deviceId);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.startDeploymentStore.setState({
      devices: [],
      versions: [],
      applications: [],
      areApplicationsLoading: true,
      areDevicesLoading: true,
      areVersionsLoading: true,
      hasError: false,
    });
  }
}
