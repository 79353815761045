import {
  AfterViewInit,
  Component,
  ViewChild,
  TemplateRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { NavigationEnd, Router, RouterLink, Scroll } from '@angular/router';
import { CookieInformationDialogContentComponent } from 'src/app/features/landing-page/pages/components/cookie-information-dialog-content/cookie-information-dialog-content.component';
import { DialogType } from 'src/app/shared/components/dialog/models/dialogType';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { DialogProps } from 'src/app/shared/components/dialog/models/dialogProps';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [
    RouterLink,
    CookieInformationDialogContentComponent,
    TranslateModule,
    NgClass,
  ],
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {
  year = new Date().getFullYear();
  appVersion = environment.appVersion;
  @ViewChild('cookieInformationDialogContent')
  cookieInformationDialogContent?: TemplateRef<any>;
  isStartingPage = false;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private dialogService: DialogService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isStartingPage = event.url.includes('getting-started');
      }
      if (event instanceof Scroll) {
        this.isStartingPage = event.routerEvent.url.includes('getting-started');
      }
    });
  }

  ngAfterViewInit(): void {
    this.showCookieInformationDialogIfNeeded();
  }

  private showCookieInformationDialogIfNeeded(): void {
    const cookiePolicyPageUrl: string = window.location.pathname;
    const storedCookieAcceptanceResult: string | null =
      localStorage.getItem('cookiesAccepted');
    if (
      storedCookieAcceptanceResult !== 'yes' &&
      cookiePolicyPageUrl !== '/cookie-policy'
    ) {
      this.openCookieInformationDialog();
    }
  }

  private openCookieInformationDialog(): void {
    const config: DialogProps = {
      type: DialogType.CONFIRM,
      title: this.translate.instant(
        'LandingPage.CookieInformationDialog.Title',
      ),
      confirmText: this.translate.instant(
        'LandingPage.CookieInformationDialog.Accept',
      ),
      disableBackdropPress: true,
      showCloseButton: false,
    };

    const dialogRef: MatDialogRef<DialogComponent, any> =
      this.dialogService.openDialogRef(
        config,
        this.cookieInformationDialogContent,
      );

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async () => {
        localStorage.setItem('cookiesAccepted', 'yes');
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
