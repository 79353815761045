import { Injectable } from '@angular/core';
import { VecuApiService } from '../vecu-api-service/vecu-api.service';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { VecuListApi } from '../../vecu-list.component';
import { VecuActionResponse } from '../../models/vecu';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VecuActionsService {

  constructor(
    private vecuApiService: VecuApiService,
  ) { }

  handlevecuAction(
    API: Record<VecuListApi, ApiRecord> | undefined,
    vecuId: string,
    command: string
  ): Observable<VecuActionResponse | null> {
    {
      if (!API) return of(null);;
      return this.vecuApiService.actionVecu(API.actionVecu, vecuId, command);
    }
  }
}
