import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { ProjectsState } from './models/projectsState';
import { Project } from './models/project';

@Injectable({
  providedIn: 'root',
})
/**
 * This service is a reactive store of data for projects
 * displayed in the HomeComponent
 * @extends Store
 */
export class ProjectsStore extends Store<ProjectsState> {
  constructor() {
    super({ projects: [], isLoading: true, hasError: false });
  }

  get projects$(): Observable<Project[]> {
    return this.state$.pipe(map((state) => state.projects));
  }

  get isLoading$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.isLoading));
  }

  get isError$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.hasError));
  }

  getProjectById$(id: string): Observable<Project | null> {
    return this.state$.pipe(
      map(({ projects }) => {
        const project = projects.find((project) => project.id === id);
        return project ? project : null;
      }),
    );
  }
}
