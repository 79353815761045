import { Injectable } from '@angular/core';
import {
  Observable,
  catchError,
  interval,
  map,
  of,
  startWith,
  switchMap,
} from 'rxjs';

import { Quota } from 'src/app/core/models/interfaces/quota';
import { BaseQuota } from 'src/app/core/models/interfaces/quotaAPI';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { ApiService } from 'src/app/core/services/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class UserQuotaService {
  constructor(private apiService: ApiService) {}

  readonly interval: number = 60000;

  getQuota<T extends BaseQuota & { consumedQuotaTime?: number; consumedVhpcTime?: number }>(
    apiRecord: ApiRecord
  ): Observable<Quota> {
    const minute: number = 60;
    const fullPercentage: number = 100;

    return interval(this.interval).pipe(
      startWith(this.apiService.request<T>({ apiRecord })),
      switchMap(() => this.apiService.request<T>({ apiRecord })),
      map((quota) => {
        const consumedTime = quota.consumedQuotaTime ?? quota.consumedVhpcTime ?? 0;
        return {
          isLimited: quota.limitedByTime,
          projectQuotaMinutes: parseFloat(
            (quota.projectQuotaTime / minute).toFixed(1),
          ),
          userQuotaMinutes: parseFloat(
            (consumedTime / minute).toFixed(1),
          ),
          remainingUserQuotaPercentage:
            fullPercentage -
            parseFloat(
              (
                (consumedTime / quota.projectQuotaTime || 0) *
                fullPercentage
              ).toFixed(0),
            ),
          isQuotaExceeded: quota.limitedByTime
            ? consumedTime >= quota.projectQuotaTime
            : false,
        };
      }),
      catchError((error) => {
        console.error('Error: ', error.message);
        return of({
          isLimited: false,
          projectQuotaMinutes: 0,
          userQuotaMinutes: 0,
          remainingUserQuotaPercentage: 0,
          isQuotaExceeded: false,
        });
      }),
    );
  }
}