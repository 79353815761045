import { Component, OnDestroy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule, MatStepper } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { CopyToClipboardService } from 'src/app/shared/utils/copy-to-clipboard.service';
import { TranslateService } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { CreateVecuDialogComponent } from '../../dialogs/create-vecu-dialog/create-vecu-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { switchMap, of, takeUntil, Subject } from 'rxjs';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { VecuApiService } from '../../../services/vecu-api-service/vecu-api.service';
import { VecuCreateResponse } from '../../../models/vecu';
import { CaeButtonComponent } from '../../../../../shared/components/cae-button/cae-button.component';
import { VECU_INSTALLER_URL } from '../../../config/vecu-config';
import { ConfigService } from 'src/app/core/services/config/config.service';
import { generalAnimations } from 'src/app/shared/animations/general-animations';

export type VecuListApi =
  | 'getVecus'
  | 'createVecu';

@Component({
  selector: 'app-getting-started-vecu',
  standalone: true,
  imports: [
    TranslateModule,
    MatTabsModule,
    MatStepperModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    CaeButtonComponent
  ],
  templateUrl: './getting-started-vecu.component.html',
  styleUrl: './getting-started-vecu.component.scss',
  animations: [generalAnimations],
})

export class GettingStartedVecuComponent extends FeatureComponent<VecuListApi> implements OnDestroy {

  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    private copyToClipboardService: CopyToClipboardService,
    private router: Router,
    private configService: ConfigService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private vecuApiService: VecuApiService,
  ) {
    super();
  }

  createVECUButtonClicked() {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '600px';
    dialogConfig.height = '350px';
    dialogConfig.data = {
      apiRecord: this.API?.getVecus
    };

    const dialogRef = this.dialog.open(CreateVecuDialogComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((vecuName: string) => {
          if (!vecuName || !this.API?.createVecu) {
            return of(null);
          }
          return this.vecuApiService.createVecu(this.API.createVecu, vecuName);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (value: VecuCreateResponse | null) => {
          if (value) {
            this.snackbarService.notifyInfo(
              'VecuList.CreateDialog.SuccessMessage',
            );
          }
        },
        error: () => {
          this.snackbarService.notifyError(
            'VecuList.CreateDialog.ErrorMessage',
          );
        },
      });
  }


  copyToClipboard(translationKey: string): void {
    const command = this.translate.instant(translationKey);
    this.copyToClipboardService.copyToClipboard(command);
  }

  navigateToVECUPage(toBlankPage: boolean = true) {
    const url = this.router.createUrlTree(['../'], {
      relativeTo: this.activatedRoute,
    }).toString();

    if (toBlankPage) {
      window.open(url, '_blank');
    } else {
      this.router.navigateByUrl(url);
    }
  }

  navigateBack() {
    this.navigateToVECUPage(false);
  }

  navigateToCertificatesPage() {
    this.configService
      .getCCPTenantId()
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe((CCPTenantId) => {
        const url = `https://${CCPTenantId}.ccp.continental.exchange/certificates`;
        window.open(url, '_blank');
      });
  }

  downloadInstaller() {
    // Will be changed in the future to download the installer from the backend if an API endpoint is available
    // To bypass the CORS issue, we are directly downloading the installer from the artifactory
    window.open(VECU_INSTALLER_URL, '_blank');
  }

  goToNextStep(stepper: MatStepper) {
    stepper.next();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
