export const VECU_SORT_KEYS = {
  ALPHABETICAL: 'VecuList.SortKeys.Alphabetical',
  CREATION_DATE: 'VecuList.SortKeys.CreationDate',
} as const;

export type VecuSortKey =
  | typeof VECU_SORT_KEYS.ALPHABETICAL
  | typeof VECU_SORT_KEYS.CREATION_DATE;

export const VECU_STATUS_KEYS = {
  ALL: 'VecuList.VecuStatus.All',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  IN_PROGRESS: 'In progress',
  ERROR: 'Error',
  UNKNOWN: 'Unknown',
  RUNNING: 'Running',
  CREATE_IN_PROGRESS: 'Creating',
  DELETE_IN_PROGRESS: 'Deleting',
  DELETION_FAILED: 'Deletion failed',
  PENDING_INSTALL: 'Installing',
  PENDING_UPGRADE: 'Upgrading',
  PENDING_ROLLBACK: 'Rollback',
  UNINSTALLING: 'Uninstalling',
  IDLE: 'Idle',
  UNDEFINED: 'Undefined',
  DEPLOYED: 'Deployed',
  FAILED: 'Failed',
  PENDING: 'Pending',
  SUCCEEDED: 'Succeeded',
  WAITING: 'Waiting',
  TERMINATED: 'Terminated',
} as const;

export type VecuStatusKey =
  | typeof VECU_STATUS_KEYS.ALL
  | typeof VECU_STATUS_KEYS.ACTIVE
  | typeof VECU_STATUS_KEYS.INACTIVE
  | typeof VECU_STATUS_KEYS.IN_PROGRESS
  | typeof VECU_STATUS_KEYS.ERROR;
