import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthTokenInterceptor } from './core/interceptors/auth-token/auth-token.interceptor';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GlobalHttpErrorInterceptor } from './core/interceptors/global-http-error/global-http-error.interceptor';
import { ConfigService } from './core/services/config/config.service';
import { AuthService } from './core/services/auth/auth.service';
import { Router } from '@angular/router';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthServiceTest } from '../../cypress/utils/core/services/authTest.service';
import { getParamFromUrl } from './shared/utils/getParamFromId';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { InitialUrlService } from 'src/app/core/services/initial-url/initial-url.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

/**
 * This factory function gets run during app initialization,
 * before any dynamic routes are loaded.
 *
 * The functions check if the user is authenticated by
 * accessing the tenant property from the retrieveUserData$ getter.
 * This property is being passed down to the getConfigJSON function
 * where the authentication status of the user is handled.
 *
 * This function also extracts the projectId and applicationId from
 * the current url if the user decides to open the application
 * from a dynamic route like /dashboard/project/ambient-light/application/macarena
 * These ids are passed down to the getConfigJSON.
 *
 * Once the fetching of the config file is completed, we can initialize
 * the navigation of the router (we are using initialNavigation disabled).
 *
 * @param {AuthService} authService - [Authentication Service]
 * @param {AuthServiceTest} authServiceTest - [Authentication Service used by E2E tests]
 * @param {ConfigService} configService - [Http Service used for config file fetching]
 * @param {Router} router - [Angular Router]
 */
export function ConfigServiceFactory(
  authService: AuthService,
  authServiceTest: AuthServiceTest,
  configService: ConfigService,
  router: Router,
) {
  const projectId = getParamFromUrl(window.location.pathname, '/project/');
  const applicationId = getParamFromUrl(
    window.location.pathname,
    '/application/',
  );

  const unsubscribe$ = new Subject<void>();
  let service = environment.enableGuard ? authService : authServiceTest;
  return () => {
    service.retrieveUserData$
      .pipe(
        switchMap(({ tenant }) =>
          configService.getConfigJSON(tenant, projectId, applicationId),
        ),
        takeUntil(unsubscribe$),
      )
      .subscribe(() => {
        router.initialNavigation();
        unsubscribe$.complete();
      });
  };
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatNativeDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpErrorInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigServiceFactory,
      deps: [
        AuthService,
        AuthServiceTest,
        ConfigService,
        Router,
        InitialUrlService,
      ],
      multi: true,
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: 'MMMM d, y' },
    },
  ],
})
export class AppModule {}
