import { GeneralApi, GeneralFeatureConfig } from 'src/app/core/services/config/models/generalApi';
import { FeatureConfig } from 'src/app/shared/stores/config/models/featureConfig';
import { Method } from 'src/app/shared/stores/config/models/method';
import { FeatureId } from './feature-id';
import { AppConfigInstanceType } from 'src/app/shared/stores/config/models/appConfigInstanceType';
import { environment } from '../../../../environments/environment';

export const SIMULATION_JOB_MONITORING = (
  options: GeneralFeatureConfig<GeneralApi>,
) => {
  return {
    id: '123456789',
    featureId: 'SIMULATION_JOB_MONITORING',
    label: 'Jobs',
    type: 'FEATURE',
    icon: 'task_alt',
    path: 'project/:projectId/jobs',
    API: {
      jobMonitoringSimulation: {
        url: `${options.api.dev}/information/api/v1/workflows/~sim/runs`,
        method: Method.GET,
      },
      jobMonitoringReporting: {
        url: `${options.api.dev}/information/api/v1/workflows/~kpi/runs`,
        method: Method.GET,
      },
      getProjects: {
        url: `${options.api.dev}/api/v1/project/`,
        method: Method.GET,
      },
      getWorkflows: {
        url: `${options.api.dev}/api/v1/project/:projectName/workflows`,
        method: Method.GET,
      },
      playlist: {
        url: `${options.api.playlist}/${environment.AWS_STAGE}/v2/lookups?mode=list_playlists`,
        method: Method.POST,
      },
      getInputDataSets: {
        url: `${options.api.dataSets}/input_sets/names?input_set_root_url=/:projectName&with_leafs=true&no_cache=false`,
        method: Method.GET,
      },
      getProjectConfig: {
        url: `${options.api.playlist}/${environment.AWS_STAGE}/v2/lookups?mode=get_project_config`,
        method: Method.POST,
      },
      getParameterTemplates: {
        url: `${options.api.dev}/api/v1/project/:projectName/workflows/:id/templates`,
        method: Method.GET,
      },
      createJob: {
        url: `${options.api.dev}/api/v1/project/:projectName/workflows/:workflow`,
        method: Method.POST,
      },
    },
    children: [
      {
        id: '1234567891',
        featureId: 'SIMULATION_JOB_MONITORING_DETAILS',
        label: 'Job Details',
        type: 'FEATURE',
        icon: 'task_alt',
        path: ':jobId',
        API: {
          getJobExecutionDetailsReporting: {
            url: `${options.api.dev}/information/api/v1/workflows/~kpi/runs/:dagRunId`,
            method: Method.GET,
          },
          getJobExecutionDetailsSimulation: {
            url: `${options.api.dev}/information/api/v1/workflows/~sim/runs/:dagRunId`,
            method: Method.GET,
          },
          getChildJobsSimulation: {
            url: `${options.api.dev}/information/api/v1/workflows/srr-stage1/runs/:dagRunId/childjobs`,
            method: Method.GET,
          },
          cancelJob: {
            url: `${options.api.dev}/api/v1/project//workflows//:run_id`,
            method: Method.DELETE,
          },
          getChildJobSteps: {
            url: `${options.api.dev}/information/api/v1/workflows/srr-stage1/runs/:dagRunId/childjobs/:childJobId`,
            method: Method.GET,
          },
          requestStepDownload: {
            url: `${options.api.dev}/logs/get_logs`,
            method: Method.POST,
          },
          requestLogDownload: {
            url: `${options.api.prod}/logs/get_logs`,
            method: Method.POST,
          },
          downloadConfigFile: {
            //ToDo: only for the moment - they will change the custom domain name suffix to tenant
            url: `${options.api.downloadJobConfig}/config-download`,
            method: Method.POST,
          },
        },
      },
    ],
  };
};

export const YIELD_REPORT_PAGE = (options: GeneralFeatureConfig<GeneralApi>): Partial<FeatureConfig> => {
  return {
    id: '000112233',
    featureId: FeatureId.YIELD_REPORT_PAGE,
    label: 'Yield report',
    type: AppConfigInstanceType.FEATURE,
    icon: 'auto_awesome_motion',
    path: 'project/:projectId/yield_report',
    API: {
      downloadYieldReport: {
        url: `${options.api.yieldReport}/:projectName/reports/yield_report/:fileName`,
        method: Method.GET,
      },
    },
  };
};
  