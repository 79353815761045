import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureComponent } from '../../core/models/classes/feature.component';
import { WorkspaceService } from './services/workspaces.service';
import { HeaderTemplateComponent } from '../../shared/components/header/header-template.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthStore } from 'src/app/shared/stores/auth/auth.store';
import { map, Observable, Subject, takeUntil, takeWhile, timer } from 'rxjs';
import { JwtDecoder } from 'src/app/shared/utils/jwtDecoder';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CaeButtonComponent } from '../../shared/components/cae-button/cae-button.component';
import { MatIconModule } from '@angular/material/icon';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import {
  CreateWorkspaceStatus,
  IWorkbenchDataType,
  Instance,
  WorkbenchType,
  WorkspaceCreation,
  WorkspaceState,
} from 'src/app/shared/stores/workbenches/models/workbenches';
import { WorkbenchStore } from 'src/app/shared/stores/workbenches/workbenches.stores';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { connectBtnPipe } from '../deployment-list/utils/pipes/worspace-connect-button.pipe';
import {
  ChipComponent,
  ChipVariant,
} from 'src/app/shared/components/chip/chip.component';
import { FeatureService } from 'src/app/core/services/config/feature.service';
import { FeatureConfig } from 'src/environments/featureConfig';
export type WorkspaceApi =
  | 'getWorkspaceTypes'
  | 'createWorkspace'
  | 'deleteWorkspaceInstance'
  | 'resetWorkspaceInstance'
  | 'updateWorkspaceInstance'
  | 'resetWorkspacePassword'
  | 'requestWorkspaceAccess';

@Component({
  selector: 'app-workspace-page',
  standalone: true,
  templateUrl: './workspace-page.component.html',
  styleUrls: ['./workspace-page.component.scss'],
  imports: [
    CommonModule,
    HeaderTemplateComponent,
    TranslateModule,
    MatProgressBarModule,
    CaeButtonComponent,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    connectBtnPipe,
    ChipComponent,
  ],
})
export class WorkspacePageComponent
  extends FeatureComponent<WorkspaceApi>
  implements OnInit, OnDestroy
{
  private readonly initialWaitTime = 5 * 60 * 1000;
  private readonly unsubscribe$: Subject<void> = new Subject();
  isRequestButtonActive = this.featureService.isFeatureEnabled(
    FeatureConfig.workbencheRequest,
  );

  loading = false;
  jwtToken = this.authStore.state.tokens?.idToken;
  WorkspaceState = WorkspaceState;
  workspaceTypes$: Observable<IWorkbenchDataType> =
    this.workspaceStore.workbenchTypes$;
  ChipVariant = ChipVariant;
  constructor(
    private workspaceService: WorkspaceService,
    private authStore: AuthStore,
    private snackbarService: SnackbarService,
    private workspaceStore: WorkbenchStore,
    private translateService: TranslateService,
    private featureService: FeatureService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchWorkspaceTypes();
    this.initDeleteNotifier();
  }

  private initDeleteNotifier() {
    this.workspaceService.deleteNotifier$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isDeleted) => {
        if (isDeleted) {
          this.fetchWorkspaceTypes();
        }
      });
  }

  fetchWorkspaceTypes(): void {
    if (!this.API) return;
    if (!this.jwtToken) return;

    this.loading = true;

    this.workspaceService
      .getWorkspaceTypes(
        this.API.getWorkspaceTypes,
        this.extractUserId(this.jwtToken),
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (this.checkPending(response)) {
          this.startWorkbenchPolling();
        }
        this.loading = false;
      });
  }

  private checkPending(workbenchTypes: IWorkbenchDataType) {
    return workbenchTypes.WorkBenchTypes.some(
      (type) => type.instance?.state === WorkspaceState.PENDING,
    );
  }

  startWorkbenchPolling(): void {
    if (!this.API) return;
    if (!this.jwtToken) return;
    const userId = this.extractUserId(this.jwtToken);
    this.workspaceService
      .triggerWorkbenchPolling(this.API.getWorkspaceTypes, userId)
      .pipe(
        map((response) => {
          return response.WorkBenchTypes.some(
            (workBenTypes) =>
              workBenTypes.instance?.state === WorkspaceState.PENDING,
          );
        }),
        takeWhile((isPending) => isPending),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  createWorkspace(workbenchName: string): void {
    if (!this.API) return;
    if (!this.jwtToken) return;

    const userId = this.extractUserId(this.jwtToken);

    this.workspaceService
      .createWorkspace(this.API.createWorkspace, userId, workbenchName)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: WorkspaceCreation) => {
          if (response.status === CreateWorkspaceStatus.SUCCESS) {
            this.snackbarService.notifyInfo(
              this.translateService.instant('Workspaces.CreationTime.Tooltip'),
            );
            setTimeout(() => {
              this.startWorkbenchPolling();
            }, this.initialWaitTime);
          } else {
            this.snackbarService.notifyError(response.message);
          }
        },
        error: () => {
          this.snackbarService.notifyError(
            this.translateService.instant('Workspaces.CreationFailed'),
          );
        },
      });
  }

  requestWorkspaceAccess(workbenchName: string): void {
    if (!this.API) return;
    if (!this.jwtToken) return;

    const userId = this.extractUserId(this.jwtToken);

    this.workspaceService
      .getRequestWorkspaceAccess(
        this.API.requestWorkspaceAccess,
        userId,
        workbenchName,
      )
      .pipe()
      .subscribe({
        next: () => {
          this.snackbarService.notifyInfo('Workspaces.RequestSuccess');
          timer(this.initialWaitTime).subscribe(() => {
            this.startWorkbenchPolling();
          });
        },
        error: () => {
          this.snackbarService.notifyError('Workspaces.RequestFailed');
        },
      });
  }

  extractUserId(token: string): string {
    const decoder = new JwtDecoder();
    const userIdWithDomain = decoder.decodeJwt(token).preferred_username;
    const atIndex = userIdWithDomain.indexOf('@');
    return atIndex !== -1
      ? userIdWithDomain.substring(0, atIndex)
      : userIdWithDomain;
  }

  disableConnectButton(event: Event, workbenchType: WorkbenchType): void {
    const disabledTime = 15000; // 15 seconds
    if (workbenchType.enable) {
      event.preventDefault();
    }
    workbenchType.enable = true;
    timer(disabledTime).subscribe(() => {
      workbenchType.enable = false;
    });
  }

  openDeleteInstanceDialog(workspaceId: string | undefined) {
    if (!workspaceId) return;
    if (!this.API) return;
    this.workspaceService.openDeleteInstanceDialog(
      workspaceId,
      this.API.deleteWorkspaceInstance,
    );
  }

  openUpdateInstanceOptions(instance: Instance | undefined) {
    if (!instance) return;
    if (!this.API) return;
    this.workspaceService.openUpdateInstanceDialog(
      instance,
      this.API.updateWorkspaceInstance,
      this.unsubscribe$,
    );
  }

  resetWorkbenchDialog(workbenchId: string | undefined) {
    if (!workbenchId) return;
    if (!this.API) return;
    this.workspaceService.openResetWorkbenchDialog(
      workbenchId,
      this.API.resetWorkspaceInstance,
      this.unsubscribe$,
    );
  }

  resetPasswordDialog() {
    if (!this.jwtToken) return;
    if (!this.API) return;
    const userId = this.extractUserId(this.jwtToken);
    this.workspaceService.openResetPasswordDialog(
      userId,
      this.API.resetWorkspacePassword,
      this.unsubscribe$,
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
