<table class="list-container">
  <thead>
    <tr>
      <th>ID</th>
      <th>Type</th>
      <th>Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let device of devices | async">
      <td>{{ device.deviceId }}</td>
      <td>{{ device.instanceType }}</td>

      @if ((loading | async) === false) {
        <td data-cy="bulk-deletion-status">
          {{ device.deviceId | checkDeleteStatus: (deletionResponse | async) }}
        </td>
      } @else {
        <td><div class="dot-pulse"></div></td>
      }
    </tr>
  </tbody>
</table>
