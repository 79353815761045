import { Component, OnDestroy, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { catchError, Observable, of, switchMap, combineLatest, map, Subject, takeUntil, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  DetailsExpansionComponent,
  MenuCommand,
  MenuItem,
} from '../../../../shared/components/details-expansion/details-expansion.component';
import { HeaderTemplateComponent } from '../../../../shared/components/header/header-template.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProjectDetailsService } from './services/project-details.service';
import { ProjectTableComponent } from './components/contacts-table/contacts-table.component';
import { ProjectServicesListComponent } from 'src/app/core/components/project-dashboard/project-dashboard-details/components/project-services-list/project-services-list.component';
import { ProjectDetails } from './models/project-details';
import { ProjectsStore } from '../../../../shared/stores/projects/projects.store';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { FeatureService } from 'src/app/core/services/config/feature.service';
import { FeatureConfig } from 'src/environments/featureConfig';
import { DisplayDetailComponent } from 'src/app/shared/components/display-detail/display-detail.component';
import { CaeButtonComponent } from 'src/app/shared/components/cae-button/cae-button.component';
import { DeleteProjectDialogComponent } from 'src/app/core/components/home/components/delete-project-dialog/delete-project-dialog.component';
import { UserRightsService } from 'src/app/shared/services/user-rights/user-rights.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ChipComponent } from 'src/app/shared/components/chip/chip.component';
import { ChipVariant } from 'src/app/shared/components/chip/chip.component';
import { ProjectTenantDetailsComponent } from 'src/app/shared/components/display-project-tenant-details/project-tenant-details.component';
import { ApprovalStatus, Project } from 'src/app/shared/stores/projects/models/project';

@Component({
  selector: 'app-project-dashboard-details',
  standalone: true,
  imports: [
    CommonModule,
    DisplayDetailComponent,
    DetailsExpansionComponent,
    HeaderTemplateComponent,
    TranslateModule,
    MatProgressBarModule,
    ProjectTableComponent,
    ProjectServicesListComponent,
    MatDivider,
    ProjectTenantDetailsComponent,
    CaeButtonComponent,
    MatTooltipModule,
    ChipComponent,
  ],
  templateUrl: './project-dashboard-details.component.html',
  styleUrls: ['./project-dashboard-details.component.scss'],
})
export class ProjectDashboardDetailsComponent implements OnDestroy {

  readonly ChipVariant = ChipVariant;
  readonly ApprovalStatus = ApprovalStatus;
  readonly isBlueprintEnabled: boolean;
  private readonly unsubscribe$ = new Subject<void>();

  isProjectProcessedForDeletionSignal = signal<boolean>(false);

  workbench = {
    Admin: 'Continental',
    Date: new Date().toString(),
  };
  menuItems: MenuItem[] = [
    {
      command: MenuCommand.EDIT,
      disabled: true,
      translationKey: 'General.Edit',
    },
  ];

  project$: Observable<Project | null> = this.activatedRoute.params.pipe(
    switchMap((params) => {
      return this.projectsStore.getProjectById$(params['projectId']);
    }),
  );

  projectDetails$: Observable<ProjectDetails | null> =
    this.activatedRoute.params.pipe(
      switchMap((params) => {
        const projectId = params['projectId'];
        return this.projectDetailsService.getProjectDetailsById(projectId).pipe(
          catchError((err) => {
            console.error(err);
            return of(null);
          }),
        );
      }),
      tap((projectDetails) => {
        if(projectDetails?.StatusApprovalDeletion === ApprovalStatus.INITIATED) {
          this.isProjectProcessedForDeletionSignal.set(true);
        }
      }),
    );

  isUserOwnerOrCoOwner$ = this.projectDetails$.pipe(
    map((projectDetails) => {
      return projectDetails?.transformedContacts ?? [];
    }),
    map((projectContacts) => {
      return this.userRightsService.isCurrentUserOwnerOrCoOwner(projectContacts);
    }),
  );

  combinedData$ = combineLatest([this.projectDetails$, this.project$]).pipe(
    map(([projectDetails, project]: [ProjectDetails | null, Project | null]) => ({ projectDetails, project }))
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private projectsStore: ProjectsStore,
    private projectDetailsService: ProjectDetailsService,
    private featureService: FeatureService,
    private dialog: MatDialog,
    private userRightsService: UserRightsService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
  ) {
    this.isBlueprintEnabled = this.featureService.isFeatureEnabled(
      FeatureConfig.bluePrintServiceReady
    );
  }

  openDeleteProjectDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';

    const dialogRef = this.dialog.open(
      DeleteProjectDialogComponent,
      dialogConfig
    );

    dialogRef.afterClosed()
      .pipe(
        switchMap((projectId) => {
          if (!projectId) {
            return of(null);
          }
          return this.projectDetailsService.deleteProjectById(projectId);
        }),
        takeUntil(this.unsubscribe$),
      ).subscribe({
        next: (dialogEmission) => {
          if (dialogEmission) {
            this.snackbarService.notifySuccess(
              this.translate.instant('Project.DeleteProjectDialog.SuccessSnackbar')
            );
            this.isProjectProcessedForDeletionSignal.set(true);
          }
        },
        error: () => {
          this.snackbarService.notifyError(
            this.translate.instant('Project.DeleteProjectDialog.FailedSnackbar')
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
