@if (config) {
  <div class="upload-file">
    <input
      type="file"
      class="file-input"
      (change)="onFileSelected($event)"
      [accept]="allowedFileEndings"
      #fileUpload
    />
    <div class="file-upload">
      <div class="flex-grow">
        <button
          mat-flat-button
          color="primary"
          class="upload-btn"
          (click)="fileUpload.click()"
        >
          <p>{{ name }}</p>
          <mat-icon>attach_file</mat-icon>
        </button>
      </div>
      @if (loading) {
        <mat-spinner [diameter]="24"></mat-spinner>
      }
      <span class="status flex-grow">{{ fileName }}</span>
    </div>
  </div>
}
