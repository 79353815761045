import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { generatePath } from '../../utils/generatePath';
import { AppConfigInstanceType } from './models/appConfigInstanceType';
import { NormalizedAppConfigItem } from './models/normalizedAppConfigItem';
import { SidebarRoutingItem } from './models/sidebarRoutingItem';

/*
 * These hardcoded items are displayed on the tenant level.
 * You can add other tenant specific sidebar items here
 * and display them conditionally based on roles.
 * */

const home = {
  fullPath: 'home',
  label: 'Projects',
  nonNavigatable: false,
  isNested: false,
  icon: 'space_dashboard',
};

const tenantUsers = {
  fullPath: 'users',
  label: 'Users',
  nonNavigatable: false,
  isNested: false,
  icon: 'group',
};

export const rootSidebarRoutingItems: SidebarRoutingItem[] = [
  home,
  tenantUsers,
];

export interface RoutingStoreState {
  items: SidebarRoutingItem[];
  isSidebarExpanded: boolean;
  projectId: string;
  applicationId: string;
}

export const initialRoutingStoreState: RoutingStoreState = {
  items: rootSidebarRoutingItems,
  isSidebarExpanded: true,
  projectId: '',
  applicationId: '',
};

@Injectable({
  providedIn: 'root',
})
export class RoutingStore extends Store<RoutingStoreState> {
  constructor(private router: Router) {
    super(initialRoutingStoreState);
  }

  /**
   * Returns an observable with the current expanded
   * state of the sidebar.
   *
   * @returns {Observable<boolean>}
   */
  get isSidebarExpanded$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.isSidebarExpanded));
  }

  /**
   * Returns the current sidebar items with the populated ids
   * for the current level selected based on the projectId and
   * applicationId stored in the state.
   *
   * There are 3 levels:
   * Tenant > Project > Application
   *
   * The logical mapping is the following:
   * No projectId is present -> Hardcoded Tenant sidebar items are returned
   * projectId is present and applicationId is not present -> Project sidebar items are returned
   * projectId and applicationId are present -> Application sidebar items are returned
   *
   * @returns {Observable<SidebarRoutingItem[]>} [The current sidebar items]
   */
  get selectedSidebarItems$(): Observable<SidebarRoutingItem[]> {
    return this.state$.pipe(
      map(({ items, projectId, applicationId }) => {
        // Tenant Project level
        if (projectId === '') {
          return [home, tenantUsers];
        }
        const forApplications = [...items];

        const routingItems: SidebarRoutingItem[] = this.populatePathIds(
          forApplications,
          {
            projectId,
            appName: applicationId,
          },
        );

        // Tenant > Project
        if (applicationId === '') {
          return routingItems;
        }

        // Tenant > Project > Application
        const selectedApplication = [...routingItems];
        return this.populatePathIds(selectedApplication, {
          projectId,
          appName: applicationId,
        });
      }),
    );
  }

  /**
   * Toggles the expanded state of the sidebar
   *
   */
  toggleIsSidebarExpanded(): void {
    this.setState({
      ...this.state,
      isSidebarExpanded: !this.state.isSidebarExpanded,
    });
  }

  /**
   * Collapse the sidebar
   *
   */
  collapseSidebar(): void {
    this.setState({
      ...this.state,
      isSidebarExpanded: false,
    });
  }

  /**
   * Initializes the state holding the items displayed
   * in the sidebar based on a given projectId and applicationId.
   *
   * @param {NormalizedAppConfigItem[]} config
   * @param {string} projectId
   * @param {string} applicationId
   */
  initSidebarRoutingItems(
    config: NormalizedAppConfigItem[],
    projectId: string,
    applicationId: string,
  ) {
    const sidebarRoutingConfig = this.buildSidebarRoutes(config);
    const flattenedSidebarRoutes =
      this.flattenFeatureSections(sidebarRoutingConfig);
    this.setState({
      ...this.state,
      projectId,
      applicationId,
      items: flattenedSidebarRoutes,
    });
  }

  /**
   * Extracts a nested structure from a normalized app config
   * for a given project. The nested structure is used for
   * displaying sidebar items.
   *
   * @param {NormalizedAppConfigItem[]} projectConfig
   * @returns {SidebarRoutingItem[]} [Sidebar Items Nested Structure]
   */
  buildSidebarRoutes(
    projectConfig: NormalizedAppConfigItem[],
  ): SidebarRoutingItem[] {
    return projectConfig.map((item: NormalizedAppConfigItem) => {
      const {
        children,
        isNested,
        label,
        type,
        extends: { fullPath, icon, nonNavigatable },
      } = item;

      return {
        fullPath,
        isNested,
        label,
        type,
        ...(icon && { icon }),
        nonNavigatable,
        ...(children &&
          children.length > 0 && {
            children: this.buildSidebarRoutes(children),
          }),
      };
    });
  }

  /**
   * Extracts the children of identified Feature Sections
   * to be on the same level as the Feature Section.
   *
   * @param {SidebarRoutingItem[]} items
   * @returns {SidebarRoutingItem[]} [items with flattened Feature Sections]
   */
  flattenFeatureSections(items: SidebarRoutingItem[]): SidebarRoutingItem[] {
    return items.flatMap(({ children, ...item }) => {
      if (item.type === AppConfigInstanceType.FEATURE_SECTION && children) {
        return [item, ...children];
      }

      return [
        {
          ...item,
          ...(children && { children: this.flattenFeatureSections(children) }),
        },
      ];
    });
  }

  /**
   * Sets the applicationId in the state and navigates to
   * a given application under a given project.
   * If no projectId is specified, the projectId from the
   * state is being used.
   *
   * @param {string} applicationId
   * @param {string} [projectId]
   */
  navigateToApplication(
    applicationId: string,
    projectId: string = this.state.projectId,
  ) {
    this.setApplicationId(applicationId);
    this.router.navigate([
      'dashboard/project',
      projectId,
      'application',
      applicationId,
    ]);
  }

  /**
   * Navigates to device details page for a given device
   * and under the current project.
   * ProjectId is retrieved from the state.
   *
   * @param {string} deviceId
   */
  navigateToDeviceDetails(deviceId: string) {
    this.router.navigate([
      'dashboard/project',
      this.state.projectId,
      'device-list',
      deviceId,
    ]);
  }

  /**
   * Sets the projectId in the state
   * and navigates to the current project dashboard
   * if no projectId is specified.
   *
   * @param {string} [projectId]
   */
  navigateToProject(projectId: string = this.state.projectId) {
    this.router.navigate(['dashboard/project', projectId]);
  }

  /**
   * Clears the sidebar items which are found on
   * the application level.
   *
   */
  clearApplicationSidebarItems(): void {
    this.setApplicationId('');
  }

  /**
   * Sets the applicationId in the state to a given applicationId
   *
   * @param {string} applicationId
   */
  setApplicationId(applicationId: string): void {
    this.setState({ ...this.state, applicationId });
  }

  /**
   * Sets the items in the state to a given applicationId
   *
   * @param {string} items
   * @param projectId
   */
  setItemsandProjectId(items: SidebarRoutingItem[], projectId: string): void {
    this.setState({ ...this.state, items, projectId });
  }

  /**
   * Clears the sidebar items which are found on the
   * project level.
   *
   */
  clearProjectSidebarItems(): void {
    this.setState({
      ...initialRoutingStoreState,
      isSidebarExpanded: this.state.isSidebarExpanded,
    });
  }

  /**
   * Populates all of the paths of the items in a sidebar routing item list
   * with the ids provided in a param object. This allows for the correct
   * navigation to the given path when clicking on a sidebar item.
   *
   * @param {SidebarRoutingItem[]} items [items with path placeholders]
   * @param {Record<string, string>} params -
   * [Object holding the parameter keys and values to be replaced in the path]
   * @returns {SidebarRoutingItem[]} [items with populated paths]
   */
  populatePathIds(
    items: SidebarRoutingItem[],
    params: Record<string, string>,
  ): SidebarRoutingItem[] {
    return items.map((item: SidebarRoutingItem) => {
      const { children, fullPath } = item;
      return {
        ...item,
        fullPath: generatePath(fullPath, params),
        ...(children &&
          children.length > 0 && {
            children: this.populatePathIds(children, params),
          }),
      };
    });
  }
}
