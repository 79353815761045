import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import {
  Observable,
  of,
  switchMap,
  startWith,
  combineLatest,
  map,
  Subject,
  takeUntil,
} from 'rxjs';
import { Project } from 'src/app/shared/stores/projects/models/project';
import { ProjectsStore } from 'src/app/shared/stores/projects/projects.store';
import { ProjectDetailsComponent } from './components/project-details/project-details.component';
import {
  ListGridToggleComponent,
  View,
} from 'src/app/shared/components/list-grid-toggle/list-grid-toggle.component';
import { SelectMenuComponent } from 'src/app/shared/components/select-menu/select-menu.component';
import { ApplicationListComponent } from './components/application-list/application-list.component';
import { FormControl } from '@angular/forms';
import { HomeService } from 'src/app/features/home/services/home/home.service';
import { ApplicationListService } from 'src/app/features/project-dashboard/components/application-list/services/application-list.service';
import { Option } from 'src/app/core/models/interfaces/option';
import { SortKey } from 'src/app/shared/utils/sortKeys';
import { FunctionBarComponent } from 'src/app/shared/components/function-bar/function-bar.component';
import { ApplicationListApiService } from './components/application-list/services/application-list-api.service';
import { ApplicationsState } from 'src/app/shared/stores/applications/models/applicationsState';
import { ApplicationsStore } from 'src/app/shared/stores/applications/applications.store';
import { ContentWrapperComponent } from 'src/app/shared/components/content-wrapper/content-wrapper.component';
import { EmptySectionComponent } from 'src/app/shared/components/empty-section/empty-section.component';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { TranslateModule } from '@ngx-translate/core';
import { AddApplicationDialogComponent } from './components/add-application-dialog/add-application-dialog.component';
import { ProjectDashboardService } from './service/project-dashboard.service';
import { HeaderTemplateComponent } from 'src/app/shared/components/header/header-template.component';
import { Application } from 'src/app/shared/stores/deployment/models/application';
import { AddApplicationData } from './components/add-application-dialog/add-application-dialog.component';
import { ApplicationTypeKey } from '../device-list/utils/applicationSelectionKeys';
import { DetailsExpansionComponent } from '../../shared/components/details-expansion/details-expansion.component';
import { CustomFormatDatePipe } from '../../core/pipes/custom-format-date.pipe';
import { DisplayDetailComponent } from '../application-dashboard/components/application-details/display-detail.component';
import { EmptySectionScope } from 'src/app/shared/utils/emptySectionScope';

export type ApplicationListApi =
  | 'getApplications'
  | 'createApplication'
  | 'getRepositoryProviderList'
  | 'getProviderRepositories'
  | 'deleteApplication';

@Component({
  standalone: true,
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss'],
  imports: [
    ProjectDetailsComponent,
    CommonModule,
    ListGridToggleComponent,
    SelectMenuComponent,
    ApplicationListComponent,
    FunctionBarComponent,
    MatButtonModule,
    ContentWrapperComponent,
    EmptySectionComponent,
    TranslateModule,
    HeaderTemplateComponent,
    AddApplicationDialogComponent,
    DetailsExpansionComponent,
    CustomFormatDatePipe,
    DisplayDetailComponent,
  ],
})
export class ProjectDashboardComponent
  extends FeatureComponent<ApplicationListApi>
  implements OnInit, OnDestroy
{
  private readonly unsubscribe$: Subject<void> = new Subject();

  searchApplicationsFilterControl: FormControl = new FormControl('');
  searchApplicationsFilter$: Observable<string> =
    this.searchApplicationsFilterControl.valueChanges.pipe(startWith(''));
  projectsView: View = 'grid';

  sortByOptions$: Observable<Option<SortKey>[]> =
    this.homeService.sortByOptions$;
  selectedSortBy$: Observable<Option<SortKey>> =
    this.homeService.selectedSortBy$;
  sortByData$ = combineLatest([this.sortByOptions$, this.selectedSortBy$]).pipe(
    map(([options, selectedOption]) => ({ options, selectedOption })),
  );

  project$: Observable<Project | null> = of(null);

  filteredApplications$ = combineLatest([
    this.applicationStore.applications$,
    this.searchApplicationsFilter$,
    this.applicationService.selectedApplicationType$,
    this.selectedSortBy$,
  ]).pipe(map(this.applicationService.applicationMap));

  applicationsData$: Observable<ApplicationsState>;
  EmptySectionScope = EmptySectionScope;

  applicationTypeData$ = combineLatest([
    this.applicationService.applicationTypeOptions$,
    this.applicationService.selectedApplicationType$,
  ]).pipe(map(([options, selectedOption]) => ({ options, selectedOption })));

  constructor(
    private projectsStore: ProjectsStore,
    private homeService: HomeService,
    private applicationService: ApplicationListService,
    private applicationListApi: ApplicationListApiService,
    private applicationStore: ApplicationsStore,
    private routes: ActivatedRoute,
    private projectDashboardService: ProjectDashboardService,
  ) {
    super();
    this.applicationsData$ = this.combineApplicationData$(
      this.filteredApplications$,
      this.applicationStore.isLoading$,
      this.applicationStore.isError$,
      this.applicationStore.errorMessage$,
      this.applicationStore.errorStatusCode$,
    );
  }

  ngOnInit(): void {
    this.fetchApplicationList();
    this.project$ = this.routes.params.pipe(
      switchMap((params) => {
        return this.projectsStore.getProjectById$(params['projectId']);
      }),
    );
  }

  combineApplicationData$(
    applications: Observable<Application[]>,
    isLoading: Observable<boolean>,
    hasError: Observable<boolean>,
    errorMessage: Observable<string | undefined>,
    errorStatusCode: Observable<number | undefined>,
  ): Observable<ApplicationsState> {
    return combineLatest([
      applications,
      isLoading,
      hasError,
      errorMessage,
      errorStatusCode,
    ]).pipe(
      map(
        ([
          applications,
          isLoading,
          hasError,
          errorMessage,
          errorStatusCode,
        ]) => {
          return {
            applications,
            isLoading,
            hasError,
            errorMessage,
            errorStatusCode,
          };
        },
      ),
    );
  }

  selectApplicationTypeOption(
    applicationTypeOption: Option<ApplicationTypeKey>,
  ): void {
    this.applicationService.selectedApplicationType(applicationTypeOption);
  }

  selectSortOption(option: Option<SortKey>): void {
    this.homeService.setSelectedSortBy(option);
  }

  fetchApplicationList() {
    if (this.API) {
      this.applicationListApi
        .getApplications(this.API.getApplications)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe();
    }
  }

  openAddApplicationDialog(): void {
    if (this.API) {
      const data: AddApplicationData = {
        createApplicationApiRecord: this.API.createApplication,
        getRepositoryProviderApiRecord: this.API.getRepositoryProviderList,
        getProviderRepositoriesApiRecord: this.API.getProviderRepositories,
        getApplicationsApiRecord: this.API.getApplications,
        unsubscribe$: this.unsubscribe$,
      };
      this.projectDashboardService.openDialog(data, this.unsubscribe$);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
