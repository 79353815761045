import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const generalAnimations = [
  trigger('fadeIn', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('500ms', style({ opacity: 1 })),
    ]),
  ]),
  trigger('fadeInLeftStagger', [
    transition('* => *', [
      query(
        ':enter',
        [
          style({ opacity: 0, transform: 'translateX(-10px)' }), // Initial styles for the animation (off to the left)
          stagger(
            100,
            animate('200ms', style({ opacity: 1, transform: 'none' }))
          ), // Stagger with a 100ms delay
        ],
        { optional: true }
      ),
    ]),
  ]),
];

export const routerAnimations = [
  trigger('routerAnimation', [
    transition('* <=> *', [
      style({ position: 'relative' }),
      animate('300ms ease', style({ opacity: 0 })),
    ]),
  ]),
];
