import { ApplicationType } from '../../applications/models/applicationType';
import { PackageType } from 'src/app/shared/models/packageType';
import { SourceType } from 'src/app/shared/models/sourceType';

export interface IDataRepositories {
  name: string;
  url: string;
}

export interface IDataApplication {
  appId: string;
  appType: ApplicationType;
  appReference: string;
  creationDate: string;
}
export interface IApplication {
  appName: string;
  appType: ApplicationType;
  codeRepoName: string;
  creationDate: number;
  appSource: SourceType;
  appPackage: PackageType;
}

export class Application implements IApplication {
  appName!: string;
  appType!: ApplicationType;
  appSource!: SourceType;
  appPackage!: PackageType;
  codeRepoName!: string;
  creationDate!: number;

  public static Factory(appData: IDataApplication) {
    const application = new Application();
    const date: number = parseInt(appData.creationDate);
    application.appName = appData.appId;
    application.appType = appData.appType;
    application.codeRepoName = appData.appReference;
    application.appSource = SourceType.ARTIFACTORY;

    switch (application.appType) {
      case ApplicationType.SINGLE_SERVICE:
        application.appSource = SourceType.GITHUB;
        application.appPackage = PackageType.SOURCE_CODE;
        break;
      case ApplicationType.QNX:
        application.appPackage = PackageType.CONAN;
        break;
      case ApplicationType.LINUX_NATIVE:
        application.appPackage = PackageType.CONAN;
        break;
      case ApplicationType.ANDROID:
        application.appPackage = PackageType.APK;
        break;
      case ApplicationType.CLASSIC_AUTOSAR_IMAGE:
        application.appPackage = PackageType.FIRMWARE_IMAGE;
        break;
      case ApplicationType.ADAPTIVE_AUTOSAR:
        application.appPackage = PackageType.UCM;
        break;
    }
    application.creationDate = isNaN(date) ? -1 : date;
    return application;
  }
}
