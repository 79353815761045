@if (runId) {
  <h3>Successful<mat-icon>done</mat-icon></h3>
  <div class="info">
    <span class="key">Run ID</span>
    <span class="value">{{ runId }}</span>
  </div>
} @else {
  <h3>{{ newJob.workflow }}</h3>
  <div class="info">
    <span class="key">Input</span>
    <span class="value">{{ newJob.input_location }}</span>
  </div>
  <div class="info">
    <span class="key">Output</span>
    <span class="value">{{ newJob.output_location }}</span>
  </div>

  @if (newJob.workflow_configuration; as jobConfig) {
    @for (job of jobConfig[0] | keyvalue; track job.key) {
      <div class="info">
        <span class="key">{{ job.key }}</span>
        <span class="value">{{ job.value }}</span>
      </div>
    }
  }
}
