import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
  MatTreeModule,
} from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { ITreeNode, TreeNode } from '../../models/tree-node';
import { FlatNode } from '../../models/flat-node';

@Component({
  selector: 'app-reporting-tree-view',
  standalone: true,
  imports: [
    CommonModule,
    MatTreeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
  ],
  templateUrl: './reporting-tree-view.component.html',
  styleUrls: ['./reporting-tree-view.component.scss'],
})
export class ReportingTreeViewComponent implements OnInit {
  @Input() data!: TreeNode[];
  @Output() selectedNodesEmitter: EventEmitter<FlatNode[]> = new EventEmitter();

  workflow = '';
  selectedNodes: FlatNode[] = [];
  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.sets,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  ngOnInit(): void {
    this.dataSource.data = this.data;
    this.expandAllNodes(this.treeControl.dataNodes);
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  private expandAllNodes(dataNodes: FlatNode[]) {
    if (!dataNodes) return;
    dataNodes.forEach((node) => this.treeControl.expand(node));
  }

  private transformer(node: ITreeNode, level: number): FlatNode {
    return {
      expandable: !!node.sets && node.sets.length > 0,
      name: node.root_url || node.url,
      isLeaf: node.is_leaf,
      level: level,
      id: node.id,
    };
  }

  selectNode(node: FlatNode): void {
    const index = this.selectedNodes.indexOf(node);
    if (index > -1) {
      this.selectedNodes.splice(index, 1);
    } else {
      this.selectedNodes.push(node);
    }
    this.selectedNodesEmitter.emit(this.selectedNodes);
  }
}
