import { Injectable } from '@angular/core';
import { PlaylistDataset } from '../../job-monitoring/models/playlist-dataset';
import { Store } from '../../../core/models/classes/abstract.store';
import { IState } from '../../../shared/stores/State';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlaylistStoreService extends Store<IState<PlaylistDataset[]>> {
  constructor() {
    const initState = {
      data: [],
      isLoading: true,
      hasError: false,
    };
    super(initState);
  }

  get playlist$(): Observable<PlaylistDataset[]> {
    return this.state$.pipe(map((playlist) => playlist.data));
  }

  get isLoading$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.isLoading));
  }

  get hasError$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.hasError));
  }
}
