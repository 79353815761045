import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { ProjectsStore } from 'src/app/shared/stores/projects/projects.store';
import { ConfigStore } from 'src/app/shared/stores/config/config.store';
import { AppConfig } from 'src/app/shared/stores/config/models/appConfig';
import { Tenant } from '../../models/enums/Tenant';
import { environment } from '../../../../environments/environment';
import { ParseFeaturesFromJson } from './parse-features-from-json.service';
import { ProjectsApiService } from '../api/projectsApi/projects-api.service';
import {
  ProjectConfig,
  ServiceConfig,
} from '../../../shared/stores/config/models/projectConfig';
import { AppConfigInstanceType } from '../../../shared/stores/config/models/appConfigInstanceType';
import {
  ProjectsByTenant,
  ProjectStatus,
} from '../../../shared/stores/projects/models/project';

import { BannerService } from '../../../shared/services/banner/banner.service';
import { Banner } from '../../../shared/layout/banner/models/banner';
import { BannerType } from '../../../shared/layout/banner/models/banner-type';
import { BannerConfig } from '../../../shared/layout/banner/models/banner-config';
import { cloneDeep } from 'lodash';
import {
  BlueprintDeployment,
  BlueprintType,
} from '../../../shared/stores/config/models/blueprintDeployments';
import { isFeatureConfigArray } from '../../../shared/stores/config/models/featureConfig';
import { FeatureService } from './feature.service';
import { FeatureConfig } from '../../../../environments/featureConfig';
import { TenantsByUser } from 'src/app/shared/models/tenants';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  isBluePrintServiceReady: boolean;

  constructor(
    private http: HttpClient,
    private configStore: ConfigStore,
    private projectsStore: ProjectsStore,
    private projectsApiService: ProjectsApiService,
    private parseFeaturesFromJsonService: ParseFeaturesFromJson,
    private bannerService: BannerService,
    private featureService: FeatureService,
  ) {
    this.projectsStore.setState({
      ...this.projectsStore.state,
      projects: [...this.projectsStore.state.projects],
    });

    this.isBluePrintServiceReady = this.featureService.isFeatureEnabled(
      FeatureConfig.bluePrintServiceReady,
    );
  }

  /**
   * This function is used to fetch the JSON config structure
   * required to build the routes and sidebar items present
   * in the defined projects. Config files are tenant specific,
   *  and the projectId and applicationId need to be passed down
   *  as arguments to be able to build the sidebar item urls.
   *
   *  This function is being used in the APP_INITIALIZER initialization,
   *  before the router navigation is enabled. This allows us
   *  to fetch and build all required routes before allowing navigation
   *  in the application.
   *
   *  If the tenant argument is NO_TENANT, then it means we are not
   *  authenticated and we don't build any dynamic routes
   *
   * @param {Tenant} tenant - [The tenant to which the config structure belongs]
   * @param {string} projectId - [Id of the current project taken from the url ('' by default)]
   * @param {string} applicationId - [Id of the current application('' by default)]
   * @returns {Observable<AppConfig>} [Observable containing the raw AppConfig structure]
   */
  getConfigJSON(
    tenant: Tenant,
    projectId: string,
    applicationId: string,
  ): Observable<AppConfig> {
    if (tenant === Tenant.NO_TENANT) {
      return this.handleNoTenant();
    }

    const tenantName = tenant.toLowerCase();
    const url = `assets/static/config-${tenantName}-${environment.AWS_STAGE}.json`;
    const config = this.http.get<AppConfig>(url).pipe(
      tap((project) => {
        if (!project?.banner) return;
        this.showDisclaimerBanner(project.banner);
      }),
      catchError((err) => {
        console.log(err);
        const emptyAppConfic: AppConfig = {
          projects: [],
        };
        return of(emptyAppConfic);
      }),
    );
    const apiProjectsAsConfig: Observable<AppConfig> = this.projectsApiService
      .getTenantsFromJWT()
      .pipe(
        //get all tenant for this jwt token
        switchMap((tenantList) => {
          //retrieve all tenant ids
          return this.getTenantsFromJWT(tenantList);
        }),
        //map projectsByTenant to AppConfig
        switchMap((projects: ProjectsByTenant) => {
          return this.getAppConfigFromApiProjects(projects);
        }),
      );
    //combine local and api projects
    let allProjects: Observable<AppConfig> = this.getCombinedProjects(
      config,
      apiProjectsAsConfig,
      tenant,
    );
    return allProjects.pipe(
      tap((appConfig: AppConfig) => {
        this.updateStores(appConfig, projectId, applicationId);
      }),
      catchError((err) => {
        return this.handleError(err);
      }),
    );
  }

  showDisclaimerBanner(banner: BannerConfig) {
    const bannerConfig: Banner = {
      isClosable: false,
      type: banner.type as BannerType,
      title: banner.title,
      icon: banner.icon,
    };
    this.bannerService.createBanner(bannerConfig);
  }

  getCCPTenantId(): Observable<number> {
    return this.projectsApiService.getTenantsFromJWT().pipe(
      map((tenantList) => {
        //retrieve all tenant ids
        return tenantList.tenants.map((tenant) => {
          return tenant.CCPRealmId;
        });
      }),
      map((tenantIds) => {
        return tenantIds[0];
      }),
    );
  }

  getTenantId(): Observable<string> {
    return this.projectsApiService.getTenantsFromJWT().pipe(
      map((tenantList) => {
        //retrieve all tenant ids
        return tenantList.tenants.map((tenant) => {
          return tenant.skey;
        });
      }),
      map((tenantIds) => {
        if (tenantIds.length === 1) return tenantIds[0];
        if (tenantIds.length === 0) return '';
        // at the moment only Caedge and Aurora have multiple tenants and only for Caedge we need this feature
        // ToDo change it as soon only one tenant id is used or the ui is used for ageda too
        return 'T002';
      }),
    );
  }

  updateApiServices(projectId: string): Observable<BlueprintDeployment[]> {
    if (this.configStore.isProjectServiceAlreadyFetched(projectId))
      return of([]);

    return this.projectsApiService
      .getProjectBlueprintDeployments(projectId)
      .pipe(
        tap((apiServices: BlueprintDeployment[]) => {
          if (apiServices.length !== 0) {
            const updatedAppConfig: AppConfig = this.addServicesIntoProjects(
              apiServices,
              projectId,
            );
            this.configStore.setState(updatedAppConfig);
            this.configStore.loadDynamicProjectRoutes(projectId, '');
          }
          this.configStore.addFetchedProjectId(projectId);
        }),
        catchError((err) => {
          console.error(err);
          return of([]);
        }),
        take(1),
      );
  }

  /**
   * There is still the need to combine local and blueprint services of one project,
   * because not all services (example: data and sim) are available as blueprints
   * currently.
   */
  combineLocalAndBlueprintService(
    blueprintServiceConfig: ProjectConfig,
    localProjectConfig: ProjectConfig,
    newAppConfig: AppConfig,
  ): AppConfig {
    // parse blueprint services to match it with the already parsed local services
    const blueprintServices =
      this.parseFeaturesFromJsonService.populateWithFeatures({
        projects: [blueprintServiceConfig],
      })[0].children;

    const currentServices = localProjectConfig.children;

    /**
     * Combine blueprint services and local service - BP services should be prioritized:
     * That means that if the same local service exists already it should be replaced with the bp service.
     * In case no local service exists already: The BP service should just be added
     */
    if (
      blueprintServices &&
      currentServices &&
      isFeatureConfigArray(currentServices) &&
      isFeatureConfigArray(blueprintServices)
    ) {
      blueprintServices.forEach((featureConfig) => {
        // check if FeatureConfig is already in use per default (example dashboard or project team)
        const indexToUpdate = localProjectConfig.children?.findIndex(
          (child) => child.label === featureConfig.label,
        );
        // check if a local service for this specific bp service exists already
        if (indexToUpdate !== undefined && indexToUpdate >= 0) {
          // if so - replace it with the bp service
          localProjectConfig.children?.splice(indexToUpdate, 1, featureConfig);
        } else if (
          !featureConfig?.id ||
          !currentServices.some(
            (blueprintFeature) => blueprintFeature.id === featureConfig.id,
          )
        ) {
          // if not (and it is a 'real' service no divider or similar) - add the bp service to the config
          localProjectConfig.children?.push(featureConfig);
        }
      });
    }

    // replace single project entry in app config
    newAppConfig.projects[
      newAppConfig.projects.findIndex(
        (project) => project.id === localProjectConfig.id,
      )
    ] = localProjectConfig;
    return newAppConfig;
  }

  private handleNoTenant(): Observable<AppConfig> {
    this.projectsStore.setState({
      projects: [],
      isLoading: false,
      hasError: false,
    });
    return of({ projects: [] });
  }

  private updateStores(
    appConfig: AppConfig,
    projectId: string,
    applicationId: string,
  ) {
    const newConfig = {
      projects:
        this.parseFeaturesFromJsonService.populateWithFeatures(appConfig),
    };
    this.configStore.initState(newConfig, projectId, applicationId);
    this.projectsStore.setState({
      projects: appConfig.projects.map((project) => ({
        id: project.id,
        abbreviation: project.abbreviation,
        label: project.label,
        description: project.description,
        createdDate: project.createdDate,
        creatorName: project.creatorName,
        receivedAt: project.receivedAt,
        statusApprovalDeletion: project.statusApprovalDeletion,
      })),
      isLoading: false,
      hasError: false,
    });
  }

  private handleError(err: any) {
    this.projectsStore.setState({
      projects: [],
      isLoading: false,
      hasError: true,
    });
    console.error(err);
    return of({ projects: [] });
  }

  private getTenantsFromJWT(
    tenantList: TenantsByUser,
  ): Observable<ProjectsByTenant> {
    //retrieve all tenantIds
    const tenantIds: string[] = tenantList.tenants.map((tenant) => {
      return tenant.skey;
    });
    const projectList: Observable<ProjectsByTenant>[] = [];
    //get all observables for getting the projects by tenant
    tenantIds.forEach((value) =>
      projectList.push(this.projectsApiService.getProjects(value)),
    );
    if (tenantIds.length <= 1) {
      return projectList[0];
    }
    //combine multiple observables into one
    return forkJoin(projectList).pipe(
      map((projects) => {
        return this.combineProjectsByTenants(projects);
      }),
    );
  }

  private combineProjectsByTenants(projects: ProjectsByTenant[]) {
    const result: ProjectsByTenant = { projects: [] };
    const projectsFirstTenant = 0;
    const projectsSecondTenant = 1;
    result.projects = [
      ...projects[projectsFirstTenant].projects.concat(
        projects[projectsSecondTenant].projects,
      ),
    ];
    return result;
  }

  private getAppConfigFromApiProjects(
    projects: ProjectsByTenant,
  ): Observable<AppConfig> {
    const projectConfig: ProjectConfig[] = [];
    projects.projects.forEach((project) => {
      // show only the projects which are not deleted
      if (project.Status === ProjectStatus.READY) {
        projectConfig.push({
          id: project.pkey + '-' + project.skey,
          description: project.Description,
          type: AppConfigInstanceType.PROJECT,
          label: project.Name,
          receivedAt: project.ReceivedAt,
          statusApprovalDeletion: project.StatusApprovalDeletion,
          creatorName:
            project.Contacts.ProjectOwner?.Name === ''
              ? project.Contacts.ProjectOwner?.Email
              : project.Contacts.ProjectOwner?.Name,
          ...project,
        });
      }
    });

    return of({
      projects: projectConfig,
    });
  }

  private getCombinedProjects(
    config: Observable<AppConfig>,
    apiProjectsAsConfig: Observable<AppConfig>,
    tenant: string,
  ): Observable<AppConfig> {
    return forkJoin([config, apiProjectsAsConfig]).pipe(
      map((projects) => {
        const localProjects = 0;
        const apiProjects = 1;
        if (
          !projects[localProjects] ||
          !projects[localProjects].projects ||
          projects[localProjects].projects.length === 0
        ) {
          if (!projects[apiProjects]) {
            return {
              projects: [],
            };
          }
          return projects[apiProjects];
        }
        this.mapChildren(projects[localProjects], projects[apiProjects]);
        // For playground tenant only the users own project should be displayed, which are fetched from the api
        if (tenant === 'ACQUISITION') {
          return projects[apiProjects];
        }
        return this.combineAppConfigs(
          projects[localProjects],
          projects[apiProjects],
        );
      }),
    );
  }

  private combineAppConfigs(
    localProjects: AppConfig,
    apiProjects: AppConfig,
  ): AppConfig {
    const result: AppConfig = { projects: [] };
    result.projects = [...localProjects.projects.concat(apiProjects.projects)];
    return result;
  }

  private mapChildren(localConfig: AppConfig, apiConfig: AppConfig) {
    apiConfig.projects.map((apiProject) => {
      const localProject = localConfig.projects.find(
        (localProject) => localProject.id === apiProject.id,
      );
      if (localProject) {
        apiProject.children = localProject.children;
        localConfig.projects = localConfig.projects.filter(
          (project) => project.id !== apiProject.id,
        );
      }
    });
  }

  private addServicesIntoProjects(
    blueprintDeployments: BlueprintDeployment[],
    projectId: string,
  ): AppConfig {
    const newAppConfig = this.configStore.state;
    const currentProjectConfig = newAppConfig.projects.find(
      (project) => project.id === projectId,
    );
    if (currentProjectConfig) {
      return this.handleBlueprints(
        blueprintDeployments,
        currentProjectConfig,
        newAppConfig,
      );
    }
    return newAppConfig;
  }

  private handleBlueprints(
    blueprintDeployments: BlueprintDeployment[],
    currentProjectConfig: ProjectConfig,
    newAppConfig: AppConfig,
  ): AppConfig {
    const newServiceConfig = cloneDeep(currentProjectConfig);
    newServiceConfig.children = [];

    blueprintDeployments.forEach((blueprintDeployment) => {
      const tenantID = blueprintDeployment.TenantId;
      const accountName = this.extractAccountNumber(
        blueprintDeployment.AccountName,
      );
      const stage = blueprintDeployment.Stage;
      let singleConfig: ServiceConfig = {
        serviceId: '',
        features: [],
        api: {},
      };

      const vECUConfig: ServiceConfig = {
        serviceId: '',
        features: [],
        api: {},
      };


      blueprintDeployment.BlueprintProduct.Blueprints.forEach((blueprint) => {
        const baseUrl = environment.COGNITO_CONFIG.BASE_HDK_API_URL;
        const startUrl = environment.COGNITO_CONFIG.BASE_HDK_API_URL_START;
        const startVhpcUrl = environment.COGNITO_CONFIG.BASE_VHPC_API_URL_START;
        const startObservabilityUrl =
          environment.COGNITO_CONFIG.BASE_HDK_OBSERVABILITY_API_URL;
        const apiUrl = `https://${startUrl}.${stage}-${accountName}.${tenantID}.${baseUrl}`;
        const vhpcAPiUrl = `https://${startVhpcUrl}.${stage}-${accountName}.${tenantID}.${baseUrl}`;
        const grafanaURL = `https://${startObservabilityUrl}.${stage}-${accountName}.${tenantID}.${baseUrl}`;
        const startVecuURL = environment.COGNITO_CONFIG.BASE_VECU_API_URL_START;
        const vECUrl = `https://${startVecuURL}.${stage}-${accountName}.${tenantID}.${baseUrl}`;

        if (blueprint.BlueprintType === BlueprintType.vECU) {
          // ToDo add here the BlueprintType for obs, hdk and vhpc in a switch instead of if statement
          vECUConfig.serviceId = 'VECU_CREATOR';
          vECUConfig.features = [];
          vECUConfig.label = 'vECU Creator',
          vECUConfig.api = {
              vecu: vECUrl
            };

          newServiceConfig.children?.push(vECUConfig);
          singleConfig = {
            serviceId: '',
            features: [],
            api: {},
          };

        }

        switch (blueprint.BlueprintType) {
          case BlueprintType.HDK:
            singleConfig.serviceId = 'HDK';
            singleConfig.features = ['HDK'];
            singleConfig.api = {
              ...singleConfig.api,
              dev: apiUrl,
              toolchain: apiUrl,
            };
            break;
          case BlueprintType.VHPC:
            singleConfig.serviceId = 'HDK';
            singleConfig.features = ['HDK'];
            singleConfig.api = { ...singleConfig.api, vhpc: vhpcAPiUrl };
            break;
          case BlueprintType.Observability:
            singleConfig.serviceId = 'HDK';
            singleConfig.features = ['HDK'];
            singleConfig.api = {
              ...singleConfig.api,
              grafana: grafanaURL,
              observability: apiUrl,
            };
            break;
        }
      });

      newServiceConfig.children?.push(singleConfig);

      singleConfig = {
        serviceId: '',
        features: [],
        api: {},
      };
      
    });

    return this.combineLocalAndBlueprintService(
      newServiceConfig,
      currentProjectConfig,
      newAppConfig,
    );
  }

  private extractAccountNumber(accountName: string): string {
    const splittedArray: string[] = accountName.split('-');
    return splittedArray[splittedArray.length - 1];
  }
}
