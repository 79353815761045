import { Component, inject } from '@angular/core';
import { SelectedDeviceService } from '../../services/selected-device/selected-device.service';
import { CommonModule } from '@angular/common';
import { CheckDeleteStatusPipe } from '../../../../core/pipes/check-delete-status.pipe';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-delete-multiple-devices',
  standalone: true,
  imports: [CommonModule, CheckDeleteStatusPipe, MatIcon],
  templateUrl: './delete-multiple-devices.component.html',
  styleUrl: './delete-multiple-devices.component.scss',
})
export class DeleteMultipleDevicesComponent {
  private selectedDeviceService = inject(SelectedDeviceService);

  devices = this.selectedDeviceService.devices$;
  loading = this.selectedDeviceService.loading$;
  deletionResponse = this.selectedDeviceService.deleteDeviceResponse$;
}
