import { BehaviorSubject, Observable } from 'rxjs';

/**
 * This class should be used to hold state in a reactive
 * manner. It uses a generic argument for the type of the
 * state stored in it.
 *
 * Any kind of state management services should inherit
 * from this class and pass a type argument for the desired
 * state type
 * @type T - [Type of the state managed in the store]
 */
export class Store<T> {
  private _state$: BehaviorSubject<T>;

  protected constructor(initialState: T) {
    this._state$ = new BehaviorSubject(initialState);
  }

  get state$(): Observable<T> {
    return this._state$.asObservable();
  }

  get state(): T {
    return this._state$.getValue();
  }

  setState(nextState: T): void {
    this._state$.next(nextState);
  }
}
