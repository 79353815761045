<div class="update-dialogue-box">
  <ng-container *ngIf="updateType === UpdateType.DEFAULT">
    <div
      class="dialogue-header"
      data-cy="default-update-title"
      mat-dialog-title
    >
      {{ "Workspaces.UpdateWorkspaces.DefaultUpdate.Title" | translate }}
    </div>
    <div class="description" data-cy="default-update-description">
      (Default setting)<br />{{
        "Workspaces.UpdateWorkspaces.DefaultUpdate.Description" | translate
      }}
    </div>
    <app-update-version
      [currentImageVersion]="instance.currentImageVersion"
      [updatedVersion]="instance.updatedVersion"
    >
    </app-update-version>

    <app-dialog-hint
      data-cy="update-information"
      [isWarning]="false"
      [message]="'Workspaces.UpdateWorkspaces.UpdateInfo' | translate"
    ></app-dialog-hint>
  </ng-container>

  <ng-container *ngIf="updateType === UpdateType.SCHEDULE">
    <div
      class="dialogue-header"
      data-cy="schedule-update-title"
      mat-dialog-title
    >
      {{ "Workspaces.UpdateWorkspaces.ScheduleUpdate.Title" | translate }}
    </div>
    <div class="description" data-cy="schedule-update-desciption">
      {{ "Workspaces.UpdateWorkspaces.ScheduleUpdate.Description" | translate }}
    </div>
    <div class="schedule-input" data-cy="schedule-update-input">
      <mat-form-field appearance="fill">
        <mat-label>Choose a date</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          placeholder=" "
          [(ngModel)]="scheduleDate"
          [min]="minDate"
          (dateChange)="onDateSelected($event.value)"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <app-update-version
      [currentImageVersion]="instance.currentImageVersion"
      [updatedVersion]="instance.updatedVersion"
    >
    </app-update-version>
    <app-dialog-hint
      data-cy="schedule-information"
      [isWarning]="false"
      [message]="'Workspaces.UpdateWorkspaces.UpdateInfo' | translate"
    ></app-dialog-hint>
  </ng-container>

  <ng-container *ngIf="updateType === UpdateType.IMMEDIATE">
    <div
      class="dialogue-header"
      data-cy="immediate-update-title"
      mat-dialog-title
    >
      {{ "Workspaces.UpdateWorkspaces.ImmediateUpdate.Title" | translate }}
    </div>
    <div class="description" data-cy="immediate-update-description">
      {{
        "Workspaces.UpdateWorkspaces.ImmediateUpdate.Description" | translate
      }}
    </div>
    <app-update-version
      [currentImageVersion]="instance.currentImageVersion"
      [updatedVersion]="instance.updatedVersion"
    >
    </app-update-version>
    <app-dialog-hint
      data-cy="immediate-update-information"
      [isWarning]="false"
      [message]="'Workspaces.UpdateWorkspaces.UpdateInfo' | translate"
    ></app-dialog-hint>
    <app-dialog-hint
      data-cy="immediate-update-warning"
      [isWarning]="true"
      [message]="'Workspaces.UpdateWorkspaces.UpdateWarning' | translate"
    ></app-dialog-hint>
  </ng-container>
</div>
