import { Component, Input, OnDestroy, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { StepComponent } from '../../models/stepComponent';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { User } from '../../../../../../../shared/models/tenantUsers';
import {
  filterContacts,
  getUserMail,
  mapUserToContact,
  ValidateUser,
} from '../../utils/utils';
import { CaeButtonComponent } from '../../../../../../../shared/components/cae-button/cae-button.component';
import { Subject, takeUntil } from 'rxjs';
import { Billing } from 'src/app/shared/models/billing';

export const percentagePattern =
  '(^100(\\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\\.[0-9]{1,2})?$)';

/**
 * `StepComponent` implementation for the billing information step of the project creation process.
 *
 * @export
 * @class BillingInformationComponent
 */
@Component({
  selector: 'app-billing-information',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CaeButtonComponent,
  ],
  templateUrl: './billing-information.component.html',
  styleUrls: ['./billing-information.component.scss'],
})
export class BillingInformationComponent implements OnDestroy, StepComponent {
  totalPercentage = signal<number>(100);
  percentageValid = signal<boolean>(true);

  readonly maxBillingEntries = 10;
  readonly validPercentage = 100;

  billingFormGroup = this.fb.group({
    controllingContact: new FormControl('', [
      Validators.required,
      ValidateUser,
    ]),
    percentage: new FormControl('50', [
      Validators.required,
      Validators.pattern(percentagePattern),
    ]),
    costCenter: new FormControl('', [Validators.required]),
    costCenterOwner: new FormControl('', [Validators.required, ValidateUser]),
    legalEntity: new FormControl('', [Validators.required]),
    wbsElement: new FormControl(''),
  });

  formGroup: FormGroup = this.fb.group({
    billingContact: ['', [Validators.required, ValidateUser]],
    billingGroups: this.fb.array([
      new FormGroup({
        controllingContact: new FormControl('', [
          Validators.required,
          ValidateUser,
        ]),
        percentage: new FormControl(this.validPercentage.toString(), [
          Validators.required,
          Validators.pattern(percentagePattern),
        ]),
        costCenter: new FormControl('', [Validators.required]),
        costCenterOwner: new FormControl('', [
          Validators.required,
          ValidateUser,
        ]),
        legalEntity: new FormControl('', [Validators.required]),
        wbsElement: new FormControl(''),
      }),
    ]),
  });

  billings = this.formGroup.get('billingGroups') as FormArray;
  unsubscribe$ = new Subject<void>();

  @Input() tenantUsers: User[] | null = [];
  filteredUsers: User[] | undefined;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
  ) {
    this.filteredUsers = this.tenantUsers?.slice();
    this.billings.controls[0]
      .get('percentage')
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.updateTotalPercentage());
  }

  get dataToUpdate() {
    const formValue = this.formGroup.getRawValue();
    const billingInformation: Billing[] = [];
    this.billings.controls.forEach((billingEntry) => {
      billingInformation.push({
        Percentage: (
          parseInt(billingEntry.get('percentage')?.value, 10) / 100
        ).toString(),
        ControllingContact: billingEntry.get('controllingContact')?.value.email,
        LegalEntity: billingEntry.get('legalEntity')?.value,
        OrgMapping: {
          CostCenter: billingEntry.get('costCenter')?.value,
          CostCenterOwner: billingEntry.get('costCenterOwner')?.value.email,
        },
        WBSElement: billingEntry.get('wbsElement')?.value,
      });
    });
    return {
      Contacts: {
        Billing: mapUserToContact(formValue.billingContact),
      },
      Billing: billingInformation,
    };
  }

  get stepValid(): boolean {
    return this.formGroup.valid && this.percentageValid();
  }

  getUserMail(option: User) {
    return getUserMail(option);
  }

  filter(inputField: HTMLInputElement): void {
    this.filteredUsers = filterContacts(inputField, this.tenantUsers);
  }

  addBillingEntry() {
    this.billings.push(this.billingFormGroup);
    this.billings.controls[this.billings.controls.length - 1]
      .get('percentage')
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.updateTotalPercentage());
    this.updateTotalPercentage();
  }

  updateTotalPercentage() {
    let totalPercentage: number = 0;
    this.billings.controls.forEach((control) => {
      totalPercentage =
        totalPercentage + parseFloat(control.get('percentage')?.value);
    });
    this.totalPercentage.set(totalPercentage);
    this.percentageValid.set(totalPercentage === this.validPercentage);
  }

  removeBillingEntry(index: number) {
    this.billings.removeAt(index);
    this.updateTotalPercentage();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
