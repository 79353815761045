export interface BackendDataReportsConfiguration {
    reports: BackendDataReports;
    schedule: string;
};

export interface AdjustedReportsConfiguration {
    reports: Reports;
    schedule: string;
};

export class ReportsConfiguration implements AdjustedReportsConfiguration {
    reports!: Reports;
    schedule!: string;

    public static Factory(dataReportConfiguration: BackendDataReportsConfiguration): ReportsConfiguration {
        const newReportConfiguration = new ReportsConfiguration();
        newReportConfiguration.reports = Reports.Factory(dataReportConfiguration.reports);
        newReportConfiguration.schedule = dataReportConfiguration.schedule;
        return newReportConfiguration;
    }
};

export interface BackendDataReports {
    'yield-report': Report;
};

export interface AdjustedReports {
    yieldReport: Report
}

export class Reports implements AdjustedReports {
    yieldReport!: Report;

    public static Factory(dataReport: BackendDataReports): Reports {
        const newReports = new Reports();
        newReports.yieldReport = dataReport['yield-report'];
        return newReports;
    };
}

export interface Report {
    name: string;
    source: string;
};
