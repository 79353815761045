import { AwsStage } from '../app/core/models/enums/AwsStage';
import { cognitoConfig } from '../app/core/services/auth/cognitoConfig';
import { AwsOrg } from '../app/core/models/enums/AwsOrg';
import { FeatureConfig } from './featureConfig';

const transformVersion = (version: string) => {
  return version.substring(0, 5);
};

const features: FeatureConfig[] = [
  FeatureConfig.workbencheRequest,
  FeatureConfig.languageSelection,
  FeatureConfig.downloadDevTools,
  FeatureConfig.partitionDropdown,
  FeatureConfig.addNewProjectMembers,
  FeatureConfig.bluePrintServiceReady,
];

export const environment = {
  production: false,
  appVersion: transformVersion(require('../../package.json').version) + '-dev',
  AWS_STAGE: AwsStage.DEV,
  COGNITO_CONFIG: cognitoConfig[AwsOrg.PROD],
  enableGuard: true,
  features: features,
  projectId: 'dev-10020481',
};
