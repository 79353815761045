export interface Job {
  count: number;
  runs: Run[];
}

export interface CancelledJob {
  Entries: Array<{ EventId: string }>,
  FailedEntryCount: number
}

export interface Run {
  Caller: string;
  EndDateTime: string;
  RunID: string;
  StartDateTime: string;
  Status: string;
  SubmissionDateTime: string;
  WorkflowName: string;
}

export class Job {
  public static Factory(job: Job) {
    const newJob = new Job();
    Object.assign(newJob, job);
    return newJob;
  }
}
