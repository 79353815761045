import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'lodash';

@Pipe({
  name: 'checkArrayType',
  standalone: true,
})
export class CheckArrayTypePipe implements PipeTransform {
  transform(value: string | string[]): boolean {
    return isArray(value);
  }
}
