import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderTemplateComponent } from '../../shared/components/header/header-template.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { CaeButtonComponent } from '../../shared/components/cae-button/cae-button.component';
import { JobMonitoringService } from './services/job-monitoring.service';
import { Job, Run } from './models/job';
import { JobTableComponent } from '../../shared/components/job-table/job-table.component';
import { BehaviorSubject } from 'rxjs';
import { FeatureComponent } from '../../core/models/classes/feature.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AddJobDialogService } from './services/add-job-dialog.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

export type JobMonitoringApi =
  | 'jobMonitoringSimulation'
  | 'jobMonitoringReporting'
  | 'getProjects'
  | 'getWorkflows'
  | 'getInputDataSets'
  | 'getParameterTemplates'
  | 'playlist'
  | 'getProjectConfig'
  | 'createJob';

@Component({
  selector: 'app-job-monitoring',
  standalone: true,
  imports: [
    CommonModule,
    HeaderTemplateComponent,
    MatTabsModule,
    TranslateModule,
    CaeButtonComponent,
    JobTableComponent,
    MatProgressBarModule,
  ],
  templateUrl: './job-monitoring.component.html',
  styleUrls: ['./job-monitoring.component.scss'],
})
export class JobMonitoringComponent extends FeatureComponent<JobMonitoringApi> {
  loading = false;
  runs: Run[] | null = [];
  job$: BehaviorSubject<Job | null> = new BehaviorSubject<Job | null>(null);
  tab = CurrentTab.SIMULATION;
  currentTab = CurrentTab;

  constructor(
    private jobMonitoringService: JobMonitoringService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private addJobDialog: AddJobDialogService,
  ) {
    super();
    this.initializeContent();
  }

  initializeContent() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const tabIndexQueryParam: string = params['tabIndex'];
      if (tabIndexQueryParam === '') {
        this.getJobs(CurrentTab.SIMULATION);
      } else {
        const tabIndex: number = isNaN(+tabIndexQueryParam)
          ? CurrentTab.SIMULATION
          : +tabIndexQueryParam;
        this.getJobs(tabIndex);
        this.tab = tabIndex;
      }
    });
  }

  async getJobs(tab: CurrentTab) {
    try {
      if (!this.API) return;
      this.loading = true;
      const api =
        tab === CurrentTab.SIMULATION
          ? this.API.jobMonitoringSimulation
          : this.API.jobMonitoringReporting;

      const response = await this.jobMonitoringService.getJobs(api);
      this.job$.next(response);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  createJob() {
    if (!this.API) return;
    this.addJobDialog.openDialog(this.tab, this.API);
  }

  async onTabChange(index: number) {
    this.router.navigate([], {
      queryParams: {
        tabIndex: index,
        sort: null,
        sortedColumn: null,
        filterText: null,
      },
      relativeTo: this.route,
      replaceUrl: true,
    });

    this.tab = index;
    await this.getJobs(index);
  }
}

export enum CurrentTab {
  SIMULATION = 0,
  REPORTING = 1,
}
