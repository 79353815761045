@if (vecus.length === 0) {
	<app-empty-section
		[iconName]="EmptySectionIcon.COFFEE_MAKER"
		[emptySectionScope]="EmptySectionScope.PAGE">
		{{ "VecuList.NoVecus" | translate }}
	</app-empty-section>
} @else {
	<div
		data-cy="vecu-list-container"
		class="vecu-list-container">
		@for (vecu of vecus; track $index) {
			<app-vecu-list-item
				data-cy="vecu-list-item"
				[ngClass]="VecuColorStateMapping[vecu.state.color]"
				[vecu]="vecu"
			/>
		}
	</div>
}
