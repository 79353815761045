import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { Version } from '../deployment/models/version';
import { StartDeploymentState } from './models/startDeploymentState';
import { Device } from '../devices/models/device/device';
import { Application } from '../deployment/models/application';

@Injectable({
  providedIn: 'root',
})
export class StartDeploymentStore extends Store<StartDeploymentState> {
  constructor() {
    super({
      devices: [],
      versions: [],
      applications: [],
      areApplicationsLoading: true,
      areDevicesLoading: true,
      areVersionsLoading: true,
      hasError: false,
    });
  }

  get devices$(): Observable<Device[]> {
    return this.state$.pipe(map((state) => state.devices));
  }

  get applications$(): Observable<Application[]> {
    return this.state$.pipe(map((state) => state.applications));
  }

  get versions$(): Observable<Version[]> {
    return this.state$.pipe(map((state) => state.versions));
  }

  get isLoading$(): Observable<boolean> {
    return this.state$.pipe(
      map(
        (state) =>
          state.areDevicesLoading ||
          state.areVersionsLoading ||
          state.areApplicationsLoading
      )
    );
  }

  get hasError$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.hasError));
  }
}
