import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, Observable, of, tap } from 'rxjs';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { ProjectUsersStore } from 'src/app/shared/stores/project-users/projectUsers.store';
import { ProjectUserResponse } from 'src/app/shared/stores/project-users/models/project-user';
import { Method } from 'src/app/shared/stores/config/models/method';
import { environment } from 'src/environments/environment';
import { generatePath } from 'src/app/shared/utils/generatePath';
import {
  AddUsersPayload,
  RemoveUsersPayload,
} from '../model/user-roles-payload';

@Injectable({
  providedIn: 'root',
})
export class ProjectUsersApiService {
  private readonly BASE_URL = environment.COGNITO_CONFIG.BASE_DISPATCH_API_URL;

  constructor(
    private apiService: ApiService,
    private projectUsersStore: ProjectUsersStore,
  ) {}

  getProjectUsers(projectId: string): Observable<ProjectUserResponse> {
    const getProjectUsersUrl: string = `${this.BASE_URL}projects/:projectId/users?filter=serviceUsers`;
    const apiRecord: ApiRecord = {
      url: generatePath(getProjectUsersUrl, { projectId }),
      method: Method.GET,
    };
    return this.apiService.request<ProjectUserResponse>({ apiRecord }).pipe(
      tap((projectUsers) => {
        this.projectUsersStore.setState({
          projectUsers: projectUsers.Users,
          isLoading: false,
          hasError: false,
        });
      }),
      catchError((err) => {
        this.projectUsersStore.setState({
          projectUsers: [],
          isLoading: false,
          hasError: true,
        });
        return of(err);
      }),
    );
  }

  removeUser(projectId: string, users: string[]): Promise<string> {
    const usersPayload: RemoveUsersPayload = {
      users: users,
    };
    const apiRecord: ApiRecord = {
      url: generatePath(`${this.BASE_URL}projects/:projectId/service_roles`, {
        projectId,
      }),
      method: Method.DELETE,
    };
    const request = this.apiService.request<string>({
      apiRecord,
      body: usersPayload,
    });
    return lastValueFrom(request);
  }

  addUsersToProject(
    projectId: string,
    roleAssignments: Map<string, string[]>
  ): Promise<any> {
    const addUsersToProjectUrl: string = `${this.BASE_URL}projects/:projectId/service_roles`;
    const apiRecord: ApiRecord = {
      url: generatePath(addUsersToProjectUrl, { projectId }),
      method: Method.POST,
    };
    const payload: AddUsersPayload = {
      Users: roleAssignments
    };
    const request = this.apiService.request<any>({
      apiRecord,
      body: payload,
    });
    return lastValueFrom(request);
  }

  changeUserRole(
    projectId: string,
    userName: string,
    userRoles: string[],
  ): Observable<string> {
    const changeUserRoleUrl: string = `${this.BASE_URL}projects/:projectId/users/:userName/service_roles`;
    const apiRecord: ApiRecord = {
      url: generatePath(changeUserRoleUrl, { projectId, userName }),
      method: Method.PUT,
    };
    const userRolePayload = {
      Roles: userRoles,
    };

    return this.apiService.request<any>({
      apiRecord,
      body: userRolePayload,
    });
  }
}
