export enum FilterSections {
    ALL_SECTIONS_OVERLAP = 'ALL Sections Overlap',
    OK_ONLY = 'OK Only',
    NOK_ONLY = 'NOK Only'
}

export enum Sensors {
    SRR_ALL = 'SRR_ALL',
    SRR_REAR = 'SRR_REAR',
    SRR_RL = 'SRR_RL',
    SRR_RR = 'SRR_RR',
    SRR_FR = 'SRR_FR',
    SRR_FL = 'SRR_FL',
}

export enum DisplayedSensors {
    SRR_ALL = Sensors.SRR_ALL,
    SRR_REAR = Sensors.SRR_REAR,
    SRR_RL = Sensors.SRR_RL,
    SRR_RR = Sensors.SRR_RR,
}

export enum Rad6baseSensors {
    ARS = 'ARS',
    SRR_ALL = Sensors.SRR_ALL
}
