import { Injectable } from '@angular/core';
import { CreateProjectRequestBody } from '../../components/add-project-dialog/models/createProjectRequestBody';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../core/services/api/api.service';
import { Method } from '../../../../shared/stores/config/models/method';

/**
 * Service for handling api calls for projects.
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectApiService {
  baseApiUrl = environment.COGNITO_CONFIG.BASE_DISPATCH_API_URL;

  constructor(private apiService: ApiService) {}

  createProject(requestBody: CreateProjectRequestBody): Observable<any> {
    const createUrl = this.baseApiUrl + 'projects';
    return this.apiService.request<{ project: string }>({
      apiRecord: { url: createUrl, method: Method.POST },
      body: requestBody,
    });
  }
}
