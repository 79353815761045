import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHintComponent } from '../dialog-hint/dialog-hint.component';
import { UpdateType } from '../../models/update-workspace-request-body';
import { UpdateVersionComponent } from '../update-version/update-version.component';
import { Instance } from 'src/app/shared/stores/workbenches/models/workbenches';

type WorkspaceApi = 'getWorkspaceTypes' | 'createWorkspace' | 'updateWorkbench';

@Component({
    standalone: true,
    selector: 'app-update-workspace-steps',
    templateUrl: './update-workspace-steps.component.html',
    styleUrl: './update-workspace-steps.component.scss',
    imports: [
        CommonModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        FormsModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatTableModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        MatFormFieldModule,
        DialogHintComponent,
        MatDialogModule,
        UpdateVersionComponent
    ]
})
export class UpdateWorkspaceStepsComponent
  extends FeatureComponent<WorkspaceApi>
{
  @Input() instance!: Instance;
  @Input() updateType!: string;
  @Output() selectedDateChange: EventEmitter<string> = new EventEmitter<string>();  

  UpdateType = UpdateType;
  scheduleDate!: string;
  minDate: Date;
  constructor(
  ) {
    super();
    this.minDate = new Date();
  }

  onDateSelected(selectedDate: string) {
    this.selectedDateChange.emit(selectedDate);
  }

}
