import { inject, Injectable } from '@angular/core';
import { ContactRoles } from 'src/app/shared/models/contacts';
import { AuthStore } from 'src/app/shared/stores/auth/auth.store';
import { ProjectAdminRightsRole } from 'src/app/shared/models/contacts';

@Injectable({
  providedIn: 'root'
})
export class UserRightsService {

  private readonly authStore = inject(AuthStore);
  private readonly currentUserEmail?: string = this.authStore.state.user?.email;

  isCurrentUserOwnerOrCoOwner(projectContacts: ContactRoles[]): boolean {
    const adminContacts: ContactRoles[] =
      projectContacts.filter((contact) => {
        return (
          contact.role === ProjectAdminRightsRole.ProjectOwner ||
          contact.role === ProjectAdminRightsRole.ProjectCoOwner
        );
      });
    const isUserAdmin = adminContacts.some((adminContact) => adminContact.Email === this.currentUserEmail);

    return isUserAdmin;
  }
}
