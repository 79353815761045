import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  FormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { tap, Subject, takeUntil } from 'rxjs';
import { StepComponent } from 'src/app/core/components/home/components/add-project-dialog/models/stepComponent';
import { AddStagePlaylistDialogService } from '../../services/add-stage-playlist-dialog.service';
import {
  ChildCollectionContent,
  ChildCollectionRecordingContent,
  ChildCollectionRecordings,
  PlaylistMasterCollection,
} from '../../models/playlist-collections';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { PlaylistApi } from '../../playlist.component';
import { StepOneData } from '../../models/stage-data';

@Component({
  selector: 'app-add-stage-step-one',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    TranslateModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './add-stage-step-one.component.html',
  styleUrl: './add-stage-step-one.component.scss',
})
export class AddStageStepOneComponent
  implements OnInit, OnDestroy, StepComponent
{
  @Input({ required: true }) masterCollections:
    | PlaylistMasterCollection[]
    | null = [];
  @Input({ required: true }) api!: Record<PlaylistApi, ApiRecord>;
  @Input({ required: true }) apiKey!: string;

  @Output() stepOneEmitter = new EventEmitter<StepOneData>();

  selectedMasterCollection: string = '';
  selectedChildCollection: string = '';
  childCollections: ChildCollectionContent[] = [];
  private readonly unsubscribe$: Subject<void> = new Subject();

  formGroup: FormGroup = this.fb.group({
    stageOneGuids: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private playlistService: AddStagePlaylistDialogService,
  ) {}

  ngOnInit(): void {
    this.formGroup.updateValueAndValidity();
    this.initStageOneGuidsControl();
  }

  get stageOneGuidsControl() {
    return this.formGroup.get('stageOneGuids') as FormControl;
  }

  private initStageOneGuidsControl() {
    this.stageOneGuidsControl.valueChanges
      .pipe(
        tap(() => {
          this.stageOneGuidsControl.markAsTouched();
          const temporaryStageData: StepOneData = {
            guids: [this.stageOneGuidsControl.getRawValue()],
          };
          this.stepOneEmitter.emit(temporaryStageData);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  onMasterCollectionSelected(
    selectedMasterCollection: PlaylistMasterCollection | undefined,
  ): void {
    if (selectedMasterCollection) {
      this.playlistService
        .getChildCollections(
          this.api.getChildCollections,
          selectedMasterCollection.collection_id,
          this.apiKey,
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((childCollections) => {
          this.childCollections =
            childCollections[selectedMasterCollection.collection_id];
        });
    } else {
      this.selectedMasterCollection = '';
    }
  }

  onChildCollectionSelected(
    selectedChildCollection: ChildCollectionContent,
  ): void {
    if (selectedChildCollection) {
      this.playlistService
        .getChildCollectionRecordings(
          this.api.getChildCollectionRecordings,
          selectedChildCollection.collection_id,
          this.apiKey,
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((childCollectionRecordings: ChildCollectionRecordings) => {
          this.handleReceivedChildCollectionRecordings(
            childCollectionRecordings,
            selectedChildCollection,
          );
        });
    }
  }

  handleReceivedChildCollectionRecordings(
    childCollectionRecordings: ChildCollectionRecordings,
    selectedChildCollection: ChildCollectionContent,
  ): void {
    const guidsFromChildCollection: string[] =
      this.getGuidsFromChildCollectionRecordings(
        childCollectionRecordings[selectedChildCollection.collection_id],
      );

    const guidsListStringified: string = guidsFromChildCollection.join(',\n');

    this.formGroup.get('stageOneGuids')?.setValue(guidsListStringified);

    const temporaryStageData: StepOneData = {
      guids: guidsFromChildCollection,
    };
    this.stepOneEmitter.emit(temporaryStageData);
  }

  getGuidsFromChildCollectionRecordings(
    childCollectionRecordingsContents: ChildCollectionRecordingContent[],
  ): string[] {
    return childCollectionRecordingsContents.map(
      (childCollectionRecordingContent: ChildCollectionRecordingContent) =>
        `${childCollectionRecordingContent.guid}`,
    );
  }

  /**
   * Checks if the given FormControl is invalid and returns the corresponding error message.
   *
   * @param formControl the FormControl to validate
   * @return the corresponding error message or an empty string in case there is no error
   */
  getErrorMessage(formControl: FormControl | null): string {
    if (formControl) {
      if (formControl.hasError('required')) {
        return this.translate.instant(
          'Playlists.AddStageDialogCommon.FieldMandatory',
        );
      }
    }
    return '';
  }

  get dataToUpdate() {
    const formValue = this.formGroup.getRawValue();
    return {
      Collection: formValue.stageOneGuids,
      Guids: formValue.stageOneGuids,
    };
  }

  get stepValid(): boolean {
    return this.formGroup.valid;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
