import { Contact } from '../models/contact';
import { User } from '../../../../../../shared/models/tenantUsers';
import { AbstractControl } from '@angular/forms';
import { isEqual } from 'lodash';

export const mapUserToContact = (user: User | undefined): Contact => {
  const emptyValue = '';

  if (!user) {
    return {
      ContiUid: emptyValue,
      Email: emptyValue,
      Name: emptyValue,
      Phone: emptyValue,
    };
  }
  return {
    ContiUid: user.userid.includes('contiwan')
      ? user.userid.split('@')[0]
      : emptyValue,
    Email: user.email,
    Name: `${user.firstName} ${user.lastName}`,
    Phone: emptyValue,
  };
};

export const getUserMail = (option: User | null): string => {
  if (!option) return '';
  return option.email;
};

export const filterContacts = (
  inputField: HTMLInputElement,
  tenantUsers: User[] | null,
): User[] | undefined => {
  const filterValue = inputField.value.toLowerCase();
  const minCharSearch = 4;
  if (filterValue.length < minCharSearch) {
    return [];
  } else {
    return tenantUsers?.filter((user) =>
      user.email.toLowerCase().includes(filterValue),
    );
  }
};

export function ValidateUser(control: AbstractControl): {[key: string]: boolean} | null  {

  const userProperties = ['email', 'firstName', 'lastName', 'userid'];
  const userPropertyTypes = ['string', 'string', 'string', 'string'];

  let controlProperties:string[] = [];
  let controlPropertyTypes:string[] = [];

  if(control.value) {
      controlProperties = Object.keys(control.value);
      for(let property of controlProperties) {
          controlPropertyTypes.push(typeof control.value[property]);
        }
    }

  if(!isEqual(userProperties, controlProperties) || !isEqual(userPropertyTypes, controlPropertyTypes)) {
      return { 'emailNotSelected': true };
    }

  return null;
}
