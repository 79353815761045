@if (!loading) {
  <div class="root">
    <p>Select playlist(s)</p>
    <div class="table-container">
      <mat-form-field class="caedge-form-field-fill">
        <div class="search">
          <mat-icon
            (click)="clearInput($event)"
            data-cy="clear-search-icon"
            aria-label="search and clear"
          >
            {{ input.value.length > 0 ? "close" : "search" }}
          </mat-icon>
          <input
            matInput
            [(ngModel)]="searchInput"
            data-cy="jobs-search-input"
            (keyup)="applyFilter($event)"
            placeholder="Search..."
            #input
          />
        </div>
      </mat-form-field>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation(); setSelectedValue(row)"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- ID column -->
        <ng-container matColumnDef="ID">
          <th mat-header-cell *matHeaderCellDef># ID</th>
          <td
            mat-cell
            *matCellDef="let element"
            [matTooltip]="element.id"
            matTooltipPosition="right"
          >
            {{ element.id | truncate: 30 }}
          </td>
        </ng-container>

        <!-- Name column -->
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- Type column -->
        <ng-container matColumnDef="Type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element">{{ element.type }}</td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- No data row -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [colSpan]="displayedColumns.length">
            <app-empty-section> No data found </app-empty-section>
          </td>
        </tr>
      </table>
      <mat-paginator
        *ngIf="dataSource.data.length !== 0"
        [pageSize]="3"
        [pageSizeOptions]="[]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </div>
  </div>
} @else {
  <div class="loading">
    <mat-spinner></mat-spinner>
  </div>
}
