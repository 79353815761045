import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { Tenant } from 'src/app/core/models/enums/Tenant';
import { AuthState, AuthUser } from 'src/app/core/models/interfaces/authState';
import { JwtDecoder } from '../../utils/jwtDecoder';

export const initialState: AuthState = {
  user: null,
  tokens: null,
  tenant: Tenant.NO_TENANT,
};
@Injectable({
  providedIn: 'root',
})
export class AuthStore extends Store<AuthState> {
  private decoder = new JwtDecoder();

  constructor() {
    super(initialState);
  }

  get isAuthenticated$(): Observable<boolean> {
    return this.state$.pipe(
      map((auth) => auth.tokens !== null && auth.user !== null),
    );
  }

  get user$(): Observable<AuthUser> {
    return this.state$.pipe(map((auth) => auth.user!));
  }

  get userFullName$(): Observable<string> {
    return of(`${this.state.user?.firstName} ${this.state.user?.lastName}`);
  }

  get userId$(): Observable<string> {
    const jwtToken = this.state.tokens?.accessToken;
    return !jwtToken ? of('') : of(this.decoder.decodeJwt(jwtToken).sub);
  }

  get userId(): string {
    const jwtToken = this.state.tokens?.accessToken;
    return !jwtToken ? '' : this.decoder.decodeJwt(jwtToken).sub;
  }
}
