import { Injectable } from '@angular/core';
import {
  Observable,
  interval,
  switchMap,
  startWith,
  of,
  map,
  catchError,
} from 'rxjs';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { Vecu, VecuActionResponse, VecuBulkDeletionResponse, VecuCreatePayload, VecuCreateResponse, VecuDeletePayload, VecuRelease } from '../../models/vecu';
import { VecuStore } from '../../stores/vecus.store';
import { VecuCreationDialogCloseParams } from '../../models/vecu-creation-dialog-close-params';
import { Method } from 'src/app/shared/stores/config/models/method';
import { generatePath } from 'src/app/shared/utils/generatePath';


@Injectable({
  providedIn: 'root'
})
export class VecuApiService {
  private readonly fetchVecusIntervalInMs: number = 5000;

  constructor(
    private apiService: ApiService,
    private vecusStore: VecuStore
  ) { }

  getVecus(
    apiRecord: ApiRecord
  ): Observable<Vecu[]> {
    return this.apiService.request<Vecu[]>({
      apiRecord,
    });
  }

  getVecuReleases(
    apiRecord: ApiRecord
  ): Observable<VecuRelease[]> {
    return this.apiService.request<VecuRelease[]>({
      apiRecord,
    });
  }

  getVecusUsingActivePolling(
    apiRecord: ApiRecord
  ): Observable<Vecu[]> {
    return interval(this.fetchVecusIntervalInMs).pipe(
      startWith(this.apiService.request<Vecu[]>({ apiRecord })),
      switchMap(() => this.apiService.request<Vecu[]>({ apiRecord }).pipe(
        catchError((errorObj) => this.handleError(errorObj)),
      )),
    ).pipe(
      map((listOfVecus: Vecu[]) => {
        const updatedVecus = listOfVecus.map((vecu) => ({
          ...vecu,
          vecuConnectCommand: `vecu-devspace dev --var=VHPC_NAME=${vecu.vecuId}`
        }));
        return this.setVecuStore(updatedVecus);
      }),
    );
  }

  private setVecuStore(vecus: Vecu[]) {
    this.vecusStore.setState({
      ...this.vecusStore.state,
      vecus,
      isLoading: false,
      hasError: false,
    });
    return vecus;
  }

  private handleError(errorObject: any) {
    const errorStatusCode = errorObject.status;
    this.vecusStore.setState({
      ...this.vecusStore.state,
      vecus: [],
      isLoading: false,
      hasError: true,
      errorStatusCode: errorStatusCode,
    });
    return of([] as Vecu[]);
  }

  createVecu(
    apiRecord: ApiRecord,
    vEcuCreationData: VecuCreationDialogCloseParams
  ): Observable<VecuCreateResponse> {
    const requestBody: VecuCreatePayload = {
      displayName: vEcuCreationData.vEcuName,
      configuration: {
        configName: vEcuCreationData.vEcuConfiguration,
        version: vEcuCreationData.vEcuConfigurationVersion
      }
    };

    return this.apiService.request<VecuCreateResponse>({
      apiRecord,
      body: requestBody,
    });
  }

  deleteVecus(
    apiRecord: ApiRecord,
    vEcuNames: string[]
  ): Observable<VecuBulkDeletionResponse> {
    const requestBody: VecuDeletePayload = {
      vecuIdOrNames: vEcuNames
    };

    if (requestBody.vecuIdOrNames.length === 0) return of({ bulkDeletionStatus: [] });

    return this.apiService.request<VecuBulkDeletionResponse>({
      apiRecord,
      body: requestBody
    });
  }

  actionVecu(
    apiRecord: ApiRecord,
    vEcuId: string,
    command: string
  ): Observable<VecuActionResponse> {    
    return this.apiService.request<VecuActionResponse>({
      apiRecord: {
        method: Method.PUT,
        url: generatePath(apiRecord.url, { vecuIdOrName: vEcuId, cmd: command}),
      },
    });
  }
}
