import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { Option } from 'src/app/core/models/interfaces/option';

@Component({
  standalone: true,
  selector: 'app-select-menu[options][selectedOption][optionSelected]',
  templateUrl: './select-menu.component.html',
  styleUrls: ['./select-menu.component.scss'],
  imports: [CommonModule, CdkMenuModule],
})
export class SelectMenuComponent<T extends string> {
  @Input() options: Option<T>[] = [];
  @Input() selectedOption: Option<T> = this.options[0];
  @Output() optionSelected: EventEmitter<Option<T>> = new EventEmitter<
    Option<T>
  >();

  setSelectedOption(option: Option<T>): void {
    this.optionSelected.emit(option);
  }
}
