import { Component, OnDestroy, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderTemplateComponent } from '../../shared/components/header/header-template.component';
import { MatCardModule } from '@angular/material/card';
import {
  ActionCommand,
  Actions,
  CaeCardComponent,
} from '../../shared/components/cae-card/cae-card.component';
import { VdiRequestComponent } from './components/vdi-request/vdi-request.component';
import { FeatureComponent } from '../../core/models/classes/feature.component';
import { VdiRequestService } from './services/vdi-request.service';
import { VdiConfig } from './models/vdi-config';
import {
  actionsDelete,
  actionsRequest,
  actionsConfigs,
} from './models/action-buttons';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CaeButtonComponent } from '../../shared/components/cae-button/cae-button.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { FileService } from '../../core/services/file/file.service';
import { interval, Subject, Subscription, takeUntil } from 'rxjs';
import { SnackbarService } from '../../core/services/snackbar/snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatStepperModule } from '@angular/material/stepper';
import { VdiInstance } from './models/vdi-instance';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RequestVdiDialogComponent } from './components/request-vdi-dialog/request-vdi-dialog.component';
import { CopyToClipboardService } from '../../shared/utils/copy-to-clipboard.service';

export type VirtualDevelopmentInfrastructureApi =
  | 'getVDIConfigs'
  | 'downloadMatchboxScript'
  | 'getVDInstances'
  | 'delVDInstance'
  | 'putVDInstance';

@Component({
  selector: 'app-virtual-development-infrastructure',
  standalone: true,
  imports: [
    CommonModule,
    HeaderTemplateComponent,
    MatCardModule,
    CaeCardComponent,
    VdiRequestComponent,
    MatProgressSpinnerModule,
    MatStepperModule,
    CaeButtonComponent,
    MatSnackBarModule,
    TranslateModule,
  ],
  templateUrl: './virtual-development-infrastructure.component.html',
  styleUrls: ['./virtual-development-infrastructure.component.scss'],
})
export class VirtualDevelopmentInfrastructureComponent
  extends FeatureComponent<VirtualDevelopmentInfrastructureApi>
  implements OnDestroy
{
  requestAction: Actions[] = [actionsRequest];
  deleteAction: Actions[] = [actionsDelete];
  actionsConfigs = actionsConfigs;
  protected readonly ActionCommand = ActionCommand;
  loadingInstance = signal<boolean>(false);
  loadingConfig = signal<boolean>(false);
  vdiInstance = signal<VdiInstance | undefined>(undefined);
  vdiConfig = signal<VdiConfig | undefined>(undefined);
  projectName = '';
  private readonly poolingTime = 1500;
  private readonly unsubscribe$ = new Subject<void>();
  private requestIntervalSubscription: Subscription | undefined;

  constructor(
    private vdiRequestService: VdiRequestService,
    private fileService: FileService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private copyToClipboardService: CopyToClipboardService,
  ) {
    super();
    const urlPieces = window.location.href.split('/');
    this.projectName = urlPieces[urlPieces.length - 2];
    this.resolveAll();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private resolveAll() {
    Promise.all([this.getVdiInstances(false), this.getVdiConfigs()]).then();
  }

  async handleEvent(command: ActionCommand) {
    try {
      switch (command) {
        case ActionCommand.REQUEST:
          if ('srr620dp24' === this.projectName) {
            this.openRequestVdiDialog();
          } else {
            this.loadingInstance.set(true);
            await this.putVdiInstance();
          }
          break;
        case ActionCommand.DELETE:
          this.loadingInstance.set(true);
          await this.deleteVdiInstance();
          break;
        case ActionCommand.DOWNLOAD:
          await this.downloadScript();
          break;
        case ActionCommand.REFRESH:
          this.loadingConfig.set(true);
          await this.getVdiConfigs();
          break;
        case ActionCommand.COPY:
          this.copyToClipboard();
          break;
      }
    } catch (error) {
      this.snackbarService.notifyError('VDI.ActionFailed');
    } finally {
      if (command !== ActionCommand.REFRESH) {
        this.loadingInstance.set(false);
      } else {
        this.loadingConfig.set(false);
      }
    }
  }

  private copyToClipboard() {
    const copy = this.vdiConfig()?.elasticBeanstalkHostedUrl;
    if (!copy) return;
    this.copyToClipboardService.copyToClipboard(copy);
  }

  async getVdiInstances(isWaitingForRequest: boolean): Promise<void> {
    try {
      if (!this.API) return;
      this.loadingInstance.set(true);
      const request = await this.vdiRequestService.getVdiInstance(
        this.API.getVDInstances,
      );
      this.vdiInstance.set(request);
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 202) {
        this.vdiInstance.set(undefined);
      } else if (
        error instanceof HttpErrorResponse &&
        error.status !== 202 &&
        error.status !== 203
      ) {
        this.vdiInstance.set(undefined);
        this.snackbarService.notifyError('VDI.GetVdiFailed');
      }
    } finally {
      if (!isWaitingForRequest) {
        this.loadingInstance.set(false);
      }
    }
  }

  async getVdiConfigs(): Promise<void> {
    try {
      if (!this.API) return;
      this.loadingConfig.set(true);
      const request = await this.vdiRequestService.getVdiConfig(
        this.API.getVDIConfigs,
      );
      this.vdiConfig.set(request);
    } catch (err) {
      this.snackbarService.notifyError('VDI.VdiConnectionFailed');
    } finally {
      this.loadingConfig.set(false);
    }
  }

  async deleteVdiInstance() {
    try {
      if (!this.API) return;
      this.loadingInstance.set(true);
      await this.vdiRequestService.deleteVdiInstance(this.API.delVDInstance);
      await this.getVdiInstances(false);
    } catch (err) {
      this.snackbarService.notifyError('VDI.DeleteInstanceFailed');
    } finally {
      this.loadingInstance.set(false);
    }
  }

  async putVdiInstance() {
    try {
      if (!this.API) return;
      this.loadingInstance.set(true);
      await this.vdiRequestService.putVdiInstance(
        this.API.putVDInstance,
        this.projectName,
      );
      await this.waitForInstance();
    } catch (err: any) {
      this.snackbarService.notifyError('VDI.RequestInstanceFailed');
      this.loadingInstance.set(false);
      this.vdiInstance.set(undefined);
    }
  }

  private async waitForInstance() {
    this.loadingInstance.set(true);
    const source = interval(this.poolingTime);
    this.requestIntervalSubscription = source
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async () => {
        await this.getVdiInstances(true);
        if (this.vdiInstance() !== undefined) {
          this.requestIntervalSubscription?.unsubscribe();
          this.loadingInstance.set(false);
        }
      });
  }

  private async downloadScript() {
    try {
      if (!this.API) return;
      const filename = `caedge_vdi_script_${this.projectName}.ps1`;
      const blob = await this.vdiRequestService.downloadScript(
        this.API.downloadMatchboxScript,
      );
      this.fileService.downloadBlob(blob, filename);
      this.snackbarService.notifyInfo('VDI.ScriptDownloaded');
    } catch (err) {
      this.snackbarService.notifyError('VDI.ScriptDownloadFailed');
    }
  }

  private openRequestVdiDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    dialogConfig.data = {
      putVdiApiRecord: this.API?.putVDInstance,
      projectName: this.projectName,
    };

    const dialogRef = this.dialog.open(RequestVdiDialogComponent, dialogConfig);

    if (!dialogRef) return;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (triggerPolling) => {
        if (triggerPolling) {
          this.loadingInstance.set(true);
          await this.waitForInstance();
        }
      });
  }
}
