<div>
  <h2 class="header" mat-dialog-title data-cy="add-project-title">
    {{ 'Project.AddProjectDialog.Title' | translate }}
  </h2>
  <div class="sub-title-container" data-cy="add-project-subtitle">
    <h2 class="sub-title" mat-dialog-title>
      {{ steps[currentStepIndex].title}}
    </h2>
  </div>

</div>
@if (isTenantUsersLoading$ | async) {
  <div class="project-information loading-wrapper">
    <mat-spinner class="loading"></mat-spinner>
  </div>
} @else {
  <app-general-information 
    class="project-information" 
    (validationChange)="this.isCurrentStepValid(this.stepComponents, this.currentStepIndex)" 
    [hidden]="currentStepIndex !== 0" 
    [numCharsTenant]="numCharsTenant" 
    #stepComponent
  >
  </app-general-information>
}

<app-contact-information 
[tenantUsers]="tenantUsers$ | async"  
class="project-information" 
(validationChange)="this.isCurrentStepValid(this.stepComponents, this.currentStepIndex)" 
[hidden]="currentStepIndex !== 1" 
#stepComponent
>
</app-contact-information>

<app-billing-information 
[tenantUsers]="tenantUsers$ | async" 
class="project-information" 
[hidden]="currentStepIndex !== 2"                     
#stepComponent
>
</app-billing-information>

<div class="footer">
  <div class="pagination">{{ currentStepIndex + 1 }}/{{ steps.length }}</div>
  <div class="actions" mat-dialog-actions align="end">
    <button data-cy="add-project-cancel-btn" class="cancel" mat-flat-button mat-dialog-close>
      {{ 'General.Cancel' | translate }}
    </button>

    <button data-cy="add-project-back-btn" class="pagination-button" *ngIf="!isFirstStep()" (click)="prevStep()" mat-flat-button>
      {{ 'General.Back' | translate }}
    </button>

    <button 
      data-cy="add-project-next-btn" 
      class="pagination-button"  
      mat-flat-button
      *ngIf="!isLastStep()" 
      (click)="nextStep()"
      [disabled]="!currentStepValid"
    >
      {{ 'General.Next' | translate }}
    </button>

    <button
      data-cy="add-project-create-btn"
      class="add-project-btn"
      mat-flat-button
      type="submit"
      (click)="submitForm()"
      [disabled]="!isValid()"
      mat-dialog-close
    >
      {{ 'Project.AddProjectDialog.AddProject' | translate }}
    </button>
  </div>
</div>
