<div
  data-cy="device-dashboard"
  class="root"
  *ngIf="deviceId"
>
  <div class="sticky">
    <app-header-template [itemName]="deviceId" [isRowDisplayed]="true" [useBrowserHistory]="true">
    </app-header-template>
  </div>
  
  <app-content-wrapper
    data-cy="device-details-device-missing"
    [isLoading]="isDeviceLoadingSignal()"
    [isError]="isDeviceUnknown"
  >
    <ng-container *ngIf="device$ | async as device">
      <app-dialog-hint
        data-cy="device-details-connection-hint"
        class="connection-banner-container"
        [isWarning]="false"
        [message]="downloadConnectionInfo"
        [secondMessage]="downloadConnectionHint"
      >
        <div class="connection-download-btn-container">
          <button
            mat-flat-button
            data-cy="device-details-connection-download-btn"
            [ngClass]="(!device.instanceStatus || device.instanceStatus === DeviceInstanceStatus.CREATE_IN_PROGRESS) ? 'disabled-btn' : 'connection-download-btn'"
            class="connection-download-btn"
            aria-label="Download connection package"
            [disabled]="(device.instanceStatus === DeviceInstanceStatus.CREATE_IN_PROGRESS)"
            (click)="downloadConnectionPackage()"
          >
            {{downloadButtonName | translate}}
            <mat-icon>file_download</mat-icon>
          </button>
        </div>
      </app-dialog-hint>

      <app-details-expansion
        [title]="'DeviceDetails.SectionTitle' | translate"
        [expanded]="device.ui.message !== undefined"
        [hideMenu]="true"
        [hideToggle]="false"
        data-cy="device-details-section"
      >
        <app-display-details
          *ngIf="deviceData$ | async as deviceData"
          data-cy="device-details"
          translationKey="DeviceDetails"
          [data]="deviceData"
          [dateFormat]="'MMM dd, yyyy HH:mm'"
          [dataSecondColumn]="errorData"
        ></app-display-details>
      </app-details-expansion>

      <div class="device-details-content">
        <div class="sticky-header">
          <div class="myheader">
            <div class="header-wrapper">
              <app-header-template
                class="my-header"
                [showBackButton]="false"
                itemName="{{'DeviceDetails.DetailContentTitle' | translate}}"
                [isRowDisplayed]=false
                description="Partitions in total: {{device.devicePartitions.length}} "
              >
              </app-header-template>
            </div>
          </div>
        </div>
        <ng-container *ngIf="device.devicePartitions">
          <!-- display partitions -->
          <app-empty-section
            *ngIf="device.devicePartitions.length === 0; else partitions"
            [iconName]=EmptySectionIcon.COFFEE_MAKER
          >
            <p>{{ "DeviceDetails.NoPartitionsAvailable" | translate }}</p>
          </app-empty-section>

          <ng-template #partitions>

            <mat-accordion multi>
              <ng-container class="deployment-list-container">
                @for (partition of device.devicePartitions; track partition.name) {
                <app-hdk-device-detail-expansion-panel
                  data-cy="device-detail-partition-panel"
                  [device]="device"
                  [partition]='partition'
                  [deployments$]='applications$'
                >
                </app-hdk-device-detail-expansion-panel>
                }
              </ng-container>
            </mat-accordion>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
  </app-content-wrapper>
</div>
