import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderTemplateComponent } from '../../../../shared/components/header/header-template.component';
import {
  combineLatest,
  debounceTime,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { FormControl } from '@angular/forms';
import { FunctionBarComponent } from '../../../../shared/components/function-bar/function-bar.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ContentWrapperComponent } from '../../../../shared/components/content-wrapper/content-wrapper.component';
import { DeploymentListContentComponent } from './components/deployment-list-content/deployment-list-content.component';
import { DeploymentService } from '../../../../core/services/deployment-list/deployment.service';
import { AsyncPipe, CommonModule } from '@angular/common';
import { VersionsStore } from '../../../../shared/stores/deployment/versions.store';
import { IState, State } from '../../../../shared/stores/State';
import { FeatureComponent } from '../../../../core/models/classes/feature.component';
import { Version } from '../../../../shared/stores/deployment/models/version';
import { DeploymentSearchService } from './services/deployment-search.service';
import { RoutingStore } from '../../../../shared/stores/config/routing.store';
import { MatButtonModule } from '@angular/material/button';
import { AddVersionStore } from 'src/app/shared/stores/deployment/add-version.store';
import { Application } from 'src/app/shared/stores/deployment/models/application';
import { StartDeploymentStore } from 'src/app/shared/stores/start-deployment/start-deployment.store';
import { MenuCommand } from '../../../../shared/components/details-expansion/details-expansion.component';
import { DeploymentDialogActionsService } from './services/deployment-dialog-actions.service';
import { DisableDeploymentButtonPipe } from 'src/app/core/pipes/disable-deployment-button.pipe';

export type DeploymentListApi =
  | 'getAppDetails'
  | 'getAppVersions'
  | 'addVersion'
  | 'getDeploymentDev'
  | 'getDevHardware'
  | 'getQaHardware'
  | 'getDevDevices'
  | 'getQaDevices'
  | 'getProdHardware'
  | 'pipelineBuildRequest'
  | 'deleteVersion'
  | 'createDevDeployment'
  | 'deleteDevDeployment'
  | 'startDevQNXDeployment'
  | 'stopDevQNXDeployment';

@Component({
  selector: 'app-hdk-deployment-list',
  standalone: true,
  templateUrl: './deployment-list.component.html',
  styleUrls: ['./deployment-list.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    HeaderTemplateComponent,
    FunctionBarComponent,
    MatExpansionModule,
    ContentWrapperComponent,
    DeploymentListContentComponent,
    AsyncPipe,
    MatButtonModule,
    DisableDeploymentButtonPipe,
  ],
})
export class DeploymentListComponent
  extends FeatureComponent<DeploymentListApi>
  implements OnInit, OnDestroy
{
  @Input({ required: true }) application!: Application;
  @Output() eventEmitter: EventEmitter<MenuCommand> = new EventEmitter();

  deployment$!: Observable<IState<Version[]>>;
  appName$ = this.routingStore.state$.pipe(map((state) => state.applicationId));
  applicationName = '';

  // search
  searchFilterControl: FormControl = new FormControl('');
  searchFilter$: Observable<string> =
    this.searchFilterControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
    );
  filteredVersions$ = combineLatest([
    this.versionsStore.versions$,
    this.searchFilter$,
  ]).pipe(map(this.deploymentSearchService.versionMap));

  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    private deploymentService: DeploymentService,
    private versionsStore: VersionsStore,
    private addVersionStore: AddVersionStore,
    private startDeploymentStore: StartDeploymentStore,
    private deploymentSearchService: DeploymentSearchService,
    private routingStore: RoutingStore,
    private deploymentDialogService: DeploymentDialogActionsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.appName$.pipe(takeUntil(this.unsubscribe$)).subscribe((appName) => {
      this.applicationName = appName;
    });
    this.deployment$ = this.deploymentSearchService.combineVersionData$(
      this.filteredVersions$,
      this.versionsStore.isLoading$,
      this.versionsStore.hasError$,
    );
    this.fetchVersion();
  }

  fetchVersion(): void {
    this.appName$
      .pipe(
        switchMap((name) => this.getAppVersions(name)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  getAppVersions(appName: string): Observable<Version[]> {
    if (!this.API) return of([]);
    return this.deploymentService
      .getAllApplicationVersions(appName, this.API.getAppVersions)
      .pipe(
        tap((response) => {
          const state = State.Factory<Version[]>(response);
          this.versionsStore.setState(state);
        }),
      );
  }

  openAddVersionDialog() {
    if (!this.API) {
      return;
    }
    this.deploymentDialogService.openAddVersionDialog(
      this.applicationName,
      this.API.getAppDetails,
      this.API.addVersion,
      this.unsubscribe$,
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.versionsStore.setState({
      data: [],
      isLoading: true,
      hasError: false,
    });
    this.addVersionStore.setState({
      data: new Application(),
      isLoading: true,
      hasError: false,
    });
    this.startDeploymentStore.setState({
      devices: [],
      versions: [],
      applications: [],
      areApplicationsLoading: true,
      areDevicesLoading: true,
      areVersionsLoading: true,
      hasError: false,
    });
  }
}
