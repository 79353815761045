import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { DialogProps } from 'src/app/shared/components/dialog/models/dialogProps';
import { Application } from '../../../shared/stores/deployment/models/application';
import { ApplicationSettingsComponent } from '../../../core/components/application-settings/application-settings.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialogRef: MatDialogRef<any> | null = null;
  constructor(private dialog: MatDialog) {}

  openDialog(data: DialogProps): Observable<boolean> {
    return this.dialog
      .open(DialogComponent, {
        data,
        width: data.width,
        minWidth: '400px',
        maxWidth: '800px',
        autoFocus: false,
        disableClose: data.disableBackdropPress,
      })
      .afterClosed();
  }

  openDialogRef(data: DialogProps, templateRef?: TemplateRef<any>) {
    const ref = this.dialog.open(DialogComponent, {
      data,
      width: data.width,
      minWidth: '400px',
      maxWidth: '800px',
      autoFocus: false,
      disableClose: data.disableBackdropPress,
    });

    if (templateRef) {
      ref.componentInstance.customContent = templateRef;
    }

    return ref;
  }

  closeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
    }
  }

  closeDialogOnRequestComplete() {
    if (
      this.dialogRef &&
      this.dialogRef.componentInstance.data.closeOnRequestComplete
    ) {
      this.dialogRef.close();
      this.dialogRef = null;
    }
  }

  openApplicationEditDialog(application: Application): Observable<boolean> {
    return this.dialog
      .open(ApplicationSettingsComponent, { data: application, width: '400px' })
      .afterClosed();
  }
}
