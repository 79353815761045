import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Subject } from 'rxjs';
import {ReactiveFormsModule} from '@angular/forms';
import { GenericStateMatcher } from 'src/app/shared/utils/genericStateMatcher';
import { DeviceType } from 'src/app/shared/stores/devices/models/deviceType';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { AuthStore } from 'src/app/shared/stores/auth/auth.store';
import { JwtDecoder } from 'src/app/shared/utils/jwtDecoder';
import { MatFormField } from '@angular/material/form-field';

export interface CreateDeviceDialogData {
  deviceType: DeviceType;
  apiRecords: Record<'simulated' | 'toolchain' | 'vHPC', ApiRecord>;
}

export interface CreateDeviceRequestBody {
  deviceName: string;
  api: ApiRecord;
  tokenAttribute: any;
}

@Component({
  selector: 'app-create-device-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './download-devtools-dialog.component.html',
  styleUrls: ['./download-devtools-dialog.component.scss'],
})
export class DownloadDevToolsDialogComponent implements OnDestroy {
  DeviceType = DeviceType;
  errorMatcher = new GenericStateMatcher();
  decoder = new JwtDecoder();

  @ViewChild('deviceNameField', { read: ElementRef<MatFormField> })
  deviceNameFieldRef!: ElementRef<MatFormField>;
  private readonly destroy$: Subject<void> = new Subject();

  jwtToken = this.authStore.state.tokens?.accessToken;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateDeviceDialogData,
    private authStore: AuthStore,
    public dialogRef: MatDialogRef<DownloadDevToolsDialogComponent>,
  ) {}

  downloadDevTools() {
    if (!this.jwtToken) {
      return;
    }
    this.dialogRef.close(true);
  }

  closeDownloadDevTools(){
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
