@for (banner of banners; track banner) {
  <div class="banner-container" [ngClass]="banner.type">
    <div class="center-content" [class]="banner.type">
      <mat-icon *ngIf="banner.icon">{{ banner.icon }}</mat-icon>
      <p>{{ banner.title }}</p>
    </div>
    @if (banner.isClosable) {
      <button
        mat-icon-button
        class="close-button"
        (click)="closeBanner($index)"
      >
        <mat-icon>close</mat-icon>
      </button>
    }
  </div>
}
