import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { HeaderTemplateComponent } from '../../../../shared/components/header/header-template.component';
import { ChildJobStatus, SYSDAQJobDetails } from '../../models/jobDetails';
import { DetailsExpansionComponent } from '../../../../shared/components/details-expansion/details-expansion.component';
import { DisplayDetailComponent } from '../../../application-dashboard/components/application-details/display-detail.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomFormatDatePipe } from '../../../../core/pipes/custom-format-date.pipe';
import { ChildJobsComponent } from '../child-jobs/child-jobs.component';
import {
  catchError,
  combineLatest,
  Observable,
  of,
  Subject,
  takeUntil,
} from 'rxjs';
import { SnackbarService } from '../../../../core/services/snackbar/snackbar.service';
import { CaeButtonComponent } from '../../../../shared/components/cae-button/cae-button.component';
import { MatIconModule } from '@angular/material/icon';
import {
  ChipComponent,
  ChipVariant,
} from '../../../../shared/components/chip/chip.component';
import { FeatureComponent } from '../../../../core/models/classes/feature.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { DialogType } from 'src/app/shared/components/dialog/models/dialogType';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileService } from '../../../../core/services/file/file.service';
import { logDownloadRequestPayload } from '../../models/logDownloadRequest';
import { CopyToClipboardService } from '../../../../shared/utils/copy-to-clipboard.service';
import { CurrentTab } from 'src/app/features/job-monitoring/job-monitoring.component';
import { SimulationJobMonitoringService } from '../../services/simulation-job-monitoring.service';
import { CustomVariantColorPipe } from '../../../../core/pipes/custom-variant-color.pipe';
import { CheckArrayTypePipe } from '../../../../core/pipes/check-array-type.pipe';

export type JobDetailsApi =
  | 'getJobExecutionDetailsReporting'
  | 'getJobExecutionDetailsSimulation'
  | 'cancelJob'
  | 'requestLogDownload'
  | 'downloadConfigFile';

@Component({
  selector: 'app-job-details',
  standalone: true,
  imports: [
    CommonModule,
    HeaderTemplateComponent,
    DetailsExpansionComponent,
    DisplayDetailComponent,
    TranslateModule,
    CustomFormatDatePipe,
    ChildJobsComponent,
    CaeButtonComponent,
    MatIconModule,
    ChipComponent,
    MatProgressBarModule,
    MatButtonModule,
    MatTooltipModule,
    CustomVariantColorPipe,
    CheckArrayTypePipe,
  ],
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss'],
})
export class JobDetailsComponent
  extends FeatureComponent<JobDetailsApi>
  implements OnInit, OnDestroy
{
  unsubscribe$: Subject<void> = new Subject<void>();

  tab = 0;
  loading = false;
  isRequestLoading = false;
  isConfigFileDownloading = false;
  childJobId = '';
  jobDetails$: Observable<SYSDAQJobDetails | null> = of(null);
  readonly firstElement: number = 0;
  protected readonly Object = Object;
  protected readonly ChipVariant = ChipVariant;
  protected readonly CurrentTab = CurrentTab;
  protected readonly ChildJobStatus = ChildJobStatus;

  constructor(
    private simulationJobMonitoringService: SimulationJobMonitoringService,
    private urlLocation: Location,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private fileService: FileService,
    private copyToClipboardService: CopyToClipboardService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initRouteListener();
  }

  shouldCancelButtonBeDisabled(jobDetails: SYSDAQJobDetails) {
    const jobStatus: string = jobDetails.Status.toLowerCase();
    return !(
      jobStatus === 'pending' ||
      jobStatus === 'runnable' ||
      jobStatus === 'starting' ||
      jobStatus === 'running'
    );
  }

  copyToClipboard(data: string[]) {
    this.copyToClipboardService.copyToClipboard(data);
  }

  openDeleteDialog() {
    const title = 'Jobs.CancelDialog.Title';
    const message = 'Jobs.CancelDialog.Message';
    const confirmText = 'Jobs.CancelDialog.ConfirmText';

    this.dialogService.openDialog({
      type: DialogType.CONFIRM,
      title: this.translateService.instant(title),
      message: this.translateService.instant(message),
      confirmText: this.translateService.instant(confirmText),
      onConfirm: () => this.cancelJob(),
      width: '400px',
    });
  }

  cancelJob() {
    if (!this.API) return;
    this.simulationJobMonitoringService
      .cancelJob(this.API.cancelJob, this.childJobId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          const cancelJobInitiatedMessage = 'Jobs.CancelInitiatedMessage';
          this.snackbarService.notifyInfo(
            this.translateService.instant(cancelJobInitiatedMessage),
          );
          this.urlLocation.back();
        },
        error: () => {
          const cancelJobMessage = 'Jobs.CancelErrorMessage';
          this.snackbarService.notifyError(
            this.translateService.instant(cancelJobMessage),
          );
        },
      });
  }

  downloadLog() {
    if (!this.API) {
      return;
    }
    this.isRequestLoading = true;
    const payload: logDownloadRequestPayload = {
      run_id: this.childJobId,
    };

    this.simulationJobMonitoringService
      .requestLogDownloadLink(this.API.requestLogDownload, payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          if (response.message) {
            this.snackbarService.notifyError(response.message);
          } else if (response.url) {
            this.fileService.downloadFile(response.url, 'StepLog');
            this.snackbarService.notifyInfo(
              this.translateService.instant(
                'Jobs.LogFiles.DownloadSuccessfully',
              ),
            );
          }
          this.isRequestLoading = false;
        },
        error: () => {
          this.isRequestLoading = false;
          this.snackbarService.notifyError(
            this.translateService.instant('Jobs.LogFiles.DownloadError'),
          );
        },
      });
  }

  downloadConfig(fileName: string) {
    if (!this.API || this.isConfigFileDownloading) return;
    this.isConfigFileDownloading = true;
    this.simulationJobMonitoringService
      .downloadConfigFile(this.API.downloadConfigFile, fileName)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          if (response.message) {
            this.snackbarService.notifyError(response.message);
          } else if (response.url) {
            this.fileService.downloadFile(response.url, fileName);
            this.snackbarService.notifyInfo(
              this.translateService.instant(
                'Jobs.Details.ConfigFileDownloaded',
              ),
            );
          }
          this.isConfigFileDownloading = false;
        },
        error: (err) => {
          console.error(err);
          this.isConfigFileDownloading = false;
          this.snackbarService.notifyError(
            this.translateService.instant(
              'Jobs.Details.ConfigFileDownloadError',
            ),
          );
        },
      });
  }

  private initRouteListener() {
    combineLatest(
      [this.route.params, this.route.queryParams],
      (params, qparams) => ({ params, qparams }),
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((ap) => {
        this.childJobId = ap.params['jobId'];
        this.tab = parseInt(ap.qparams['tab']);
        this.fetchChildJobData();
      });
  }

  private fetchChildJobData() {
    if (!this.API) return;
    this.loading = true;
    const childJobApi =
      this.tab === CurrentTab.SIMULATION
        ? this.API.getJobExecutionDetailsSimulation
        : this.API.getJobExecutionDetailsReporting;
    this.jobDetails$ = this.simulationJobMonitoringService
      .getJobDetails(childJobApi, this.childJobId)
      .pipe(
        catchError((err) => {
          this.loading = false;
          return of(err);
        }),
        takeUntil(this.unsubscribe$),
      );
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
