<mat-expansion-panel
  class="partition-deployment mat-elevation-z0"
  [expanded]="expanded"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="part-name-col col" data-cy="partition-name-list">
        <p data-cy="partition-name">{{ "General.Partition" | translate }}: {{ partition.name }}</p>
      </div>
      <div class="part-version-col col">
        <p data-cy="partition-version">{{ "General.Version" | translate }}: {{ partition.fw_version }}</p>
      </div>
      <div class="part-type-col col">
        <p data-cy="partition-os-type">{{ "General.Type" | translate }}: {{ partition.os_type }} </p>
      </div>
      <div class="part-status-col col">
        <app-chip
          data-cy="partition-status"
          [label]="partition.status | titlecase"
          [variant]="partition.status === PartitionStatus.OFFLINE? ChipVariant.ERROR: ChipVariant.SUCCESS"
        ></app-chip>
      </div>
      <div class="part-deployment-col col">
        @if(partitionDropdown) {
          <button
          mat-flat-button
          class="add-deployment-btn"
          data-cy="add-deployment-partition"
          aria-label="Start deployment"
          (click)="startDeploymentClick(); $event.stopPropagation()"
        >
          <mat-icon>add</mat-icon>
          {{ "HDK.Deployment" | translate }}
        </button>
        }
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-container>
    <div
      class="expansion-content"
    >
      <app-content-wrapper
        class="deployment-container"
        [isError]="false"
        [isLoading]="false"
        [errorMessage]="'DeploymentList.DeploymentListError' | translate"
      >
        <ng-container *ngIf="deploymentsByPartition$ | async as deployments">
          @if (deployments.length > 0) {
          <div>
            @for (deployment of deployments; track deployment.appId) {
              <app-hdk-device-deployment-detail
                [device]="device"
                [deployment]="deployment"
                class="list-wrapper"
              ></app-hdk-device-deployment-detail>
          }
          </div>
          } @else {
            <p class="no-deployments">
              {{ "Deployments.StartYourDeployment" | translate }}
            </p>
          }

        </ng-container>
      </app-content-wrapper>
    </div>
  </ng-container>
</mat-expansion-panel>

