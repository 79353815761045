import { Injectable } from '@angular/core';
import { interval, map, Observable, startWith, switchMap } from 'rxjs';
import {
  IDataVersion,
  Version,
} from '../../../shared/stores/deployment/models/version';
import { ApiRecord } from '../../../shared/stores/config/models/apiRecord';
import { ApiService } from '../api/api.service';
import { generatePath } from '../../../shared/utils/generatePath';
import {
  Deployment,
  IDataDeployment,
} from '../../../shared/stores/deployment/models/deployment';
import { FeatureService } from '../config/feature.service';
import { FeatureConfig } from 'src/environments/featureConfig';
import {
  DeploymentActionCommand,
  DeploymentDeletionData,
  DeploymentActionData,
} from '../../../shared/models/deployment';

@Injectable({
  providedIn: 'root',
})
export class DeploymentService {
  interval = 5000;
  constructor(
    private apiService: ApiService,
    private featureService: FeatureService,
  ) {}

  getAppVersions(appName: string, apiRecord: ApiRecord): Observable<Version[]> {

    return this.apiService
      .request<IDataVersion[]>({
        apiRecord: {
          ...apiRecord,
          url: generatePath(apiRecord.url, { appName: appName }),
        },
      })
      .pipe(map((versions) => versions.map(Version.Factory)));
  }

  getAllApplicationVersions(
    appName: string,
    apiRecord: ApiRecord,
  ): Observable<Version[]> {
    return interval(this.interval).pipe(
      startWith(this.getAppVersions(appName, apiRecord)),
      switchMap(() => this.getAppVersions(appName, apiRecord)),
    );
  }

  getDeployments(
    appName: string,
    versionId: string,
    apiRecord: ApiRecord,
  ): Observable<Deployment[]> {
    return this.apiService
      .request<IDataDeployment[]>({
        apiRecord: {
          ...apiRecord,
          url: generatePath(apiRecord.url, {
            appName: appName,
            versionId: versionId,
          }),
        },
      })
      .pipe(
        map((deployments) =>
          deployments.map((deployment) =>
            Deployment.Factory(deployment, versionId),
          ),
        ),
      );
  }

  addVersion(
    appName: string,
    versionId: string,
    source: string,
    apiRecord: ApiRecord,
  ): Observable<{ message: string }> {
    return this.apiService.request({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { appName }),
      },
      body: {
        versionId: versionId,
        versionReference: source,
      },
    });
  }

  pipelineBuildRequest(
    appName: string,
    versionId: string,
    buildRequest: boolean,
    apiRecord: ApiRecord,
  ): Observable<{ version_identifier: string }> {
    return this.apiService.request<{ version_identifier: string }>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { appName, versionId }),
      },
      body: { requestExecution: buildRequest },
    });
  }

  deleteVersion(
    appName: string,
    versionId: string,
    apiRecord: ApiRecord,
  ): Observable<{ message: string }> {
    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { appName, versionId }),
      },
    });
  }

  deleteDeployment(
    data: DeploymentDeletionData,
  ): Observable<{ message: string }> {
    const appName = data.appName;
    const versionId = data.versionId;

    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...data.apiRecord,
        url: generatePath(data.apiRecord.url, {
          appName,
          versionId,
        }),
      },
      body: {
        deviceId: data.deviceName,
        forceDelete: data.isForcedDeletion,
        partitionName: data.partitionName,
      },
    });
  }

  startDeployment(
    appName: string,
    versionId: string,
    deviceName: string,
    apiRecord: ApiRecord,
    partitionName?: string,
  ): Observable<{ message: string }> {
    let requestBody;
    if (this.featureService.isFeatureEnabled(FeatureConfig.partitionDropdown)) {
      requestBody = {
        deviceId: deviceName,
        partitionName,
      };
    } else {
      requestBody = {
        deviceId: deviceName,
      };
    }

    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...apiRecord,
        url: generatePath(apiRecord.url, { appName, versionId }),
      },
      body: requestBody,
    });
  }

  startQNXDeployment(data: DeploymentActionData) {
    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...data.apiRecord,
        url: generatePath(data.apiRecord.url, {
          appName: data.appName,
          versionId: data.versionId,
        }),
      },
      body: {
        deviceId: data.deviceName,
        command: DeploymentActionCommand.START,
        partitionName: data.partitionName,
      },
    });
  }

  stopQNXDeployment(data: DeploymentActionData) {
    return this.apiService.request<{ message: string }>({
      apiRecord: {
        ...data.apiRecord,
        url: generatePath(data.apiRecord.url, {
          appName: data.appName,
          versionId: data.versionId,
        }),
      },
      body: {
        deviceId: data.deviceName,
        command: DeploymentActionCommand.STOP,
        partitionName: data.partitionName,
      },
    });
  }
}
