<div class="playlist-container">
  <div class="playlist-header header-sticky">
    <app-header-template
      translationKey="Playlist.Title"
      [showBackButton]="false"
    >
      <div data-cy="add-stage-section" class="buttons">
        <button
          data-cy="add-stage-one-btn"
          mat-flat-button
          class="add-stage-btn"
          [attr.aria-label]="'Playlists.AddStageOneButtonTitle' | translate"
          (click)="addStageOne()"
        >
          {{ 'Playlists.AddStageOneButtonTitle' | translate }}
        </button>
        <button
          data-cy="add-stage-two-btn"
          mat-flat-button
          class="add-stage-btn"
          [attr.aria-label]="'Playlists.AddStageTwoButtonTitle' | translate"
          (click)="addStageTwo()"
        >
          {{ 'Playlists.AddStageTwoButtonTitle' | translate }}
        </button>
      </div>
    </app-header-template>
  </div>

  <div class="search-input">
    <app-function-bar
      class="search-bar"
      data-cy="playlist-search"
      [searchFilterControl]="searchFilterControl"
    >
      <div class="actions-container" filters>
        <div class="select-container">
          <label for="stage-select"> Stage </label>
          <app-select-menu
            data-cy="stage-select"
            id="stage-select"
            [options]="stageOptions"
            [selectedOption]="selectStageFilterOption()"
            (optionSelected)="stageFilterSelect($event)"
          >
          </app-select-menu>
        </div>
      </div>
    </app-function-bar>
  </div>

  @if (playlist$ | async; as playlist) {
    <app-content-wrapper
      [isLoading]="playlist.isLoading"
      [isError]="playlist.hasError"
    >
      <div style="overflow: auto">
        <app-playlist-table [playlist]="playlist.data"></app-playlist-table>
      </div>
    </app-content-wrapper>
  }
</div>
