import { Component, inject } from '@angular/core';

import { CommonModule } from '@angular/common';
import { CheckDeleteStatusPipe } from '../../../../../../shared/pipes/check-delete-status.pipe';
import { SelectedHdkDeviceService } from '../../services/selected-hdk-device-service/selected-hdk-device.service';

@Component({
  selector: 'app-delete-multiple-hdk-devices',
  standalone: true,
  imports: [CommonModule, CheckDeleteStatusPipe],
  templateUrl: './delete-multiple-devices.component.html',
  styleUrl: './delete-multiple-devices.component.scss',
})
export class DeleteMultipleDevicesComponent {
  private selectedHdkDeviceService = inject(SelectedHdkDeviceService);

  devices = this.selectedHdkDeviceService.devices$;
  loading = this.selectedHdkDeviceService.loading$;
  deletionResponse = this.selectedHdkDeviceService.deleteDeviceResponse$;
}
