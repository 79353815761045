<div class="root">
    <div class="getting-started-top" data-cy="vecu-getting-started-page" @fadeIn>

      <div class="intro-text">
        <div class="header-row">
          <h1>
            {{ "Vecu.Title" | translate }}
            <span class="vecu-span">{{ "Vecu.Creator" | translate }}</span>
          </h1>
          <app-cae-button
            data-cy="go-back-button"
            color="primary"
            [attr.aria-label]="'Vecu.Close' | translate"
            (click)="navigateBack()"
          > 
            {{ "Vecu.Close" | translate }}
          </app-cae-button>
      </div>
        <p class="content-area" [innerHTML]="'Vecu.Description' | translate"></p>

        <div>
          <mat-tab-group class="vecu-tab">
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="number-icon">looks_one</mat-icon>
                <span class="vecu-tab-label" data-cy="setup-environment-span">{{ 'Vecu.Setup' | translate }}</span>
              </ng-template>

              <div class="content-area">
                <p [innerHTML]="'Vecu.SetupEnvironment' | translate"></p>

                <mat-vertical-stepper class="vecu-stepper" #setupEnvironmentStepper>
                <mat-step>
                  <ng-template matStepLabel>
                      <span data-cy="get-certificates-span">{{ 'Vecu.SetupSteps.GetCertificate' | translate }}</span>
                  </ng-template>

                  <div class="steps">
                    <p>{{ 'Vecu.SetupSteps.GetCertificateSteps.Step1' | translate }}</p>
                    <p>{{ 'Vecu.SetupSteps.GetCertificateSteps.Step2' | translate }}</p>  
                  </div>

                  <div class="centered-container">
                    <app-cae-button
                      data-cy="navigate-to-certificate-page-button"
                      class="navigate-to-certificate-page-button"
                      color="primary"
                      [icon]="'forward'"
                      [attr.aria-label]="'Vecu.BuildSteps.Navigate.Button' | translate"
                      (click)="navigateToCertificatesPage()"
                    >
                        {{ "Vecu.BuildSteps.Navigate.CertificateButton" | translate }}
                    </app-cae-button>
                  </div>
                  <div class="caedge-logo">
                    <img class="image" src="assets/images/vecu-tutorial/demo-certificates.gif" alt="caedge-logo" />
                  </div>

                  <div class="next-button-container centered-container">
                    <app-cae-button
                      color="primary"
                      [icon]="'arrow_downward'"
                      data-cy="next-button-certificate"
                      [attr.aria-label]="'Vecu.BuildSteps.Navigate.Button' | translate"
                      (click)="goToNextStep(setupEnvironmentStepper)"
                    >
                      {{ 'Vecu.Next' | translate }}
                    </app-cae-button>
                  </div>

                </mat-step>
                <mat-step>
                  <ng-template matStepLabel>
                     <span data-cy="execute-installer-span">{{ 'Vecu.SetupSteps.ExecuteInstaller' | translate }}</span>
                  </ng-template>

                  <ul data-cy="setup-step-list" class="execute-list">
                    <li>{{ 'Vecu.SetupSteps.GetCertificateSteps.Step3' | translate }}</li>

                    <app-cae-button
                      data-cy="download-cli-button"
                      class="navigate-to-certificate-page-button"
                      color="primary"
                      [attr.aria-label]="'Vecu.SetupSteps.DownloadCLI' | translate"
                      [icon]="'download'"
                      (click)="downloadInstaller()"
                    >
                      {{ "Vecu.SetupSteps.DownloadCLI" | translate }}
                    </app-cae-button>
                    <li>{{ 'Vecu.SetupSteps.ExecuteCLISteps.Step1' | translate }}</li>
                    <li>{{ 'Vecu.SetupSteps.ExecuteCLISteps.Step2' | translate }}</li>
                    <li>
                      <div>{{ 'Vecu.SetupSteps.ExecuteCLISteps.Step3' | translate }}</div>
                      <div class="command-box">
                        <span class="aligned-text">{{ 'Vecu.SetupSteps.ExecuteCLISteps.Command' | translate }}</span>
                        <mat-icon
                          class="aligned-icon copy-icon"
                          data-cy="copy-to-clipboard-icon-step-3"
                          matTooltip="{{ 'Vecu.SetupSteps.ExecuteCLISteps.Tooltip' | translate }}"
                          matTooltipPosition="right"
                          (click)="copyToClipboard('Vecu.SetupSteps.ExecuteCLISteps.Command')"
                        >file_copy</mat-icon>
                      </div>
                    </li>
                    <li>
                      {{ 'Vecu.SetupSteps.ConfigureStep.Description' | translate }}

                      @defer (on idle) {
                         @if (tenantDetails$ | async; as tenantDetails) {
                            <div class="command-box">
                              <span class="aligned-text">{{ 'Vecu.SetupSteps.ConfigureStep.Command' | translate }}{{projectID+' '+tenantDetails.skey}}</span>
                              <mat-icon
                                class="aligned-icon copy-icon"
                                data-cy="copy-to-clipboard-icon-step-configure"
                                matTooltip="{{ 'Vecu.SetupSteps.ExecuteCLISteps.Tooltip' | translate }}"
                                matTooltipPosition="right"
                                (click)="copyToClipboard('Vecu.SetupSteps.ConfigureStep.Command', projectID+' '+tenantDetails.skey)"
                              >file_copy</mat-icon>
                            </div>
                        }
                      }
                      @loading (after 1ms; minimum 500ms) {
                          <div class="command-box">  
                            <span class="aligned-text">{{ 'Vecu.SetupSteps.ConfigureStep.Loading' | translate }}</span>
                          </div>
                      }
                    </li>
                    <li>
                      
                      {{ 'Vecu.SetupSteps.ExecuteCLISteps.Step4' | translate }}
                      
                      <div class="command-box">
                        <span class="aligned-text">{{ 'Vecu.SetupSteps.ExecuteCLISteps.Step4Command' | translate }}</span>
                        <mat-icon
                          class="aligned-icon copy-icon"
                          data-cy="copy-to-clipboard-icon-step-4"
                          matTooltip="{{ 'Vecu.SetupSteps.ExecuteCLISteps.Tooltip' | translate }}"
                          matTooltipPosition="right"
                          (click)="copyToClipboard('Vecu.SetupSteps.ExecuteCLISteps.Step4Command')"
                        >file_copy</mat-icon>
                      </div>
                    </li>
                  </ul>

                </mat-step>
                </mat-vertical-stepper>
              </div>
            </mat-tab>

            <mat-tab data-cy="create-vecu-tab">
              <ng-template  mat-tab-label>
                <mat-icon class="number-icon">looks_two</mat-icon>
                <span class="vecu-tab-label" data-cy="create-vecu-span">{{ 'Vecu.CreateVECU.Title' | translate }}</span>
              </ng-template>

              <div class="content-area" data-cy="create-vecu-content">
                  <p [innerHTML]="'Vecu.CreateVECU.Description' | translate"></p>

                  <app-cae-button
                    data-cy="create-vecu-button"
                    class="create-vecu-button"
                    [matTooltip]="'Vecu.QuotaExceeded' | translate"
                    [matTooltipDisabled]="!quota.isQuotaExceeded"
                    color="primary"
                    [icon]="'add'"
                    [disable]="quota.isQuotaExceeded"
                    [attr.aria-label]="'Vecu.CreateVECU.ButtonText' | translate"
                    (clicked)="createVecuButtonClicked()"
                  >
                    {{ "Vecu.CreateVECU.ButtonText" | translate }}
                  </app-cae-button>

              </div>
            </mat-tab>

            <mat-tab data-cy="remopte-connection-tab">
              <ng-template  mat-tab-label>
                <mat-icon class="number-icon">looks_3</mat-icon>
                <span class="vecu-tab-label" data-cy="build-remote-connection-span">{{ 'Vecu.Remote' | translate }}</span>
              </ng-template>

              <div class="content-area">
                <p [innerHTML]="'Vecu.BuildRemoteConnection' | translate"></p>

                <mat-vertical-stepper  class="vecu-stepper"  #buildRemoteConnectionStepper>

                  <mat-step>
                    <ng-template matStepLabel>  {{ 'Vecu.BuildSteps.Configure.Title' | translate }}</ng-template>

                    <div class="step-description">
                      {{ 'Vecu.BuildSteps.Configure.Description' | translate }}
                    </div>

                    <div class="step-description">
                      {{ 'Vecu.BuildSteps.Configure.Extension' | translate }}
                    </div>

                    <div class="command-box">
                      <span class="aligned-text">{{ 'Vecu.BuildSteps.Configure.Command' | translate }}</span>
                      <mat-icon
                        class="aligned-icon copy-icon"
                        data-cy="copy-to-clipboard-icon-configure-step"
                        matTooltip="{{ 'Vecu.SetupSteps.ExecuteCLISteps.Tooltip' | translate }}"
                        matTooltipPosition="right"
                        (click)="copyToClipboard('Vecu.BuildSteps.Configure.Command')"
                      >file_copy</mat-icon>
                    </div>

                    <div class="next-button-container centered-container">
                      <app-cae-button
                        color="primary"
                        [icon]="'arrow_downward'"
                        data-cy="next-button-remote-step-1"
                        [attr.aria-label]="'Vecu.BuildSteps.Navigate.Button' | translate"
                        (click)="goToNextStep(buildRemoteConnectionStepper)"
                      >
                        {{ 'Vecu.Next' | translate }}
                      </app-cae-button>
                    </div>

                  </mat-step>

                  <mat-step>
                      <ng-template matStepLabel>  {{ 'Vecu.BuildSteps.Navigate.Title' | translate }}</ng-template>

                      <div class="step-description">
                        {{ 'Vecu.BuildSteps.Navigate.Description' | translate }}
                      </div>

                      <app-cae-button
                        data-cy="navigate-to-vecu-page-button"
                        class="navigate-to-vecu-page-button"
                        color="primary"
                        [icon]="'forward'"
                        [attr.aria-label]="'Vecu.BuildSteps.Navigate.Button' | translate"
                        (click)="navigateToVECUPage()"
                      >
                       {{ "Vecu.BuildSteps.Navigate.Button" | translate }}
                      </app-cae-button>

                      <div class="next-button-container centered-container">
                        <app-cae-button
                          color="primary"
                          [icon]="'arrow_downward'"
                          data-cy="next-button-remote-step-2"
                          [attr.aria-label]="'Vecu.BuildSteps.Navigate.Button' | translate"
                          (click)="goToNextStep(buildRemoteConnectionStepper)"
                        >
                          {{ 'Vecu.Next' | translate }}
                        </app-cae-button>
                      </div>

                  </mat-step>

                  <mat-step>
                      <ng-template matStepLabel>{{ 'Vecu.BuildSteps.Select.Title' | translate }}</ng-template>

                      <div class="step-description">
                        {{ 'Vecu.BuildSteps.Select.Description' | translate }}
                      </div>
                      <img class="image" src="assets/images/vecu-tutorial/search.png" alt="caedge-logo" />
                      <div class="next-button-container centered-container">  
                        <app-cae-button
                          color="primary"
                          [icon]="'arrow_downward'"
                          data-cy="next-button-remote-step-3"
                          [attr.aria-label]="'Vecu.BuildSteps.Navigate.Button' | translate"
                          (click)="goToNextStep(buildRemoteConnectionStepper)"
                        >
                          {{ 'Vecu.Next' | translate }}
                        </app-cae-button>
                      </div>

                  </mat-step>

                  <mat-step>
                    <ng-template matStepLabel>{{ 'Vecu.BuildSteps.Copy.Title' | translate }}</ng-template>

                    <div class="step-description">
                      {{ 'Vecu.BuildSteps.Copy.Description' | translate }}
                    </div>
                    
                    <img class="image" src="assets/images/vecu-tutorial/copy.png" alt="caedge-logo" />
                    <div class="next-button-container centered-container">  
                      <app-cae-button
                        color="primary"
                        [icon]="'arrow_downward'"
                        data-cy="next-button-remote-step-4"
                        [attr.aria-label]="'Vecu.BuildSteps.Navigate.Button' | translate"
                        (click)="goToNextStep(buildRemoteConnectionStepper)"
                      >
                        {{ 'Vecu.Next' | translate }}
                      </app-cae-button> 
                    </div>
                  </mat-step>

                  <mat-step>
                    <ng-template matStepLabel>{{ 'Vecu.BuildSteps.Run.Title' | translate }}</ng-template>

                    <div class="step-description">
                      {{ 'Vecu.BuildSteps.Run.Description' | translate }}
                    </div>
                    
                    <div class="step-description">
                      {{ 'Vecu.BuildSteps.Run.Note' | translate }}
                    </div>

                    <div class="centered-container">
                      <app-cae-button
                        data-cy="visit-vecu-list-button"
                        class="navigate-to-certificate-page-button"
                        color="primary"
                        [attr.aria-label]="'Vecu.Close' | translate"
                        (click)="navigateBack()"
                      >
                      {{ "Vecu.CloseGuide" | translate }}
                      </app-cae-button>
                    </div>
                  </mat-step>

                </mat-vertical-stepper>
              </div>

            </mat-tab>

          </mat-tab-group>
        </div>
      </div>

    </div>
</div>

