import { Injectable } from '@angular/core';
import { ApiRecord } from '../../../../../shared/stores/config/models/apiRecord';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { of, Subject, switchMap, takeUntil } from 'rxjs';

import {
  AddVersionDialogComponent,
  AddVersionDialogResponse,
} from '../components/add-version-dialog/add-version-dialog.component';
import { DeploymentService } from '../../../../../core/services/deployment-list/deployment.service';
import { SnackbarService } from '../../../../../core/services/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DeploymentDialogActionsService {
  constructor(
    public dialog: MatDialog,
    private deploymentService: DeploymentService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
  ) {}

  openAddVersionDialog(
    applicationName: string,
    getAppDetails: ApiRecord,
    addVersionApiConfig: ApiRecord,
    unsubscribe$: Subject<void>,
  ): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    dialogConfig.height = '360px';
    dialogConfig.data = {
      appName: applicationName,
      getAppDetailsApiConfig: getAppDetails,
    };

    const dialogRef = this.dialog.open(AddVersionDialogComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((data: AddVersionDialogResponse) => {
          if (!data) {
            return of(null);
          }
          return this.deploymentService.addVersion(
            applicationName,
            data.versionId,
            data.source,
            addVersionApiConfig,
          );
        }),
        takeUntil(unsubscribe$),
      )
      .subscribe({
        next: (value) => {
          if (value) {
            this.snackbarService.notifySuccess(
              this.translate.instant('Versions.VersionCreated'),
            );
          }
        },
        error: () => {
          this.snackbarService.notifyError(
            this.translate.instant('Versions.VersionCreationFailed'),
          );
        },
      });
  }
}
