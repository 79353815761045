import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureComponent } from 'src/app/core/models/classes/feature.component';
import { RoutingStore } from 'src/app/shared/stores/config/routing.store';
import { combineLatest, map, Subject, takeUntil, tap } from 'rxjs';
import { HeaderTemplateComponent } from '../../shared/components/header/header-template.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { DialogType } from 'src/app/shared/components/dialog/models/dialogType';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApplicationDashboardApiService } from './services/application-dashboard-api.service';
import { Application } from 'src/app/shared/stores/deployment/models/application';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ApplicationStore } from 'src/app/shared/stores/application/application.store';
import { ContentWrapperComponent } from 'src/app/shared/components/content-wrapper/content-wrapper.component';
import { StartDeploymentService } from 'src/app/core/services/start-deployment/start-deployment.service';
import { DeploymentListComponent } from '../vecu-ami/components/deployment-list/deployment-list.component';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  DetailsExpansionComponent,
  MenuCommand,
  MenuItem,
} from '../../shared/components/details-expansion/details-expansion.component';
import { MatDividerModule } from '@angular/material/divider';
import { Version } from '../../shared/stores/deployment/models/version';
import { ConvertToTypeLabelsPipe } from 'src/app/shared/pipes/convert-to-type-labels.pipe';
import { VersionsStore } from 'src/app/shared/stores/deployment/versions.store';
import { DisplayDetailComponent } from 'src/app/shared/components/display-detail/display-detail.component';
import { ApplicationListApiService } from 'src/app/core/components/project-dashboard/components/application-list/services/application-list-api.service';

export type ApplicationDashboardApi =
  | 'deleteApplication'
  | 'getApplicationDetails'
  | 'createDevDeployment'
  | 'getDevDevices'
  | 'getQaDevices'
  | 'getAppVersions';

@Component({
  selector: 'app-application-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    HeaderTemplateComponent,
    ContentWrapperComponent,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    DeploymentListComponent,
    DisplayDetailComponent,
    MatExpansionModule,
    DetailsExpansionComponent,
    TranslateModule,
    MatDividerModule,
    ConvertToTypeLabelsPipe,
  ],
  templateUrl: './application-dashboard.component.html',
  styleUrls: ['./application-dashboard.component.scss'],
})
export class ApplicationDashboardComponent
  extends FeatureComponent<ApplicationDashboardApi>
  implements OnInit, OnDestroy
{
  unsubscribe$: Subject<void> = new Subject<void>();
  deleteToolTipMessage = this.translateService.instant(
    'Application.DeleteDialog.Tooltip',
  );
  isDeleteButtonDisabled = true;
  dateFormat: string = 'MMMM d, y hh:MM:ss';
  applicationName = '';
  menuItems: MenuItem[] = [
    {
      command: MenuCommand.EDIT,
      translationKey: 'General.Edit',
    },
  ];

  constructor(
    private routingStore: RoutingStore,
    private dialogService: DialogService,
    private applicationDashboardApiService: ApplicationDashboardApiService,
    private translateService: TranslateService,
    private applicationListApi: ApplicationListApiService,
    private snackbarService: SnackbarService,
    private applicationStore: ApplicationStore,
    private startDeploymentService: StartDeploymentService,
    private versionsStore: VersionsStore,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setApplicationId();
    this.fetchApplicationDetails();
    this.fetchApplicationVersions();
    this.versionsStateListener();
  }

  applicationData$ = combineLatest([
    this.applicationStore.application$,
    this.applicationStore.versions$,
    this.applicationStore.isLoading$,
    this.applicationStore.hasError$,
  ]).pipe(
    map(
      ([application, versions, isLoading, hasError]: [
        Application,
        Version[],
        boolean,
        boolean,
      ]) => {
        return {
          application,
          versions,
          isLoading,
          hasError,
        };
      },
    ),
  );

  openDeleteDialog() {
    const title = 'Application.DeleteDialog.Title';
    const message = 'Application.DeleteDialog.Message';
    const confirmText = 'Application.DeleteDialog.ConfirmText';

    this.dialogService.openDialog({
      type: DialogType.CONFIRM,
      title: this.translateService.instant(title),
      message: this.translateService.instant(message),
      confirmText: this.translateService.instant(confirmText),
      onConfirm: () => this.deleteApplication(this.applicationName),
      width: '400px',
    });
  }

  deleteApplication(applicationName: string) {
    if (!this.API) {
      return;
    }
    this.applicationListApi
      .deleteApplication(this.API.deleteApplication, applicationName)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.snackbarService.notifyInfo(
            this.translateService.instant(
              'Application.DeleteDialog.DeletionInitiated',
            ),
          );
          this.routingStore.navigateToProject();
        },
        error: () => {
          this.snackbarService.notifyError(
            this.translateService.instant(
              'Application.DeleteDialog.DeletionFailed',
            ),
          );
        },
      });
  }

  fetchApplicationDetails(): void {
    if (!this.API) {
      return;
    }

    this.applicationDashboardApiService
      .getApplicationDetails(
        this.API.getApplicationDetails,
        this.applicationName,
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (application) => {
          this.applicationStore.setState({
            ...this.applicationStore.state,
            application,
            isApplicationLoading: false,
          });
        },
        error: () => {
          this.applicationStore.setState({
            ...this.applicationStore.state,
            isApplicationLoading: false,
            hasError: true,
          });
        },
      });
  }

  fetchApplicationVersions(): void {
    if (!this.API) {
      return;
    }

    this.applicationDashboardApiService
      .getApplicationVersions(this.API.getAppVersions, this.applicationName)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (versions) => {
          if (versions.length > 0) {
            this.menuItems.map((item) => {
              item.command === MenuCommand.DELETE
                ? (item.disabled = true)
                : (item.disabled = false);
            });
            this.isDeleteButtonDisabled = true;
          } else {
            this.isDeleteButtonDisabled = false;
          }

          this.applicationStore.setState({
            ...this.applicationStore.state,
            versions,
            isVersionsLoading: false,
          });
        },
        error: () => {
          this.applicationStore.setState({
            ...this.applicationStore.state,
            isVersionsLoading: false,
            hasError: true,
          });
        },
      });
  }

  versionsStateListener() {
    this.versionsStore.versions$
      .pipe(
        tap((versions) => {
          if (versions.length === 0) {
            this.isDeleteButtonDisabled = false;
          } else {
            this.isDeleteButtonDisabled = true;
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  startDeploymentClick() {
    if (!this.API) {
      return;
    }
    this.startDeploymentService.openStartDeploymentDialog(
      this.applicationName,
      {
        createDevDeployment: this.API.createDevDeployment,
        getVersions: this.API.getAppVersions,
        getDevDevices: this.API.getDevDevices,
        getApplicationDetails: this.API.getApplicationDetails,
      },
      {},
      this.unsubscribe$,
    );
  }

  setApplicationId() {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      if (params['appName']) {
        this.routingStore.setApplicationId(params['appName']);
        this.applicationName = params['appName'];
      }
    });
  }

  handleEventEmitter(command: MenuCommand, application: Application): void {
    switch (command) {
      case MenuCommand.EDIT:
        this.openEditDialog(application);
        break;
    }
  }

  openEditDialog(application: Application) {
    this.dialogService.openApplicationEditDialog(application);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.applicationStore.setState({
      application: new Application(),
      versions: [],
      isApplicationLoading: true,
      isVersionsLoading: true,
      hasError: false,
    });
  }
}
