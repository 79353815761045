import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cookie-information-dialog-content',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './cookie-information-dialog-content.component.html',
  styleUrl: './cookie-information-dialog-content.component.scss'
})
export class CookieInformationDialogContentComponent {

}
