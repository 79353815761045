import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UpdateType } from '../../models/update-workspace-request-body';

@Component({
  selector: 'app-update-workspace-footer',
  standalone: true,
  templateUrl: './update-workspace-footer.component.html',
  styleUrl: './update-workspace-footer.component.scss',
  imports: [TranslateModule, MatButtonModule, MatDialogModule, CommonModule],
})
export class UpdateWorkspaceFooterComponent {
  @Input() updateType!: string;
  @Input() disabled!: string;
  @Input() currentStepIndex: number = 1;
  @Output() previous: EventEmitter<number> = new EventEmitter();
  @Output() scheduleUpdateClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() immediateUpdateClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() defaultUpdateClicked: EventEmitter<void> = new EventEmitter<void>();

  UpdateType = UpdateType;
  public readonly steps = [
    {
      title: [this.translate.instant('Update workbench instance')],
    },
    {
      title: [
        this.translate.instant('Update after next backup'),
        this.translate.instant('Schedule update for later'),
        this.translate.instant('Update immediately'),
      ],
    },
  ];

  constructor(private translate: TranslateService) {}

  prevStep() {
    if (this.isFirstStep()) {
      return;
    }
    this.currentStepIndex--;
    this.previous.emit(this.currentStepIndex);
  }

  isFirstStep(): boolean {
    return this.currentStepIndex === 0;
  }

  defaultUpdate() {
    this.defaultUpdateClicked.emit();
  }

  scheduleUpdate() {
    this.scheduleUpdateClicked.emit();
  }

  immediateUpdate() {
    this.immediateUpdateClicked.emit();
  }
}
