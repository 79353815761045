<h2 mat-dialog-title data-cy="create-job-title">Create a new job</h2>

<!-- First page -->
<div mat-dialog-content>
  @if (workflows | async; as workflow) {
    <app-select-workflow
      [hidden]="!(currentIndex === 0)"
      (workflowEventEmitter)="setWorkflow($event)"
      [data]="workflow"
    ></app-select-workflow>
  } @else {
    <app-empty-section>
      <p>Workflow not found</p>
    </app-empty-section>
  }

  <!-- Second page -->

  @if (projectType === CurrentTab.SIMULATION) {
    <!-- Simulation playlist -->

    <app-simulation-playlist
      [hidden]="currentIndex !== 1"
      [projectName]="projectName"
      [api]="data.apis.playlist"
      [stage]="stage"
      (setId)="setInputLocation($event)"
    ></app-simulation-playlist>
  } @else {
    <!-- Reporting tree view -->
    @if (inputDataSet | async; as data) {
      <app-reporting-tree-view
        [hidden]="currentIndex !== 1"
        [data]="data"
        (selectedNodesEmitter)="setSelectedNodes($event)"
      ></app-reporting-tree-view>
    }
  }

  <!-- Third page -->

  @if (currentIndex === steps.third) {
    <app-define-parameter
      [api]="apiRecords.getParameterTemplates"
      [hidden]="currentIndex !== steps.third"
      [currentTab]="projectType"
      [workflow]="newJob.workflow"
      [projectName]="projectName"
      (selectedParams)="setSelectedParams($event)"
    ></app-define-parameter>
  }

  <!-- Fourth page -->

  @if (currentIndex === 3) {
    <app-add-job-overview
      [newJob]="newJob"
      [stage]="stage"
      [runId]="runId"
    ></app-add-job-overview>
  }
</div>

<!-- Footer -->

<app-add-job-footer
  mat-dialog-actions
  [enableSubmitButton]="enableSubmitButton"
  [enableNextButton]="enableNextButton"
  [repeat]="repeat"
  (next)="jumpToStep($event)"
  (previous)="jumpToStep($event)"
  (repeatSteps)="jumpToStep($event)"
  (submitValues)="submitCreateJobForms()"
></app-add-job-footer>
