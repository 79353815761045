import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkDeleteStatus',
  standalone: true,
})
export class CheckDeleteStatusPipe implements PipeTransform {
  transform(
    deviceId: string,
    deletionResponse: DeviceDeletionResponse[] | null,
  ): number | string {
    const response = deletionResponse?.find(
      (r) => r.deviceId === deviceId || r.vhpcName === deviceId,
    );
    switch (response?.statusCode) {
      case 202:
        return 'Request accepted';
      default:
        return response?.message || '';
    }
  }
}

export interface DeviceDeletionResponse {
  statusCode: number;
  deviceId?: string;
  vhpcName?: string;
  message: string;
}
