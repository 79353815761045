<link href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Sharp"
rel="stylesheet"/>

<div class="help-container">
  <h2>{{ "Header.HelpMenu" | translate }}</h2>

  <button class="caedge-wiki" mat-flat-button *ngIf="displayCaedgeDocs">
    <span class="icon-and-title">
      <mat-icon fontSet="material-icons-sharp">library_books</mat-icon>
      <h4>CAEdge Docs</h4>
      <mat-icon class="right-arrow" fontSet="material-icons-sharp">keyboard_arrow_right</mat-icon>
    </span>
  </button>
  <mat-divider *ngIf="displayCaedgeDocs" />
  <div class="info-container">
    <mat-icon fontSet="material-icons-sharp" class="info-icon">info</mat-icon>
    <p class="info-text">
      {{ "HelpMenu.MainText" | translate }}
    </p>
  </div>
  <button
    data-cy="support-container"
    class="email-container icon-and-title" mat-flat-button *ngIf="currentTenant$ | async as currentTenant">
    <mat-icon class="email-icon" fontSet="material-icons-sharp">add_box</mat-icon>
    <a
      id="create-support-link"
      data-cy="create-support-button"
      href="{{currentTenant === tenants.PACCAR ? paccarSupport: hondaSupport}}"
      target="_blank"
    >
      <p>{{ "HelpMenu.CreateTicket" | translate }}</p>
    </a>
  </button>
</div>
