import { BackendDataProjectProperties, ProjectProperties } from './project-properties';

export interface ProjectConfigurationRequestBody {
    project: string;
}

export interface BackendDataProjectConfiguration {
    name: string;
    'full-name': string;
    folder: string;
    tenant: string;
    specific_class_stage1: boolean;
    specific_class_stage2: boolean;
    properties: BackendDataProjectProperties;
};

export interface AdjustedProjectConfiguration {
    name: string;
    fullName: string;
    folder: string;
    tenant: string;
    specificClassStage1: boolean;
    specificClassStage2: boolean;
    properties: ProjectProperties;
};

export class ProjectConfiguration implements AdjustedProjectConfiguration {
    name!: string;
    fullName!: string;
    folder!: string;
    tenant!: string;
    specificClassStage1!: boolean;
    specificClassStage2!: boolean;
    properties!: ProjectProperties;

    public static Factory(dataProjectConfiguration: BackendDataProjectConfiguration): ProjectConfiguration {
        const newProjectConfiguration = new ProjectConfiguration();
        newProjectConfiguration.name = dataProjectConfiguration.name;
        newProjectConfiguration.fullName = dataProjectConfiguration['full-name'];
        newProjectConfiguration.folder = dataProjectConfiguration.folder;
        newProjectConfiguration.tenant = dataProjectConfiguration.tenant;
        newProjectConfiguration.specificClassStage1 = dataProjectConfiguration.specific_class_stage1;
        newProjectConfiguration.specificClassStage2 = dataProjectConfiguration.specific_class_stage2;
        newProjectConfiguration.properties = ProjectProperties.Factory(dataProjectConfiguration.properties);
        return newProjectConfiguration;
    };
}
