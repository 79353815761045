import { Pipe, PipeTransform } from '@angular/core';
import { PipelineStatus } from '../../shared/stores/deployment/models/pipeline';
import { ChipVariant } from '../../shared/components/chip/chip.component';

@Pipe({
  name: 'pipelineEnvironmentStatus',
  standalone: true,
})
export class PipelineEnvironmentStatusPipe implements PipeTransform {
  transform(status: PipelineStatus | undefined): ChipVariant {
    switch (status) {
      case PipelineStatus.PIPELINE_CREATED:
      case PipelineStatus.PIPELINE_BUILD_SUCCESS:
        return ChipVariant.SUCCESS;
      case PipelineStatus.PIPELINE_IN_CREATION:
      case PipelineStatus.PIPELINE_BUILDING:
        return ChipVariant.SECONDARY;
      case PipelineStatus.PIPELINE_IN_DELETION:
        return ChipVariant.DISABLED;
      case PipelineStatus.PIPELINE_BUILD_FAILED:
      case PipelineStatus.NOT_FOUND:
        return ChipVariant.ERROR;
      default:
        return ChipVariant.INFO;
    }
  }
}
