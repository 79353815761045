<div class="footer">
  <div class="pagination">{{ currentStepIndex + 1 }}/{{ steps.length }}</div>
  <div class="actions" mat-dialog-actions align="end">
    <!-- Cancel button -->
    <button
      data-cy="update-workbench-cancel-btn"
      class="cancel"
      mat-flat-button
      mat-dialog-close
    >
      {{ "General.Cancel" | translate }}
    </button>

    <!-- Back button -->
    <button
      *ngIf="!isFirstStep()"
      class="pagination-button"
      (click)="prevStep()"
      mat-flat-button
    >
      {{ "General.Back" | translate }}
    </button>

    <!-- Button based on update type -->
    <button
      *ngIf="updateType === UpdateType.DEFAULT"
      data-cy="default-workbench-update-button"
      class="update-btn"
      mat-flat-button
      type="submit"
      (click)="defaultUpdate()"
    >
      {{ "Workspaces.UpdateWorkspaces.ScheduleUpdateBtn" | translate }}
    </button>

    <button
      *ngIf="updateType === UpdateType.SCHEDULE"
      data-cy="schedule-workbench-update-button"
      class="update-btn"
      mat-flat-button
      type="submit"
      (click)="scheduleUpdate()"
      [disabled]="!disabled"
    >
      {{ "Workspaces.UpdateWorkspaces.ScheduleUpdateBtn" | translate }}
    </button>

    <button
      *ngIf="updateType === UpdateType.IMMEDIATE"
      data-cy="immediate-workbench-update-button"
      class="immediate-update-btn"
      mat-flat-button
      type="submit"
      (click)="immediateUpdate()"
    >
      {{ "Workspaces.UpdateWorkspaces.ImmediateUpdateBtn" | translate }}
    </button>
  </div>
</div>
