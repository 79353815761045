export enum PipelineStatus {
  NOT_FOUND = 'NOT_FOUND',
  PIPELINE_IN_CREATION = 'PIPELINE_IN_CREATION',
  PIPELINE_CREATED = 'PIPELINE_CREATED',
  PIPELINE_IN_DELETION = 'PIPELINE_IN_DELETION',
  PIPELINE_BUILDING = 'PIPELINE_BUILDING',
  PIPELINE_BUILD_SUCCESS = 'PIPELINE_BUILD_SUCCESS',
  PIPELINE_BUILD_FAILED = 'PIPELINE_BUILD_FAILED',
}

export const PipelineStatusMap = {
  [PipelineStatus.NOT_FOUND]: 'not found',
  [PipelineStatus.PIPELINE_IN_CREATION]: 'in creation',
  [PipelineStatus.PIPELINE_CREATED]: 'created',
  [PipelineStatus.PIPELINE_IN_DELETION]: 'in deletion',
  [PipelineStatus.PIPELINE_BUILDING]: 'building',
  [PipelineStatus.PIPELINE_BUILD_SUCCESS]: 'success',
  [PipelineStatus.PIPELINE_BUILD_FAILED]: 'failed',
};

export interface IDataPipeline {
  app_name: string;
  version_id: string;
  pipeline_creation_request: boolean;
  pipeline_stop_request: boolean;
  pipeline_status: PipelineStatus;
  commit_id_of_last_build: string;
  pipeline_date: string;
}

export interface IPipeline {
  appName: string;
  versionId: string;
  pipelineCreationRequest: boolean;
  pipelineStopRequest: boolean;
  pipelineStatus: PipelineStatus;
  commitIdOfLastBuild: string;
  pipelineDate: Date | null;
}

export class Pipeline implements IPipeline {
  appName: string = '';
  versionId: string = '';
  pipelineCreationRequest: boolean = false;
  pipelineStopRequest: boolean = false;
  pipelineStatus: PipelineStatus = PipelineStatus.NOT_FOUND;
  commitIdOfLastBuild: string = '';
  pipelineDate: Date | null = null;

  public static Factory(data: IDataPipeline) {
    const pipeline = new Pipeline();
    pipeline.appName = data.app_name;
    pipeline.versionId = data.version_id;
    pipeline.pipelineCreationRequest = data.pipeline_creation_request;
    pipeline.pipelineStopRequest = data.pipeline_stop_request;
    pipeline.pipelineStatus = data.pipeline_status;
    pipeline.commitIdOfLastBuild = data.commit_id_of_last_build;
    pipeline.pipelineDate = new Date(data.pipeline_date);
    return pipeline;
  }
}
