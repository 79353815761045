<form class="form" [formGroup]="formGroup">
    <p>{{ "Playlists.AddStageDialogCommon.EnterInformation" | translate }}</p>
    <mat-form-field class="caedge-form-field-outline">
        <mat-label>{{ "Playlists.AddStageDialogCommon.SelectMasterCollection" | translate }}</mat-label>
        <mat-select
            data-cy="playlist-master-collection-select"
            [(value)]="selectedMasterCollection"
            (selectionChange)="onMasterCollectionSelected($event.value)"
        >
            <mat-option>{{ "Playlists.AddStageDialogCommon.None" | translate }}</mat-option>
            @for (collection of masterCollections; track collection) {
                <mat-option [value]="collection">{{ collection.name }}</mat-option>
            }
        </mat-select>
    </mat-form-field>

    <mat-form-field class="caedge-form-field-outline">
        <mat-label>{{ "Playlists.AddStageDialogCommon.SelectChildCollection" | translate }}</mat-label>
        <mat-select
            data-cy="playlist-child-collection-select"
            [(value)]="selectedChildCollection"
            [disabled]="selectedMasterCollection === ''"
            (selectionChange)="onChildCollectionSelected($event.value)"
        >
            <mat-option>{{ "Playlists.AddStageDialogCommon.None" | translate }}</mat-option>
            @for (childCollection of childCollections; track childCollection) {
                <mat-option [value]="childCollection">{{ childCollection.name }}</mat-option>
            }
        </mat-select>
        <mat-hint align="start" data-cy="create-device-dialog-name-hint">{{
            "Playlists.AddStageDialogCommon.CollectionSource" | translate
        }}</mat-hint>
    </mat-form-field>
    
    <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
        <mat-label>{{
            "Playlists.AddStageDialogCommon.PasteGuids" | translate
        }}</mat-label>
        <textarea
            data-cy="playlist-guid-textarea"
            matInput
            formControlName="stageOneGuids"
            rows="5"
        >
        </textarea>
        @if(stageOneGuidsControl.invalid) {
            <mat-error>
                {{ getErrorMessage(stageOneGuidsControl) }}
            </mat-error>
        }
        <mat-hint align="start" data-cy="create-device-dialog-name-hint">{{
            "Playlists.AddStageDialogCommon.SeparateByComma" | translate
        }}</mat-hint>
    </mat-form-field>
</form>
