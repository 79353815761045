import { FeatureConfig } from '../../../../shared/stores/config/models/featureConfig';
import { FeatureId } from '../../../../shared/stores/config/models/featureId';
import { AppConfigInstanceType } from '../../../../shared/stores/config/models/appConfigInstanceType';

export const Divider = {
  id: 'divider',
  type: AppConfigInstanceType.DIVIDER,
  path: '',
};

export const ProjectDashboardDetailsPage: Partial<FeatureConfig> = {
  id: '111222',
  featureId: FeatureId.PROJECT_DASHBOARD_DETAILS_PAGE,
  label: 'Dashboard',
  type: AppConfigInstanceType.FEATURE,
  icon: 'dashboard',
  path: 'project/:projectId/overview',
  API: {},
};

export const ProjectUserListPage: Partial<FeatureConfig> = {
  id: '222333',
  featureId: FeatureId.PROJECT_USER_LIST,
  label: 'Project team',
  type: AppConfigInstanceType.FEATURE,
  icon: 'supervised_user_circle',
  path: 'project/:projectId/project-team',
  API: {},
};
