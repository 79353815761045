import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationSettingsComponent } from '../../../project-dashboard/components/application-settings/application-settings.component';
import { CustomFormatDatePipe } from '../../../../core/pipes/custom-format-date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { CopyToClipboardService } from 'src/app/shared/utils/copy-to-clipboard.service';
import { TruncatePipe } from 'src/app/core/pipes/truncate.pipe';

@Component({
  selector: 'app-display-details',
  standalone: true,
  imports: [
    CommonModule,
    ApplicationSettingsComponent,
    CustomFormatDatePipe,
    TranslateModule,
    MatIconModule,
    TruncatePipe,
  ],
  templateUrl: './display-detail.component.html',
  styleUrls: ['./display-detail.component.scss'],
})
export class DisplayDetailComponent implements OnChanges {
  @Input({ required: true }) data: any;
  @Input() dataSecondColumn: any = undefined;
  @Input() displayCopyIcon: boolean = false;
  @Input() translationKey: string = '';
  @Input() dateFormat: string = 'MMMM d, y';

  entries: { key: string; value: any }[] = [];
  entriesSecondColumn: { key: string; value: any }[] = [];

  constructor(private copyToClipboardService: CopyToClipboardService) { }

  convertToKeyValues(obj: any): { key: string; value: any }[] {
    return Object.entries(obj).map(([key, value]) => ({ key, value }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.entries = this.convertToKeyValues(changes['data'].currentValue);
    }

    if (
      changes['dataSecondColumn'] &&
      changes['dataSecondColumn'].currentValue
    ) {
      this.entriesSecondColumn = this.convertToKeyValues(
        changes['dataSecondColumn'].currentValue,
      );
    }
  }

  copyToClipboard(data: string): void {
    this.copyToClipboardService.copyToClipboard(data);
  }
}
