<div class="update-dialogue-box" *ngIf="currentStepIndex === 0">
  <div
    class="dialogue-header"
    data-cy="update-workspace-title"
    mat-dialog-title
  >
    {{ "Workspaces.UpdateWorkspaces.Title" | translate }}
  </div>
  <div class="dialogue-subtitle" data-cy="update-workspace-subtitle">
    {{ "Workspaces.UpdateWorkspaces.SubTitle" | translate }}
  </div>
  <ng-container>
    <div class="update-options">
      <div class="update-option">
        <mat-icon class="mat-icon" data-cy="default-update-icon"
          >update</mat-icon
        >
        <div class="title" data-cy="default-update-title">
          {{ "Workspaces.UpdateWorkspaces.DefaultUpdate.Title" | translate }}
          <div class="description" data-cy="default-update-description">
            (Default setting)<br />{{
              "Workspaces.UpdateWorkspaces.DefaultUpdate.Description"
                | translate
            }}
          </div>
        </div>
        <mat-icon
          class="mat-icon-button"
          data-cy="default-update-btn"
          (click)="openDialog(UpdateType.DEFAULT)"
        >
          navigate_next
        </mat-icon>
      </div>
      <div class="update-option">
        <mat-icon class="mat-icon" data-cy="schedule-update-icon"
          >event</mat-icon
        >
        <div class="title" data-cy="schedule-update-title">
          {{ "Workspaces.UpdateWorkspaces.ScheduleUpdate.Title" | translate }}
          <div class="description" data-cy="schedule-update-description">
            {{
              "Workspaces.UpdateWorkspaces.ScheduleUpdate.Description"
                | translate
            }}
          </div>
        </div>
        <mat-icon
          class="mat-icon-button"
          data-cy="schedule-update-btn"
          (click)="openDialog(UpdateType.SCHEDULE)"
        >
          navigate_next
        </mat-icon>
      </div>
      <div class="update-option">
        <mat-icon class="mat-icon" data-cy="immediate-update-icon"
          >report_problem</mat-icon
        >
        <div class="title" data-cy="immediate-update-title">
          {{ "Workspaces.UpdateWorkspaces.ImmediateUpdate.Title" | translate }}
          <div class="description" data-cy="immediate-update-description">
            {{
              "Workspaces.UpdateWorkspaces.ImmediateUpdate.Description"
                | translate
            }}
          </div>
        </div>
        <mat-icon
          class="mat-icon-button"
          data-cy="immediate-update-btn"
          (click)="openDialog(UpdateType.IMMEDIATE)"
        >
          navigate_next
        </mat-icon>
      </div>
    </div>
  </ng-container>
</div>

@if (currentStepIndex === 1) {
  <app-update-workspace-steps
    [updateType]="dialogData.updateType"
    [instance]="dialogData.instance"
    (selectedDateChange)="onSelectedDateChange($event)"
  ></app-update-workspace-steps>
}

<app-update-workspace-footer
  [currentStepIndex]="currentStepIndex"
  (previous)="onPreviousClicked()"
  [updateType]="currentUpdateType"
  [disabled]="scheduleDate"
  (defaultUpdateClicked)="updateWorkspace(UpdateType.DEFAULT)"
  (scheduleUpdateClicked)="updateWorkspace(UpdateType.SCHEDULE)"
  (immediateUpdateClicked)="updateWorkspace(UpdateType.IMMEDIATE)"
></app-update-workspace-footer>
