<table class="list-container">
    <thead>
      <tr>
        <th>Name</th>
      </tr>
    </thead>
    <tbody>
        @for (vecu of vecus | async; track $index) {
            <tr>
                <td>{{ vecu.displayName }}</td>
            </tr>
        }
    </tbody>
</table>  
