import { Pipe, PipeTransform } from '@angular/core';
import { Application } from '../../../shared/stores/deployment/models/application';
import { convertToAppTypeLabel } from '../../../shared/utils/convertToAppTypeLabels';

@Pipe({
  name: 'convertToTypeLabels',
  standalone: true,
})
export class ConvertToTypeLabelsPipe implements PipeTransform {
  transform(app: Application) {
    const appTypeLabel = { appType: convertToAppTypeLabel(app.appType) };
    return { ...app, ...appTypeLabel };
  }
}
