import { Component, Inject } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormGroup } from '@angular/forms';
import { Option } from 'src/app/core/models/interfaces/option';
import { SourceType } from 'src/app/shared/models/sourceType';
import { PackageType } from 'src/app/shared/models//packageType';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Application } from 'src/app/shared/stores/deployment/models/application';
import { CustomFormatDatePipe } from 'src/app/core/pipes/custom-format-date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  standalone: true,
  selector: 'app-application-settings',
  templateUrl: './application-settings.component.html',
  styleUrls: ['./application-settings.component.scss'],
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    CustomFormatDatePipe,
    TranslateModule,
  ],
})
export class ApplicationSettingsComponent {
  sourceTypes: Option<string>[] = [
    {
      id: SourceType.ARTIFACTORY,
      label: 'Artifactory',
    },
    {
      id: SourceType.GITHUB,
      label: 'GitHub',
    },
  ];

  packageTypes: Option<string>[] = [
    {
      id: PackageType.SOURCE_CODE,
      label: 'Source Code',
    },
    {
      id: PackageType.CONAN,
      label: 'Conan',
    },
  ];

  appDetailsFormGroup: FormGroup = this.fb.group({
    applicationName: [
      {
        value: this.data.appName,
        disabled: true,
      },
    ],
    applicationType: [
      {
        value: this.data.appType,
        disabled: true,
      },
    ],
    applicationSource: [
      {
        value: this.data.appSource,
        disabled: true,
      },
    ],
    applicationPackage: [
      {
        value: this.data.appPackage,
        disabled: true,
      },
    ],
    applicationRepo: [
      {
        value: this.data.codeRepoName,
        disabled: true,
      },
    ],
  });

  repoNameTranslation = {
    [SourceType.GITHUB]: 'ApplicationDetails.GithubName',
    [SourceType.ARTIFACTORY]: 'ApplicationDetails.Artifactory',
  };

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Application
  ) {}
}
