<div class="header-sticky">
  <app-header-template
    itemName="Dashboard"
    [showBackButton]="false"
    data-cy="project-details-overview"
  ></app-header-template>
</div>
@defer (on idle) {
  @if(isProjectProcessedForDeletionSignal()) {
    <div class="deletion-chip-message">
      <app-chip
        [variant]="ChipVariant.ERROR"
        [icon]="['warning', 'left']"
        [label]="'Project.DeletionProcess' | translate"
        data-cy="project-details-dashboard-deletion-warning"
      ></app-chip>    
    </div>
  }
  @if (combinedData$ | async; as combinedData) {
    @if (combinedData.projectDetails) {
      <app-details-expansion
        data-cy="api-project-details"
        title="{{ 'ProjectDetails.Title' | translate }}"
        description="{{ 'ProjectDetails.EditByAdmin' | translate }}"
        [hideToggle]="true"
        [menuItems]="menuItems"
      >
      <app-project-tenant-details type="Project" [detailsContent]="combinedData.projectDetails"></app-project-tenant-details>

      </app-details-expansion>
      @if(isBlueprintEnabled) {
        <div class="myheader">
            <app-project-services-list [projectId]="combinedData.projectDetails.skey+'-'+combinedData.projectDetails.pkey">
            </app-project-services-list>
        </div>
        <mat-divider></mat-divider>
      }
      <div class="myheader">
        <app-header-template
          [showBackButton]="false"
          [isRowDisplayed]="false"
          translationKey="ProjectDetails.ProjectAdminsTitle"
          description="ProjectDetails.ProjectAdminsDescription"
        ></app-header-template>
      </div>
      <app-contacts-table
        data-cy="project-admins-list"
        [data]="combinedData.projectDetails.transformedContacts"
      ></app-contacts-table>
      <ng-container *ngTemplateOutlet="deleteProjectSection"></ng-container>
    } @else {
      <app-details-expansion
        data-cy="local-project-details"
        title="{{ 'ProjectDetails.Title' | translate }}"
        description="{{ 'ProjectDetails.EditByAdmin' | translate }}"
        [hideToggle]="true"
        [menuItems]="menuItems"
      >
        <app-display-details
          data-cy="project-details"
          translationKey="Project"
          [data]="project$ | async"
        ></app-display-details>
      </app-details-expansion>
      <ng-container *ngTemplateOutlet="deleteProjectSection"></ng-container>
    }
  }
} @loading (after 1ms; minimum 500ms) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }

<ng-template #deleteProjectSection>
  @if({ value: isUserOwnerOrCoOwner$ | async }; as isUserOwnerOrCoOwner) {
    <mat-divider></mat-divider>
    <div class="project-bottom-actions">
      <app-cae-button
        (clicked)="openDeleteProjectDialog()"
        [buttonText]="'Project.DeleteProjectDialog.OpenMainDialog' | translate"
        [icon]="'delete'"
        [color]="'warning'"
        [matTooltip]="'Project.DeleteProjectDialog.OpenMainDialogDisableMessage' | translate"
        [matTooltipDisabled]="isUserOwnerOrCoOwner.value"
        [disable]="!isUserOwnerOrCoOwner.value || isProjectProcessedForDeletionSignal()"
        data-cy="delete-project-button"
      ></app-cae-button>
    </div>
  }
  <mat-divider></mat-divider>
</ng-template>
