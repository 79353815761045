import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SideNavigationComponent } from '../../shared/layout/side-navigation/side-navigation.component';
import { HeaderComponent } from '../../shared/layout/header/header.component';

@Component({
  standalone: true,
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  imports: [RouterModule, SideNavigationComponent, HeaderComponent],
})
export class DashboardComponent {
  constructor() {}
}
