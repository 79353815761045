import { ApiRecord } from './apiRecord';
import { AppConfigInstanceType } from './appConfigInstanceType';
import { FeatureId } from './featureId';
import { FeatureSectionConfig } from './featureSectionConfig';
import { Divider } from './divider';

export interface FeatureConfig {
  id: string;
  featureId: FeatureId;
  label: string;
  path: string;
  icon: string;
  type: AppConfigInstanceType.FEATURE;
  isNested: boolean;
  children?: (FeatureConfig | FeatureSectionConfig | Divider)[];
  API?: Record<string, ApiRecord>;
}

//Type Guard for FeatureConfig[]
export function isFeatureConfigArray(object: any): object is FeatureConfig[] {
  return 'id' in object[0];
}
