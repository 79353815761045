@if (devices.length === 0) {
<app-empty-section [iconName]="EmptySectionIcon.COFFEE_MAKER" [emptySectionScope]="EmptySectionScope.PAGE">
  {{ "DeviceList.NoDevices" | translate }}
</app-empty-section>
} @else {
  <div data-cy="device-list-container" class="device-list-container">
    @for (device of devices; track device.deviceId) {
      <app-hdk-device-list-item
        [ngClass]="device.ui.color"
        [device]="device"
        [isInactive]="isInactive(device)"
        class="device-list-item"
        data-cy="device-list-item"
      >
      </app-hdk-device-list-item>
    }
  </div>
}
