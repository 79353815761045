<div class="root">
    <div class="sticky">
      <app-header-template
        translationKey="VecuList.VecuList"
        [showBackButton]="false"
      >
        <div data-cy="new-vecu-section" class="buttons">
          <app-cae-button
            data-cy="btn-bulk-deletion"
            color="primary"
            [attr.aria-label]="'DeviceList.BulkDeletion' | translate"
            [disable]="(selectedVecus$ | async)?.length === 0"
            (clicked)="openBulkDeletionDialog()"
          >
            {{ "General.Delete" | translate }}
          </app-cae-button>

          <app-cae-button
            data-cy="btn-create-vecu"
            [buttonText]="'VecuList.CreateVecu'"
            [disable]="quota.isQuotaExceeded"
            color="primary"
            (clicked)="openCreateVecuDialog()">
          </app-cae-button>

          <app-cae-button
            data-cy="btn-show-vecu-getting-started-page"
            [buttonText]="'VecuList.Help'"
            color="primary"
            [icon]="'emoji_objects'"
            (clicked)="showGettingStartedPage()">
          </app-cae-button>
        </div>

      </app-header-template>

      @if (featureFlagShowQuotaBar) {
        <app-quota-bar [quota]="quota"></app-quota-bar>
      }

      <app-function-bar
        [searchPlaceholder]="'DeviceList.Search'"
        [searchFilterControl]="searchFilterControl"
        (resetFilters)="handleResetFilters()"
        [showFiltersBtn]="checkActiveFiltersSignal()"
      >
        <div
          class="actions-container"
          filters
        >
          <div class="select-container">
            <label for="connection-status-select"
              >{{
                "DeviceList.FilterOptions.ConnectionStatus" | translate
              }}:</label
            >
            @if(deviceConnectionStatusData$ | async; as deviceConnectionStatusData) {
              <app-select-menu
                data-cy="connection-status-menu"
                id="connection-status-select"
                [options]="deviceConnectionStatusData.options"
                [selectedOption]="deviceConnectionStatusData.selectedOption"
                (optionSelected)="selectDeviceConnectionStatusOption($event)"
              ></app-select-menu>
            }
          </div>
          <div class="select-container">
            <label for="sort-select">
              {{ "General.SortBy" | translate }}
            </label>
            @if(sortByData$ | async; as sortByData) {
              <app-select-menu
                data-cy="sort-by-menu"
                id="sort-select"
                [options]="sortByData.options"
                [selectedOption]="sortByData.selectedOption"
                (optionSelected)="selectSortOption($event)"
              ></app-select-menu>
            }
          </div>
        </div>
        <div others></div>
      </app-function-bar>
    </div>
    @if(vecusData$ | async; as vecusData) {
      <app-content-wrapper
        data-cy="vecu-list-container"
        [isError]="vecusData.hasError"
        [isLoading]="vecusData.isLoading"
        [errorStatusCode]="vecusData.errorStatusCode"
        [emptySectionScope]="EmptySectionScope.PAGE"
      >
        <app-vecu-list-content
          [vecus]="vecusData.vecus"
        ></app-vecu-list-content>
      </app-content-wrapper>
    }
</div>

<ng-template #deleteMultipleVecusComponent>
  <app-delete-multiple-vecus></app-delete-multiple-vecus>
</ng-template>
