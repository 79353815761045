import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { UpdateWorkspaceFooterComponent } from '../update-workspace-footer/update-workspace-footer.component';
import { UpdateWorkspaceStepsComponent } from '../update-workspace-steps/update-workspace-steps.component';
import {
  UpdateType,
  UpdateWorkbenchRequestBody,
} from '../../models/update-workspace-request-body';
import { Instance } from 'src/app/shared/stores/workbenches/models/workbenches';

export interface UpdateWorkbenchData {
  instance: Instance;
}

interface DialogInstance {
  updateType: string;
  instance: Instance;
}

@Component({
  standalone: true,
  selector: 'app-update-workspace',
  templateUrl: './update-workspace.component.html',
  styleUrls: ['./update-workspace.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTooltipModule,
    UpdateWorkspaceFooterComponent,
    UpdateWorkspaceStepsComponent,
    MatDialogTitle,
  ],
})
export class UpdateWorkspaceComponent {
  UpdateType = UpdateType;
  currentStepIndex = 0;
  dialogData: DialogInstance = {} as DialogInstance;
  scheduleDate: string = '';
  currentUpdateType: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UpdateWorkbenchData,
    public dialogRef: MatDialogRef<UpdateWorkspaceComponent, DialogCloseData>,
  ) {}

  onSelectedDateChange(selectedDate: string) {
    this.scheduleDate = selectedDate;
  }

  onPreviousClicked() {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
      this.currentUpdateType = '';
    }
  }

  setCurrentUpdateType(updateType: string) {
    this.currentUpdateType = updateType;
  }

  openDialog(updateType: string) {
    this.dialogData = {
      updateType,
      instance: this.data.instance,
    };
    this.setCurrentUpdateType(updateType);
    this.currentStepIndex++;
  }

  // Method to handle the default update
  defaultUpdate(): UpdateWorkbenchRequestBody {
    return {
      workbenchId: this.data.instance.id,
      updateType: UpdateType.SCHEDULE,
      scheduleDate: '',
    };
  }

  // Method to handle the scheduled update
  scheduleUpdate(): UpdateWorkbenchRequestBody {
    if (isNaN(Date.parse(this.scheduleDate))) {
      throw new Error('Invalid date: ' + this.scheduleDate);
    }

    return {
      workbenchId: this.data.instance.id,
      updateType: this.currentUpdateType,
      scheduleDate: new Date(this.scheduleDate).toISOString().split('T')[0],
    };
  }

  // Method to handle the immediate update
  immediateUpdate(): UpdateWorkbenchRequestBody {
    return {
      workbenchId: this.data.instance.id,
      updateType: this.currentUpdateType,
      scheduleDate: '',
    };
  }

  // Method to handle the API call
  callUpdateService(requestBody: UpdateWorkbenchRequestBody): void {
    const data = {
      requestBody: requestBody,
      request: true,
    };
    this.dialogRef.close(data);
  }

  // The main method that uses the above methods
  updateWorkspace(updateType: UpdateType): void {
    let requestBody: UpdateWorkbenchRequestBody;

    try {
      switch (updateType) {
        case UpdateType.DEFAULT:
          requestBody = this.defaultUpdate();
          break;
        case UpdateType.SCHEDULE:
          requestBody = this.scheduleUpdate();
          break;
        case UpdateType.IMMEDIATE:
          requestBody = this.immediateUpdate();
          break;
      }

      this.callUpdateService(requestBody);
    } catch (error) {
      console.error('An error occurred when updating the workspace:', error);
    }
  }
}

export interface DialogCloseData {
  requestBody: UpdateWorkbenchRequestBody;
  request: boolean;
}
