<p>Select input data set(s)</p>
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <li class="mat-tree-node" *ngIf="node.id">
      <!-- Use a checkbox for selection, display the URL for leaf nodes -->
      <mat-checkbox [checked]="node.selected" (change)="selectNode(node)">
        <mat-icon class="material-symbols-sharp" *ngIf="node.isLeaf">
          eco
        </mat-icon>
        {{ node.name }}
      </mat-checkbox>
    </li>
  </mat-tree-node>

  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node
    *matTreeNodeDef="let node; when: hasChild"
    matTreeNodeToggle
    matTreeNodePadding
  >
    <li class="mat-tree-node">
      <div class="mat-tree-node">
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'toggle ' + node.name"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{
              treeControl.isExpanded(node) ? "arrow_drop_down" : "arrow_right"
            }}
          </mat-icon>
        </button>
        Root {{ node.name }}
      </div>
      <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-tree-node>
</mat-tree>
