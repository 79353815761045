<mat-expansion-panel
  class="deployment mat-elevation-z0"
  (opened)="loadDeviceData()"
  [expanded]="expandListItem(deployments.dev)"
  *ngIf="version"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="version-id-col col">
        <p>VERSION: {{ version.versionReference }}</p>
        <p data-cy="deployment-list-version-id">ID: {{ version.versionId }}</p>
      </div>
      <ng-container>
        <div class="pipeline-status-col col">
          @if (version.executionStatus !== pipelineStatus.NOT_FOUND) {
            <app-chip
              data-cy="pipelineStatus"
              [label]="version.executionStatus | pipelineStatus"
              [variant]="version.executionStatus | pipelineEnvironmentStatus"
            ></app-chip>
          }
          <div class="pipeline-actions">
            @switch (version.executionStatus) {
              @case (pipelineStatus.PIPELINE_IN_CREATION) {
                <mat-spinner
                  class="spinner"
                  [diameter]="loadingSpinnerDiameter"
                ></mat-spinner>
              }
              @case (pipelineStatus.PIPELINE_BUILD_SUCCESS) {
                <ng-container *ngTemplateOutlet="rebuildButton"></ng-container>
              }
              @case (pipelineStatus.PIPELINE_BUILD_FAILED) {
                <ng-container *ngTemplateOutlet="rebuildButton"></ng-container>
              }
              @case (pipelineStatus.PIPELINE_BUILDING) {
                <ng-container *ngTemplateOutlet="stopBuild"></ng-container>
              }
              @case (pipelineStatus.PIPELINE_IN_DELETION) {
                <mat-spinner
                  class="spinner"
                  [diameter]="loadingSpinnerDiameter"
                ></mat-spinner>
              }
            }
            <ng-template #stopBuild>
              <mat-spinner class="spinner" diameter="16"></mat-spinner>
              <button
                mat-icon-button
                [matTooltip]="'Deployments.StopBuild' | translate"
                [attr.aria-label]="'Deployments.StopBuild' | translate"
                (click)="pipelineBuildRequest($event, false)"
              >
                <mat-icon>stop</mat-icon>
              </button>
            </ng-template>
            <ng-template #rebuildButton>
              <button
                mat-icon-button
                data-cy="rebuildButton"
                [matTooltip]="'Deployments.RebuildPipeline' | translate"
                [attr.aria-label]="'Deployments.RebuildPipeline' | translate"
                (click)="pipelineBuildRequest($event, true)"
              >
                <mat-icon>rotate_left</mat-icon>
              </button>
            </ng-template>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <div class="actions-col col">
          <div class="start-deployment-col">
            <button
              class="add-deployment"
              data-cy="add-deployment-version"
              mat-flat-button
              (click)="startDeploymentClick($event)"
              [disabled]="disableDeployment(version.executionStatus)"
            >
              <mat-icon>add</mat-icon>
              {{ "HDK.NewDeployment" | translate }}
            </button>
          </div>
        </div>
        <div class="menu-col">
          <button
            data-cy="version-menu"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [attr.aria-label]="'HDK.MenuTriggerButton' | translate"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu class="caedge-menu" #menu="matMenu">
            <a
              class="caedge-menu-item"
              data-cy="repository-link-button"
              mat-menu-item
              [href]="version.repositoryUrl"
              [disabled]="!version.repositoryUrl.length"
              target="_blank"
            >
              @if (
                application.appType === ApplicationType.SINGLE_SERVICE
              ) {
                {{ "Deployments.GoToRepository" | translate }}
              } @else {
                {{ "Deployments.GoToArtifactory" | translate }}
              }
            </a>
            <button
              class="caedge-menu-item"
              data-cy="delete-version-button"
              mat-menu-item
              [disabled]="versionHasDeployments()"
              (click)="openDeleteVersionDialog()"
            >
              <span>{{
                "Deployments.DeleteVersionDialog.Confirm" | translate
              }}</span>
            </button>
          </mat-menu>
        </div>
      </ng-container>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-container>
    <div
      class="expansion-content"
      *ngIf="onDeployedDevice | async as deviceData"
    >
      <app-content-wrapper
        class="deployment-container"
        [isError]="deviceData.hasError"
        [isLoading]="deviceData.isLoading"
        [errorMessage]="'DeploymentList.DeploymentListError' | translate"
      >
        <div *ngIf="deviceData.data.length > 0; else noDevices">
          <app-list-item
            *ngFor="let hardware of deviceData.data; trackBy: trackByName"
            [device]="hardware"
            [deployments]="deployments"
            [version]="version"
            [appName]="application.appName"
            class="list-wrapper"
          ></app-list-item>
        </div>
        <ng-template #noDevices>
          <p class="no-devices">
            {{ "Deployments.StartYourDeployment" | translate }}
          </p>
        </ng-template>
      </app-content-wrapper>
    </div>
  </ng-container>
</mat-expansion-panel>
