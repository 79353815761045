import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { DeviceDeletionResponse } from '../../../../../../shared/pipes/check-delete-status.pipe';

@Injectable({
  providedIn: 'root',
})
export class SelectedDeviceService {
  private readonly localStorageKey = 'selectedDeviceIds';
  private _devices = new BehaviorSubject<Device[]>([]);
  private _loading = new BehaviorSubject<boolean>(false);
  private _deleteDeviceResponse = new BehaviorSubject<DeviceDeletionResponse[]>(
    [],
  );

  get devices$() {
    return this._devices.asObservable();
  }

  get loading$() {
    return this._loading.asObservable();
  }

  get deleteDeviceResponse$() {
    return this._deleteDeviceResponse.asObservable();
  }

  loadSelectedDevices(devices: Device[]) {
    const savedDeviceIds = localStorage.getItem(this.localStorageKey);
    if (savedDeviceIds) {
      const deviceIds = JSON.parse(savedDeviceIds);
      const selectedDevices = devices.filter((device) =>
        deviceIds.includes(device.deviceId),
      );
      this._devices.next(selectedDevices);
    }
  }

  isSelected(device: Device): boolean {
    return this._devices.getValue().includes(device);
  }

  selectDevice(device: Device) {
    const currentDevices = this._devices.getValue();
    const index = currentDevices.indexOf(device);
    if (index === -1) {
      this._devices.next([...currentDevices, device]);
    } else {
      this._devices.next(currentDevices.filter((d) => d !== device));
    }
    this.saveSelectedDevices();
  }

  clearSelectedDevices() {
    this._devices.next([]);
    this._loading.next(false);
    this._deleteDeviceResponse.next([]);
    localStorage.removeItem(this.localStorageKey);
  }

  clearDeleteDeviceResponse() {
    this._deleteDeviceResponse.next([]);
    this._loading.next(false);
  }

  get selectedDevices(): Device[] {
    return this._devices.getValue();
  }

  private saveSelectedDevices() {
    const deviceIds = this._devices.getValue().map((device) => device.deviceId);
    localStorage.setItem(this.localStorageKey, JSON.stringify(deviceIds));
  }

  setLoading(loading: boolean) {
    this._loading.next(loading);
  }

  setDeleteDeviceResponse(response: DeviceDeletionResponse[]) {
    this._deleteDeviceResponse.next(response);
  }
}
