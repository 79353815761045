import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-details-expansion',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    TranslateModule,
    MatDividerModule,
  ],
  templateUrl: './details-expansion.component.html',
  styleUrls: ['./details-expansion.component.scss'],
})
export class DetailsExpansionComponent {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() expanded?: boolean = true;
  @Input() hideToggle?: boolean = false;
  @Input() menuItems?: MenuItem[] = [];
  @Input() hideMenu?: boolean = false;
  @Input() hideDivider?: boolean = false;
  @Output() eventEmitter: EventEmitter<MenuCommand> = new EventEmitter();
}

export interface MenuItem {
  translationKey: string;
  command: MenuCommand;
  disabled?: boolean;
}

export enum MenuCommand {
  ADD = 'Add',
  DELETE = 'Delete',
  EDIT = 'Edit',
}
