import { Injectable } from '@angular/core';
import { ProjectUser, User } from 'src/app/shared/components/user-list-components/models/user';
import { Option } from 'src/app/core/models/interfaces/option';
import { AlphabeticalOrderId } from 'src/app/core/models/enums/alphabeticalOrder';

@Injectable({
  providedIn: 'root',
})
export class UserListService {
  constructor() {}

  projectUserFilterMap = ([users, searchFilter, selectedAlphabetOrder]: [
    ProjectUser[],
    string,
    Option<AlphabeticalOrderId.AZ | AlphabeticalOrderId.ZA>,
  ]): ProjectUser[] => {
    return users
      .filter((user) => this.filterUser(user, searchFilter))
      .sort(this.alphabeticalOrdering(selectedAlphabetOrder));
  };

  userFilterMap = ([users, searchFilter, selectedAlphabetOrder]: [
    User[],
    string,
    Option<AlphabeticalOrderId.AZ | AlphabeticalOrderId.ZA>,
  ]): User[] => {
    return users
      .filter((user) => this.filterUser(user, searchFilter))
      .sort(this.alphabeticalOrdering(selectedAlphabetOrder));
  };

  filterUser = (user: ProjectUser | User, searchFilter: string): boolean => {
    const firstName = user.firstName.toLowerCase();
    const lastName = user.lastName.toLowerCase();
    const email = user.email.toLowerCase();
    const fullName = firstName + lastName;
    const reverseFullName = lastName + firstName;
    const search = searchFilter.toLowerCase();

    const searchResult = [
      firstName,
      lastName,
      email,
      fullName,
      reverseFullName,
    ].some((input) => {
      return input.includes(search.replace(/\s/g, '').trim());
    });
    return searchResult;
  };

  alphabeticalOrdering = (
    selectedAlphabetOrder: Option<
      AlphabeticalOrderId.AZ | AlphabeticalOrderId.ZA
    >,
  ): ((a: User, b: User) => number) => {
    switch (selectedAlphabetOrder.id) {
      case AlphabeticalOrderId.AZ:
        return (a: User, b: User) =>
          a.firstName.localeCompare(b.firstName, 'en');
      case AlphabeticalOrderId.ZA:
        return (a: User, b: User) =>
          b.firstName.localeCompare(a.firstName, 'en');
    }
  };
}
