<div class="fields-container" [formGroup]="appDetailsFormGroup">
  <ng-container>
    <h3 class="section-heading">
      {{ "ApplicationDetails.SectionTitle" | translate }}
    </h3>
    <p class="section-info">
      {{ "ApplicationDetails.SectionInfo" | translate }}
    </p>
  </ng-container>
  <mat-form-field class="project-dashboard-input caedge-form-field-outline">
    <mat-label>{{ "ApplicationDetails.Name" | translate }}</mat-label>
    <input formControlName="applicationName" matInput />
    <mat-hint align="end"
      >{{ appDetailsFormGroup.value?.applicationName?.length }}/100</mat-hint
    >
  </mat-form-field>
  <mat-form-field class="caedge-form-field-outline">
    <mat-label>{{ "ApplicationDetails.Type" | translate }}</mat-label>
    <input formControlName="applicationType" matInput />
  </mat-form-field>
  <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
    <mat-label>{{ "ApplicationDetails.Source" | translate }}</mat-label>
    <mat-select formControlName="applicationSource">
      <mat-option *ngFor="let source of sourceTypes" [value]="source.id">
        {{ source.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
    <mat-label>{{ "ApplicationDetails.Package" | translate }}</mat-label>
    <mat-select formControlName="applicationPackage">
      <mat-option *ngFor="let item of packageTypes" [value]="item.id">
        {{ item.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
    <mat-label>{{ repoNameTranslation[data.appSource] | translate }}</mat-label>
    <input formControlName="applicationRepo" matInput />
    <mat-hint align="end"
      >{{ appDetailsFormGroup.value?.applicationRepo?.length }}/100</mat-hint
    >
  </mat-form-field>
  <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
    <mat-label>{{ "ApplicationDetails.Date" | translate }}</mat-label>
    <input matInput disabled [value]="data.creationDate | customFormatDate" />
  </mat-form-field>
</div>
