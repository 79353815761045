import { Pipe, PipeTransform } from '@angular/core';
import { VecuActions, VecuUiStates } from '../models/vecu';

@Pipe({
  name: 'checkActionStatus',
  standalone: true,
})
export class CheckActionStatusPipe implements PipeTransform {
  transform(
    vecuStateUi: string,
    actionInitiated: boolean,
    command: VecuActions
  ): boolean {
    switch(command) {
      case VecuActions.START:
        return vecuStateUi === VecuUiStates.INACTIVE && actionInitiated === false;
      case VecuActions.STOP:
        return vecuStateUi === VecuUiStates.ACTIVE && actionInitiated === false;
    }
  }
}
