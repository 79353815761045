import { Injectable } from '@angular/core';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { SelectedVecuService } from '../selected-vecu-service/selected-vecu.service';
import { VecuApiService } from '../vecu-api-service/vecu-api.service';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { VecuListApi } from '../../vecu-list.component';
import { Vecu, VecuBulkDeletionResponse } from '../../models/vecu';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class VecuDeletionService {

  constructor(
    private vecuApiService: VecuApiService,
    private selectedVecuService: SelectedVecuService,
    private snackbarService: SnackbarService,
    private translateService: TranslateService
  ) { }

  handleVecuDeletion(
    API: Record<VecuListApi, ApiRecord> | undefined,
  ): void {
    if (!API) return;

    const vecuDeletionObservable: Observable<VecuBulkDeletionResponse> = this.createDeletionObservables(API);


    vecuDeletionObservable.subscribe({
      next: () => {
        this.snackbarService.notifyInfo(
          this.translateService.instant('VecuList.BulkDeletionInitialized')
        );
        this.clearSelection();
      },
      error: () => {
        this.snackbarService.notifyError(
          this.translateService.instant('VecuList.BulkDeletionFailed')
        );
      },
    });
  }

  private createDeletionObservables(API: Record<VecuListApi, ApiRecord>): Observable<VecuBulkDeletionResponse> {
    const selectedVecuNames: string[] = this.selectedVecuService.selectedVecus.map((vecu: Vecu) => vecu.displayName);

    const vecuDeletionObservable$: Observable<VecuBulkDeletionResponse> = selectedVecuNames.length
      ? this.vecuApiService.deleteVecus(
        API.deleteVecu,
        selectedVecuNames,
      )
      : of({
        bulkDeletionStatus: [],
      });

    return vecuDeletionObservable$;
  }

  clearSelection() {
    this.selectedVecuService.clearSelectedVecus();
  }
}
