<form [formGroup]="deleteProjectFormGroup" data-cy="delete-project-dialog">
  <h1 mat-dialog-title> {{ "Project.DeleteProjectDialog.MainDialog.Title" | translate }} </h1>
  <div mat-dialog-content class="dialog-main-container">
    <mat-checkbox formControlName="deleteProjectCheckbox" data-cy="delete-project-checkbox">
      {{ 'Project.DeleteProjectDialog.MainDialog.CheckBoxText' | translate }}
    </mat-checkbox>
    <p>
      {{ 'Project.DeleteProjectDialog.MainDialog.ProjectIDText' | translate }} <b> {{ projectId }} </b>
    </p>
    <mat-form-field
      class="caedge-form-field-outline"
      ngDefaultControl>
      <input
        matInput
        formControlName="projectName"
        data-cy="delete-project-id-input"
      />
      <mat-label> {{ "Project.DeleteProjectDialog.MainDialog.ProjectID" | translate }} </mat-label>
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end">
    <button
      mat-flat-button
      (click)="closeDialogEmission()"
      data-cy="delete-project-dialog-cancel-button"
    > {{ 'General.Cancel' | translate }} </button>
    <button
      class="dialog-delete-project-button"
      mat-flat-button
      [disabled]="!deleteProjectFormGroup.valid"
      (click)="deleteProjectEmission()"
      data-cy="delete-project-dialog-confirm-button"
    > {{ "Project.DeleteProjectDialog.MainDialog.ConfirmText" | translate }} 
    </button>
  </div>
</form>

<ng-template #deletionHint>
  <mat-hint> {{ "Project.DeleteProjectDialog.SecondDialog.HintText" | translate }} </mat-hint>
</ng-template>
