import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  signal,
  OnDestroy,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  FormControl,
  FormGroup,
  FormsModule,
  FormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { StepComponent } from '../../models/stepComponent';
import { Subject, take, takeUntil, tap } from 'rxjs';

/**
 * `StepComponent` implementation for the general information step of the project creation process.
 * @export
 * @class GeneralInformationComponent
 */
@Component({
  selector: 'app-general-information',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
})
export class GeneralInformationComponent
  implements OnInit, OnDestroy, StepComponent
{
  @Input() numCharsTenant = 0;

  @Output() validationChange = new EventEmitter<any>();

  private isValid = false;
  unsubscribe$: Subject<void> = new Subject<void>();

  formGroup: FormGroup = this.fb.group({
    projectName: [
      '',
      [Validators.required, Validators.pattern('[a-zA-Z0-9-]*')],
    ],
    projectDescription: [''],
  });

  projectNameMaxLength = 0;

  readonly minCharsWB = 8;
  private readonly charsLeft = 24;

  charsAvailable = signal(10);

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
  ) {}

  get projectNameControl() {
    return this.formGroup.get('projectName') as FormControl;
  }

  get dataToUpdate() {
    const formValue = this.formGroup.getRawValue();
    return {
      Description: formValue.projectDescription,
      Name: formValue.projectName,
    };
  }

  get stepValid(): boolean {
    if (this.formGroup.valid !== this.isValid) {
      this.isValid = this.formGroup.valid;
      this.validationChange.emit();
    }
    return this.isValid;
  }

  ngOnInit(): void {
    this.projectNameMaxLength = this.charsLeft - this.numCharsTenant;
    this.projectNameControl.addValidators(
      Validators.maxLength(this.projectNameMaxLength - this.minCharsWB),
    );
    this.formGroup.updateValueAndValidity();
    this.initProjectNameControl();
  }

  private initProjectNameControl() {
    this.projectNameControl.valueChanges
      .pipe(
        take(1),
        tap(() => {
          this.projectNameControl.markAsTouched();
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  updateCharsAvailable(): void {
    let charsleft =
      this.projectNameMaxLength -
      this.projectNameControl.value.length -
      this.minCharsWB;
    this.charsAvailable.set(charsleft);
  }

  /**
   * Checks if the given FormControl is invalid and returns the corresponding error message.
   *
   * @param formControl the FormControl to validate
   * @return the corresponding error message or an empty string in case there is no error
   */
  getErrorMessage(formControl: FormControl | null): string {
    if (formControl) {
      if (formControl.hasError('required')) {
        return this.translate.instant(
          'Project.AddProjectDialog.ErrorMessage.Required',
        );
      }
      if (formControl.hasError('pattern')) {
        return this.translate.instant(
          'Project.AddProjectDialog.ErrorMessage.Pattern',
        );
      }
      if (formControl.hasError('maxlength')) {
        return this.translate.instant(
          'Project.AddProjectDialog.ErrorMessage.MaxLengthProjectName',
        );
      }
    }
    return '';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
