import { Injectable } from '@angular/core';
import { Store } from '../../../core/models/classes/abstract.store';
import { Deployment } from './models/deployment';
import { IState } from '../State';
import { IAllEnvironment } from '../../../features/vecu-ami/components/deployment-list/components/deployment-list-expansion-panel/deployment-list-expansion-panel.component';

@Injectable({
  providedIn: 'root'
})
export class DeploymentsStore extends Store<IState<IAllEnvironment<Deployment[]>>> {
  constructor() {
    super({
      data: {
        dev: []
      },
      isLoading: true,
      hasError: false
    });
  }
}
