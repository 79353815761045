<div class="restricted-input-container" data-cy="restricted-input-container">
  <div
    [ngClass]="{
      content: hasFieldValue,
      'no-value-content': !hasFieldValue
    }"
  >
    @if (hasApplicationPatternFailed !== undefined) {
      <div class="icon-and-text" data-cy="application-pattern-title">
        <p>{{ "InputRestrictions.Application" | translate }}</p>
      </div>
      <div data-cy="application-pattern-container">
        <ul *ngFor="let item of applicationPattern">
          <div 
            [ngClass]="{
              error: item.value,
              success: !item.value,
              'hidden-icon': !hasFieldValue
            }"
            data-cy="restrictor-icon"
          >
            @if (item.value) {
              <mat-icon data-cy="application-failed-mark">close</mat-icon>
            } @else {
              <mat-icon data-cy="application-check-mark">check</mat-icon>
            }
            <p>{{ item.name | translate }}</p>
          </div>
        </ul>
      </div>
    }

    @if (hasDevicePatternFailed !== undefined) {
      <div class="icon-and-text" data-cy="device-pattern-title">
        <p>{{ "InputRestrictions.Device" | translate }}</p>
      </div>
      <div data-cy="device-pattern-container">
      <ul *ngFor="let item of devicePattern">
        <div
          [ngClass]="{
            error: item.value,
            success: !item.value,
            'hidden-icon': !hasFieldValue
          }"
          data-cy="device-icon-restrictor-helper"
        >
          @if (item.value) {
            <mat-icon data-cy="device-failed-mark">close</mat-icon>
          } @else {
            <mat-icon data-cy="device-check-mark">check</mat-icon>
          }
          <p>{{ item.name | translate }}</p>
        </div>
      </ul>
    </div>
    }

    @if (hasVersionPatternFailed !== undefined) {
      <div class="icon-and-text" data-cy="version-pattern-container">
        <p>{{ "InputRestrictions.Version" | translate }}</p>
      </div>
      <ul *ngFor="let item of versionPattern">
        <div
          [ngClass]="{
            error: item.value,
            success: !item.value,
            'hidden-icon': !hasFieldValue
          }"
          data-cy="restrictor-icon"
        >
          @if (item.value) {
            <mat-icon data-cy="version-failed-mark">close</mat-icon>
          } @else {
            <mat-icon data-cy="version-check-mark">check</mat-icon>
          }
          <p>{{ item.name | translate }}</p>
        </div>
      </ul>
    }

    @if (hasNameRestrictionFailed !== undefined) {
      <div class="icon-and-text" data-cy="restricted-naming-title">
        <p>{{ "InputRestrictions.CannotStartWith" | translate }}</p>
      </div>
      <div  data-cy="restricted-naming-container" class="list">
        <ul *ngFor="let item of restrictedNames">
          <div>
            <div
              [ngClass]="{
                error: item.value,
                success: !item.value,
                'hidden-icon': !hasFieldValue
              }"
              data-cy="restrictor-icon"
              class="icon-and-text"
            >
              @if (item.value) {
                <mat-icon data-cy="restrictor-failed-mark">close</mat-icon>
              } @else {
                <mat-icon data-cy="restrictor-check-mark">check</mat-icon>
              }
              <p>{{ item.name }}</p>
            </div>
          </div>
        </ul>
      </div>
    }
  </div>
</div>
